import React from 'react';
import { EmblaOptionsType } from 'embla-carousel-react';
import { AutoplayOptionsType } from 'embla-carousel-autoplay/components/Options';
import { SliderFrame } from './SliderFrame';
import { SliderFrameWithAutoplay } from './SliderFrameWithAutoplay';
import { SharedSliderProps } from './SliderMarkup';

interface SliderProps extends SharedSliderProps {
	children?: React.ReactElement | React.ReactElement[];
	startIndex?: number;
	autoPlaySpeed?: number;
	loop?: boolean;
}

/**
 * Generic Slider component, used for handling all sliders on site (hero banners,
 * product sliders, product galleries, fullscreen galleries, all of it).
 */
export const Slider: React.FunctionComponent<SliderProps> = ({
	startIndex,
	autoPlaySpeed,
	loop,
	...props // For passing SharedSliderProps
}) => {
	const sliderOptions: EmblaOptionsType = {
		align: 'start',
		containScroll: !loop && props.slidesWidth ? 'trimSnaps' : '',
		loop: loop || undefined,
		speed: 15,
		startIndex: startIndex || 0,
		draggable: props.transitionType !== 'fade',
	};

	const autoplayOptions: AutoplayOptionsType = {
		delay: autoPlaySpeed || 7000,
		stopOnMouseEnter: false,
		stopOnLastSnap: false,
		stopOnInteraction: false,
	};

	// TODO:
	//  - Add lazy-loading to images at a later point:
	//    Demo: https://codesandbox.io/s/embla-carousel-lazyload-react-z7xiq?file=/src/js/EmblaCarousel.js

	if (autoPlaySpeed) {
		return <SliderFrameWithAutoplay sliderOptions={sliderOptions} autoplayOptions={autoplayOptions} {...props} />;
	}

	return <SliderFrame sliderOptions={sliderOptions} {...props} />;
};
