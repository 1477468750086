import React from 'react';
import classNames from 'classnames';
import { ColumnType } from '../context/SizeSplitContext';
import styles from './SizeSplitHeader.module.scss';

export interface SizeSplitHeaderProps<T = string> {
	columns: ColumnType<T>[];
	id: string;
	isRowHeader: (column: ColumnType<T>) => boolean;
	isColumnHeader: (column: ColumnType<T>) => boolean;
}

export const SizeSplitHeader: React.FunctionComponent<SizeSplitHeaderProps> = ({
	columns,
	id,
	isRowHeader,
	isColumnHeader,
}) => {
	return (
		<div className={styles.tableHead} role="row">
			{columns.map((column, index) => (
				<div
					key={`${id}-header-column-${column.header}-${index}`}
					className={classNames(styles.tableCell, styles.tableColumnHeader, {
						[styles.columnHeader]: isColumnHeader(column),
						[styles.rowHeader]: isRowHeader(column),
						[styles.doubleCell]: column.subheader,
					})}
				>
					<span
						role="columnheader"
						className={classNames(styles.headerCell)}
						id={`${id}-column-${column.header}`}
					>
						{column.header}
					</span>
					{column.subheader && (
						<span
							role="columnheader"
							className={classNames(styles.headerCell, styles.rowHeader)}
							id={`${id}-column-subheader-${column.key}`}
						>
							{column.subheader}
						</span>
					)}
				</div>
			))}
		</div>
	);
};
