import React from 'react';
import classNames from 'classnames';
import { InputCheckbox } from 'components/shared/InputCheckbox';
import { SearchBar } from 'components/shared/SearchBar';
import { FilterResponse, FilterValueResponse } from 'generated/data-contracts';
import useInfiniteScroll from 'helpers/useInfiniteScroll';
import { useViewportSize } from 'helpers/useViewportSize';
import styles from './CheckboxFilter.module.scss';

interface CheckboxFilterProps extends FilterResponse {
	checkboxClassName?: string;
	onCheckboxChange?: (e) => void;
	fullScreen?: boolean;
	uniqueFilterId?: string;

	// Filter values
	search?: {
		searchText: string;
		setSearchText: (searchText: string) => void;
		clearSearchText: () => void;
	};
	hasNextPage?: boolean;
	loadMore?: () => void;
}

export const CheckboxFilter: React.FunctionComponent<CheckboxFilterProps> = ({
	name,
	values,
	checkboxClassName,
	onCheckboxChange,
	uniqueFilterId,
	fullScreen,
	hasNextPage,
	loadMore,
	search,
}) => {
	const [filteredValues, setFilteredValues] = React.useState<FilterValueResponse[]>(values || []);

	const { isMobile } = useViewportSize();

	// Reset search & filteredValues, when values change
	React.useEffect(() => {
		if (!values) return;

		setFilteredValues(values);
	}, [values]);

	const renderLabel = (item: FilterValueResponse): React.ReactNode => (
		<div className={styles.checkboxLabel}>
			<span>{item.label}</span>
			{(item.hitCount ?? 0) > 0 && <span className={styles.hitCount}>({item.hitCount})</span>}
		</div>
	);

	const infinityScrollRef = useInfiniteScroll<HTMLDivElement>(() => {
		if (!loadMore || !hasNextPage) return;
		loadMore();
	});
	return (
		<div
			className={classNames(styles.scrollWrapper, 'u-scroll-wrapper-y', {
				[styles.isFullScreen]: fullScreen,
			})}
		>
			{search && (
				<SearchBar
					className={styles.searchBar}
					value={search.searchText}
					onChange={(e): void => search.setSearchText(e.target.value)}
					onClear={search.clearSearchText}
				/>
			)}
			<div
				className={classNames(styles.innerWrapper, {
					[styles.desktopLimitHeight]: !isMobile,
				})}
			>
				{filteredValues?.map((item, i) => (
					<InputCheckbox
						className={checkboxClassName}
						label={renderLabel(item)}
						id={name + item.value + uniqueFilterId}
						name={item.value || ''}
						checked={item.isSelected || false}
						onChange={(e): void => {
							if (onCheckboxChange) {
								onCheckboxChange(e);
							}
						}}
						key={name + item.value + i}
					/>
				))}
				<div ref={infinityScrollRef} />
			</div>
		</div>
	);
};
