import React from 'react';
import classNames from 'classnames';
import { SplashResponse, SplashType } from 'generated/data-contracts';
import styles from './Splash.module.scss';

interface SplashProps {
	splash: SplashResponse;
}

export const Splash: React.FC<SplashProps> = ({ splash }) => {
	return (
		<div
			className={classNames(styles.splash, {
				[styles.isDiscount]: splash.type === SplashType.Discount,
				[styles.isInverted]:
					splash.type === SplashType.NeverOutOfStock || splash.type === SplashType.DeliveryMonth,
			})}
		>
			{splash.text && (
				<span className={classNames(styles.content, { ['u-p-xs']: !splash.type })}>{splash.text}</span>
			)}
		</div>
	);
};
