/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AbsoluteUrl,
  BasketDetailsResponse,
  BasketFamilyTotalsResponse,
  BasketFilterType,
  BasketGroupingType,
  BasketId,
  BasketLineQuantityResponse,
  BasketLineRequest,
  BasketListResponse,
  BasketQuantityRequest,
  BasketResponse,
  CopyBasketRequest,
  CreateBasketRequest,
  CreateOrUpdateBasketRequest,
  ErrorResult,
  ExtraModelsResponse,
  PresentationBrandId,
  ProblemDetails,
  ProductFamilyId,
  RemoveBasketLineRequest,
  RemoveLinesForBundleRequest,
  RemoveLinesForFamilyRequest,
  RemoveLinesForMasterRequest,
  RouteResponse,
  SetBasketResponse,
  ShipToId,
  UpdateNameAndShipTosForBasketRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Basket<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Basket
   * @name BasketList
   * @summary Retrieves the user's current basket.
   * @request GET:/api/basket
   * @secure
   */
  basketList = (
    query?: {
      shipmentFilterType?: BasketFilterType;
      /** @format date */
      shipmentFilterValue?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<BasketDetailsResponse, ErrorResult | void>({
      path: `/api/basket`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Basket
   * @name BasketIndexv2List
   * @summary Retrieves the user's current basket.
   * @request GET:/api/basket/indexv2
   * @secure
   */
  basketIndexv2List = (
    query?: {
      shippingFilter?: BasketFilterType;
      groupingType?: BasketGroupingType;
      /** @format date */
      shippingFilterDate?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<BasketResponse, ExtraModelsResponse | ErrorResult | void>({
      path: `/api/basket/indexv2`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Basket
   * @name BasketSetbasketCreate
   * @summary Set basket for user
   * @request POST:/api/basket/setbasket/{basketId}
   * @secure
   */
  basketSetbasketCreate = (
    basketId: BasketId,
    query?: {
      productFamilyId?: ProductFamilyId;
    },
    params: RequestParams = {},
  ) =>
    this.request<SetBasketResponse, ErrorResult | void>({
      path: `/api/basket/setbasket/${basketId}`,
      method: "POST",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Basket
   * @name BasketListList
   * @summary List all baskets for the logged-in user
   * @request GET:/api/basket/list
   * @secure
   */
  basketListList = (
    query?: {
      SearchPhrase?: string;
      BasketCreators?: string[];
      BrandIds?: PresentationBrandId[];
      SubBrandIds?: PresentationBrandId[];
      ShipToIds?: ShipToId[];
    },
    params: RequestParams = {},
  ) =>
    this.request<BasketListResponse, ErrorResult | void>({
      path: `/api/basket/list`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Basket
   * @name BasketTotalquantityList
   * @summary Retrieves the total basket quantity.
   * @request GET:/api/basket/totalquantity
   * @secure
   */
  basketTotalquantityList = (params: RequestParams = {}) =>
    this.request<BasketId, void>({
      path: `/api/basket/totalquantity`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Basket
   * @name BasketFamilytotalquantityList
   * @summary Retrieves the total bundled quantity for all families in the current basket.
   * @request GET:/api/basket/familytotalquantity
   * @secure
   */
  basketFamilytotalquantityList = (params: RequestParams = {}) =>
    this.request<BasketFamilyTotalsResponse, void>({
      path: `/api/basket/familytotalquantity`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Basket
   * @name BasketCreateCreate
   * @summary Creates a new basket with the specified settings
   * @request POST:/api/basket/create
   * @secure
   */
  basketCreateCreate = (
    data: CreateBasketRequest,
    query?: {
      productFamilyId?: ProductFamilyId;
    },
    params: RequestParams = {},
  ) =>
    this.request<SetBasketResponse, ErrorResult | void>({
      path: `/api/basket/create`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Basket
   * @name BasketSetorderreferenceCreate
   * @summary Updates basket with the specified order reference / name
   * @request POST:/api/basket/setorderreference/{basketId}
   * @secure
   */
  basketSetorderreferenceCreate = (basketId: BasketId, data: AbsoluteUrl, params: RequestParams = {}) =>
    this.request<void, ErrorResult | void>({
      path: `/api/basket/setorderreference/${basketId}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Basket
   * @name BasketSetorderreferenceandshiptosCreate
   * @summary Updates the name and ship tos for the specified basket
   * @request POST:/api/basket/setorderreferenceandshiptos/{basketId}
   * @secure
   */
  basketSetorderreferenceandshiptosCreate = (
    basketId: BasketId,
    data: UpdateNameAndShipTosForBasketRequest,
    params: RequestParams = {},
  ) =>
    this.request<BasketDetailsResponse, ErrorResult | void>({
      path: `/api/basket/setorderreferenceandshiptos/${basketId}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Basket
   * @name BasketDeletemanyCreate
   * @summary Deletes the specified baskets
   * @request POST:/api/basket/deletemany
   * @secure
   */
  basketDeletemanyCreate = (data: BasketId[], params: RequestParams = {}) =>
    this.request<BasketListResponse, ErrorResult | void>({
      path: `/api/basket/deletemany`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Basket
   * @name BasketRemoveCreate
   * @summary Remove a line from the users current basket.
   * @request POST:/api/basket/remove
   * @secure
   */
  basketRemoveCreate = (data: RemoveBasketLineRequest, params: RequestParams = {}) =>
    this.request<BasketDetailsResponse, ErrorResult | void>({
      path: `/api/basket/remove`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Basket
   * @name BasketRemovemasterCreate
   * @summary Remove master from the users current basket.
   * @request POST:/api/basket/removemaster
   * @secure
   */
  basketRemovemasterCreate = (data: RemoveLinesForMasterRequest, params: RequestParams = {}) =>
    this.request<BasketDetailsResponse, ErrorResult | void>({
      path: `/api/basket/removemaster`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Basket
   * @name BasketCreateorupdatebasketbyshiptosCreate
   * @summary Create or update basket by ship to ids
   * @request POST:/api/basket/createorupdatebasketbyshiptos
   * @secure
   */
  basketCreateorupdatebasketbyshiptosCreate = (data: CreateOrUpdateBasketRequest, params: RequestParams = {}) =>
    this.request<RouteResponse, void>({
      path: `/api/basket/createorupdatebasketbyshiptos`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Basket
   * @name BasketRemovebundleCreate
   * @summary Remove lines related to product bundle from the users current basket.
   * @request POST:/api/basket/removebundle
   * @secure
   */
  basketRemovebundleCreate = (data: RemoveLinesForBundleRequest, params: RequestParams = {}) =>
    this.request<BasketDetailsResponse, ErrorResult | void>({
      path: `/api/basket/removebundle`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Basket
   * @name BasketRemovefamilyCreate
   * @summary Remove lines related to product family from the users current basket.
   * @request POST:/api/basket/removefamily
   * @secure
   */
  basketRemovefamilyCreate = (data: RemoveLinesForFamilyRequest, params: RequestParams = {}) =>
    this.request<BasketDetailsResponse, ErrorResult | void>({
      path: `/api/basket/removefamily`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Basket
   * @name BasketSetlinequantityCreate
   * @summary Set the quantity on a line.
   * @request POST:/api/basket/setlinequantity
   * @secure
   */
  basketSetlinequantityCreate = (data: BasketLineRequest, params: RequestParams = {}) =>
    this.request<BasketDetailsResponse, ErrorResult | void>({
      path: `/api/basket/setlinequantity`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Basket
   * @name BasketSetbasketquantityCreate
   * @summary Modifies the quantity on multiple basketlines. Only the lines that are present in the request will be modified.
   * @request POST:/api/basket/setbasketquantity
   * @secure
   */
  basketSetbasketquantityCreate = (data: BasketQuantityRequest, params: RequestParams = {}) =>
    this.request<BasketLineQuantityResponse[], ErrorResult | void>({
      path: `/api/basket/setbasketquantity`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Basket
   * @name BasketCopycurrentbasketCreate
   * @summary Copies the current basket and basket lines to a new basket with the specified ship to ids and name
   * @request POST:/api/basket/copycurrentbasket
   * @secure
   */
  basketCopycurrentbasketCreate = (data: CopyBasketRequest, params: RequestParams = {}) =>
    this.request<BasketDetailsResponse, ErrorResult | ProblemDetails | void>({
      path: `/api/basket/copycurrentbasket`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
