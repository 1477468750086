import React from 'react';
import classNames from 'classnames';
import { Link, Logo } from 'components/shared';
import { Area, LayoutResponse, TranslationsWebsite } from 'generated/data-contracts';
import CompanyInfo from './CompanyInfo';
import styles from './Footer.module.scss';
import Menu from './Menu';

interface FooterProps {
	critical?: boolean;
	layout?: LayoutResponse | null;
	translations?: TranslationsWebsite;
	className?: string;
}

const Footer: React.FunctionComponent<FooterProps> = ({ critical, layout, translations, className }) => {
	const frontPageUrl = layout?.frontPageUrl;

	const route = {
		area: Area.CMS,
	};

	return (
		<footer className={classNames(styles.wrapper, className)}>
			<div
				className={classNames(styles.footerInnerWrapper, 'u-container-full', { [styles.isCritical]: critical })}
			>
				{!critical && (
					<nav className={styles.menuNav} aria-label={translations?.shared.navigation.footer}>
						<div className={classNames(styles.menuCol, styles.isSocial)}>
							<Link to={frontPageUrl || '/'} state={route} className={styles.brandLogoWrapper}>
								<Logo
									name={layout?.selectedBrandId || 'default'}
									svgClassName={styles.brandLogo}
									width={'11.5rem'}
									height={'1.25rem'}
								/>
							</Link>
							<div className={styles.cmsComponents}>
								<CompanyInfo cmsSettings={layout?.cmsSettings} audience={layout?.audienceTargeting} />
								<Menu className={styles.footerMenu} />
							</div>
						</div>
					</nav>
				)}

				<div className={classNames(styles.contactWrapper, { [styles.criticalWrapper]: critical })}>
					<p>{translations?.footer.copyright}</p>
				</div>
			</div>
		</footer>
	);
};

export const MemoizedFooter = React.memo(Footer);
