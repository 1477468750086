import React from 'react';
import { Builder, BuilderElement } from '@builder.io/react';
import { ProductBundleQuery, useProductBundleQuery } from 'api/product';
import { RelewiseProductRecommendationQuery } from 'api/recommendations';
import { useTranslationQuery } from 'api/translations';
import { ProductSlider as DSProductSlider } from 'components/shared';
import { useViewportSize } from 'helpers/useViewportSize';
import { ProductFilterProps, convertPropsToProductFilter } from '../../shared/ProductsFilters';

export enum ProductSlideInputNames {
	amountOfProducts = 'amountOfProducts',
	areVariantsShownInRecommendations = 'areVariantsShownInRecommendations',
	collectionIds = 'collectionIds',
	defaultSorting = 'defaultSorting',
	deliveryMonths = 'deliveryMonths',
	fillInRecommendations = 'fillInRecommendations',
	productCatalogues = 'productCatalogues',
	productAssortments = 'productAssortments',
	productFits = 'productFits',
	productGender = 'productGender',
	productLineIds = 'productLineIds',
	productMainColours = 'productMainColours',
	productNoos = 'productNoos',
	productSizes = 'productSizes',
	recommendations = 'recommendations',
	sortingDirection = 'sortingDirection',
}

interface ProductSliderProps extends ProductFilterProps {
	builderBlock: BuilderElement;
	numberOfProductsShown: { desktop: number; mobile: number; tablet: number };
	infiniteScroll?: boolean;
	autoScroll?: boolean;
	autoScrollDelay?: number;
}

export const ProductSlider: React.FunctionComponent<ProductSliderProps> = (props) => {
	const query: ProductBundleQuery | RelewiseProductRecommendationQuery = convertPropsToProductFilter(
		props,
		'ProductSlider',
	);
	const viewportSize = useViewportSize();
	const isOnTablet = viewportSize.matchesSize(['sm']);
	const isOnPhone = viewportSize.matchesSize(['xs']);

	let amountOfProducts = props?.numberOfProductsShown?.desktop;

	if (isOnTablet) {
		amountOfProducts = props?.numberOfProductsShown?.tablet;
	} else if (isOnPhone) {
		amountOfProducts = props?.numberOfProductsShown?.mobile;
	}

	const { data: response } = useProductBundleQuery(query);
	const productCardInfo = response?.pages?.at(-1)?.products;
	const { data: translations } = useTranslationQuery();

	const isPreviewingFromBuilder = Builder.isEditing;
	// This is only shown in the builder preview.
	if (isPreviewingFromBuilder && (!productCardInfo || !productCardInfo.length)) {
		return <h2>{translations?.productList.noResults}</h2>;
	}

	return (
		<DSProductSlider
			products={productCardInfo}
			numberOfProductsShown={amountOfProducts}
			autoScroll={props.autoScroll}
			infiniteScroll={props.infiniteScroll}
			autoScrollDelay={props.autoScrollDelay}
		/>
	);
};
