import React from 'react';
import classNames from 'classnames';
import { AllOrNone } from 'types/allOrNone';
import styles from './Toggle.module.scss';

type ToggleVariants = 'secondary' | 'primary';

type ControlledToggleProps = AllOrNone<{
	value: boolean;
	onChange: (value: boolean) => void;
}>;

export type ToggleProps = {
	disabled?: boolean;
	variant?: ToggleVariants;
} & ControlledToggleProps &
	Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value' | 'type' | 'disabled'>;

const Toggle: React.FunctionComponent<ToggleProps> = ({
	disabled = false,
	variant = 'primary',
	onChange,
	value,
	...props
}) => {
	const [checked, setChecked] = React.useState<boolean>(value ?? false);

	const onToggle = (e: React.ChangeEvent<HTMLInputElement>): void => {
		if (disabled) return;
		setChecked(e.target.checked);
	};

	React.useEffect(() => {
		if (onChange && checked !== value) {
			onChange(checked);
		}
	}, [checked, onChange, value]);

	return (
		<input
			disabled={disabled}
			className={classNames(styles.toggle, styles[variant])}
			type="checkbox"
			checked={checked}
			data-checked={checked}
			onChange={onToggle}
			{...props}
		/>
	);
};

export default Toggle;
