import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { portalRootId } from 'helpers';
import { capitalize } from 'lodash';
import { Button } from '../Button';
import { Icon } from '../Icon';
import styles from './Modal.module.scss';

export const modalSizes = ['sm', 'md', 'wd', 'fullscreen'] as const;

export type ModalSize = (typeof modalSizes)[number];

type ModalProps = {
	children?: React.ReactElement | React.ReactElement[];
	className?: string;
	close: () => void;
	hideCloseButton?: boolean;
	modalRef: React.RefObject<HTMLDialogElement>;
	mounted: boolean;
	rendered: boolean;
	size?: ModalSize;
};

export const Modal: FC<ModalProps> = ({ children, className, close, hideCloseButton, modalRef, mounted, size }) => {
	if (!mounted) return null;

	const portalRoot: HTMLElement | null = document.getElementById(portalRootId) || document.body;

	return createPortal(
		<dialog
			ref={modalRef}
			className={classNames(className, styles.modal, {
				[styles[`size${capitalize(size)}`]]: size,
			})}
		>
			{children}

			{!hideCloseButton && (
				<Button hasOnlyIcon size="xs" variant="outline" className={styles.closeButton} onClick={close}>
					<Icon size="sm" name={'close'} />
				</Button>
			)}
		</dialog>,
		portalRoot,
	);
};
