import { configureStore, EnhancedStore } from '@reduxjs/toolkit';
import appReducer from 'store/reducers/appReducer';
import basketReducer from 'store/reducers/basketReducer';
import { InitialState, AppTypes } from 'store/types';

export function setUpStore(initialState: InitialState): EnhancedStore {
	return configureStore({
		reducer: {
			app: appReducer,
			basket: basketReducer,
		},
		preloadedState: initialState,
		devTools: {
			trace: true,
			traceLimit: 20,
		},
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: {
					ignoredActions: [AppTypes.ADD_NOTIFICATION, AppTypes.REMOVE_NOTIFICATION],
				},
			}),
	});
}
