import { Builder } from '@builder.io/react';
import { fontSizes } from 'types/fontSize';
import { Text } from '../Text';

Builder.registerComponent(Text, {
	name: 'Text',
	override: true,
	inputs: [
		{
			name: 'text',
			type: 'html',
			required: true,
			autoFocus: true,
			bubble: true,
			defaultValue: 'Enter some text...',
		},
		{
			name: 'forceFontSize',
			type: 'enum',
			enum: [{ label: 'unset', value: 'unset' }, ...fontSizes],
		},
	],
});
