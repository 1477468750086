import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { useCarouselStore } from 'components/shared/Carousel/state/CarouselContext';
import { ArrowButton } from 'components/shared/Slider/ArrowButton';
import { useDebounce } from 'helpers/useDebounce';
import { useViewportSize } from 'helpers/useViewportSize';
import { useProductCardContext } from '../../context/ProductCardContext';
import styles from './ProductCardImageGalleryNavigation.module.scss';

export interface ProductCardImageGalleryNavigationProps {}

export const ProductCardImageGalleryNavigation: React.FunctionComponent<
	ProductCardImageGalleryNavigationProps
> = () => {
	const translations = useTranslationQuery().data;
	const { isBeingHovered } = useProductCardContext();
	const {
		slides: { activeSlide, nextSlide, prevSlide, numberOfSlides },
	} = useCarouselStore();
	const { isMobile } = useViewportSize();

	const [isScrolling, setIsScrolling] = React.useState(false);
	const NAV_CLICK_TIMEOUT = 300;

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const setScrollingFalse = useCallback(
		useDebounce(() => setIsScrolling(false), NAV_CLICK_TIMEOUT),
		[setIsScrolling],
	);

	if (isMobile) return null;
	return (
		<nav
			className={classNames(styles.navigation, {
				[styles.blockTouchEvents]: isScrolling,
			})}
			aria-label={translations?.shared.navigation.slider}
			hidden={!isBeingHovered}
		>
			<ArrowButton
				className={classNames(styles.arrow)}
				onClick={(e) => {
					setIsScrolling(true);
					setScrollingFalse();
					e.preventDefault();
					prevSlide();
				}}
				disabled={activeSlide === 0}
				prev
				hasNoStyles
				size="sm"
				iconProps={{
					size: 'md',
				}}
			/>
			<ArrowButton
				className={classNames(styles.arrow)}
				onClick={(e) => {
					setIsScrolling(true);
					setScrollingFalse();
					e.preventDefault();
					nextSlide();
				}}
				disabled={activeSlide === numberOfSlides - 1}
				next
				hasNoStyles
				size="sm"
				iconProps={{
					size: 'md',
				}}
			/>
		</nav>
	);
};
