import React from 'react';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import { useOrderLineContext } from '../state/useOrderLineContext';
import styles from './OrderDetails.module.scss';
import {
	EstimatedShippingDate,
	OrderNumber,
	Platform,
	Quantity,
	Value,
	WarehouseShippingEta,
	WholesalePrice,
	OrderStatus,
	RecommendedRetailPrice,
	WholesalePriceNet,
	Discount,
	Markup,
} from './cells';

type OrderLineDetailsSubComponents = {
	EstimatedShippingDate: typeof EstimatedShippingDate;
	OrderNumber: typeof OrderNumber;
	Platform: typeof Platform;
	Quantity: typeof Quantity;
	Value: typeof Value;
	WarehouseShippingEta: typeof WarehouseShippingEta;
	WholesalePrice: typeof WholesalePrice;
	OrderStatus: typeof OrderStatus;
	RecommendedRetailPrice: typeof RecommendedRetailPrice;
	WholesalePriceNet: typeof WholesalePriceNet;
	Markup: typeof Markup;
	Discount: typeof Discount;
};

interface OrderLineDetailsProps {
	OrderDetailsSm?: React.ReactNode;
	OrderDetailsMd?: React.ReactNode;
	OrderDetailsLg?: React.ReactNode;
}

export const OrderLineDetails: React.FunctionComponent<OrderLineDetailsProps> & OrderLineDetailsSubComponents = ({
	OrderDetailsLg,
	OrderDetailsMd,
	OrderDetailsSm,
}: OrderLineDetailsProps): JSX.Element => {
	const { size } = useOrderLineContext();
	let returnedContent;
	switch (size) {
		case 'sm':
			returnedContent = OrderDetailsSm;
			break;
		case 'md':
			returnedContent = OrderDetailsMd;
			break;
		default:
			returnedContent = OrderDetailsLg;
	}
	return <div className={classNames(styles.orderInfo, styles[`size${capitalize(size)}`])}>{returnedContent}</div>;
};

OrderLineDetails.EstimatedShippingDate = EstimatedShippingDate;
OrderLineDetails.OrderNumber = OrderNumber;
OrderLineDetails.Platform = Platform;
OrderLineDetails.Quantity = Quantity;
OrderLineDetails.Value = Value;
OrderLineDetails.WarehouseShippingEta = WarehouseShippingEta;
OrderLineDetails.WholesalePrice = WholesalePrice;
OrderLineDetails.OrderStatus = OrderStatus;
OrderLineDetails.RecommendedRetailPrice = RecommendedRetailPrice;
OrderLineDetails.WholesalePriceNet = WholesalePriceNet;
OrderLineDetails.Markup = Markup;
OrderLineDetails.Discount = Discount;
