import React, { PropsWithChildren } from 'react';
import { useLocalStorage } from './useLocalStorage';

const HIDE_WHS_PRICE_NAME = 'hideWhsPrice';

interface UseHideWhsPriceSettingsReturnType {
	hideWhsPrice: boolean;
	handleClick: (value: boolean) => void;
}

const useHideWhsPriceSettingsState = (): UseHideWhsPriceSettingsReturnType => {
	const [hideWhsPrice, setHideWhsPrice] = useLocalStorage<boolean>(HIDE_WHS_PRICE_NAME, false);

	return {
		hideWhsPrice,
		handleClick: setHideWhsPrice,
	};
};

const HideWhsPriceSettingsContext = React.createContext<UseHideWhsPriceSettingsReturnType | null>(null);

export const HideWhsPriceSettingsProvider: React.FunctionComponent<PropsWithChildren<object>> = ({ children }) => {
	const value = useHideWhsPriceSettingsState();

	return <HideWhsPriceSettingsContext.Provider value={value}>{children}</HideWhsPriceSettingsContext.Provider>;
};

export const useHideWhsPriceSettings = (): UseHideWhsPriceSettingsReturnType => {
	const context = React.useContext(HideWhsPriceSettingsContext);

	if (!context) {
		throw new Error('useHideWhsPriceSettings must be used within a HideWhsPriceSettingsProvider');
	}

	return context;
};
