import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import { BasketSelector } from 'components/fragments/BasketSelector';
import { Button, Icon } from 'components/shared';
import { Drawer, DrawerHeader, DrawerHeaderWBackButton, DrawerLevel } from 'components/shared/Drawer';
import styles from './OpenBaskets.module.scss';

interface OpenBasketsProps {
	handleClose?: () => void;
}

export const OpenBaskets: FC<OpenBasketsProps> = ({ handleClose }) => {
	const { data: layout } = useLayoutQuery();

	const { data: translations } = useTranslationQuery();
	const selectedBrandId = layout?.selectedBrandId;
	const [searchParams] = useSearchParams();
	const [isOpen, setIsOpen] = useState(false);

	const handleBack = (): void => setIsOpen(false);

	const handleOpen = (): void => {
		setIsOpen(true);
	};

	useEffect(() => {
		if (searchParams.get('openBasket') === 'Desktop' && selectedBrandId) {
			setIsOpen(true);
		}
	}, [searchParams, selectedBrandId]);

	return (
		<>
			<Button className={styles.openButton} hasNoStyles onClick={handleOpen}>
				<div className={styles.titleWrapper}>
					<Icon className={'u-mr-sm'} name={'basket'} size={'md'} />
					<span className={styles.title}>{translations?.openBaskets.title}</span>
				</div>
				<Icon className={'u-ml-sm'} name={'chevronDown'} size={'lg'} rotate={270} />
			</Button>
			{isOpen &&
				(handleClose ? (
					<DrawerLevel isActive={isOpen}>
						<DrawerHeaderWBackButton
							handleBack={handleBack}
							handleClose={handleClose}
							title={translations?.openBaskets.title}
						/>
						<BasketSelector onClose={handleClose} />
					</DrawerLevel>
				) : (
					<Drawer onClose={handleBack}>
						<DrawerHeader handleClose={handleBack} title={translations?.openBaskets.title} />
						<BasketSelector onClose={handleBack} />
					</Drawer>
				))}
		</>
	);
};
