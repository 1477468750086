import React from 'react';
import classNames from 'classnames';
import { Link } from 'components/shared';
import { MegaMenuItem, PageType } from 'generated/data-contracts';
import { useOnClickAway } from 'helpers/useOnClickAway';
import { useOnEscape } from 'helpers/useOnEscape';
import styles from './MenuDropdown.module.scss';

interface MenuDropdownProps {
	items: MegaMenuItem[];
	ariaControlId: string;
	onClose: () => void;
}

export const MenuDropdown: React.FunctionComponent<MenuDropdownProps> = ({ items, ariaControlId, onClose }) => {
	const dropdownRef = React.useRef<HTMLDivElement>(null);
	const amountOfItems = 6;
	const [dropdownItems, setDropdownItems] = React.useState<MegaMenuItem[]>(items);
	const [isOpen, setIsOpen] = React.useState(false);

	React.useEffect(() => {
		const newItems: MegaMenuItem[] = [];
		items.map((item) => {
			newItems.push(item);
		});
		setDropdownItems(newItems);
	}, [items]);

	React.useEffect(() => {
		setIsOpen(true);
	}, []);

	const handleClose = React.useCallback((): void => {
		if (!onClose) return;
		setIsOpen(false);
		onClose();
	}, [onClose]);

	const handleShowBorder = (index, length): boolean => {
		const nearestNum = Math.floor(index / amountOfItems) * amountOfItems;

		return nearestNum + amountOfItems >= length;
	};

	useOnEscape(handleClose, isOpen);
	useOnClickAway(dropdownRef, handleClose, isOpen);

	return (
		<section
			ref={dropdownRef}
			className={styles.dropdownList}
			aria-hidden={!isOpen}
			id={ariaControlId}
			role={'menu'}
		>
			{dropdownItems.map((item, index) => (
				<div
					className={classNames(styles.dropdownLinkWrapper, {
						[styles.onLastCol]: handleShowBorder(index, dropdownItems.length),
						[styles.viewAll]: item.route?.pageType === PageType.All,
					})}
					key={index}
					role={'menuitem'}
				>
					<Link
						className={styles.dropdownLink}
						to={item.route?.externalRoute + '?page=1'}
						state={{ ...item.route, resetFilters: true }}
						isTextLink
						onClick={onClose}
					>
						{item.name}
					</Link>
				</div>
			))}
		</section>
	);
};
