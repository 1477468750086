import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import { Button } from 'components/shared/Button';
import { Chip } from 'components/shared/Chip';
import { ActiveFilterResponse, CurrencyPosition, PriceSliderResponse } from 'generated/data-contracts';
import styles from './ActiveFilters.module.scss';

interface ActiveFiltersProps {
	className?: string;
	priceFilters?: PriceSliderResponse;
	activeFilters?: ActiveFilterResponse[];
	disabled?: boolean;
	onReset: () => void;
	onRemoveFilter: (id: string, value?: string) => void;
}

interface ActiveFilterItem {
	id: string;
	label: string;
	value?: string;
	excluded?: boolean;
}

export const priceFilterId = 'price';

export const ActiveFilters: React.FunctionComponent<ActiveFiltersProps> = ({
	className,
	activeFilters,
	priceFilters,
	disabled,
	onReset,
	onRemoveFilter,
}) => {
	const { data: layout } = useLayoutQuery();

	const currentCurrencySymbol = priceFilters?.currencySymbol || layout?.currencySymbol;
	const { data: translations } = useTranslationQuery();

	const buildRangeLabel = React.useCallback(
		(min, max, selectedMin, selectedMax, id): ActiveFilterItem => {
			let preString = min;
			let postString = max;

			if (selectedMin) preString = selectedMin;
			if (selectedMax) postString = selectedMax;

			if (id === priceFilterId) {
				if (layout?.currencyPosition === CurrencyPosition.Right) {
					preString += ` ${currentCurrencySymbol}`;
					postString += ` ${currentCurrencySymbol}`;
				} else {
					preString = `${currentCurrencySymbol} ${preString}`;
					postString = `${currentCurrencySymbol} ${postString}`;
				}
			}

			return { id: id, label: `${preString}-${postString}` };
		},
		[currentCurrencySymbol, layout?.currencyPosition],
	);

	const updateActiveValues = useMemo(() => {
		if (!activeFilters) {
			return [];
		}

		const newValues = activeFilters;

		if (!priceFilters) {
			return newValues;
		}

		const { minPrice, maxPrice, selectedMinPrice, selectedMaxPrice } = priceFilters;

		if (!selectedMinPrice && !selectedMaxPrice) {
			return newValues;
		}

		const priceValue = buildRangeLabel(minPrice, maxPrice, selectedMinPrice, selectedMaxPrice, priceFilterId);

		if (!priceValue) {
			return newValues;
		}

		newValues?.push(priceValue as ActiveFilterResponse);
		return newValues;
	}, [activeFilters, buildRangeLabel, priceFilters]);
	if (!updateActiveValues.length) return null;
	return (
		<section className={classNames(styles.wrapper, className)}>
			{updateActiveValues?.map((val) => (
				<Chip
					className={classNames({ [styles.disabledChip]: val?.excluded })}
					onCancel={(): void => onRemoveFilter(val.id, val.value)}
					key={val.id + val.value}
					disabled={disabled}
				>
					{val.label}
				</Chip>
			))}
			{!!updateActiveValues?.length && (
				<Button disabled={disabled} className={styles.resetButton} variant={'linkButton'} onClick={onReset}>
					{translations?.productList.filters.resetFilters}
				</Button>
			)}
		</section>
	);
};
