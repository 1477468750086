import React from 'react';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import { LoadingBar } from 'components/shared/LoadingBar';
import { useOrderLineContext } from 'components/shared/OrderLine/state/useOrderLineContext';
import styles from '../OrderProductDetails.module.scss';

export interface OrderProductDetailsSkeletonProps {}

const OrderProductDetailsSkeleton: React.FunctionComponent<OrderProductDetailsSkeletonProps> = () => {
	const { size } = useOrderLineContext();
	return (
		<div className={classNames(styles.info, styles[`size${capitalize(size)}`])}>
			<div className={styles.brandName}>
				<LoadingBar width="30%" />
			</div>
			<div className={styles.productName}>
				<LoadingBar width="70%" />
			</div>
			<div className={styles.styleNumber}>
				<LoadingBar width="20%" />
			</div>
		</div>
	);
};

export default OrderProductDetailsSkeleton;
