import React from 'react';
import classNames from 'classnames';
import { FontSize } from 'types/fontSize';
import styles from './Text.module.scss';

export interface TextProps {
	text?: string;
	forceFontSize?: FontSize | 'unset';
}

/**
 * CMS Text component, used for creating html text. It overrides Builder's default text component.
 * */
export const Text: React.FC<TextProps> = ({ text, forceFontSize }) => {
	const hasFontSize = forceFontSize !== undefined && forceFontSize !== 'unset';

	return (
		<div
			className={classNames(
				{
					[styles.hasFontSize]: hasFontSize,
				},
				/* NOTE: This class name must be "builder-text" for inline editing to work in the Builder editor */
				'builder-text',
			)}
			style={
				{
					'--fontSize': hasFontSize ? forceFontSize : undefined,
				} as React.CSSProperties
			}
			dangerouslySetInnerHTML={{ __html: text ?? '' }}
		/>
	);
};
