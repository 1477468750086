import React from 'react';
import classNames from 'classnames';
import styles from './InputText.module.scss';

interface InputTextProps extends React.InputHTMLAttributes<HTMLInputElement> {
	inputClassName?: string;
	label: string | React.ReactNode;
	prefix?: string;
	suffix?: string;
	hideLabel?: boolean;
	inputRef?: React.RefObject<HTMLInputElement>;
	isSmall?: boolean;
}

/**
 * Generic InputText component, used for creating text type input fields
 */
export const InputText: React.FunctionComponent<InputTextProps> = ({
	className,
	inputClassName,
	id,
	label,
	prefix,
	suffix,
	hideLabel,
	isSmall,
	disabled,
	inputRef,
	required,
	...rest
}) => {
	const renderInput = (hasPrefix: boolean, hasSuffix: boolean): React.ReactNode => {
		return (
			<input
				className={classNames(
					styles.input,
					{ [styles.hasPrefix]: hasPrefix, [styles.hasSuffix]: hasSuffix },
					inputClassName,
				)}
				id={id}
				disabled={disabled}
				required={required}
				ref={inputRef}
				{...rest}
			/>
		);
	};

	return (
		<div
			className={classNames(
				styles.wrapper,
				{ [styles.isDisabled]: disabled, [styles.isSmall]: isSmall },
				className,
			)}
		>
			<label
				className={classNames(styles.label, { [styles.required]: required }, hideLabel && 'u-hide-visually')}
				htmlFor={id}
			>
				{label}
			</label>

			{prefix || suffix ? (
				<div className={styles.inputWrapper}>
					{prefix ? <span className={classNames(styles.affix, styles.isPrefix)}>{prefix}</span> : null}
					{renderInput(Boolean(prefix), Boolean(suffix))}
					{suffix ? <span className={classNames(styles.affix, styles.isSuffix)}>{suffix}</span> : null}
				</div>
			) : (
				renderInput(false, false)
			)}
		</div>
	);
};
