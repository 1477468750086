import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import { BreadCrumbModel, PageType, Area } from 'generated/data-contracts';

export const ORDERNUMBER_PARAM = 'orderNumber';

export const useGetOrderDetailBreadCrumb = (orderNumber?: string): BreadCrumbModel | undefined => {
	const { data: translations } = useTranslationQuery();
	const { data: layout } = useLayoutQuery();
	const orderRoute = layout?.staticLinks.find((link) => link.pageType === PageType.OrderDetail);
	if (!translations || !orderRoute || !orderNumber) return;
	const url = `${orderRoute.url}?${ORDERNUMBER_PARAM}=${orderNumber}`;
	const breadcrumb: BreadCrumbModel = {
		text: orderNumber,
		link: url,
		route: {
			area: Area.StaticPages,
			pageType: orderRoute.pageType,
			externalRoute: url,
		},
	};
	return breadcrumb;
};
