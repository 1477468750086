/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BrandListResponse,
  BrandResponse,
  PresentationBrandId,
  ProblemDetails,
  RouteResponse,
  SetBrandRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Brand<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Brand
   * @name BrandListList
   * @summary List all brands available to the user
   * @request GET:/api/brand/list
   * @secure
   */
  brandListList = (params: RequestParams = {}) =>
    this.request<BrandListResponse, void>({
      path: `/api/brand/list`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Brand
   * @name BrandGetbypresentationbrandList
   * @summary Brand and coherent subbrand
   * @request GET:/api/brand/getbypresentationbrand
   * @secure
   */
  brandGetbypresentationbrandList = (
    query: {
      presentationBrandId: PresentationBrandId;
    },
    params: RequestParams = {},
  ) =>
    this.request<BrandResponse, void | ProblemDetails>({
      path: `/api/brand/getbypresentationbrand`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Brand
   * @name BrandSetbrandCreate
   * @summary Select brand for user
   * @request POST:/api/brand/setbrand
   * @secure
   */
  brandSetbrandCreate = (data: SetBrandRequest, params: RequestParams = {}) =>
    this.request<RouteResponse, void | ProblemDetails>({
      path: `/api/brand/setbrand`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
