import React from 'react';
import { FetchNextPageOptions, UseInfiniteQueryResult } from '@tanstack/react-query';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Button } from 'components/shared';
import { ProgressBar } from 'components/shared/ProgressBar';
import styles from './Pagination.module.scss';

interface PaginationProps {
	currentPage?: number;
	highestPage?: number;
	className?: string;
	totalCount: number;
	pageSize: number;
	hasNextPage?: boolean;
	fetchNextPage?: (options?: FetchNextPageOptions) => Promise<UseInfiniteQueryResult>;
}

export const Pagination: React.FunctionComponent<PaginationProps> = ({
	currentPage,
	highestPage,
	className,
	totalCount,
	pageSize,
	hasNextPage,
	fetchNextPage,
}) => {
	const { data: translations } = useTranslationQuery();
	const lastPage = currentPage || highestPage || 1;
	const isLastPage = lastPage >= Math.ceil(totalCount / pageSize);
	const viewedProducts = isLastPage ? totalCount : lastPage * pageSize;

	return (
		<div className={classNames(styles.pagination, className)}>
			<p className={styles.paginationInfo}>
				{translations?.shared.pagination.youHaveViewed} {viewedProducts} {translations?.shared.pagination.of}{' '}
				{totalCount} {translations?.shared.pagination.products}.
			</p>
			<div className={styles.progressWrapper}>
				<ProgressBar progressPercent={(viewedProducts / totalCount) * 100} />
			</div>
			{(!isLastPage || hasNextPage) && fetchNextPage && (
				<Button className="u-mt-lg" variant={'dark'} onClick={() => fetchNextPage()}>
					{translations?.shared.pagination.showNext}
				</Button>
			)}
		</div>
	);
};
