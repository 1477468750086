import React, { FC, ReactElement } from 'react';
import styles from './BottomSplashes.module.scss';
import { Delivered } from './Delivered';
import { FashionForward } from './FashionForward';
import { InBasket } from './InBasket';
import { Inbound } from './Inbound';

export interface BottomSplashesProps {
	LeftSplashes: ReactElement;
	RightSplashes: ReactElement;
}

export type BottomSplashesSubComponents = {
	Inbound: FC;
	Devliverd: FC;
	InBasket: FC;
	FashionForward: FC;
};

export const BottomSplashes: FC<BottomSplashesProps> & BottomSplashesSubComponents = ({
	LeftSplashes,
	RightSplashes,
}: BottomSplashesProps) => {
	return (
		<section className={styles.bottom}>
			<div className={styles.leftSplashes}>{LeftSplashes}</div>
			<div className={styles.rightSplashes}>{RightSplashes}</div>
		</section>
	);
};

BottomSplashes.Inbound = Inbound;
BottomSplashes.Devliverd = Delivered;
BottomSplashes.InBasket = InBasket;
BottomSplashes.FashionForward = FashionForward;
