import React, { PropsWithChildren } from 'react';
import { useTranslationQuery } from 'api/translations';
import { useGetOrderDetailBreadCrumb } from 'components/features/OrderDetail/hooks/useGetOrderDetailBreadCrumb';
import {
	OrderHistoryViewportSize,
	useOrderHistoryViewportSize,
} from 'components/shared/hooks/useOrderHistoryViewportSize';
import {
	ColourResponse,
	OrderHistoryProductMasterResponse,
	OrderLineResponse,
	ProductImageResponse,
	TrackAndTraceResponse,
} from 'generated/data-contracts';
import { createSkeletonOrderLine } from '../helpers/skeletons';

// In future will come from BE
export type Invoice = {
	invoiceNumber: string;
	shipToId: string;
};

type OrderLineProductInfo = {
	styleName?: string;
	styleNumber: string;
	familyNumber?: string;
	brandName?: string;
	mainColor?: ColourResponse;
	primaryImage?: ProductImageResponse;
	masters?: OrderHistoryProductMasterResponse[];
	productImages?: ProductImageResponse[];
	productUrl?: string;
};

type OrderLineDetails = {
	orderNumber?: string;
	orderStatus?: string;
	isDelivered: boolean;
	basketId?: number;
	url?: string;
	estimatedShippingDate?: string;
	warehouseShippingETA?: string;
	platform?: string;
	quantity?: string;
	totalItems?: number;
	recommendedRetailPrice?: string;
	wholesalePrice?: string;
	wholesaleNetPrice?: string;
	markup?: string;
	discount?: string;
	value?: string;
	isFreeAssortment?: boolean;
	trackAndTraceCodes: TrackAndTraceResponse[];
	invoices: Invoice[];
};

type UseOrderLineContextReturnType = {
	productInfo?: OrderLineProductInfo;
	orderLineDetails: OrderLineDetails;
	isSkeleton: boolean;
	size: OrderHistoryViewportSize;
};
type OrderLineType = OrderLineResponse;

const useGetProductInfo = (orderLine: OrderLineType): OrderLineProductInfo | undefined => {
	const { data: translations } = useTranslationQuery();
	const { product } = orderLine;

	if (!product) {
		return {
			styleName: translations?.myOrders.orderDetails.styleNotFound ?? '',
			styleNumber: translations?.myOrders.orderDetails.notAvailable ?? '',
		};
	}
	const masters = product.masters;
	const styleName = product.styleName ?? translations?.myOrders.orderDetails.styleNotFound ?? '';
	const styleNumber = product.styleNumber;
	let mainColor: ColourResponse | undefined = product.primaryColour;
	let primaryImage = product.primaryImage;
	const productImages = product.productImages;
	const firstMaster = masters[0];
	const brandName = product.brandName ?? undefined;
	if (firstMaster) {
		if (mainColor === undefined) mainColor = firstMaster.colour;
		if (primaryImage === undefined) primaryImage = firstMaster.image;
	}
	const productUrl = product.url ?? undefined;

	return {
		styleName,
		familyNumber: `F_${styleNumber}`,
		styleNumber,
		mainColor,
		primaryImage,
		masters,
		productImages,
		brandName,
		productUrl,
	};
};

const useGetOrderLineDetails = (orderLine: OrderLineType): OrderLineDetails => {
	const { data: translations } = useTranslationQuery();

	// These fields are on both types of OrderLines
	const {
		orderNumber,
		basketId,
		wholeSalePrice,
		markupPrice: markup,
		rrp: recommendedRetailPrice,
		discount,
		platform,
		isDelivered,
		estimatedShippingDate,
		warehouseShippingETA,
		status,
		bundleSize,
		orderLineQuantityPieces,
		unitPrice,
		isFreeAssortment,
		trackAndTrace,
		bundleQuantity,
		orderLineNetPrice,
		invoices,
	} = orderLine;

	const quantity = isFreeAssortment ? `${bundleQuantity}` : `${bundleQuantity} x ${bundleSize}`;
	const totalItems = orderLineQuantityPieces;

	const route = useGetOrderDetailBreadCrumb(orderNumber);
	const url = route?.link;
	return {
		orderNumber,
		orderStatus: status ?? translations?.myOrders.orderDetails.notAvailable,
		isDelivered,
		basketId,
		estimatedShippingDate: estimatedShippingDate ?? translations?.myOrders.orderDetails.notAvailable,
		warehouseShippingETA: warehouseShippingETA ?? translations?.myOrders.orderDetails.notAvailable,
		platform: platform ?? translations?.myOrders.orderDetails.notAvailable,
		quantity,
		totalItems,
		wholesalePrice: wholeSalePrice?.toFixed(2),
		value: orderLineNetPrice,
		isFreeAssortment: isFreeAssortment ?? undefined,
		trackAndTraceCodes: trackAndTrace,
		invoices,
		url,
		discount,
		markup: markup?.toFixed(2),
		recommendedRetailPrice: recommendedRetailPrice?.toFixed(2),
		wholesaleNetPrice: unitPrice.toFixed(2),
	};
};

const useOrderLineContextState = (
	orderLine: OrderLineType,
	isSkeleton: boolean,
	setSize?: OrderHistoryViewportSize,
): UseOrderLineContextReturnType => {
	const productInfo = useGetProductInfo(orderLine);
	const orderLineDetails = useGetOrderLineDetails(orderLine);
	const size = useOrderHistoryViewportSize();
	return {
		productInfo,
		orderLineDetails,
		isSkeleton,
		size: setSize ?? size,
	};
};

const OrderLineContext = React.createContext<UseOrderLineContextReturnType | null>(null);

export const OrderLineContextProvider: React.FunctionComponent<
	PropsWithChildren<{
		setSize?: OrderHistoryViewportSize;
		orderLine?: OrderLineType;
	}>
> = ({ children, orderLine, setSize }) => {
	const orderLineOrSkeleton = orderLine ?? createSkeletonOrderLine();
	const value = useOrderLineContextState(orderLineOrSkeleton, !orderLine, setSize);

	return <OrderLineContext.Provider value={value}>{children}</OrderLineContext.Provider>;
};

export const useOrderLineContext = (): UseOrderLineContextReturnType => {
	const orderLineStateContext = React.useContext(OrderLineContext);

	if (!orderLineStateContext) {
		throw new Error('useOrderLineContext must be used within a OrderLineContextProvider');
	}

	return orderLineStateContext;
};
