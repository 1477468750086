import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { Button } from 'components/shared/Button';
import styles from './MobileFilterFooter.module.scss';

interface MobileFilterFooterProps {
	submitButtonLabel?: string;
	handleReset: () => void;
	handleSubmit: () => void;
}

export const MobileFilterFooter: React.FC<MobileFilterFooterProps> = ({
	handleReset,
	handleSubmit,
	submitButtonLabel,
}) => {
	const { data: translations } = useTranslationQuery();
	return (
		<div className={styles.footer}>
			<Button className={styles.footerBtn} variant="outline" size="sm" onClick={handleReset}>
				{translations?.productList.filters.resetFilters}
			</Button>
			<Button className={styles.footerBtn} variant="dark" size="sm" onClick={handleSubmit}>
				{submitButtonLabel ?? translations?.productList.filters.showProductsButton}
			</Button>
		</div>
	);
};
