import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { clamp, uniqueId } from 'lodash';
import { useTranslationQuery } from 'api/translations';
import { ArrowButton } from '../Slider/ArrowButton';
import { DotButton } from '../Slider/DotButton';
import { useScrollable } from '../hooks/useScrollable';
import styles from './NewSlider.module.scss';

type NewSliderProps = {
	showNavigation?: boolean;
	arrowsClassName?: string;
	arrowContainerClassName?: string;
	numberOfItemsShown?: number;
	slideWidth?: string;
	infiniteScroll?: boolean;
	autoScroll?: boolean;
	autoScrollDelay?: number;
};

const NewSlider: React.FunctionComponent<PropsWithChildren<NewSliderProps>> = ({
	children,
	showNavigation = true,
	arrowsClassName,
	arrowContainerClassName,
	numberOfItemsShown: _numberOfItemsShown = 1,
	slideWidth,
	...props
}) => {
	// useRef is used to store the key as a constant value, which doesn't change between renders
	const key = useRef(uniqueId('slider')).current;
	// useRef is used to store the scroll amount as a constant value, which doesn't change between renders, but only changes if the width of the wrapper changes
	const scrollAmount = useRef(500);
	const {
		scrollableActionsStates: { left, right },
		scrollableTo,
		scrollableRefs,
		scrollableClasses,
	} = useScrollable({
		scrollInPx: scrollAmount.current,
		infiniteScroll: props.infiniteScroll,
		autoScroll: props.infiniteScroll ? props.autoScroll : false,
		autoScrollDelay: props.autoScrollDelay,
		hideHorizontalScroll: true,
	});
	const [clientWidth, setClientWidth] = useState(scrollableRefs.wrapper.current?.clientWidth);

	useEffect(() => {
		setClientWidth(scrollableRefs.wrapper.current.clientWidth);
		scrollAmount.current = scrollableRefs.wrapper.current.clientWidth;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scrollableRefs.wrapper.current?.clientWidth]);

	// If infinite scroll is enabled, the scroll width is divided by 2, as all items in the list are duplicated.
	const scrollWidth = props.infiniteScroll
		? scrollableRefs.wrapper.current?.scrollWidth / 2
		: scrollableRefs.wrapper.current?.scrollWidth;
	const translations = useTranslationQuery().data;
	const numOfItems = React.Children.count(children);
	const numberOfPages = clamp(Math.round(scrollWidth / scrollAmount.current), 1, numOfItems);
	const selectedPage = useMemo(
		// The selected page is calculated by dividing the current scroll position by the scroll amount.
		() => {
			const currentPageInSlider = Math.round(scrollableRefs.wrapper.current?.scrollLeft / scrollAmount.current);

			return currentPageInSlider >= numberOfPages ? currentPageInSlider - numberOfPages : currentPageInSlider;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[scrollableRefs.wrapper.current?.scrollLeft, numberOfPages],
	);
	const paginationItems = useMemo(
		() =>
			numberOfPages > 1
				? Array.from({ length: numberOfPages }).map((_, index) => {
						return (
							<DotButton
								key={`${key}-dot-${index}`}
								index={index}
								selected={index === selectedPage}
								onClick={(): void => {
									scrollableRefs.wrapper.current.scrollTo({
										left: index * scrollableRefs.wrapper.current.clientWidth,
										behavior: 'smooth',
									});
								}}
							/>
						);
				  })
				: [],
		[key, numberOfPages, scrollableRefs.wrapper, selectedPage],
	);

	return (
		<section
			className={styles.slider}
			ref={scrollableRefs.container}
			style={
				{
					'--view-width': `${clientWidth}px`,
					'--num-of-items': props.infiniteScroll ? numOfItems * 2 : numOfItems,
					'--shown-items': Math.max(_numberOfItemsShown, 1),
					'--slide-width': slideWidth,
					'--max-card-width': 'auto',
				} as React.CSSProperties
			}
		>
			{showNavigation && (
				<nav
					className={classNames(styles.navigation, scrollableClasses.navigation, arrowContainerClassName)}
					aria-label={translations?.shared.navigation.slider}
				>
					<ArrowButton
						className={classNames(styles.arrow, arrowsClassName)}
						onClick={scrollableTo.left}
						disabled={left}
						prev
					/>
					<ArrowButton
						className={classNames(styles.arrow, arrowsClassName)}
						onClick={scrollableTo.right}
						disabled={right}
						next
					/>
				</nav>
			)}
			<ul
				className={classNames(styles.list, 'u-scroll-bar-hidden', scrollableClasses.wrapper)}
				ref={scrollableRefs.wrapper}
			>
				{React.Children.map(children, (child, index) => {
					return (
						<li key={`${key}-item-${index}`} className={styles.sliderItem}>
							{child}
						</li>
					);
				})}
			</ul>
			<div className={styles.pagination}>{paginationItems}</div>
		</section>
	);
};

export default NewSlider;
