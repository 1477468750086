import React from 'react';
import classNames from 'classnames';
import styles from './ProgressBar.module.scss';

interface ProgressBarProps {
	progressPercent: number;
	accentColorClassName?: string;
}

/**
 * Generic ProgressBar component, used for showing progress in a bar (e.g. in pagination)
 */
export const ProgressBar: React.FunctionComponent<ProgressBarProps> = ({ progressPercent, accentColorClassName }) => {
	const [progress, setProgress] = React.useState(progressPercent || 0);

	React.useEffect(() => {
		if (progressPercent < 0) {
			setProgress(0);
		} else if (progressPercent > 100) {
			setProgress(100);
		} else {
			setProgress(progressPercent);
		}
	}, [progressPercent]);

	return (
		<div className={styles.wrapper}>
			<div
				className={classNames(styles.progress, accentColorClassName)}
				style={{ transform: `translateX(${progress}%)` }}
			/>
		</div>
	);
};
