import React from 'react';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import { useTranslationQuery } from 'api/translations';
import CopyTextButton from 'components/shared/CopyTextButton';
import { Link } from 'components/shared/Link';
import { ResourceLocation, Area } from 'generated/data-contracts';
import { useOrderLineContext } from '../../state/useOrderLineContext';
import styles from './OrderProductDetails.module.scss';
import OrderProductDetailsSkeleton from './OrderProductDetailsSkeleton';

export interface OrderProductDetailsProps {}

const OrderProductDetails: React.FunctionComponent<OrderProductDetailsProps> = () => {
	const { isSkeleton, size, productInfo } = useOrderLineContext();
	const { data: translations } = useTranslationQuery();
	if (isSkeleton) return <OrderProductDetailsSkeleton />;
	let route: ResourceLocation | undefined;
	if (productInfo?.productUrl) {
		route = {
			area: Area.Product,
			productFamilyId: productInfo.familyNumber,
			externalRoute: productInfo.productUrl,
		};
	}
	return (
		<div className={classNames(styles.info, styles[`size${capitalize(size)}`])}>
			<p title={productInfo?.brandName} className={classNames(styles.brandName, 'u-ellipsis')}>
				{productInfo?.brandName}
			</p>
			<Link
				to={productInfo?.productUrl}
				route={route}
				title={productInfo?.styleName}
				className={classNames(styles.productName, 'u-ellipsis')}
			>
				{productInfo?.styleName}
			</Link>
			<CopyTextButton
				className={styles.styleNumber}
				copySuccessMessage={translations?.productDetails.copiedStyleNumber}
				text={productInfo?.styleNumber}
				title={translations?.productDetails.copyStyleNumber}
			/>
		</div>
	);
};

export default OrderProductDetails;
