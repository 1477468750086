import { Routing } from 'generated/Routing';
import { RouteResponse } from 'generated/data-contracts';
import { HttpResponse } from 'generated/http-client';
import { setHeaders } from './apiConfig';

export interface RoutingQueryObject {
	path: string;
}

export const getRoutingList = (query: RoutingQueryObject): Promise<HttpResponse<RouteResponse, void>> => {
	const routingApi = new Routing({
		baseApiParams: { headers: setHeaders() },
	});

	return routingApi.routingList(query);
};
