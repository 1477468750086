import { isAncestorOf } from "./isAncestorOf";

/**
 * Check whether param 1 element is the same or contains param 2 element
 *
 * @param container
 * @param child
 */
export const isSameOrContains = (container: Element | null, child: Element): boolean => {
	if (!container) {
		return false;
	}

	return container === child || isAncestorOf(container, child);
};
