import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { usePDFExportContext } from 'components/fragments/PDFExport/context/PDFExportContext';
import { Badge, Dropdown, Icon } from 'components/shared';
import styles from './PDFGenerationIndicator.module.scss';

export interface PDFGenerationIndicatorProps {}

const PDFGenerationIndicator: React.FunctionComponent<PDFGenerationIndicatorProps> = () => {
	const { data: translations } = useTranslationQuery();
	const { generationJobsQueue } = usePDFExportContext();

	return (
		<Dropdown
			contentClassName={styles.dropdownContent}
			buttonVariant="outline"
			hideArrow
			buttonSize="sm"
			buttonClassName={classNames(styles.button, {
				[styles.hidden]: generationJobsQueue.length === 0,
			})}
			buttonLabel={
				<>
					<Icon name="download" size="md" />
					<Badge className={styles.badge} value={generationJobsQueue.length} />
				</>
			}
		>
			<div className={styles.container}>
				<h4 className={styles.title}>{translations?.shared.exports.generatingPdfsTitle}</h4>
				<hr className={classNames('u-hr', styles.divider)} />
				<ul className={styles.list}>
					{generationJobsQueue.map((job) => (
						<li key={job.id} className={styles.indicator}>
							<span>{job.title}</span>
						</li>
					))}
				</ul>
			</div>
		</Dropdown>
	);
};

export default PDFGenerationIndicator;
