import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { useImageLookup } from 'api/image';
import { useTranslationQuery } from 'api/translations';
import DefaultImage from 'assets/images/defaultImage.jpg';
import { ImageLookupRequestItem } from 'generated/data-contracts';
import { formatTranslationAsJsx } from 'helpers/index';
import styles from './DAMVideo.module.scss';

interface ImageResponse {
	size100: { url: string };
	size450: { url: string };
	size1800: { url: string };
	altText: string;
}

interface DAMVideoProps {
	children?: React.ReactNode;
	video?: ImageLookupRequestItem;
	posterImage?: ImageLookupRequestItem;
	autoPlay?: boolean;
	controls?: boolean;
	muted?: boolean;
	loop?: boolean;
	objectFit?: string;
	videoPosition?: { x?: number; y?: number };
	fitContent?: boolean;
	aspectRatio?: number;
	attributes?: React.HTMLAttributes<HTMLDivElement>;
}

const fallBackImage: ImageResponse = {
	size100: { url: DefaultImage },
	size450: { url: DefaultImage },
	size1800: { url: DefaultImage },
	altText: '',
};

/**
 * CMS Video component, used for adding video from the DAM. It is used instead of
 * Builder's default Video component with similar functionality.
 * */
export const DAMVideo: React.FunctionComponent<DAMVideoProps> = ({
	children,
	video,
	posterImage,
	autoPlay,
	controls,
	muted,
	loop,
	objectFit,
	videoPosition,
	fitContent,
	aspectRatio,
	attributes,
}) => {
	const { data: translations } = useTranslationQuery();
	const [files, setFiles] = useState<ImageLookupRequestItem[]>([]);

	useEffect(() => {
		const newFiles: ImageLookupRequestItem[] = [];

		if (video?.fileId) {
			newFiles.push({
				fileId: video.fileId,
				croppingId: (video && video.croppingId) ?? null,
				filePath: video.filePath ?? null,
			});
		}

		if (posterImage && posterImage.fileId) {
			newFiles.push({
				fileId: posterImage.fileId,
				croppingId: posterImage.croppingId ?? null,
				filePath: posterImage.filePath ?? null,
			});
		}

		setFiles(newFiles);
	}, [posterImage, video]);

	const { data: damVideos } = useImageLookup(files);

	const damVideo = damVideos?.images?.[0] ?? undefined;
	const damPoster =
		!!damVideos?.images?.length && damVideos?.images?.length > 1 ? damVideos?.images?.[1] : fallBackImage;

	const { className: builderClassNames, ...restOfAttributes } = attributes || {};

	const renderChildren = (): React.ReactNode => {
		if (fitContent) {
			return children;
		}
		return <div className={styles.childrenContainer}>{children}</div>;
	};

	const getPosterUrl = () => {
		if (!video || posterImage) {
			return damPoster.size1800?.url;
		}
		return undefined;
	};

	return (
		<div
			className={classNames(
				styles.wrapper,
				{ [styles.hasChildren]: children },
				{ [styles.hasAspectRatio]: aspectRatio || fitContent },
				builderClassNames,
			)}
			{...restOfAttributes}
			style={
				{
					'--objectFit': objectFit ? objectFit : 'cover',
					'--objectPosX': videoPosition?.x !== undefined ? `${videoPosition.x}%` : 'center',
					'--objectPosY': videoPosition?.y !== undefined ? `${videoPosition.y}%` : 'center',
					'--aspectRatio': aspectRatio ? aspectRatio : undefined,
				} as React.CSSProperties
			}
		>
			<video
				className={styles.video}
				width={damVideo?.size450?.width || undefined}
				controls={controls || undefined}
				autoPlay={autoPlay || undefined}
				muted={muted || undefined}
				loop={loop || undefined}
				preload="metadata"
				poster={getPosterUrl()}
				playsInline
			>
				{damVideo?.size450 && (
					<source
						src={`${damVideo?.size450?.url}${damPoster?.size1800?.url ? '' : '#t=0.1'}`}
						type="video/mp4"
					/>
				)}

				<p>
					{formatTranslationAsJsx(translations?.shared?.unsupportedVideo || '', {
						0: (
							<a href={damVideo?.size450 && damVideo?.size450?.url}>
								{translations?.shared?.unsupportedVideoLinkText}
							</a>
						),
					})}
				</p>
				<track kind="captions" />
			</video>
			{(!fitContent || isEmpty(children)) && aspectRatio !== undefined && aspectRatio > 0 && (
				<div className={styles.aspectRatio}></div>
			)}

			{children && renderChildren()}
		</div>
	);
};
