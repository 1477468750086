import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { useOrderLineContext } from 'components/shared/OrderLine/state/useOrderLineContext';
import { OrderDetailCell } from '../../OrderDetailCell';

export interface ValueProps {}

export const Value: React.FunctionComponent<ValueProps> = () => {
	const { orderLineDetails } = useOrderLineContext();
	const { data: translations } = useTranslationQuery();
	return <OrderDetailCell title={translations?.myOrders.orderDetails?.value} value={orderLineDetails.value} />;
};
