/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { OrderHistoryLineExportRequest, PDFDownloadWithSummaryResponseWrapper, ProblemDetails } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class OrderHistoryExport<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags OrderHistoryExport
   * @name OrderhistoryexportListaspdfCreate
   * @summary List products in order history to generate a PDF
   * @request POST:/api/orderhistoryexport/listaspdf
   * @secure
   */
  orderhistoryexportListaspdfCreate = (data: OrderHistoryLineExportRequest, params: RequestParams = {}) =>
    this.request<PDFDownloadWithSummaryResponseWrapper, ProblemDetails | void>({
      path: `/api/orderhistoryexport/listaspdf`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderHistoryExport
   * @name OrderhistoryexportExportasexcelCreate
   * @summary Gets the Excel file with all of detailed information about the products
   * @request POST:/api/orderhistoryexport/exportasexcel
   * @secure
   */
  orderhistoryexportExportasexcelCreate = (data: OrderHistoryLineExportRequest, params: RequestParams = {}) =>
    this.request<File, ProblemDetails | void>({
      path: `/api/orderhistoryexport/exportasexcel`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
