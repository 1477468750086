import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { useOrderLineContext } from 'components/shared/OrderLine/state/useOrderLineContext';
import { OrderDetailCell } from '../../OrderDetailCell';

export interface WarehouseShippingEtaProps {}

export const WarehouseShippingEta: React.FunctionComponent<WarehouseShippingEtaProps> = () => {
	const { data: translations } = useTranslationQuery();
	const { orderLineDetails } = useOrderLineContext();
	return (
		<OrderDetailCell
			title={translations?.myOrders.orderDetails.warehouseEta}
			value={orderLineDetails.warehouseShippingETA}
		/>
	);
};
