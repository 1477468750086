import { createReducer, nanoid } from '@reduxjs/toolkit';
import { BrowserDeviceType } from 'generated/data-contracts';
import { appActions } from 'store/actions/appActions';
import { AppState } from 'store/types';

export const initialAppState: AppState = {
	segmentationId: 1,
	showOverlay: false,
	notifications: [],
	pageOrigin: '',
	device: BrowserDeviceType.Desktop,
};

const appReducer = createReducer(initialAppState, (builder) => {
	builder.addCase(appActions.setShowOverlay, (state, action) => {
		state.showOverlay = action.payload.showOverlay;
	});
	builder.addCase(appActions.addNotification, (state, action) => {
		state.notifications = [
			...state.notifications,
			{
				...action.payload,
				options: {
					duration: 3000,
					isClosable: true,
					...action.payload.options,
				},
				id: action.payload.id || nanoid(),
			},
		];
	});
	builder.addCase(appActions.removeNotification, (state, action) => {
		state.notifications = state.notifications.filter((item) => item.id !== action.payload);
	});
	builder.addCase(appActions.clearNotifications, (state) => {
		state.notifications = [];
	});
	builder.addCase(appActions.updateSegmentation, (state, action) => {
		state.segmentationId = action.payload;
	});
	builder.addCase(appActions.setDevice, (state, action) => {
		state.device = action.payload;
	});
});

export default appReducer;
