export const DATA_DISABLE_ZOOM_NAME = 'data-disable-zoom';

export const breakpoints = {
	xs: 360,
	sm: 768,
	md: 1024,
	lg: 1320,
	xl: 1600,
};

export const heightBreakpoints = {
	h600: 600,
};

export type BreakpointTypes = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type HeightBreakpointTypes = 'h600';

const getMediaQuery = (type: 'min' | 'max', dimension: 'width' | 'height', size: string) => {
	const htmlTag = document.getElementsByTagName('html');

	const sizeInPixels = `${breakpoints[size] - (type === 'min' ? 0 : 1)}px`;
	try {
		const disableZoomValue = htmlTag[0].attributes.getNamedItem(DATA_DISABLE_ZOOM_NAME)?.value;
		if (disableZoomValue !== undefined && Boolean(JSON.parse(disableZoomValue))) {
			return `(-webkit-max-device-pixel-ratio: 1.09) and (${type}-${dimension}:  ${sizeInPixels}),	
		(-webkit-min-device-pixel-ratio: 1.1)  and (-webkit-max-device-pixel-ratio: 1.24) and (${type}-${dimension}:  calc(${sizeInPixels} / 1.1)),	
		(-webkit-min-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.49) and (${type}-${dimension}:  calc(${sizeInPixels} / 1.25)),
		(-webkit-min-device-pixel-ratio: 1.5)  and (-webkit-max-device-pixel-ratio: 1.74) and (${type}-${dimension}:  calc(${sizeInPixels} / 1.5)),
		(-webkit-min-device-pixel-ratio: 1.75) and (-webkit-max-device-pixel-ratio: 1.99) and (${type}-${dimension}:  calc(${sizeInPixels} / 1.75)),
		(-webkit-min-device-pixel-ratio: 2) and (${type}-width: ${sizeInPixels})`;
		}
	} catch {
		console.log('Error parsing disable zoom value');
	}
	return `(${type}-width: ${sizeInPixels})`;
};

export const getMediaPure = (size: string): Record<string, string> => {
	return {
		min: getMediaQuery('min', 'width', size),
		max: getMediaQuery('max', 'width', size),
	};
};

export const getMediaHeightPure = (size: string): Record<string, string> => {
	return {
		min: getMediaQuery('min', 'height', size),
		max: getMediaQuery('max', 'height', size),
	};
};

export const gridSettings = {
	units: 24,
};
