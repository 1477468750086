import React, { useEffect, useRef } from 'react';
import { useOnEscape, useScrollLock } from 'helpers';
import useDelayedRender from '../hooks/useDelayedRender';

type Options = {
	onCloseCallback?: () => void;
};

export type UseModalReturnProps = {
	modalRef: React.RefObject<HTMLDialogElement>;
	mounted: boolean;
	rendered: boolean;
	showModal: () => void;
	close: () => void;
};

type UseModalProps = {
	(options?: Options): UseModalReturnProps;
};

export const useModal: UseModalProps = (options: Options = {}) => {
	const modalRef = useRef<HTMLDialogElement>(null);
	const { mounted, rendered, setActive } = useDelayedRender(false);
	const setScrollLock = useScrollLock();

	useEffect(() => {
		setScrollLock(mounted);
		return () => {
			setScrollLock(false);
		};
	}, [mounted, setScrollLock]);

	useEffect(() => {
		if (rendered) modalRef?.current?.showModal();
		else modalRef?.current?.close();
	}, [rendered]);

	const showModal = (): void => {
		setActive(true);
	};

	const close = (): void => {
		setActive(false);
		options.onCloseCallback?.();
	};

	useOnEscape(close, rendered);

	return { modalRef, mounted, rendered, showModal, close };
};
