import React from 'react';
import { DrawerFooter } from 'components/shared/Drawer';
import styles from './OrderLineDrawerFooter.module.scss';

export interface OrderLineDrawerFooterProps {
	TopActions?: React.ReactNode;
	BottomActions?: React.ReactNode;
}

export const OrderLineDrawerFooter: React.FunctionComponent<OrderLineDrawerFooterProps> = ({
	TopActions,
	BottomActions,
}) => {
	return (
		<DrawerFooter className={styles.footer}>
			<div className={styles.dropdowns}>{TopActions}</div>
			<div className={styles.bottomActions}>{BottomActions}</div>
		</DrawerFooter>
	);
};
