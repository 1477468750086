import React, { ReactElement, createContext, useContext, useRef } from 'react';
import { StoreApi, createStore, useStore } from 'zustand';
import { useViewportSize } from 'helpers/useViewportSize';

type SlideState = {
	numberOfSlides: number;
	setNumberOfSlides: (numberOfSlides: SlideState['numberOfSlides']) => void;

	activeSlide: number;
	setActiveSlide: (index: number, disableIntersection?: boolean) => void;

	intersectionEnabled: boolean;
	setIntersectionEnabled: (enabled: boolean) => void;

	nextSlide: () => void;
	prevSlide: () => void;
};

type Config = {
	showNavigation: boolean;
	showBadge: boolean;
};

type CarouselState = {
	carouselRef: React.RefObject<HTMLUListElement>;
	slides: SlideState;
	config: Config;
};

const CarouselContext = createContext<StoreApi<CarouselState> | null>(null);

export interface CarouselProviderProps extends React.PropsWithChildren {
	showNavigation?: boolean;
	showBadge?: boolean;
}

export const CarouselProvider = ({ children, showNavigation, showBadge }: CarouselProviderProps): ReactElement => {
	const storeRef = useRef<StoreApi<CarouselState>>();
	const listRef = useRef<HTMLUListElement>(null);

	const { isMobile } = useViewportSize();
	if (!storeRef.current) {
		storeRef.current = createStore<CarouselState>((set) => ({
			carouselRef: listRef,
			slides: {
				numberOfSlides: 0,
				setNumberOfSlides: (numberOfSlides: SlideState['numberOfSlides']): void =>
					set((state) => ({ slides: { ...state.slides, numberOfSlides } })),
				activeSlide: 0,
				intersectionEnabled: false,
				setIntersectionEnabled: (enabled: boolean): void =>
					set((state) => ({ slides: { ...state.slides, intersectionEnabled: enabled } })),
				nextSlide: (): void =>
					set((state) => ({
						slides: {
							...state.slides,
							intersectionEnabled: false,
							activeSlide: state.slides.activeSlide + 1,
						},
					})),
				prevSlide: (): void =>
					set((state) => ({
						slides: {
							...state.slides,
							intersectionEnabled: false,
							activeSlide: state.slides.activeSlide - 1,
						},
					})),
				setActiveSlide: (index, disableIntersection = true): void =>
					set((state) => ({
						slides: {
							...state.slides,
							activeSlide: index,
							intersectionEnabled: !disableIntersection,
						},
					})),
			},
			config: {
				showNavigation: showNavigation ?? !isMobile,
				showBadge: showBadge ?? isMobile,
			},
		}));
	}
	return <CarouselContext.Provider value={storeRef.current}>{children}</CarouselContext.Provider>;
};

export const useCarouselStore = (
	selector: (state: CarouselState) => CarouselState = (state): CarouselState => state,
): CarouselState => {
	const store = useContext(CarouselContext);
	if (!store) {
		throw new Error('Missing CarouselProvider');
	}
	return useStore(store, selector);
};
