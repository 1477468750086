import React from 'react';
import throttle from 'lodash/throttle';
import { HeightBreakpointTypes, getMediaHeightPure } from './cssVariables';
import { getMatchMedia } from './dom';

/**
 * Returns whether viewport matches specific breakpoint and listens for resize events
 *
 * Example use:
 * 	const viewportSize = useViewportSize();
 *
 *  const currentBreakpoint = viewportSize.size;
 *  const isSmallScreenSize = !viewportSize.matchesSize(['h600']);
 * */
export const useViewportHeight = (): {
	size: HeightBreakpointTypes | undefined;
	isSmallScreenSize: boolean;
	matchesSize: (options: (HeightBreakpointTypes | undefined)[]) => boolean;
} => {
	const [size, setSize] = React.useState<HeightBreakpointTypes>();

	React.useEffect(() => {
		const doSizing = throttle(() => {
			const mediaHeightPure = {
				h600: getMediaHeightPure('h600'),
			};
			if (getMatchMedia(mediaHeightPure.h600.max)) {
				setSize('h600');
			}
		}, 100);

		doSizing();

		window.addEventListener('resize', doSizing);

		return (): void => {
			window.removeEventListener('resize', doSizing);
		};
	}, []);

	const match = (options: (HeightBreakpointTypes | undefined)[]): boolean => options.includes(size);

	return {
		size,
		isSmallScreenSize: match(['h600']),
		matchesSize: match,
	};
};
