import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { ArrowButton } from 'components/shared/Slider/ArrowButton';
import { useScrollable } from 'components/shared/hooks/useScrollable';
import { useViewportSize } from 'helpers/useViewportSize';
import styles from './Thumbnails.module.scss';

interface ThumbnailsProps {
	className?: string;
	children?: React.ReactNode;
	selected?: number;
	onSelect?: (index: number) => void;
	isVertical?: boolean;
	shouldSelectOnHover?: boolean;
	fullscreen?: boolean;
}

/**
 * Thumbnails component, used as one type of pagination in Slider
 */
export const Thumbnails: React.FunctionComponent<ThumbnailsProps> = ({
	className,
	selected,
	onSelect,
	isVertical,
	shouldSelectOnHover,
	fullscreen,
	children,
}) => {
	const { data: translations } = useTranslationQuery();
	const childrenCount = React.Children.count(children);

	const { isMobile } = useViewportSize();

	const [changeOnHover, setChangeOnHover] = React.useState(shouldSelectOnHover);

	const ThumbnailScrollSize = 200;
	const { scrollableRefs, scrollableClasses, scrollableTo, scrollableActionsStates } = useScrollable({
		scrollInPx: ThumbnailScrollSize,
		hideHorizontalScroll: true,
	});

	const divRef = React.useRef<HTMLDivElement>(null);

	useEffect(() => {
		// Switch to click on touch devices
		if ('ontouchstart' in window) {
			setChangeOnHover(false);
		}
	}, [childrenCount, isMobile, isVertical]);

	if (selected !== undefined && !shouldSelectOnHover) {
		const elementChild = scrollableRefs.container.current.children?.[selected];
		if (isMobile) {
			elementChild.scrollIntoView({ inline: 'center', block: 'nearest' });
		} else {
			elementChild.scrollIntoView({ block: 'center' });
		}
	}

	const onThumbSelect = React.useCallback(
		(index: number) => {
			if (!onSelect) return;

			onSelect(index);
		},
		[onSelect],
	);

	return (
		<div
			className={classNames(
				styles.wrapper,
				{
					[styles.isVertical]: isVertical,
					[styles.isFullscreenVertical]: isVertical && fullscreen,
					[styles.isTopLeftAligned]: !fullscreen,
				},
				className,
			)}
			ref={divRef}
		>
			<div
				className={classNames(styles.viewport, 'u-scroll-bar-hidden', scrollableClasses.wrapper)}
				ref={scrollableRefs.wrapper}
			>
				<ul className={styles.sliderWrapper} ref={scrollableRefs.container}>
					{React.Children.map(children as never, (child: React.ReactElement, index) => {
						return (
							<li className={styles.slide} key={index}>
								<button
									className={classNames(styles.thumbnail, {
										[styles.isSelected]: index === selected,
									})}
									onClick={!changeOnHover ? () => onThumbSelect(index) : undefined}
									onMouseOver={changeOnHover ? () => onThumbSelect(index) : undefined}
									onFocus={changeOnHover ? () => onThumbSelect(index) : undefined}
								>
									{child}
								</button>
							</li>
						);
					})}
				</ul>
			</div>
			<nav className={classNames(styles.arrows)} aria-label={translations?.shared.navigation.slider}>
				<ArrowButton
					className={styles.arrow}
					onClick={isVertical ? scrollableTo.up : scrollableTo.left}
					disabled={isVertical ? scrollableActionsStates.up : scrollableActionsStates.left}
					isVertical={isVertical}
					prev
				/>
				<ArrowButton
					className={styles.arrow}
					onClick={isVertical ? scrollableTo.down : scrollableTo.right}
					disabled={isVertical ? scrollableActionsStates.down : scrollableActionsStates.right}
					isVertical={isVertical}
					next
				/>
			</nav>
		</div>
	);
};
