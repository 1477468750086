import React, { FC } from 'react';
import classNames from 'classnames';
import { Area, ProductItemResponse, ResourceLocation } from 'generated/data-contracts';
import { ProductThumbList } from '../ProductThumbList';
import { BottomSplashes } from './BottomSplashes';
import styles from './ProductCard.module.scss';
import { ProductCardImage } from './ProductCardImage';
import { ProductCardPricing } from './ProductCardPricing';
import { ProductCardTitle } from './ProductCardTitle';
import { TopSplashes } from './TopSplashes';
import { ProductCardContextProvider, useProductCardContext } from './context/ProductCardContext';

export interface ProductCardProps {
	isSkeleton?: boolean;
	product?: ProductItemResponse;
	isHidden?: boolean;
	isLazy?: boolean;
	showRemoveIcon?: boolean;
	hideFavIcon?: boolean;
	renderedOnFavouriteList?: boolean;
	updateNavParams?: () => void;
}

/**
 * Generic ProductCard component, used on PLP, CMS product block etc.
 */
export const ProductCardInner: FC = () => {
	const {
		activeMaster,
		setActiveMaster,
		product: { id, styleNumber, name, pricingDetails, favouriteListCases, masters },
		isSkeleton,
		isHidden,
		setIsBeingHovered,
		renderedOnFavouriteList,
	} = useProductCardContext();

	let productUrl = '';

	if (activeMaster) {
		if (renderedOnFavouriteList) {
			const favouriteListData = favouriteListCases?.[0];

			productUrl = `${activeMaster.url}&deliveryDate=${favouriteListData?.deliveryDate}&selectedView=${
				favouriteListData?.isFreeAssortment ? 'freeAssortments' : 'boxes'
			}`;
		} else {
			productUrl = `${activeMaster.url}&deliveryDate=${activeMaster?.deliveryDate}&selectedView=${
				activeMaster?.isFreeAssortment ? 'freeAssortments' : 'boxes'
			}`;
		}
	}

	const route: ResourceLocation = {
		area: Area.Product,
		productFamilyId: id,
		externalRoute: productUrl,
	};

	return (
		<article
			className={classNames(styles.wrapper, {
				[styles.isHidden]: isHidden,
			})}
			aria-hidden={isHidden}
			aria-label={name}
			onMouseEnter={(): void => setIsBeingHovered(true)}
			onMouseLeave={(): void => setIsBeingHovered(false)}
		>
			<div className={styles.imgWrapper}>
				<TopSplashes />
				<ProductCardImage />
				<BottomSplashes
					LeftSplashes={<BottomSplashes.FashionForward />}
					RightSplashes={
						<>
							<BottomSplashes.InBasket />
							<BottomSplashes.Inbound />
							<BottomSplashes.Devliverd />
						</>
					}
				/>
			</div>
			<div className={styles.details}>
				<ProductThumbList
					className={styles.productCardThumbList}
					items={masters}
					isLazyImage
					route={route}
					hasHoverEffect
					setCurrentActiveMaster={setActiveMaster}
					isSkeleton={isSkeleton}
				/>
				<div className={styles.info}>
					<ProductCardTitle
						isSkeleton={isSkeleton}
						name={name}
						styleNumber={styleNumber}
						url={productUrl}
						route={route}
					/>
					<hr className={classNames(styles.divider, 'u-hr')} />
					<ProductCardPricing isSkeleton={isSkeleton} pricingDetails={pricingDetails} />
				</div>
			</div>
		</article>
	);
};

export const ProductCard = (props: ProductCardProps): JSX.Element => {
	const { isSkeleton, product, isHidden, showRemoveIcon, hideFavIcon, renderedOnFavouriteList, updateNavParams } =
		props;

	return (
		<ProductCardContextProvider
			product={product}
			isSkeleton={isSkeleton}
			isHidden={isHidden}
			showRemoveIcon={showRemoveIcon}
			hideFavIcon={hideFavIcon}
			renderedOnFavouriteList={renderedOnFavouriteList}
			updateNavParams={updateNavParams}
		>
			<ProductCardInner />
		</ProductCardContextProvider>
	);
};
