/**
 * Sets cookie
 *
 * Usage:
 *
 * createCookie("myCookieUniqueName", value, 30);
 * createCookie("myJsonCookieUniqueName", json, 30);
 *
 * @param name
 * @param value
 * @param days
 */
export const createCookie = (name: string, value: string, days: number): void => {
	const date = new Date();
	date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
	const expires = "; expires=" + date.toUTCString();

	document.cookie = name + "=" + value + expires + "; path=/";
};

/**
 * Gets cookie by name
 *
 * Usage:
 *
 * const value = getCookie("myCookieUniqueName", document.cookie);
 * const json = JSON.parse(getCookie("myJsonCookieUniqueName", document.cookie);
 *
 * @param name
 * @param cookies
 */
export const getCookie = (name: string, cookies: string): string | undefined => {
	const nameEQ = name + "=";
	const ca = cookies.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == " ") c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
	}
	return undefined;
};

/**
 * Erases cookie by name
 *
 * Usage:
 *
 * eraseCookie("myCookieUniqueName");
 *
 * @param name
 */
export const eraseCookie = (name: string): void => {
	createCookie(name, "", -1);
};
