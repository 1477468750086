/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ErrorResult,
  FilterRequest,
  NoPresentationBrandResult,
  PageNumber,
  PageSize,
  ProblemDetails,
  ProductCategoryId,
  ProductFamilyId,
  ProductListResponse,
  ProductResponse,
  ProductSearchRequest,
  QuickSearchResponse,
  SearchRequest,
  SearchType,
  SegmentationId,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Product<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Product
   * @name ProductDetail
   * @summary Retrieves detailed information about a product.
   * @request GET:/api/product/{productFamilyId}
   * @secure
   */
  productDetail = (productFamilyId: ProductFamilyId, params: RequestParams = {}) =>
    this.request<ProductResponse, NoPresentationBrandResult | void | ProblemDetails>({
      path: `/api/product/${productFamilyId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Product
   * @name ProductSearchList
   * @summary Retrieves a list of products.
   * @request GET:/api/product/search
   * @secure
   */
  productSearchList = (
    query: {
      SegmentationId: SegmentationId;
      Phrase?: string;
      /** @format int32 */
      MinPrice?: number;
      /** @format int32 */
      MaxPrice?: number;
      Page?: PageNumber;
      PageSize?: PageSize;
      SortBy?: string;
      SortDirection?: string;
      SearchType?: SearchType;
      ProductCategoryId?: ProductCategoryId;
      Filters?: FilterRequest[];
    },
    params: RequestParams = {},
  ) =>
    this.request<ProductListResponse, void>({
      path: `/api/product/search`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Product
   * @name ProductSearchCreate
   * @summary Retrieves a list of products.
   * @request POST:/api/product/search
   * @secure
   */
  productSearchCreate = (data: SearchRequest, params: RequestParams = {}) =>
    this.request<ProductListResponse, NoPresentationBrandResult | void>({
      path: `/api/product/search`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Product
   * @name ProductSearchqueryCreate
   * @request POST:/api/product/searchquery
   * @secure
   */
  productSearchqueryCreate = (data: ProductSearchRequest, params: RequestParams = {}) =>
    this.request<ProductListResponse, ErrorResult | void>({
      path: `/api/product/searchquery`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Product
   * @name ProductQuicksearchList
   * @summary Performs a text search for products that can be used to create search box functionality.
   * @request GET:/api/product/quicksearch
   * @secure
   */
  productQuicksearchList = (
    query: {
      segmentationId: SegmentationId;
      phrase?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<QuickSearchResponse, NoPresentationBrandResult | void>({
      path: `/api/product/quicksearch`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
