import { Builder } from '@builder.io/react';
import { spacingSizes } from 'types/spacing';
import { VerticalSpacer } from '../VerticalSpacer';

Builder.registerComponent(VerticalSpacer, {
	name: 'VerticalSpacer',
	image: new URL('/dist/images/cms/verticalSpacer.svg', window.location.origin).href,
	noWrap: true,
	inputs: [
		{
			name: 'desktopSize',
			type: 'string',
			required: true,
			enum: Array.from(spacingSizes),
			defaultValue: 'md',
		},
		{
			name: 'tabletSize',
			type: 'string',
			enum: [{ label: 'unset', value: 'unset' }, ...spacingSizes],
			helperText: 'Optional size for tablet breakpoint. If unset, will use desktop size.',
			defaultValue: 'unset',
		},
		{
			name: 'mobileSize',
			type: 'string',
			enum: [{ label: 'unset', value: 'unset' }, ...spacingSizes],
			helperText:
				'Optional size for mobile breakpoint. If unset, will use tablet size, or desktop size if tablet size is unset.',
			defaultValue: 'unset',
		},
	],
});
