import React, { useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { isEmpty, uniqueId } from 'lodash';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import {
	AppSearch,
	Link,
	MemoizedBreadcrumbs,
	Sorting,
	MobileFilterMenu,
	ActiveFilters,
	FilterList,
	ProductCard,
} from 'components/shared';
import { PageType, Area } from 'generated/data-contracts';
import { formatTranslation, formatTranslationAsJsx, isScannerApp, useViewportSize } from 'helpers/index';
import { CategoryMenu } from './CategoryMenu';
import { Header } from './Header';
import { Pagination } from './Pagination';
import { PaginationPrevious } from './PaginationPrevious';
import styles from './ProductList.module.scss';
import { UseProductListActionsProps, skeletonProductCards } from './helpers';

interface ProductListProps {
	actions: UseProductListActionsProps;
	isPlpPlaceholder?: boolean;
}

const ProductList: React.FunctionComponent<ProductListProps> = ({ actions, isPlpPlaceholder = false }) => {
	const {
		changeFilter,
		changeFilterMobile,
		changePriceFilter,
		changeSorting,
		fetchNextPage,
		fetchPreviousPage,
		hasNextPage,
		hasPreviousPage,
		loading,
		pageData,
		priceFilter,
		products,
		removeFilter,
		resetFilters,
		updateNavParams,
		setPriceFilter,
		setToBeRemovedFilters,
		setToBeSetFilters,
		toBeRemoved,
		toBeSet,
		changeCategory,
		uniqueFilterId,
	} = actions;
	const key = useRef(uniqueId(`product-list`)).current;
	const { data: translations } = useTranslationQuery();
	const { data: layout } = useLayoutQuery();

	const layoutSwitch = 'sm';
	const layoutSwitchAside = 'md';
	const { isMobile } = useViewportSize();
	const [showCategoryMenu, setShowCategoryMenu] = React.useState(true);

	const filterValues = Object.values(pageData?.filters || {});
	const activeFilters = pageData?.activeFilters.filter((filter) => filter.id !== 'price');

	const initialNumberOfShownFilters = 3;

	const hasFilters = (activeFilters?.length || 0) > 0 || pageData?.filters.some((filter) => filter);

	const showPLP = !isEmpty(products) || loading || hasFilters;

	const [shouldShowPrevious, setShouldShowPrevious] = React.useState(
		Boolean(
			pageData?.pagingInformation &&
				pageData?.pagingInformation?.currentPage &&
				products &&
				products.length !== pageData?.pagingInformation?.currentPage * pageData?.pagingInformation?.pageSize &&
				pageData?.pagingInformation?.totalNumberOfItems !== products.length,
		),
	);

	useEffect(() => {
		setShouldShowPrevious(
			Boolean(
				pageData?.pagingInformation &&
					pageData?.pagingInformation?.currentPage &&
					products &&
					products.length !==
						pageData?.pagingInformation?.currentPage * pageData?.pagingInformation?.pageSize &&
					pageData?.pagingInformation?.totalNumberOfItems !== products.length,
			),
		);
	}, [pageData?.pagingInformation?.currentPage, pageData?.pagingInformation, products]);

	useEffect(() => {
		const isMatch = layout?.megaMenu?.children?.find((child) => {
			return (
				(pageData?.productCategoryIds?.length ?? 0) > 0 &&
				(pageData?.productCategoryIds?.some((cid) => cid === child.route?.productCategoryId) ?? false)
			);
		});

		setShowCategoryMenu(!isEmpty(isMatch));
	}, [layout?.megaMenu?.children, pageData?.productCategoryIds]);

	const numberOfProductsTranslation = `${pageData?.pagingInformation?.totalNumberOfItems ?? 0} ${translations
		?.productList?.products}`;

	const PLPHeaders = useMemo(() => {
		if (isPlpPlaceholder) return null;
		return (
			<>
				{isScannerApp && (
					<div className="u-px-md u-pt-xs">
						<AppSearch />
					</div>
				)}
				{pageData?.category?.breadcrumbs && pageData?.hasCategory ? (
					<MemoizedBreadcrumbs breadCrumbs={pageData?.category?.breadcrumbs} />
				) : (
					!isScannerApp && <div className={styles.breadCrumbsPlaceholder} />
				)}
				<Header
					pagingInformation={
						pageData?.pagingInformation || { currentPage: 1, pageSize: 36, totalNumberOfItems: 1 }
					}
					phrase={pageData?.phrase}
					didYouMean={pageData?.didYouMean}
					category={pageData?.category}
					layoutSwitch={layoutSwitch}
					isMobile={isMobile}
					loading={loading}
					hasFilters={hasFilters}
				/>
			</>
		);
	}, [
		loading,
		isMobile,
		isPlpPlaceholder,
		pageData?.category,
		pageData?.didYouMean,
		pageData?.hasCategory,
		pageData?.pagingInformation,
		pageData?.phrase,
		hasFilters,
	]);

	const renderProducts = (): React.ReactElement => {
		if (activeFilters?.length && activeFilters?.every((filter) => filter.excluded)) {
			const staticLinks = layout?.staticLinks;
			const searchUrl = staticLinks?.find((item) => item.pageType === PageType.Search)?.url;

			return (
				<section className={styles.noResultsContainer}>
					<h1 className={styles.noResultsTitle}>
						{pageData?.phrase
							? formatTranslation(translations?.productList.noResultsFor, { 0: pageData?.phrase })
							: translations?.productList.noProducts}
					</h1>
					{pageData?.didYouMean && (
						<p>
							{formatTranslationAsJsx(translations?.productList.didYouMean || '', {
								0: (
									<Link
										to={{ pathname: searchUrl, search: `?phrase=${pageData?.didYouMean}` }}
										state={{ area: Area.StaticPages, pageType: PageType.Search }}
										isRichTextLink
										className={styles.didYouMeanText}
									>
										{pageData?.didYouMean}?
									</Link>
								),
							})}
						</p>
					)}

					{pageData?.phrase && (
						<div className={styles.suggestions}>
							<h2 className={styles.suggestionsHeading}>
								{translations?.productList.searchSuggestionsHeadline}
							</h2>

							<ul className={styles.suggestionsList}>
								<li>{translations?.productList.searchSuggestionsText1}</li>
								<li>{translations?.productList.searchSuggestionsText2}</li>
								<li>{translations?.productList.searchSuggestionsText3}</li>
							</ul>
						</div>
					)}
				</section>
			);
		}

		if (loading && isEmpty(products)) {
			return (
				<ul className={classNames(styles.productList, 'u-grid u-grid-cols-custom')}>
					{skeletonProductCards(36).map((product, i) => (
						<li key={`${key}-skeleton-${i}`} className={styles.product}>
							<ProductCard {...product} isSkeleton hideFavIcon />
						</li>
					))}
				</ul>
			);
		}

		return (
			<section>
				{shouldShowPrevious && !loading && hasPreviousPage && (
					<PaginationPrevious className="u-mb-xl" fetchPreviousPage={fetchPreviousPage} />
				)}
				<ul className={classNames(styles.productList, 'u-grid u-grid-cols-custom')}>
					{products.map((product, index) => {
						const navIndex = index + 1;

						return (
							<li key={`${key}-${product.product?.id}-${index}`} className={styles.product}>
								<ProductCard
									{...product}
									isLazy={index > 3}
									updateNavParams={(): void =>
										updateNavParams ? updateNavParams(navIndex) : undefined
									}
								/>
							</li>
						);
					})}
				</ul>
				{pageData?.pagingInformation && (
					<Pagination
						className="u-mt-xl"
						highestPage={pageData?.pagingInformation?.currentPage ?? 1}
						pageSize={pageData?.pagingInformation?.pageSize}
						totalCount={pageData?.pagingInformation?.totalNumberOfItems}
						hasNextPage={hasNextPage}
						fetchNextPage={fetchNextPage}
					/>
				)}
			</section>
		);
	};

	return (
		<>
			{PLPHeaders}
			{showPLP && (
				<article className={classNames(styles.contentWrapper, 'u-container-full')}>
					{!isMobile && (
						<aside className={classNames(styles.categoryMenu, `u-hide-${layoutSwitchAside}-max`)}>
							<span className={styles.categoriesHeader}>{translations?.productList?.categories}</span>
							<CategoryMenu
								changeCategory={changeCategory}
								productCategoryId={pageData?.category?.id}
								matchingProductCategoryIds={pageData?.productCategoryIds}
								phrase={pageData?.phrase ?? undefined}
								isLoading={loading && !pageData}
							/>
						</aside>
					)}
					<main
						className={classNames(styles.mainContent, {
							[styles.mainContentPlpPlaceholder]: isPlpPlaceholder,
						})}
					>
						{hasFilters && (
							<>
								{/* Mobile top */}
								<div className={classNames(styles.mobileTop, `u-hide-${layoutSwitchAside}-min`)}>
									<div className={styles.mobileTopFilters}>
										{showCategoryMenu && (
											<CategoryMenu
												changeCategory={changeCategory}
												productCategoryId={pageData?.category?.id}
												isMobile
												matchingProductCategoryIds={pageData?.productCategoryIds}
												phrase={pageData?.phrase ?? undefined}
											/>
										)}
										<MobileFilterMenu
											buttonClassName={styles.mobileTopFilter}
											filters={filterValues || []}
											priceSliderValues={pageData?.priceSliderValues}
											isMobile={isMobile}
											changeFilterMobile={changeFilterMobile}
											toBeSet={toBeSet}
											toBeRemoved={toBeRemoved}
											setToBeSetFilters={setToBeSetFilters}
											setToBeRemovedFilters={setToBeRemovedFilters}
											resetFilters={resetFilters}
											priceFilter={priceFilter}
											setPriceFilter={setPriceFilter}
											sorting={pageData?.sorting}
											currentSorting={pageData?.currentSorting}
										/>
									</div>

									<span className={styles.totalProducts}>{numberOfProductsTranslation}</span>
								</div>

								{/* Desktop top */}
								{!isMobile && (
									<div className={styles.desktopTop}>
										<div className={styles.desktopFiltersWrapper}>
											<FilterList
												activeFilters={activeFilters}
												disabled={loading}
												changeFilter={changeFilter}
												filterValues={filterValues}
												toBeSet={toBeSet}
												toBeRemoved={toBeRemoved}
												setToBeSetFilters={setToBeSetFilters}
												setToBeRemovedFilters={setToBeRemovedFilters}
												priceFilter={priceFilter}
												setPriceFilter={setPriceFilter}
												uniqueFilterId={uniqueFilterId}
												changePriceFilter={changePriceFilter}
												defaultNumberOfShownFilters={initialNumberOfShownFilters}
												priceSliderValues={pageData?.priceSliderValues}
											/>
											{pageData?.sorting && pageData?.currentSorting && (
												<Sorting
													disabled={loading}
													sorting={pageData?.sorting}
													currentSorting={pageData?.currentSorting}
													setSorting={changeSorting}
													isMobile={isMobile}
												/>
											)}
										</div>
										<ActiveFilters
											activeFilters={activeFilters}
											priceFilters={pageData?.priceSliderValues}
											disabled={loading}
											onReset={resetFilters}
											onRemoveFilter={removeFilter}
										/>
										<span className={styles.totalProducts}>{numberOfProductsTranslation}</span>
									</div>
								)}
							</>
						)}

						{(!isEmpty(products) || loading) && renderProducts()}
					</main>
				</article>
			)}
		</>
	);
};

export default ProductList;
