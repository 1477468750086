import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { isServer, mergeRefs, portalRootId } from 'helpers';
import { useTooltipState } from './helpers/useTooltipState';
import styles from './Tooltip.module.scss';

export const TooltipContent = React.forwardRef<
	HTMLDivElement,
	React.HTMLProps<HTMLElement> & { style?: React.CSSProperties }
>(function TooltipContent(props, propRef) {
	const state = useTooltipState();

	const ref = React.useMemo(() => mergeRefs([state.floating, propRef]), [state.floating, propRef]);

	const portalRoot: HTMLElement | null = document.getElementById(portalRootId) || document.body;

	if (isServer()) return null;

	return ReactDOM.createPortal(
		state.open && (
			<>
				<div
					className={classNames(styles.tooltipContent, { [styles.showArrow]: state.showArrow })}
					ref={ref}
					style={{
						position: state.strategy,
						top: state.y ?? 0,
						left: state.x ?? 0,
						visibility: state.x == null ? 'hidden' : 'visible',
						...props.style,
					}}
				>
					<aside {...state.getFloatingProps(props)} />
				</div>
			</>
		),
		portalRoot,
	);
});
