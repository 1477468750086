import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Icon, Button } from 'components/shared';
import { MegaMenuModel } from 'generated/data-contracts';
import { MenuDropdown } from '../MenuDropdown';
import styles from './DesktopMenu.module.scss';

interface DesktopMenuProps {
	className?: string;
	megaMenu: MegaMenuModel;
}

export const DesktopMenu: FC<DesktopMenuProps> = ({ className, megaMenu }) => {
	const { data: translations } = useTranslationQuery();

	const [isOpen, setIsOpen] = useState(false);

	const handleOpen = (): void => {
		setIsOpen(true);
	};

	const handleClose = (): void => {
		setIsOpen(false);
	};

	return (
		<nav
			className={classNames(styles.wrapper, className)}
			aria-label={translations?.shared.navigation.primary}
			role={'navigation'}
		>
			<ul className={styles.mainList} role={'menubar'}>
				<li role={'menuitem'} className={classNames({ [styles.hasPointerCursor]: isOpen })}>
					<Button
						className={classNames(styles.drawerButton, { [styles.isNotClickable]: isOpen })}
						variant={'secondary'}
						size={'sm'}
						onClick={handleOpen}
					>
						{translations?.shared.navigation.allCategories}
						<Icon className="u-ml-2xs" name={'chevronDown'} size={'lg'} />
					</Button>
					{isOpen && (
						<MenuDropdown
							items={megaMenu.children}
							ariaControlId={translations?.shared.navigation.allCategories || ''}
							onClose={handleClose}
						/>
					)}
				</li>
			</ul>
		</nav>
	);
};
