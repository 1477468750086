import React from 'react';
import { SupportedCssUnits } from 'types/cssUnits';
import { ColourResponse } from 'generated/data-contracts';
import { ColorDot, ColorDotSize } from '../ColorDot';
import { Image } from '../Image';
import { ZoomableWrapper } from '../Image/ZoomableImage';

interface ProductImageProps {
	canZoom?: boolean;
	className?: string;
	colorDotClassName?: string;
	desktopSrc?: string;
	desktopSrc2x?: string;
	mobileSrc?: string;
	mobileSrc2x?: string;
	altText: string;
	isLazy?: boolean;
	width?: SupportedCssUnits;
	height?: SupportedCssUnits;
	isThumbnail?: boolean;
	isSmallImage?: boolean;
	colorInfo?: ColourResponse;
	isSkeleton?: boolean;
	colorDotSize?: ColorDotSize;
	hasColorDotText?: boolean;
}

/**
 * Generic ProductImage component, used to display an image, but will
 * display a ColorDot if the image is null
 */

export const ProductImage: React.FunctionComponent<ProductImageProps> = ({
	canZoom = false,
	className,
	desktopSrc,
	desktopSrc2x,
	mobileSrc,
	mobileSrc2x,
	altText,
	isLazy,
	width,
	height,
	isThumbnail,
	isSmallImage,
	isSkeleton,
	colorDotClassName,
	colorInfo,
	colorDotSize,
	hasColorDotText,
}) => {
	if (!desktopSrc && colorInfo?.hexCode) {
		return (
			<ColorDot
				className={colorDotClassName || className}
				colorInfo={colorInfo}
				hasText={hasColorDotText}
				size={colorDotSize}
			/>
		);
	}

	const ImageComp = (
		<Image
			className={className}
			desktopSrc={desktopSrc}
			desktopSrc2x={desktopSrc2x}
			mobileSrc={mobileSrc}
			mobileSrc2x={mobileSrc2x}
			isSkeleton={isSkeleton}
			altText={altText}
			isLazy={isLazy}
			width={width}
			height={height}
			isThumbnail={isThumbnail}
			isSmallImage={isSmallImage}
		/>
	);

	return canZoom ? <ZoomableWrapper>{ImageComp}</ZoomableWrapper> : ImageComp;
};
