import React from 'react';
import { BuilderComponent } from '@builder.io/react';
import { useCmsContentQuery } from 'api/cms';
import { AudienceTargetingResponse, CmsSettingsResponse } from 'generated/data-contracts';

export interface CmsHeaderUspProps {
	cmsSettings?: CmsSettingsResponse;
	audience?: AudienceTargetingResponse;
}

const CmsHeaderUsp: React.FC<CmsHeaderUspProps> = (props) => {
	const headerUspModelName = props?.cmsSettings?.headerUsp ?? '';
	const { data: headerUsp } = useCmsContentQuery('', headerUspModelName, props.audience);

	return <BuilderComponent model={headerUspModelName} content={headerUsp ?? undefined} />;
};

export default CmsHeaderUsp;
