import { Builder } from '@builder.io/react';
import { Tabs } from '../Tabs';

const defaultElement = {
	'@type': '@builder.io/sdk:Element',
	responsiveStyles: {
		large: {
			marginTop: 'var(--spacing-48)',
		},
	},
	component: {
		name: 'Text',
		options: {
			text: 'New tab content ',
		},
	},
};

Builder.registerComponent(Tabs, {
	name: 'Tabs',
	image: new URL('/dist/images/cms/tabs.svg', window.location.origin).href,
	inputs: [
		{
			name: 'tabs',
			type: 'list',
			broadcast: true,
			subFields: [
				{
					name: 'label',
					type: 'string',
					defaultValue: 'Tab title',
				},
				{
					name: 'content',
					type: 'uiBlocks',
					hideFromUI: true,
					defaultValue: [defaultElement],
				},
			],
			defaultValue: [
				{
					label: 'Some tab',
					content: [
						{
							...defaultElement,
							component: {
								name: 'Text',
								options: {
									text: 'Tab 1 content',
								},
							},
						},
					],
				},
				{
					label: 'Some other tab',
					content: [
						{
							...defaultElement,
							component: {
								name: 'Text',
								options: {
									text: 'Tab 2 content',
								},
							},
						},
					],
				},
			],
		},
	],
});
