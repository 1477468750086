import { useDispatch, useSelector } from 'react-redux';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { queryKeys, setHeaders } from 'api/apiConfig';
import { AlertTypes } from 'components/shared';
import { Tracking as TrackingApi } from 'generated/Tracking';
import { PageTrackingDataResponse, ProductTrackingDataResponse } from 'generated/data-contracts';
import { formatTranslation } from 'helpers/stringHelpers';
import { appActions } from 'store/actions/appActions';
import { InitialState } from 'store/types';
import { useTranslationQuery } from './translations';

export const usePageTrackingQuery = (): UseQueryResult<PageTrackingDataResponse> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const { data: translations } = useTranslationQuery();
	const dispatch = useDispatch();

	return useQuery({
		queryKey: queryKeys.tracking.pageFetch(segmentationId).queryKey,
		queryFn: async (): Promise<PageTrackingDataResponse> => {
			const trackingApi = new TrackingApi({ baseApiParams: { headers: setHeaders() || undefined } });
			const response = await trackingApi.trackingGlobalpagedataList();

			if (!response.ok) {
				dispatch(
					appActions.addNotification({
						children: formatTranslation(translations?.shared.layoutErrorMsg, {}),
						type: AlertTypes.DANGER,
					}),
				);
			}

			return response.data;
		},
	});
};

export const useProductTrackingQuery = (masterId): UseQueryResult<ProductTrackingDataResponse> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const { data: translations } = useTranslationQuery();
	const dispatch = useDispatch();

	return useQuery({
		queryKey: queryKeys.tracking.productFetch(segmentationId, masterId).queryKey,
		queryFn: async (): Promise<ProductTrackingDataResponse | void> => {
			if (!masterId) return;
			const trackingApi = new TrackingApi({ baseApiParams: { headers: setHeaders() || undefined } });
			const response = await trackingApi.trackingProducttrackingdataList({ styleOptionNumber: masterId });

			if (!response.ok) {
				dispatch(
					appActions.addNotification({
						children: formatTranslation(translations?.shared.layoutErrorMsg, {}),
						type: AlertTypes.DANGER,
					}),
				);
			}

			return response.data;
		},
	});
};
