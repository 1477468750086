import React from 'react';
import classNames from 'classnames';
import styles from './LoadingBar.module.scss';

interface LoadingBarProps {
	className?: string;
	width?: string;
	height?: string;
}

/**
 * Generic LoadingBar component, used in skeleton components to be shown while data loading
 */
export const LoadingBar: React.FunctionComponent<LoadingBarProps> = ({ width, height, className }) => {
	return (
		<div
			className={classNames(styles.skeleton, className)}
			style={{
				width: width || undefined,
				height: height || undefined,
			}}
		/>
	);
};
