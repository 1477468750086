import React from 'react';
import classNames from 'classnames';
import { SupportedCssUnits } from 'types/cssUnits';
import { logos } from 'assets/logos';
import styles from './Logo.module.scss';

interface LogoProps {
	className?: string;
	svgClassName?: string;
	name: string;
	width?: SupportedCssUnits;
	height?: SupportedCssUnits;
}

/**
 * Generic Logo component used for displaying svg logos with specific width and height set on them. '
 * "name" should be one of the logos' file name exported from assets/logos.
 *
 * The difference between an Icon and Logo component is, that logos can (and should) have custom size.
 */
export const Logo: React.FunctionComponent<LogoProps> = ({ className, svgClassName, name, width, height }) => {
	return (
		<i
			className={classNames(styles.logo, { [styles.hasSize]: width }, className)}
			style={
				{
					'--width': width,
					'--height': height,
				} as React.CSSProperties
			}
		>
			<svg className={classNames(styles.asset, svgClassName)}>
				{logos[name]?.id ? (
					<use xlinkHref={`#${logos[name]?.id}`} />
				) : (
					<image className={classNames(styles.asset, svgClassName)} xlinkHref={`/logo/${name}`} />
				)}
			</svg>
		</i>
	);
};
