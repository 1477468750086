import { FilterResponse, PriceSliderResponse } from 'generated/data-contracts';

export const setActiveCount = (filter: FilterResponse): number => {
	let count = 0;
	const { values, min, max, selectedMin, selectedMax } = filter;

	if (values) {
		count = filter.values?.filter((val) => val.isSelected).length || 0;
		return count;
	}

	if (selectedMin && min !== selectedMin) count++;
	if (selectedMax && max !== selectedMax) count++;
	return count;
};

export const setActivePriceCount = (priceSliderValues?: PriceSliderResponse): number => {
	let count = 0;
	if (!priceSliderValues) return count;

	const { minPrice, maxPrice, selectedMinPrice, selectedMaxPrice } = priceSliderValues;

	if (selectedMinPrice && minPrice !== selectedMinPrice) count++;
	if (selectedMaxPrice && maxPrice !== selectedMaxPrice) count++;
	return count;
};
