import React, { FC, useState, Dispatch, SetStateAction, ChangeEvent } from 'react';
import classNames from 'classnames';
import { formatTranslation, useEventListener, useDebounce } from 'helpers';
import { useLayoutQuery } from 'api/layout';
import { useShiptoSearchQuery, useShiptoSelectedListQuery } from 'api/shipTo';
import { useTranslationQuery } from 'api/translations';
import { InputCheckbox, SearchBar, SelectedShipTos } from 'components/shared';
import { ShipToInfoResponse, Role } from 'generated/data-contracts';
import { useInputDebounce } from 'helpers/useInputDebounce';
import { useViewportHeight } from 'helpers/useViewportSizeHeight';
import styles from './AccountSelectorBody.module.scss';
import { AccountSelectorList } from './AccountSelectorList';

interface AccountSelectorBodyProps {
	isCreateBasketView?: boolean;
	onToggleClick: () => void;
	handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
	selectedShipTos: ShipToInfoResponse[];
	setSelectedShipTos: Dispatch<SetStateAction<ShipToInfoResponse[]>>;
	handleSubmit: (evt) => void;
	setFilterList: React.Dispatch<React.SetStateAction<ShipToInfoResponse[]>>;
	isSelectedShipTosExpanded: boolean;
	formId: string;
	parentRef?: React.RefObject<HTMLElement>;
	page?: number;
}

const AccountSelectorBody: FC<AccountSelectorBodyProps> = ({
	selectedShipTos,
	setSelectedShipTos,
	handleSubmit,
	handleChange,
	onToggleClick,
	setFilterList,
	isCreateBasketView,
	isSelectedShipTosExpanded,
	formId,
	parentRef,
}) => {
	const { data: translations } = useTranslationQuery();
	const { isSmallScreenSize } = useViewportHeight();
	const { data: layout } = useLayoutQuery();

	const [searchText, setSearchText] = useState('');
	const debouncedInput = useInputDebounce(searchText, 300);
	const [isEnabled, setIsEnabled] = React.useState<boolean>(true);

	const ulRef = React.useRef<HTMLUListElement>(null);
	const divRef = React.useRef<HTMLDivElement>(null);

	// get saved shiptos
	const {
		data: savedShipTos,
		isFetching: isFetchingSavedShipTos,
		isError: isErrorSavedShipTos,
		isPreviousData: isPreviousDataSavedShipTos,
	} = useShiptoSelectedListQuery();

	const { isError, data, isFetching, isPreviousData, hasNextPage, fetchNextPage } = useShiptoSearchQuery({
		phrase: debouncedInput,
		sortResult: isCreateBasketView ? false : true,
		selectedShipTos: selectedShipTos.map((shipTo) => shipTo.id),
		isEnabled,
		refetchOnSelectedShipTosChange: false,
	});

	const isErrorOrErrorSavedShipTos = isError || isErrorSavedShipTos;

	React.useEffect(() => {
		if (isCreateBasketView) {
			// clear selected shipto
			setSelectedShipTos([]);
		} else if (savedShipTos) {
			setSelectedShipTos([...savedShipTos.shipTos]);
		}
	}, [setSelectedShipTos, isCreateBasketView, savedShipTos]);

	const filteredList = React.useMemo(() => {
		return data?.pages.flatMap((page) => page.shipTos) ?? [];
	}, [data]);

	React.useEffect(() => {
		setFilterList(filteredList);
	}, [filteredList, setFilterList]);

	const currentPage = data?.pages.at(-1)?.pagingInformation.currentPage ?? 1;

	React.useEffect(() => {
		if (isFetching) {
			return;
		}

		if (currentPage === 1) {
			// reset scrolling if page 1
			const listElement = ulRef.current;
			if (listElement) listElement.scrollTop = 0;
		}
	}, [currentPage, isPreviousData, isFetching, data]);

	const onSubmit = (e): void => {
		setIsEnabled(false);
		handleSubmit(e);
	};

	const handleScroll = useDebounce(async (): Promise<void> => {
		const listElement = isSmallScreenSize ? (parentRef ? parentRef.current : divRef.current) : ulRef.current;

		if (isFetching) {
			return;
		}
		if (!listElement || listElement.scrollTop + listElement.clientHeight < listElement.scrollHeight - 250) {
			return;
		}

		if (hasNextPage) {
			fetchNextPage();
		}
	}, 300);

	useEventListener('scroll', handleScroll, isSmallScreenSize ? (parentRef ? parentRef : divRef) : ulRef);

	const handleSelectAll = (): void => {
		if (selectedShipTos.length === 0) {
			// set selected list = paginglist - expands as user scrolls

			setSelectedShipTos([...filteredList]);
		} else {
			setSelectedShipTos([]);
		}
	};

	const handleSearch = (event): void => {
		const { value } = event.target;
		setSearchText(value);
	};

	const clearSearch = (): void => {
		setSearchText('');
	};

	const handleKeyDown = (event): void => {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	};

	// Function to display appropriate message when no accounts are found
	const displayNoAccountsMessage = (): string | undefined => {
		// If there's a search text, return the "no accounts for search" message
		if (searchText) {
			return translations?.accountSelector.noAccountsForSearch;
		}

		// If the user is a salesperson, return a specific message with a hint to edit accounts
		if (layout?.user?.role === Role.SalesPerson) {
			return formatTranslation(translations?.accountSelector.noLinkedAccountsForSalesPerson, {
				0: translations?.accountSelector.editAccounts.manageYourAccounts,
			});
		}

		// Default case: return the general "no accounts for search" message
		return translations?.accountSelector.noAccountsForSearch;
	};

	return (
		<div
			className={classNames(styles.body, {
				[styles.hasVisibleOverflow]: parentRef && isSmallScreenSize,
			})}
		>
			<form id={formId} className={styles.form} onSubmit={onSubmit}>
				<div
					className={classNames(styles.searchContainer, {
						[styles.hasScrollOverflow]: !parentRef && isSmallScreenSize,
						[styles.isHidden]: isSelectedShipTosExpanded,
					})}
					ref={divRef}
				>
					<div className={classNames(styles.topSection, { [styles.isExpanded]: isSelectedShipTosExpanded })}>
						<SearchBar
							classNameButtons={styles.searchBarButtons}
							classNameInput={styles.searchBarInput}
							iconSize="md"
							id="basketfindaccount"
							buttonType="button"
							onChange={handleSearch}
							onClear={clearSearch}
							onKeyDown={handleKeyDown}
							placeholder="Search accounts"
							value={searchText}
						/>
						<InputCheckbox
							className={styles.selectAll}
							checkBoxClassName={styles.checkBox}
							name="selectAll"
							onChange={handleSelectAll}
							checked={selectedShipTos.length > 0}
							partiallyChecked={filteredList?.length !== selectedShipTos.length}
							label={
								selectedShipTos.length === 0
									? `${translations?.shared.selectAll} (${filteredList.length})`
									: `${translations?.shared.deselectAll} (${selectedShipTos?.length})`
							}
						/>
					</div>

					<ul
						className={classNames(styles.list, {
							[styles.isExpanded]: isSelectedShipTosExpanded,
						})}
						ref={ulRef}
					>
						<AccountSelectorList
							filteredList={filteredList}
							handleChange={handleChange}
							isErrorOrErrorSavedShipTos={isErrorOrErrorSavedShipTos}
							isFetching={isFetching}
							isFetchingSavedShipTos={isFetchingSavedShipTos}
							isPreviousData={isPreviousData}
							isPreviousDataSavedShipTos={isPreviousDataSavedShipTos}
							searchText={searchText}
							selectedShipTos={selectedShipTos}
							displayNoAccountsMessage={displayNoAccountsMessage()}
						/>
					</ul>
				</div>

				{selectedShipTos.length > 0 && (!parentRef || !isSmallScreenSize) && (
					<SelectedShipTos
						onToggleClick={onToggleClick}
						handleChange={handleChange}
						selectedShipTos={selectedShipTos}
						isExpanded={isSelectedShipTosExpanded}
					/>
				)}
			</form>
		</div>
	);
};

export default AccountSelectorBody;
