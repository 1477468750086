import { createAction } from '@reduxjs/toolkit';
import { NotificationProps } from 'components/shared/Notifications/Notification';
import { BrowserDeviceType } from 'generated/data-contracts';
import { AppTypes, OverlayTypes } from '../types';

const setShowOverlay = createAction<{ showOverlay: OverlayTypes | false }>(AppTypes.SET_SHOW_OVERLAY);

const addNotification = createAction<NotificationProps>(AppTypes.ADD_NOTIFICATION);

const removeNotification = createAction<string>(AppTypes.REMOVE_NOTIFICATION);
const clearNotifications = createAction(AppTypes.CLEAR_NOTIFICATIONS);
const updateSegmentation = createAction<number>(AppTypes.SEGMENTATION_UPDATE);

const setDevice = createAction<BrowserDeviceType>(AppTypes.SET_DEVICE);

export const appActions = {
	setShowOverlay,
	addNotification,
	removeNotification,
	clearNotifications,
	updateSegmentation,
	setDevice,
};
