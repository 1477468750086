import React, { FC, ReactNode, useCallback, useState } from 'react';
import classNames from 'classnames';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import CmsHeaderMenuLinks from 'components/features/Cms/CmsHeaderMenuLinks';
import {
	Button,
	Icon,
	Drawer,
	DrawerBody,
	DrawerHeader,
	DrawerHeaderWBackButton,
	DrawerLevel,
	Link,
} from 'components/shared';
import drawerStyles from 'components/shared/Drawer/Drawer.module.scss';
import { MegaMenuItem, PageType } from 'generated/data-contracts';
import { BrandSelectorMenu } from '../BrandSelectorMenu';
import { DrawerHeaderSubBrandMenu } from './DrawerHeaderSubBrandMenu';
import styles from './MobileMenu.module.scss';
import { SubBrandSelectorMenuMobile } from './SubBrandSelectorMenuMobile';

export const MobileMenu: FC = () => {
	const { data: translations } = useTranslationQuery();
	const { data: layout } = useLayoutQuery();

	const megaMenu = layout?.megaMenu;
	const selectedBrandId = layout?.selectedBrandId;
	const [menuPath, setMenuPath] = useState<string[]>([]);
	const [isOpen, setIsOpen] = useState(false);
	const [isBrandSelectorMenuOpen, setIsBrandSelectorMenuOpen] = useState(false);
	const [isSubBrandSelectorMenuOpen, setSubIsBrandSelectorMenuOpen] = useState(false);
	const [isCategoryMenuOpen, setIsCategoryMenuOpen] = useState(false);

	const BrandSelectorMenuTitle = selectedBrandId
		? translations?.brandSelectorMenu.title
		: translations?.brandSelectorMenu.loginTitle;

	const handleOpen = useCallback((): void => {
		setIsOpen(true);
	}, []);

	const handleClose = (): void => {
		setIsOpen(false);
		setIsBrandSelectorMenuOpen(false);
		setIsCategoryMenuOpen(false);
		setTimeout(() => {
			setMenuPath([]);
		}, 400);
	};

	const handleItemActivation = (id): void => {
		setMenuPath([...menuPath, id]);
	};

	const handleOpenCategories = (): void => {
		setIsCategoryMenuOpen(true);
	};

	const handleCloseCategories = (): void => {
		setIsCategoryMenuOpen(false);
	};

	const toggleOpenBrandSelectorMenu = (): void => {
		setIsBrandSelectorMenuOpen((prevOpenBrandSelectorMenu) => !prevOpenBrandSelectorMenu);
	};

	const toggleSubBrandMenu = (): void => {
		setSubIsBrandSelectorMenuOpen(!isSubBrandSelectorMenuOpen);
	};

	const handleBack = (): void => {
		if (menuPath.length === 0) return;
		menuPath.pop();
		setMenuPath([...menuPath]);
	};

	const handleBackInBrandSelctorMenu = (): void => {
		setIsBrandSelectorMenuOpen(false);
	};

	const renderMenuItem = (item: MegaMenuItem, index: number): ReactNode => {
		const itemId = item.id ?? `${item.name}-${index}`;
		const lastMenuItem = menuPath[menuPath.length - 1];

		return (
			<li className={drawerStyles.item} key={itemId}>
				<Link
					className={classNames(drawerStyles.itemLink, {
						[styles.viewAll]: item.route?.pageType === PageType.All,
					})}
					to={item.route?.externalRoute}
					state={item.route}
					{...(item.route && { onClick: handleClose })}
				>
					{item.name}
				</Link>
				{item.children?.length > 0 && (
					<Button
						className={drawerStyles.itemButton}
						hasNoStyles
						onClick={(): void => handleItemActivation(itemId)}
					>
						<Icon name="chevronDown" size="lg" rotate={270} />
					</Button>
				)}
				{lastMenuItem === itemId && (
					<DrawerLevel isActive={lastMenuItem === itemId}>
						<DrawerHeaderWBackButton
							backButtonText={item.name}
							handleBack={handleBack}
							handleClose={handleClose}
							title={item.name}
						/>
						<DrawerBody>
							<ul>
								{item.children.length > 0 && item.children.map((child, i) => renderMenuItem(child, i))}
							</ul>
						</DrawerBody>
					</DrawerLevel>
				)}
			</li>
		);
	};

	return (
		<>
			{/* Trigger button */}
			<Button title={translations?.shared.menuLabel} onClick={handleOpen} variant={'ghost'} hasOnlyIcon>
				<Icon name="burger" size="md" />
			</Button>

			{/* Mobile menu */}
			{isOpen && (
				<Drawer {...(selectedBrandId && { onClose: handleClose, desktopWidth: '31.25rem' })}>
					<DrawerHeaderSubBrandMenu
						handleClose={handleClose}
						logo={layout?.selectedBrandId || 'bestsellerLogo'}
						handleToggle={toggleOpenBrandSelectorMenu}
						showBrandSelectorMenu
						showSubBrandSelectorMenu={!layout?.layoutBrandOptions?.hideBrandDropdownSelection}
						handleToggleSubBrandMenu={toggleSubBrandMenu}
					/>
					<DrawerBody className={'u-px-md'}>
						{isSubBrandSelectorMenuOpen && <SubBrandSelectorMenuMobile handleClose={handleClose} />}

						{isBrandSelectorMenuOpen && (
							<DrawerLevel isActive={isBrandSelectorMenuOpen} direction="Left">
								<DrawerHeader
									className={classNames(styles.brandHeader, {
										[styles.isCentered]: !selectedBrandId,
									})}
									{...(selectedBrandId && { handleClose: handleClose })}
									title={BrandSelectorMenuTitle}
									showBackButton
									handleBack={handleBackInBrandSelctorMenu}
								/>
								<BrandSelectorMenu onClose={handleClose} />
							</DrawerLevel>
						)}
						{megaMenu && megaMenu.children.length > 0 && !layout?.layoutBrandOptions?.hideCategories && (
							<Button hasNoStyles className={styles.categoryButton} onClick={handleOpenCategories}>
								<span>{megaMenu.menuName || translations?.shared.navigation.allCategories}</span>
								<Icon name="chevronDown" rotate={270} size="lg" />
							</Button>
						)}
						{isCategoryMenuOpen && (
							<DrawerLevel isActive={isCategoryMenuOpen} handleClose={handleClose}>
								<DrawerHeaderWBackButton
									handleClose={handleClose}
									handleBack={handleCloseCategories}
									title={megaMenu?.menuName || translations?.shared.navigation.allCategories}
								/>
								<DrawerBody>
									<ul>{megaMenu?.children.map((menuItem, i) => renderMenuItem(menuItem, i))}</ul>
								</DrawerBody>
							</DrawerLevel>
						)}
						<div className={styles.headerMenu}>
							<CmsHeaderMenuLinks linkClassName={styles.headerMenuChild} onLinkClick={handleClose} />
						</div>
					</DrawerBody>
				</Drawer>
			)}
		</>
	);
};
