import React from 'react';
import classNames from 'classnames';
import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';
import { RenderStates, useMounting } from 'components/shared/hooks/useMounting';
import styles from './Accordion.module.scss';

interface AccordionProps {
	title: string | React.ReactNode;
	id: string;
	isExpanded?: boolean;
	onToggle?: (isExpanded: boolean) => void;
	iconIsFlipped?: boolean;
	titleClassName?: string;
	titleButtonClassName?: string;
	children?: React.ReactNode;
}

/**
 * Generic Accordion component, used for displaying content in an accordion / collapse.
 */
export const Accordion: React.FunctionComponent<AccordionProps> = ({
	title,
	id,
	isExpanded,
	onToggle,
	iconIsFlipped,
	titleClassName,
	children,
	titleButtonClassName,
}) => {
	const panelRef = React.useRef<HTMLDivElement>(null);
	const labelId = 'label' + id;

	const { UNMOUNTED } = RenderStates;

	const [expanded, setExpanded] = React.useState(isExpanded || false);
	const { status } = useMounting(expanded);

	const handleClick = (): void => {
		setExpanded(!expanded);

		if (!onToggle) return;
		onToggle(!expanded);
	};

	return (
		<>
			<h2 className={classNames(titleClassName, styles.titleWrapper)}>
				<Button
					className={classNames(titleButtonClassName, styles.titleButton, {
						[styles.isFlipped]: iconIsFlipped,
					})}
					hasNoStyles
					id={labelId}
					aria-expanded={expanded}
					aria-controls={id}
					onClick={handleClick}
				>
					<Icon
						className={styles.icon}
						name={'chevronDown'}
						size={'md'}
						rotate={expanded ? 180 : undefined}
					/>

					{typeof title === 'string' ? <span className={styles.title}>{title}</span> : <>{title}</>}
				</Button>
			</h2>
			<div
				className={styles.panel}
				ref={panelRef}
				id={id}
				role={'region'}
				aria-labelledby={labelId}
				aria-hidden={!expanded}
			>
				{status !== UNMOUNTED && <div className={styles.contentWrapper}>{children}</div>}
			</div>
		</>
	);
};
