import { useState, useRef, useCallback } from 'react';

export type AccordionOptions = {
	enterDelayInMs?: number;
	exitDelayInMs?: number;
};

type UseDelayedRenderProps = {
	(
		active: boolean,
		options?: AccordionOptions,
	): { mounted: boolean; rendered: boolean; setActive: React.Dispatch<React.SetStateAction<boolean>> };
};

/**
 * `useDelayedRender` is a react hook for delaying the render and unmount of a component.
 * This is commonly used to animate UI on unmount.
 *
 * Options:
 * - `active`: Whether your component is in an active state
 * - `enterDelay`: After mounting, the delay before rendered becomes true
 * - `exitDelay`: After rendered becomes false, the delay before unmounting
 *
 * Return values:
 * - `mounted`: Whether your component should be mounted in the DOM
 * - `rendered`: Whether your component should be visible/animated
 */

const useDelayedRender: UseDelayedRenderProps = (_active = false, options: AccordionOptions = {}) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [, force] = useState<any>();
	const [active, setActive] = useState(_active);
	const mounted = useRef(_active);
	const rendered = useRef(_active);
	const prevActive = useRef(_active);
	const renderTimer = useRef<NodeJS.Timeout | null>(null);
	const unmountTimer = useRef<NodeJS.Timeout | null>(null);

	const recalculate = useCallback(() => {
		const { enterDelayInMs = 0, exitDelayInMs = 0 } = options;

		if (prevActive.current) {
			// Mount immediately
			mounted.current = true;
			if (unmountTimer.current) clearTimeout(unmountTimer.current);

			if (enterDelayInMs <= 0) {
				// Render immediately
				rendered.current = true;
			} else {
				if (renderTimer.current) return;

				// Render after a delay
				renderTimer.current = setTimeout(() => {
					rendered.current = true;
					renderTimer.current = null;
					force({});
				}, enterDelayInMs);
			}
		} else {
			// Immediately set to unrendered
			rendered.current = false;

			if (exitDelayInMs <= 0) {
				mounted.current = false;
			} else {
				if (unmountTimer.current) return;

				// Unmount after a delay
				unmountTimer.current = setTimeout(() => {
					mounted.current = false;
					unmountTimer.current = null;
					force({});
				}, exitDelayInMs);
			}
		}
	}, [options]);

	// When the active prop changes, need to re-calculate
	if (active !== prevActive.current) {
		prevActive.current = active;
		// We want to do this synchronously with the render, not in an effect
		// this way when active → true, mounted → true in the same pass
		recalculate();
	}

	return {
		mounted: mounted.current,
		rendered: rendered.current,
		setActive,
	};
};

export default useDelayedRender;
