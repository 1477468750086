import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import classNames from 'classnames';
import { Icon } from 'components/shared/Icon';
import { ResourceLocation, Url } from 'generated/data-contracts';
import { isScannerApp } from 'helpers/app';
import { capitalize } from 'helpers/stringHelpers';
import styles from './Link.module.scss';

export type LinkSizeType = 'xs' | 'sm' | 'md' | 'lg';
export type LinkWeightType = 'regular' | 'bold';

export interface LinkProps extends Partial<RouterLinkProps> {
	className?: string;
	linkUrl?: Url;
	route?: ResourceLocation;
	mailTo?: string;
	size?: LinkSizeType;
	weight?: LinkWeightType;
	isTextLink?: boolean;
	isRichTextLink?: boolean;
	hasExternalIconHidden?: boolean;
	hasMinHeight?: boolean;
}

const getParamsFromURI = (uri): [string, URLSearchParams] => {
	// Get everything after the `?`
	const [linkPath, paramString] = uri.split('?');

	// Return parameters
	return [linkPath, new URLSearchParams(paramString)];
};

export const Link: React.FunctionComponent<LinkProps> = ({
	children,
	className,
	to,
	linkUrl,
	target,
	route,
	title,
	mailTo,
	isTextLink,
	isRichTextLink,
	size,
	weight,
	hasExternalIconHidden,
	hasMinHeight,
	...props // For passing props inherited from HTMLAnchorElement
}) => {
	const cssClasses = classNames(
		styles.link,
		{
			[styles.isTextLink]: isTextLink,
			[styles.isRichTextLink]: isRichTextLink,
			[styles.hasMinHeight]: hasMinHeight,
		},
		size && styles['size' + capitalize(size)],
		weight && styles['weight' + capitalize(weight)],
		className,
	);

	if (to || route) {
		return (
			<RouterLink
				className={cssClasses}
				to={to || route?.externalRoute || ''}
				state={route}
				target={target}
				title={title}
				{...props}
			>
				{children}
			</RouterLink>
		);
	}

	if (linkUrl || mailTo) {
		// Set target to _blank by default on external links
		let setTarget = target;
		const [url, urlParams] = getParamsFromURI(linkUrl ?? `mailto:${mailTo}`);

		if (url[0] !== '/') {
			setTarget = '_blank';
		}

		if (isScannerApp && setTarget === '_blank') {
			// If the link is external and the app is the scanner app, open the link in the browser.
			// Makeable has implemented a feature where the scanner app can open links in the browser by using `openInBrowser=true`.
			urlParams.append('openInBrowser', 'true');
		}
		const setLink = `${url}?${urlParams.toString()}`;

		const showExternalIcon = setTarget === '_blank' && typeof children === 'string' && !hasExternalIconHidden;

		return (
			<a className={cssClasses} href={setLink} target={setTarget} title={title} {...props}>
				{children}
				{showExternalIcon && <Icon name={'external'} size={'md'} />}
			</a>
		);
	}

	return (
		<span className={cssClasses} title={title} {...props}>
			{children}
		</span>
	);
};
