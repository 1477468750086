import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Icon } from 'components/shared/Icon';
import { Link } from 'components/shared/Link';
import { Tooltip, TooltipTrigger, TooltipContent } from 'components/shared/Tooltip';
import { Area, PageType, ResourceLocation } from 'generated/data-contracts';
import { useOrderLineContext } from '../../state/useOrderLineContext';
import { SkeletonButton } from '../SkeletonButton';
import styles from './ViewOrderButton.module.scss';

export interface ViewOrderButtonProps {
	className?: string;
	buttonClassName?: string;
}

export const ViewOrderButton: React.FunctionComponent<ViewOrderButtonProps> = ({ className, buttonClassName }) => {
	const { data: translations } = useTranslationQuery();
	const {
		isSkeleton,
		orderLineDetails: { url },
	} = useOrderLineContext();
	if (isSkeleton) return <SkeletonButton />;

	// Currently this button does nothing, as the url is always undefined until we have the OrderDetails page
	const disableViewOrderButton = !url;
	const route: ResourceLocation | undefined = url
		? {
				area: Area.StaticPages,
				pageType: PageType.OrderDetail,
				externalRoute: url,
		  }
		: undefined;
	const ButtonComponent = (
		<Link
			size="sm"
			to={url}
			route={route}
			className={classNames(styles.viewOrderButton, buttonClassName, {
				[styles.disabled]: disableViewOrderButton,
			})}
		>
			{translations?.myOrders.orderHistoryLine.viewOrder}
			<Icon name="chevronDown" size={'sm'} rotate={270} />
		</Link>
	);

	if (!disableViewOrderButton) {
		return <span className={classNames(className, styles.container)}>{ButtonComponent}</span>;
	}
	return (
		<Tooltip placement="bottom">
			<TooltipTrigger
				className={classNames(className, styles.container, {
					[styles.disabled]: disableViewOrderButton,
				})}
			>
				{ButtonComponent}
			</TooltipTrigger>
			<TooltipContent className={styles.tooltipText}>
				{translations?.myOrders.orderHistoryLine.viewOrdersButtonDisabled}
			</TooltipContent>
		</Tooltip>
	);
};
