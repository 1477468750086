import React, { PropsWithChildren, useRef } from 'react';
import classNames from 'classnames';
import styles from './ZoomableImage.module.scss';
import { useZoomable } from './useZoomable';

export const ZoomableWrapper: React.FunctionComponent<PropsWithChildren<unknown>> = ({ children }) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const wrapperRef = useRef<HTMLDivElement | null>(null);

	useZoomable({ ref, wrapperRef });

	return (
		<>
			<div
				className={styles.imageWrapper}
				ref={wrapperRef}
				style={{
					overflow: 'clip',
				}}
			>
				<div className={classNames(styles.image)} ref={ref}>
					{children}
				</div>
			</div>
		</>
	);
};
