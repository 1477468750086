import React from 'react';
import classNames from 'classnames';
import styles from './DrawerFooter.module.scss';

export interface DrawerFooterProps {
	className?: string;
	children?: React.ReactNode;
	isSticky?: boolean;
}

export const DrawerFooter: React.FunctionComponent<DrawerFooterProps> = ({ className, isSticky, children }) => {
	return (
		<footer className={classNames(styles.footer, { [styles.isSticky]: isSticky }, className)}>{children}</footer>
	);
};
