import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';
import { useLayoutQuery } from 'api/layout';
import { useBasketQuery } from 'api/newBasket';
import { useTranslationQuery } from 'api/translations';
import { AlertTypes } from 'components/shared';
import {
	Area,
	BasketFilterType,
	BasketGroupingType,
	BasketResponse,
	ErrorResult,
	ErrorResultType,
	ExtraModelsResponse,
	PageType,
} from 'generated/data-contracts';
import { formatTranslation } from 'helpers/stringHelpers';
import { useViewportSize } from 'helpers/useViewportSize';
import { appActions } from 'store/actions/appActions';

type UseBasketDataReturnProps = {
	isSDO: boolean;
	isFetching: boolean;
	isInitialLoading: boolean;
	basket: BasketResponse;
	error: boolean;
	currentDeliveryDate?: string | null;
	refetchBasket: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
	) => Promise<QueryObserverResult<void | BasketResponse | ExtraModelsResponse | ErrorResult, unknown>>;
	filter: BasketFilterType;
};

type ShipmentFilter = {
	shipmentFilterType: BasketFilterType;
	shipmentFilterValue: string;
};

export const useBasketData = (): UseBasketDataReturnProps => {
	const [searchParams] = useSearchParams();
	const { isMobile } = useViewportSize();
	const updatedParams = new URLSearchParams(searchParams.toString());
	const currentDeliveryDate = updatedParams.get('deliveryDate');
	const currentShipmentFilter = currentDeliveryDate ? BasketFilterType.DeliveryDate : BasketFilterType.All;

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { data: translations } = useTranslationQuery();
	const { data: layout } = useLayoutQuery();

	const accountSelectorUrl = layout?.staticLinks.find((link) => link.pageType === PageType.AccountSelector)?.url;

	let deliveryDateObject: ShipmentFilter | undefined;
	if (currentDeliveryDate) {
		deliveryDateObject = {
			shipmentFilterType: currentShipmentFilter,
			shipmentFilterValue: currentDeliveryDate || 'All',
		};
	}

	let groupingTypeObject: BasketGroupingType | undefined;
	if (isMobile) groupingTypeObject = BasketGroupingType.ShipTo;

	const query = useBasketQuery({ ...deliveryDateObject, groupingType: groupingTypeObject });
	const { isFetching, data, isInitialLoading, refetch, isLoadingError } = query;

	useEffect(() => {
		if (isLoadingError) {
			dispatch(
				appActions.addNotification({
					children: formatTranslation(translations?.basket.basketNotFoundError, {}),
					type: AlertTypes.DANGER,
				}),
			);
			navigate(
				{ pathname: accountSelectorUrl },
				{ state: { pageType: PageType.AccountSelector, area: Area.StaticPages } },
			);
		}
	}, [accountSelectorUrl, dispatch, isLoadingError, navigate, translations?.basket.basketNotFoundError]);

	const basket = data as BasketResponse;
	const isSDO = basket?.basketShipTos.length === 1;

	let error = false;
	if ((data as ErrorResult)?.type === ErrorResultType.PresentationBrandBadRequest) error = true;

	return {
		isSDO,
		isFetching,
		isInitialLoading,
		basket,
		error,
		currentDeliveryDate,
		refetchBasket: refetch,
		filter: currentShipmentFilter,
	};
};
