import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { BasketSelectorCardDataProps } from 'components/fragments/BasketSelector/BasketSelectorCard';
import { Badge, Button, Icon } from 'components/shared';
import styles from './BasketSelectorCardAccounts.module.scss';

interface BasketSelectorCardAccountsProps {
	data: BasketSelectorCardDataProps;
}

export const BasketSelectorCardAccounts: React.FunctionComponent<BasketSelectorCardAccountsProps> = ({ data }) => {
	const { data: translations } = useTranslationQuery();

	const [isShipTosExpanded, setIsShipTosExpanded] = useState(false);
	const displayedShipTos = data?.shipTos?.length ? (isShipTosExpanded ? data.shipTos : [data.shipTos[0]]) : [''];

	const toggleShipTos = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		event.currentTarget.blur();

		setIsShipTosExpanded((prevExpanded) => !prevExpanded);
	};

	return (
		<div className={classNames(styles.contentShipTos, { [styles.isDisabled]: !data.otherShipTosCount })}>
			<div className={styles.shipToRows}>
				{displayedShipTos.map((shipTo) => (
					<div className={styles.shipTo} key={`ship-to-${shipTo}`}>
						<div className={styles.shipToSection}>
							<Icon name="shipTo" size="md" />
							<span className={styles.shipToName}>{shipTo}</span>
						</div>
					</div>
				))}
			</div>

			{!!data.otherShipTosCount && (
				<div className={styles.otherShipTos}>
					{!isShipTosExpanded && <Badge value={data.otherShipTosCount} />}

					<Button
						className={styles.extendShipTos}
						hasOnlyIcon
						size="xs"
						variant="ghost"
						title={isShipTosExpanded ? translations?.shared.collapse : translations?.shared.expand}
						onClick={toggleShipTos}
					>
						<Icon name="chevronDown" size="md" {...(isShipTosExpanded && { rotate: 180 })} />
					</Button>
				</div>
			)}
		</div>
	);
};
