import React from "react";
import {
	autoUpdate,
	flip,
	offset as calculateOffset,
	shift,
	useDismiss,
	useFloating,
	useFocus,
	useHover,
	useInteractions,
	useRole,
} from "@floating-ui/react-dom-interactions";
import { TooltipOptions } from "./Tooltip";

/* https://floating-ui.com/docs/react-dom-interactions*/
export function useTooltip({
	delay = 80,
	initialOpen = false,
	offset = 10,
	onOpenChange: setControlledOpen,
	open: controlledOpen,
	placement = "right",
	showArrow = false,
	strategy = "fixed",
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
TooltipOptions = {}): any {
	const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

	const open = controlledOpen ?? uncontrolledOpen;
	const setOpen = setControlledOpen ?? setUncontrolledOpen;

	const data = useFloating({
		placement,
		open,
		onOpenChange: setOpen,
		whileElementsMounted: autoUpdate,
		middleware: [calculateOffset(offset), flip(), shift()],
		strategy,
	});
	const context = data.context;

	const hover = useHover(context, {
		move: false,
		enabled: controlledOpen == null,
		delay,
	});
	const focus = useFocus(context, {
		enabled: controlledOpen == null,
	});
	const dismiss = useDismiss(context);
	const role = useRole(context, { role: "tooltip" });
	const interactions = useInteractions([hover, focus, dismiss, role]);

	return React.useMemo(
		() => ({
			open,
			showArrow,
			setOpen,
			...interactions,
			...data,
		}),
		[open, showArrow, setOpen, interactions, data],
	);
}
