import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { createBrowserRouter, Route, RouterProvider, Routes } from 'react-router-dom';
import { EnhancedStore } from '@reduxjs/toolkit';
import { broadcastQueryClient } from '@tanstack/query-broadcast-client-experimental';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { setAllQueryDefaults } from 'api/apiConfig';
import { getRoutingList } from 'api/routing';
import { PDFExportContextProvider } from 'components/fragments/PDFExport/context/PDFExportContext';
import { HideWhsPriceSettingsProvider } from 'components/shared/hooks/useHideWhsPriceSettings';
import { ZoomCorrectionProvider } from 'components/shared/hooks/useZoomCorrectionSettings';
import { BrowserDeviceType, ProductBasketResponse, ResourceLocation, RouteResponse } from 'generated/data-contracts';
import { HttpResponse } from 'generated/http-client';
import { isScannerApp } from 'helpers/app';
import { InitialState } from 'store/types';
import Router from './Router';
import './builder-settings';
import { ViewportSizeProvider } from './helpers';
import { setUpStore } from './store';

interface WindowProps extends Window {
	initialData: {
		routing: ResourceLocation | null;
		page: {
			props: {
				page: React.ReactElement;
			};
		};
		locale: string;
		isProduction?: boolean;
	};
	initialReduxState: InitialState;
	ssrError: { msg: string };
	store: EnhancedStore;
}

declare const window: WindowProps;
const initialData = window.initialData || { routing: null, page: null, locale: 'en' };
const initialState = window.initialReduxState || null;
const ssrError = window.ssrError || { msg: null };
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 5 * 1000, // global default to prevent duplicate queries across render cycles
		},
	},
});

if (isScannerApp) {
	broadcastQueryClient({
		queryClient: queryClient,
		broadcastChannel: 'bestsellerApp',
	});
}

setAllQueryDefaults(queryClient);
let basketTotalQuantity: number;
let store: EnhancedStore;
let routing: HttpResponse<RouteResponse, unknown>;

function getRouting(): Promise<void | Record<string, string>> {
	const locationPath = window.location.pathname + window.location.search;

	const query = { path: locationPath };

	return getRoutingList(query).then((response) => {
		routing = response;

		if (window.location.pathname !== routing.data.route.externalRoute)
			window.history.replaceState({}, '', routing.data.route.externalRoute);
	});
}

function renderPage(): void {
	const container = document.getElementById('react-app');
	if (!container) {
		throw new Error('react-app-element not found!');
	}

	function Root(): JSX.Element {
		return (
			<Routes>
				<Route
					path="*"
					element={
						<Router
							page={initialData.page?.props?.page as React.ReactElement}
							routingData={initialData.routing || routing?.data.route}
							isNotFound={routing?.data.isNotFound ?? false}
							{...(routing?.data.userMessage && { userMessage: routing?.data.userMessage })}
							routingError={routing?.error}
							segmentationId={initialState?.app.segmentationId}
						/>
					}
				/>
			</Routes>
		);
	}
	const router = createBrowserRouter([{ path: '*', Component: Root }]);

	const app = (
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<HelmetProvider>
					<ZoomCorrectionProvider>
						<HideWhsPriceSettingsProvider>
							<ViewportSizeProvider>
								<PDFExportContextProvider>
									<RouterProvider router={router} />
								</PDFExportContextProvider>
							</ViewportSizeProvider>
						</HideWhsPriceSettingsProvider>
					</ZoomCorrectionProvider>
				</HelmetProvider>
			</Provider>
			<ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
		</QueryClientProvider>
	);
	if (!initialState) {
		const root = createRoot(container);
		root.render(app);
	} else {
		hydrateRoot(container, app);
	}
}

if (ssrError.msg) {
	console.error('SSR Failed', ssrError.msg);
}

if (!initialState) {
	getRouting().then(() => {
		store = setUpStore({
			basket: {
				basket: null,
				pdpBasket: {} as ProductBasketResponse,
				preBasket: { familyId: '', basketLines: [], basketDeliveryDate: '' },
				miniBasketQuantity: basketTotalQuantity,
			},
			app: {
				segmentationId: routing.data.segmentationId,
				showOverlay: false,
				notifications: [],
				pageOrigin: window.location.hostname,
				device: BrowserDeviceType.Desktop,
			},
		});

		window.store = store;

		renderPage();
	});
} else {
	store = setUpStore(initialState);
	window.store = store;
	renderPage();
}
