import React from 'react';
import { createContext } from 'react';

export type NewTabsContextType = {
	tabList: { label: string; id: number }[];
	tabRefs: React.MutableRefObject<(HTMLButtonElement | null)[]>;
	selectedTab: number;
	handleKeyPress: (event: React.KeyboardEvent) => void;
	handleSelectTab: (index: number) => void;
	animationTime: number;
	indicatorLabel: string;
};

type NewTabsProviderProps = {
	tabList: { label: string; id: number }[];
	initiallySelectedTab?: number;
	children: React.ReactNode;
};

export interface TabItem {
	label: string;
	id: number;
}

export const NewTabsContext = createContext<NewTabsContextType | undefined>(undefined);

export const NewTabsProvider = ({ initiallySelectedTab = 0, tabList, children }: NewTabsProviderProps) => {
	const [selectedTab, setSelectedTab] = React.useState(initiallySelectedTab);
	const [selectedTabText, setSelectedTabText] = React.useState(tabList[initiallySelectedTab]?.label);
	const [animationTime, setAnimationTime] = React.useState(0);
	const ANIMATION_TIME = 500;
	React.useEffect(() => {
		// This is to ensure that the content doesn't fade in on the first render
		setTimeout(() => setAnimationTime(ANIMATION_TIME), ANIMATION_TIME);
	}, []);
	const tabRefs = React.useRef<(HTMLButtonElement | null)[]>([]);

	const handleSelectTab = (index): void => {
		setSelectedTab(index);
		// Set the label of the indicator to the selected tab text when it's in the middle of it's transition
		setTimeout(() => setSelectedTabText(tabList[index].label), ANIMATION_TIME / 2);
	};

	// Helper to calculate correct next or prev tab
	const handleTabSelection = (firstTabInRound: number, nextTab: number, lastTabInRound: number): void => {
		const tabToSelect = selectedTab === lastTabInRound ? firstTabInRound : nextTab;
		handleSelectTab(tabToSelect);
		const tabRef = tabRefs.current[tabToSelect];
		if (tabRef) {
			tabRef.focus();
		}
	};

	// Handle navigation with arrow keys
	const handleKeyPress = (event): void => {
		const tabCount = tabList.length - 1;

		if (event.key === 'ArrowLeft') {
			const last = tabCount;
			const next = selectedTab - 1;
			handleTabSelection(last, next, 0);
		}
		if (event.key === 'ArrowRight') {
			const first = 0;
			const next = selectedTab + 1;
			handleTabSelection(first, next, tabCount);
		}
	};
	return (
		<NewTabsContext.Provider
			value={{
				handleKeyPress,
				handleSelectTab,
				animationTime,
				selectedTab,
				tabList,
				tabRefs,
				indicatorLabel: selectedTabText,
			}}
		>
			{children}
		</NewTabsContext.Provider>
	);
};

export const useNewTabsState = (): NewTabsContextType => {
	const context = React.useContext(NewTabsContext);
	if (!context) {
		throw new Error('useNewTabsState must be used within a NewTabsProvider');
	}
	return context;
};
