import React from 'react';
import { uniqueId } from 'lodash';
import { OrderHistoryProductMasterResponse } from 'generated/data-contracts';
import { SizeSplitCell } from '../SizeSplitCell';
import { ColumnKeys, ColumnType, RowType, useSizeSplitContext } from '../context/SizeSplitContext';
import styles from './SizeSplitRow.module.scss';

export interface SizeSplitRowProps {
	variant: RowType;
	master: OrderHistoryProductMasterResponse;
}

export const SizeSplitRow: React.FunctionComponent<SizeSplitRowProps> = ({ variant, master }) => {
	const { is2DimensionalTable, columns } = useSizeSplitContext();
	const dynamicColumns = columns.filter((column) => {
		const excludedColumns: string[] = [ColumnKeys.COLOR, ColumnKeys.LENGTH, ColumnKeys.SIZE];
		return !excludedColumns.includes(column.key);
	});
	const getCurrentCell = (column: ColumnType<ColumnKeys>) => {
		const cell = master.variants.find((row) => {
			if (is2DimensionalTable) {
				return row.size === column.key && row.length === (is2DimensionalTable ? variant.length : undefined);
			}
			return row.size === variant.size;
		});
		return cell;
	};

	const key = React.useRef(uniqueId('size-split-row-')).current;
	return (
		<div className={styles.row} role="row">
			<SizeSplitCell className={styles.rowHeader}>
				<span>{is2DimensionalTable ? variant?.length : variant.size}</span>
			</SizeSplitCell>
			{dynamicColumns.map((column, columnIndex) => {
				const cell = getCurrentCell(column);
				return (
					<SizeSplitCell key={`${key}-table-${master.id}-${columnIndex}`}>
						<span>{cell?.quantity}</span>
					</SizeSplitCell>
				);
			})}
		</div>
	);
};
