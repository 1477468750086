import { useCallback, useEffect } from 'react';

/**
 * React hook to handle a scrollEnd event, as Safari has special needs as usual and does not support the 'scrollEnd' event
 *
 * @param ref The React ref for the element you want the eventlistener to be added on
 * @param callback The event handler you want to be executed after scrolling has stopped
 */
export const useScrollEnd = <T extends HTMLElement>(
	ref: React.MutableRefObject<T | null>,
	callback: () => void,
): void => {
	const DELTA_TIME_THRESHOLD_MS = 300;

	const createListeners = useCallback((): {
		start: (event: Event) => void;
		move: (event: Event) => void;
		pointerUp: (event: Event) => void;
	} => {
		let draggingScroll = false;
		let timeout: NodeJS.Timeout | undefined = undefined;

		const executeCallback = (): void => {
			clearTimeout(timeout);
			timeout = undefined;
			draggingScroll = false;
			callback();
		};
		return {
			start: (): void => {
				draggingScroll = true;
			},
			move: (): void => {
				clearTimeout(timeout);
				timeout = setTimeout(() => {
					if (!draggingScroll) {
						executeCallback();
					}
				}, DELTA_TIME_THRESHOLD_MS);
			},
			pointerUp: (): void => {
				draggingScroll = false;
				clearTimeout(timeout);
				timeout = setTimeout(() => {
					executeCallback();
				}, DELTA_TIME_THRESHOLD_MS);
			},
		};
	}, [callback]);

	useEffect(() => {
		const element = ref.current;
		if (!element) return;
		const scrollEventListener = createListeners();
		element.addEventListener('touchstart', scrollEventListener.start);
		element.addEventListener('scroll', scrollEventListener.move);
		element.addEventListener('touchend', scrollEventListener.pointerUp);

		return () => {
			element.removeEventListener('touchstart', scrollEventListener.start);
			element.removeEventListener('scroll', scrollEventListener.move);
			element.removeEventListener('touchend', scrollEventListener.pointerUp);
		};
	}, [createListeners, ref]);
};
