import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import CopyTextButton from 'components/shared/CopyTextButton';
import { useOrderLineContext } from '../../state/useOrderLineContext';
import styles from './OrderLineSummary.module.scss';

export interface OrderLineSummaryProps {}

export const OrderLineSummary: React.FunctionComponent<OrderLineSummaryProps> = () => {
	const { data: translations } = useTranslationQuery();
	const { orderLineDetails, productInfo } = useOrderLineContext();
	if (!translations) return null;
	const orderDetailsTranslation = translations.myOrders.orderDetails;
	const orderSummaryDetails: { label: string; value?: string | null; isBold?: boolean }[] = [
		{
			label: orderDetailsTranslation.orderStatus,
			value: orderLineDetails.orderStatus,
		},
		{
			label: orderDetailsTranslation.estimatedShipping,
			value: orderLineDetails.estimatedShippingDate,
		},
		{
			label: orderDetailsTranslation.warehouseEta,
			value: orderLineDetails.warehouseShippingETA,
		},
		{
			label: orderDetailsTranslation.platform,
			value: orderLineDetails.platform,
		},
	];

	return (
		<section className={styles.orderSummary}>
			<div className={styles.styleInfo}>
				<div className={styles.row}>
					<p>{orderDetailsTranslation.orderNumberShort}</p>
					<CopyTextButton
						className={classNames(styles.detail, styles.isBold)}
						copySuccessMessage={translations?.myOrders.orderDetails.copiedOrderNumber}
						text={orderLineDetails.orderNumber}
						title={translations?.myOrders.orderDetails.copyOrderNumber}
					/>
				</div>
				<div className={styles.row}>
					<p>{orderDetailsTranslation.styleNumber}</p>
					<CopyTextButton
						className={classNames(styles.detail)}
						copySuccessMessage={translations?.productDetails.copiedStyleNumber}
						text={productInfo?.styleNumber}
						title={translations?.productDetails.copyStyleNumber}
					/>
				</div>
				{orderSummaryDetails.map((detail, index) => {
					return (
						<div key={`order-info-row-${index}`} className={styles.row}>
							<p>{detail.label}</p>
							<span className={classNames(styles.detail, { [styles.isBold]: detail.isBold })}>
								{detail.isBold && '#'}
								{detail.value}
							</span>
						</div>
					);
				})}
			</div>
			<hr className={styles.divider} />
			<div className={styles.sum}>
				<div className={styles.sumColumn}>
					<p>{translations.myOrders.totalItems}</p>
					<span className={styles.isBold}>{orderLineDetails.totalItems}</span>
				</div>
				<div className={styles.sumColumn}>
					<p>{translations.myOrders.totalQuantity}</p>
					<span className={styles.isBold}>{orderLineDetails.quantity}</span>
				</div>
				<div className={styles.sumColumn}>
					<p>{translations.myOrders.totalValue}</p>
					<span className={styles.isBold}>{orderLineDetails.value}</span>
				</div>
			</div>
		</section>
	);
};
