import React from 'react';
import { useFavouritelistTotalQuery } from 'api/favouriteList';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import { Badge, IconLink } from 'components/shared';
import { Area, PageType } from 'generated/data-contracts';
import styles from './MiniFavourite.module.scss';

export const MiniFavourite: React.FunctionComponent = () => {
	const { data: layout } = useLayoutQuery();

	const staticLinks = layout?.staticLinks;

	const favouriteListUrl =
		staticLinks?.find((link) => link.pageType === PageType.FavouriteList)?.url +
		`?favouriteListId=${layout?.currentFavouriteListId}`;

	const { data: translations } = useTranslationQuery();

	const { data: totalQuantity = 0 } = useFavouritelistTotalQuery();

	const route = {
		area: Area.StaticPages,
		pageType: PageType.FavouriteList,
	};

	return (
		<IconLink
			className={styles.wrapper}
			icon={'favourite'}
			title={translations?.favouriteList.title || ''}
			to={favouriteListUrl}
			state={route}
		>
			<Badge className={styles.favouriteBadge} value={totalQuantity > 9999 ? '9999+' : totalQuantity} />
		</IconLink>
	);
};
