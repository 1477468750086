import React from "react";
import { ContextType, TooltipContext } from "./TooltipContext";

export const useTooltipState = (): ContextType => {
	const context = React.useContext(TooltipContext);

	if (context == null) {
		throw new Error("Tooltip components must be wrapped in <Tooltip />");
	}

	return context;
};
