import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useBrandGetByPresentationBrandQuery, useSetBrandMutation } from 'api/brand';
import { useLayoutQuery } from 'api/layout';
import BrandSelectSectionMobile from 'components/features/BrandSelector/BrandSelectorMobile/BrandSelectSectionMobile';
import { BrandResponse, SubBrandResponse } from 'generated/data-contracts';
import { messageToApp } from 'helpers/app';

export interface SubBrandSelectorMenuMobileProps {
	handleClose: () => void;
}

export const SubBrandSelectorMenuMobile: React.FunctionComponent<SubBrandSelectorMenuMobileProps> = () => {
	const { data: layout } = useLayoutQuery();
	const { data: brand } = useBrandGetByPresentationBrandQuery(
		layout?.segmentations[0]?.presentationBrandId || 0,
		Boolean(layout),
	);
	const setBrand = useSetBrandMutation();
	const [searchParams] = useSearchParams();

	const onChooseBrand = (_brand: BrandResponse | SubBrandResponse): void => {
		const body = {
			presentationBrandId: _brand.presentationBrandId,
			languageId: layout?.languageId,
			returnUrl: searchParams.get('returnUrl'),
		};

		setBrand.mutate(body, {
			onSuccess: (response) => {
				const frontPageRoute = response.data.route.externalRoute || '/';

				messageToApp({ type: 'presentationBrandChanged', presentationBrandId: _brand.presentationBrandId });

				// Navigate with full page reload to re-initialize all the data in the store
				window.location.href = window.location.origin + frontPageRoute;
			},
		});
	};
	return (
		<div>
			{brand && (
				<BrandSelectSectionMobile
					brand={brand}
					brandLogoHeight={'6rem'}
					lightColorMode={true}
					onChooseBrand={onChooseBrand}
					isInsideDrawer
				/>
			)}
		</div>
	);
};
