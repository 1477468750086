import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { Button, Icon } from 'components/shared';
import { ConfirmDialogProps } from 'components/shared/ConfirmDialog';
import styles from './DeleteBasketConfirmDialog.module.scss';

export const DeleteBasketConfirmDialog: React.FunctionComponent<ConfirmDialogProps> = ({
	confirm,
	reject,
	labelId,
	descriptionId,
}) => {
	const { data: translations } = useTranslationQuery();

	return (
		<section>
			<header className={styles.header}>
				<h2 id={labelId} className={styles.title}>
					{translations?.openBaskets.delete.deleteToggle}
				</h2>
				<Button
					aria-label={translations?.shared.close}
					title={translations?.shared.close}
					variant={'outline'}
					size={'sm'}
					hasOnlyIcon
					onClick={reject}
				>
					<Icon name="close" size="xs" />
				</Button>
			</header>
			<div id={descriptionId} className={styles.description}>
				<p>{translations?.openBaskets.delete.confirmDeleteSelectedBasketsDescription}</p>
			</div>

			<footer className={styles.actions}>
				{/* eslint-disable-next-line jsx-a11y/no-autofocus */}
				<Button size="sm" variant="ghost" onClick={reject} autoFocus>
					{translations?.shared.cancel}
				</Button>

				<Button size="sm" className={styles.buttonConfirm} onClick={confirm}>
					{translations?.openBaskets.delete.deleteSubmit}
				</Button>
			</footer>
		</section>
	);
};
