import { Builder } from '@builder.io/react';
import { iconNames, iconSizes } from 'components/shared';
import { Icon } from '../Icon';

Builder.registerComponent(Icon, {
	name: 'Icon',
	image: new URL('/dist/images/cms/icon.svg', window.location.origin).href,
	inputs: [
		{
			type: 'string',
			name: 'icon',
			required: true,
			enum: Array.from(iconNames),
			defaultValue: 'uspTruck',
		},
		{
			type: 'string',
			name: 'size',
			enum: Array.from(iconSizes),
			defaultValue: 'md',
		},
		{
			type: 'string',
			name: 'iconLabel',
			helperText: `Aria label for icon (used for accessibility). Add this e.g. if the icon is used as the only content
			in a button or link.`,
		},
	],
});
