import React, { PropsWithChildren } from 'react';
import throttle from 'lodash/throttle';
import { useZoomCorrectionSettings } from 'components/shared/hooks/useZoomCorrectionSettings';
import { BreakpointTypes, getMediaPure } from './cssVariables';
import { getMatchMedia } from './dom';

type UseViewportSizeState = {
	size: BreakpointTypes | undefined;
	isMobile: boolean;
};

const useViewportSizeState = () => {
	const { isZoomCorrectionEnabled } = useZoomCorrectionSettings();
	const [size, setSize] = React.useState<BreakpointTypes>();
	const doSizing = React.useCallback(() => {
		const doSizingInner = throttle(() => {
			const mediaPure = {
				xs: getMediaPure('xs'),
				sm: getMediaPure('sm'),
				md: getMediaPure('md'),
				lg: getMediaPure('lg'),
				xl: getMediaPure('xl'),
			};

			if (getMatchMedia(mediaPure.xl.min)) {
				setSize('xl');
			} else if (getMatchMedia(mediaPure.lg.min)) {
				setSize('lg');
			} else if (getMatchMedia(mediaPure.md.min)) {
				setSize('md');
			} else if (getMatchMedia(mediaPure.sm.min)) {
				setSize('sm');
			} else {
				setSize('xs');
			}
		}, 100);

		doSizingInner();
	}, []);

	React.useEffect(() => {
		doSizing();
		window.addEventListener('resize', doSizing);

		return () => {
			window.removeEventListener('resize', doSizing);
		};
	}, [doSizing, isZoomCorrectionEnabled]);
	const mobileSizes: (BreakpointTypes | undefined)[] = ['xs', 'sm'];
	return {
		size,
		isMobile: mobileSizes.includes(size),
	};
};

const ViewportSizeContext = React.createContext<UseViewportSizeState | null>(null);
export const ViewportSizeProvider: React.FunctionComponent<PropsWithChildren<object>> = ({ children }) => {
	const viewportSizeState = useViewportSizeState();
	return <ViewportSizeContext.Provider value={viewportSizeState}>{children}</ViewportSizeContext.Provider>;
};

/**
 * Returns whether viewport matches specific breakpoint and listens for resize events
 *
 * Example use:
 * 	const viewportSize = useViewportSize();
 *
 *  const currentBreakpoint = viewportSize.size;
 *  const isMobile = !viewportSize.matchesSize(['sm']);
 * */
export const useViewportSize = (): {
	size: BreakpointTypes | undefined;
	isMobile: boolean;
	matchesSize: (options: (BreakpointTypes | undefined)[]) => boolean;
} => {
	const viewportSizeState = React.useContext(ViewportSizeContext);

	if (!viewportSizeState) {
		throw new Error('useViewportSize must be used within a ViewportSizeProvider');
	}
	const match = (options: (BreakpointTypes | undefined)[]): boolean => options.includes(viewportSizeState.size);

	return {
		size: viewportSizeState.size,
		isMobile: viewportSizeState.isMobile,
		matchesSize: match,
	};
};
