import React, { ChangeEvent, useState } from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { ShipToInfoResponse } from 'generated/data-contracts';
import { formatTranslation } from 'helpers/stringHelpers';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { InputCheckbox } from '../InputCheckbox';
import styles from './SelectedShipTos.module.scss';

interface SelectedShipTosProps {
	isExpanded?: boolean;
	handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
	selectedShipTos: ShipToInfoResponse[];
	onToggleClick: () => void;
}

export const SelectedShipTos: React.FunctionComponent<SelectedShipTosProps> = ({
	isExpanded = false,
	selectedShipTos,
	onToggleClick,
	handleChange,
}) => {
	const { data: translations } = useTranslationQuery();
	const [isShipToExpanded, setIsShipToExpanded] = useState(isExpanded);

	React.useEffect(() => {
		setIsShipToExpanded(isExpanded);
	}, [isExpanded]);

	return (
		<>
			<div className={styles.selectedListHeading}>
				<div>
					{formatTranslation(translations?.accountSelector.accountsInBasket, {
						0: selectedShipTos.length,
					})}
				</div>

				<div>
					<Button variant={'ghost'} className={styles.toggleBtn} hasNoStyles onClick={onToggleClick}>
						{translations?.accountSelector.viewAccounts}

						<Icon name={'chevronDown'} rotate={isShipToExpanded ? 180 : undefined} size="md" />
					</Button>
				</div>
			</div>
			{isShipToExpanded && (
				<ul className={styles.list}>
					{selectedShipTos.map((item, index) => (
						<li
							key={`selected-${item.id}-${index}`}
							className={classNames(styles.shipToItem, styles.selected)}
						>
							<InputCheckbox
								onChange={handleChange}
								checkBoxClassName={styles.checkBox}
								name="shipToIds"
								value={item.id}
								label={
									<div>
										<span>{item.name}</span>
										<div className={styles.shipToInfo}>
											<span>
												{translations?.basket.accountId}: {item.id}
											</span>
											<span>
												{item.streetAndNumber} {item.postalCode} {item.city}
											</span>
										</div>
									</div>
								}
								checked={true}
							/>
						</li>
					))}
				</ul>
			)}
		</>
	);
};
