import React, { FC, PropsWithChildren, useRef } from 'react';
import { isEqual, uniq } from 'lodash';
import { useShiptoSelectedListQuery } from 'api/shipTo';
import { useSessionStorage } from 'components/shared/hooks/useLocalStorage';
import { OrderListUrlParamKeys } from '../constants/OrderListUrlParamKeys';


interface UseMyOrdersPageReturnType {
	selectedShipTos: string[];

	setSelectedShipTos: React.Dispatch<React.SetStateAction<string[]>>;
}

/** This maintains the selected accounts filter. */
const useMyOrdersPageState = (): UseMyOrdersPageReturnType => {
	const lastShipTosFromBasket = useRef<string[] | null>(null);
	const { data: shipTosFromBasket } = useShiptoSelectedListQuery();
	const [selectedShipTos, setSelectedShipTos] = useSessionStorage<string[]>(OrderListUrlParamKeys.ShipTo, []);
	React.useEffect(() => {
		setSelectedShipTos((prev) => {
			if (!shipTosFromBasket) return uniq(prev);
			const shipTos = shipTosFromBasket.shipTos.map((r) => r.id);
			if (lastShipTosFromBasket.current !== null && isEqual(lastShipTosFromBasket.current, shipTos)) {
				return uniq(prev);
			}
			if (shipTosFromBasket && lastShipTosFromBasket.current === null) {
				lastShipTosFromBasket.current = shipTos;
				if (prev.length > 0) return uniq(prev);
			}
			return uniq(shipTos);
		});
	}, [setSelectedShipTos, shipTosFromBasket]);

	return {
		selectedShipTos: uniq(selectedShipTos),
		setSelectedShipTos,
	};
};

const MyOrdersPageContext = React.createContext<UseMyOrdersPageReturnType | null>(null);

interface MyOrdersPageProviderProps {}

export const MyOrdersPageProvider: FC<PropsWithChildren<MyOrdersPageProviderProps>> = ({ children }) => {
	const value = useMyOrdersPageState();

	return <MyOrdersPageContext.Provider value={value}>{children}</MyOrdersPageContext.Provider>;
};

export const useMyOrdersPageContext = (): UseMyOrdersPageReturnType => {
	const context = React.useContext(MyOrdersPageContext);

	if (!context) {
		throw new Error('useMyOrdersPage must be used within a MyOrdersPageProvider');
	}

	return context;
};