import { Builder, withChildren } from '@builder.io/react';
import { Accordion } from '../Accordion';

const AccordionWithChildren = withChildren(Accordion);

Builder.registerComponent(AccordionWithChildren, {
	name: 'Accordion',
	image: new URL('/dist/images/cms/accordion.svg', window.location.origin).href,
	inputs: [
		{
			name: 'title',
			type: 'text',
			required: true,
			autoFocus: true,
			bubble: true,
			defaultValue: 'Accordion title...',
		},
		{
			name: 'isDefaultExpanded',
			type: 'boolean',
			defaultValue: false,
			friendlyName: 'Expand by default',
		},
	],
	noWrap: true,
	canHaveChildren: true,
	defaultChildren: [
		{
			'@type': '@builder.io/sdk:Element',
			responsiveStyles: {
				large: {
					padding: 'var(--spacingMd)',
				},
			},
			component: {
				name: 'Text',
				options: {
					text: `
					<p>
						I am some accordion content! You can edit this content or even delete it
						and insert your own instead.
					</p>
					`,
				},
			},
		},
	],
});
