import React from 'react';
import { Drawer } from 'components/shared/Drawer';
import { InvoicesDropdown } from '../OrderLineActions/InvoicesDropdown';
import { TrackOrdersDropdown } from '../OrderLineActions/TrackOrdersDropdown';
import { ViewOrderButton } from '../OrderLineActions/ViewOrderButton';
import { useOrderLineContext } from '../state/useOrderLineContext';
import styles from './OrderLineDrawer.module.scss';
import { OrderLineDrawerFooter } from './OrderLineDrawerFooter';
import { OrderLineDrawerHeader } from './OrderLineDrawerHeader';
import { OrderLineSizeSplit } from './OrderLineSizeSplit';
import { OrderLineSummary } from './OrderLineSummary';

export interface OrderLineDrawerProps {
	isOpen: boolean;
	onClose: () => void;
}

export const OrderLineDrawer: React.FunctionComponent<OrderLineDrawerProps> = ({ isOpen, onClose }) => {
	const { size } = useOrderLineContext();
	const side = size === 'sm' ? 'Bottom' : 'Right';
	if (!isOpen) return null;
	return (
		<Drawer
			onClose={onClose}
			direction={side}
			desktopWidth="26.25rem"
			height="90vh"
			desktopHeight="100vh"
			hasMobileHeight={side === 'Bottom'}
		>
			<OrderLineDrawerHeader onClose={onClose} />
			<div className={styles.container}>
				<OrderLineSizeSplit />
				<OrderLineSummary />
			</div>
			<OrderLineDrawerFooter
				TopActions={
					<>
						<TrackOrdersDropdown buttonClassName={styles.fullWidth} />
						<InvoicesDropdown buttonClassName={styles.fullWidth} />
					</>
				}
				BottomActions={<ViewOrderButton className={styles.fullWidth} />}
			/>
		</Drawer>
	);
};
