import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useTranslationQuery } from 'api/translations';
import { InputCheckbox, SearchBar } from 'components/shared';
import { Button } from 'components/shared/Button';
import { DrawerBody, DrawerFooter } from 'components/shared/Drawer';
import { BasketSearchResponse } from 'generated/data-contracts';
import { formatTranslation } from 'helpers/index';
import { useDebounce } from 'helpers/useDebounce';
import styles from './FilterValueView.module.scss';

interface CreateViewProps {
	filter: BasketSearchResponse;
	selectedValues: string[];
	activeValues: string[];
	setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>;
	handleApply: () => void;
	handleReset: () => void;
}

const FilterValueView: React.FunctionComponent<CreateViewProps> = ({
	filter,
	selectedValues,
	activeValues,
	setSelectedValues,
	handleApply,
	handleReset,
}) => {
	const { data: translations } = useTranslationQuery();
	const [searchPhrase, setSearchPhrase] = useState('');
	filter.values?.sort((a, b) => {
		if (!a.id || !b.id) return 0;
		if (activeValues.includes(a.id) && !activeValues.includes(b.id)) {
			return -1;
		} else if (!activeValues.includes(a.id) && activeValues.includes(b.id)) {
			return 1;
		}
		return 0;
	});
	const [displayedValues, setDisplayedValues] = useState(filter.values ?? []);

	useEffect(() => {
		setDisplayedValues(filter.values ?? []);
	}, [filter.values]);

	const includesLowercase = (mainText: string, textToInclude: string): boolean => {
		return mainText.toLocaleLowerCase().includes(textToInclude.toLocaleLowerCase());
	};

	const getResults = useDebounce((input: string) => {
		const displayedValuesNew = filter.values?.filter(
			(displayedValue) =>
				includesLowercase(displayedValue.name ?? '', input) ||
				includesLowercase(displayedValue.comment ?? '', input) ||
				includesLowercase(displayedValue.id ?? '', input),
		);
		setDisplayedValues(displayedValuesNew ?? []);
	}, 300);

	const handleSearchValueChange = (event): void => {
		const input: string = event.target.value;
		setSearchPhrase(input);
		getResults(input);
	};

	const handleSearchValueClear = (): void => {
		setSearchPhrase('');
		getResults('');
	};

	const toggleCheckbox = (value: string): void => {
		const newSelectedValues = [...selectedValues];

		const existingIndex = selectedValues.findIndex((selectedValue) => selectedValue === value);

		if (existingIndex !== -1) {
			newSelectedValues.splice(existingIndex, 1);
		} else {
			newSelectedValues.push(value ?? '');
		}
		setSelectedValues(newSelectedValues);
	};

	const toggleSelectAll = (): void => {
		let newSelectedValues = [...selectedValues];

		if (newSelectedValues.length) {
			newSelectedValues = [];
		} else {
			displayedValues.forEach((value) => {
				newSelectedValues.push(value.id ?? '');
			});
		}
		setSelectedValues(newSelectedValues);
	};

	return (
		<>
			<DrawerBody className={styles.body}>
				<SearchBar
					classNameInput={styles.searchBarInput}
					placeholder={filter.placeholder}
					value={searchPhrase}
					onChange={handleSearchValueChange}
					onClear={handleSearchValueClear}
				/>
				<div className={styles.list}>
					<div className={styles.listHeader}>
						<InputCheckbox
							checkBoxClassName={styles.checkBox}
							checked={selectedValues.length > 0}
							partiallyChecked={selectedValues.length != filter.values?.length}
							onChange={toggleSelectAll}
							label={
								selectedValues.length
									? translations?.shared.deselectAll
									: translations?.shared.selectAll
							}
							disabled={!displayedValues.length}
						/>
					</div>
					<div className={styles.listValues}>
						{displayedValues.map((value, index) => (
							<InputCheckbox
								className={styles.valueButton}
								key={`filter-value-${index}`}
								checkBoxClassName={styles.checkBox}
								checked={selectedValues.includes(value.id ?? '')}
								onChange={(): void => toggleCheckbox(value.id ?? '')}
								label={
									<div>
										<span>{value.name}</span>
										{value.comment && value.id && (
											<div className={styles.valueDescription}>
												<span>{value.comment}</span>
												<span>{`ID: ${value.id}`}</span>
											</div>
										)}
									</div>
								}
							/>
						))}
						{!displayedValues.length && (
							<div className={styles.noResultsContainer}>
								<span className={styles.noResultsTitle}>
									{formatTranslation(translations?.openBaskets.filters.noResultsFor, {
										0: !isEmpty(searchPhrase) ? searchPhrase : ' ',
									})}
								</span>
								<span className={styles.noResultsDescription}>
									{translations?.openBaskets.filters.noFiltersForSearch}
								</span>
							</div>
						)}
					</div>
				</div>
			</DrawerBody>

			<DrawerFooter className={styles.footer}>
				<Button type="submit" variant="dark" isFullWidth onClick={handleApply}>
					{translations?.openBaskets.filters.apply}
				</Button>

				<Button className={'u-mt-sm'} variant="ghost" isFullWidth onClick={handleReset}>
					{translations?.openBaskets.filters.resetFilters}
				</Button>
			</DrawerFooter>
		</>
	);
};

export default FilterValueView;
