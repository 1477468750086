import React, { ButtonHTMLAttributes } from 'react';
import { Button, ButtonSizeType, ButtonVariantType, Modal as DSModal, ModalSize, useModal } from 'components/shared';

interface ModalProps {
	children?: React.ReactElement;
	modalSize: ModalSize;
	buttonText?: string;
	buttonVariant?: ButtonVariantType;
	buttonSize?: ButtonSizeType;
	attributes?: Omit<Partial<ButtonHTMLAttributes<never>>, 'color'>;
}

/**
 * CMS Dialog component, used for showing CMS content in a dialog
 */
export const Modal: React.FC<ModalProps> = ({
	modalSize,
	children,
	buttonText,
	buttonVariant,
	buttonSize,
	attributes,
}) => {
	const modal = useModal();

	const handleOpen = React.useCallback((): void => {
		modal.showModal();
	}, [modal]);

	return (
		<>
			<Button variant={buttonVariant} size={buttonSize} onClick={handleOpen} {...attributes}>
				{buttonText}
			</Button>

			<DSModal {...modal} size={modalSize}>
				{children}
			</DSModal>
		</>
	);
};
