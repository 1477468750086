import { PageTrackingDataResponse, ProductTrackingDataResponse } from 'generated/data-contracts';

export const trackPage = (data: PageTrackingDataResponse): void => {
	const {
		userEmail,
		userId,
		userName,
		role,
		basketId,
		brandName,
		subBrandName,
		languageName,
		productLines,
		isMdo,
		shipTos,
	} = data;

	window.dataLayer = window.dataLayer || [];

	try {
		window.dataLayer.push({
			event: 'pageView',
			time_stamp: Date.now(),
			event_name: 'pageView',
			email: userEmail,
			userName: userName,
			userId: userId,
			titleRole: role,
			basketId,
			presentation_brand: brandName,
			presentation_sub_brand: subBrandName,
			presentation_brand_product_lines: productLines,
			language: languageName,
			is_mdo: isMdo,
			ship_tos: shipTos,
		});
	} catch (error) {
		console.error(error);
	}
};

export const trackDeleteBasket = (basketId: number[]): void => {
	window.dataLayer = window.dataLayer || [];

	basketId.forEach((id) => {
		try {
			window.dataLayer.push({
				event: 'deleteBasketFromCart',
				time_stamp: Date.now(),
				event_name: 'deleteBasketFromCart',
				basket_id: id,
			});
		} catch (error) {
			console.error(error);
		}
	});
};

export const trackProduct = (data: ProductTrackingDataResponse): void => {
	const { globalProductDataResponse, pageTrackingData } = data;

	window.dataLayer = window.dataLayer || [];

	try {
		window.dataLayer.push({
			event: 'productView',
			time_stamp: Date.now(),
			event_name: 'productView',
			email: pageTrackingData.userEmail,
			user_name: pageTrackingData.userName,
			user_id: pageTrackingData.userId,
			title_role: pageTrackingData.role,
			basket_id: pageTrackingData.basketId,
			presentation_brand: pageTrackingData.brandName,
			presentation_sub_brand: pageTrackingData.subBrandName,
			presentation_brand_product_Lines: pageTrackingData.productLines,
			language: pageTrackingData.languageName,
			is_mdo: pageTrackingData.isMdo,
			ship_tos: pageTrackingData.shipTos,
			item_id: globalProductDataResponse.styleId,
			item_name: globalProductDataResponse.styleName,
			brand_id: globalProductDataResponse.brandId,
			item_brand: globalProductDataResponse.brandName,
			product_line_id: globalProductDataResponse.productLineId,
			product_line_name: globalProductDataResponse.productLineName,
			master_category_id: globalProductDataResponse.masterCategoryId,
			master_category_name: globalProductDataResponse.masterCategoryName,
			collections: globalProductDataResponse.collections,
			master_sub_category_id: globalProductDataResponse.masterSubCategoryId,
			master_sub_category_name: globalProductDataResponse.masterSubCategoryName,
			master_colour_name: globalProductDataResponse.colourName,
			master_colour_code: globalProductDataResponse.colourCode,
			main_colour_name: globalProductDataResponse.mainColourName,
			main_colour_code: globalProductDataResponse.mainColourCode,
			sap_colour: globalProductDataResponse.sapColour,
			variant_colour_name: globalProductDataResponse.colourVariantText,
			pattern_id: globalProductDataResponse.patternId,
			pattern_name: globalProductDataResponse.patternName,
			style_option_number: globalProductDataResponse.styleOptionNumber,
			style_option_name: globalProductDataResponse.styleOptionName,
			business_model_id: globalProductDataResponse.businessModelId,
			business_model_name: globalProductDataResponse.businessModelName,
			gender_name: globalProductDataResponse.targetGroups,
			fit_name: globalProductDataResponse.fitName,
			sleeve_name: globalProductDataResponse.sleeveName,
			neck_name: globalProductDataResponse.neckName,
			materials: globalProductDataResponse.materials,
			quality_description: globalProductDataResponse.qualityDescription,
			sales_description: globalProductDataResponse.salesDescription,
		});
	} catch (error) {
		console.error(error);
	}
};
