import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { Button, ButtonProps } from 'components/shared/Button';
import styles from './DotButton.module.scss';

interface DotButtonProps extends Partial<ButtonProps> {
	selected?: boolean;
	index: number;
}

export const DotButton: React.FunctionComponent<DotButtonProps> = ({ selected, index, onClick }) => {
	const { data: translations } = useTranslationQuery();

	return (
		<Button
			className={styles.button}
			aria-label={`${translations?.shared.slide} ${index}`}
			aria-current={selected}
			onClick={onClick}
			hasNoStyles
		/>
	);
};
