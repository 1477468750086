import React from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { useLayoutQuery } from 'api/layout';
import { useQuickSearch } from 'api/search';
import { useTranslationQuery } from 'api/translations';
import { Link, ProductCard } from 'components/shared';
import { Area, PageType, QuickSearchResponse } from 'generated/data-contracts';
import styles from './Results.module.scss';

interface QuickSearchResultsProps {
	results: QuickSearchResponse;
}

export const QuickSearchResults: React.FunctionComponent<QuickSearchResultsProps> = ({ results }) => {
	const { data: translations } = useTranslationQuery();
	const { data: layout } = useLayoutQuery();
	const staticLinks = layout?.staticLinks;

	const [hoverResults, setHoverResults] = React.useState<QuickSearchResponse>(results);

	const { data: quickSearchResponse, isFetching } = useQuickSearch('');
	
	React.useEffect(() => {
		if (!isEmpty(quickSearchResponse)) {
			setHoverResults(quickSearchResponse);
		}
	}, [quickSearchResponse]);

	React.useEffect(() => {
		setHoverResults(results);
	}, [results]);

	const getSearchRoute = (phrase: string): { pathname: string; search: string } => {
		const url = staticLinks?.find((item) => item.pageType === PageType.Search)?.url || '';

		return {
			pathname: url,
			search: `?phrase=${encodeURIComponent(phrase)}`,
		};
	};

	return (
		<div className={styles.wrapper}>
			{hoverResults.products && (
				<div className={styles.column}>
					<div className={styles.header}>
						<div className={styles.headerInfo}>
							<h3 className={styles.heading}>{translations?.shared.quicksearch.products}</h3>
							<span className={classNames(styles.count, 'u-text-color-default-subdued')}>
								{hoverResults.products.length} of {hoverResults.totalHits}
							</span>
						</div>
						{!isFetching && hoverResults.totalHits > 4 && (
							<Link
								className={styles.showAll}
								to={getSearchRoute(hoverResults.phrase || '')}
								state={{ area: Area.StaticPages, pageType: PageType.Search }}
								isTextLink
							>
								{translations?.shared.quicksearch.showAll}
							</Link>
						)}
					</div>

					<ul className={styles.grid}>
						{isFetching ? (
							<>
								{Array.from(Array(2)).map((_, i) => (
									<li key={i} className="u-flex">
										<ProductCard isSkeleton />
									</li>
								))}
							</>
						) : (
							<>
								{hoverResults.products.map((item) => (
									<li key={item.id} className="u-flex">
										<ProductCard product={item} />
									</li>
								))}
							</>
						)}
					</ul>
				</div>
			)}
		</div>
	);
};
