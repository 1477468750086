import React from 'react';
import classNames from 'classnames';
import { Icon } from 'components/shared/Icon';
import { Logo } from 'components/shared/Logo';
import styles from './defaultImage.module.scss';

interface DefaultImageProps {
	children?: React.ReactElement;
	className?: string;
	isSkeleton?: boolean;
	isThumbnail?: boolean;
	isSmallImage?: boolean;
}

export const DefaultImage: React.FunctionComponent<DefaultImageProps> = ({
	isThumbnail,
	className,
	isSmallImage,
	isSkeleton,
}) => {
	return (
		<div className={classNames(styles.defaultImageWrapper, className)}>
			{isSkeleton ? (
				<span
					className={classNames('u-m-auto', 'u-spinner', { [styles.defaultImageSvg]: !isSmallImage })}
				></span>
			) : (
				<>
					<Icon
						name={'defaultImage'}
						className={classNames('u-m-auto', { [styles.defaultImageSvg]: !isSmallImage })}
						size="md"
					/>
					{!isThumbnail && (
						<Logo name={'comingSoon'} width={'3.75rem'} height={'.5rem'} className={'u-mt-xs'} />
					)}
				</>
			)}
		</div>
	);
};
