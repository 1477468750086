import { Input } from '@builder.io/sdk';
import { ProductBundleQuery } from 'api/product';
import { RelewiseProductRecommendationQuery } from 'api/recommendations';
import { ProductFilter, ProductSort, RelewiseRecommendationType, SortDirection } from 'generated/data-contracts';
import { isEmpty } from 'lodash';

export enum ProductFilterInputNames {
	amountOfProducts = 'amountOfProducts',
	areVariantsShownInRecommendations = 'areVariantsShownInRecommendations',
	collectionIds = 'collectionIds',
	categoryIds = 'categoryIds',
	defaultSorting = 'defaultSorting',
	deliveryMonths = 'deliveryMonths',
	productCatalogues = 'productCatalogues',
	productSubcatalogues = 'productSubcatalogues',
	productAssortments = 'productAssortments',
	productFamilyId = 'productFamilyId',
	productFits = 'productFits',
	productGender = 'productGender',
	productLineIds = 'productLineIds',
	productMainColours = 'productMainColours',
	productMasterId = 'productMasterId',
	productNoos = 'productNoos',
	productSizes = 'productSizes',
	searchPhrase = 'searchPhrase',
	recommendations = 'recommendations',
	relewiseRecommendationType = 'relewiseRecommendationType',
	sortingDirection = 'sortingDirection',
	subcategoryIds = 'subcategoryIds',
	productHasDiscountValues = 'productHasDiscountValues',
	productFashions = 'productFashions',
	productSleeves = 'productSleeves',
	productNecks = 'productNecks',
	productHasLicense = 'productHasLicense',
	productMyNoos = 'productMyNoos',
	productIds = 'ProductIds',
}
export type ProductFilterProps = {
	[ProductFilterInputNames.amountOfProducts]: number;
	[ProductFilterInputNames.areVariantsShownInRecommendations]: boolean;
	[ProductFilterInputNames.collectionIds]: string[];
	[ProductFilterInputNames.categoryIds]: string[];
	[ProductFilterInputNames.defaultSorting]: string;
	[ProductFilterInputNames.deliveryMonths]: string[];
	[ProductFilterInputNames.productCatalogues]: string[];
	[ProductFilterInputNames.productSubcatalogues]: string[];
	[ProductFilterInputNames.productAssortments]: string[];
	[ProductFilterInputNames.productFamilyId]: string;
	[ProductFilterInputNames.productFits]: string[];
	[ProductFilterInputNames.productGender]: string[];
	[ProductFilterInputNames.productFashions]: string[];
	[ProductFilterInputNames.productSleeves]: string[];
	[ProductFilterInputNames.productNecks]: string[];
	[ProductFilterInputNames.productHasLicense]: string[];
	[ProductFilterInputNames.productHasDiscountValues]: string[];
	[ProductFilterInputNames.productLineIds]: string[];
	[ProductFilterInputNames.productMainColours]: string[];
	[ProductFilterInputNames.productMasterId]: string;
	[ProductFilterInputNames.productNoos]: string[];
	[ProductFilterInputNames.productSizes]: string[];
	[ProductFilterInputNames.searchPhrase]: string;
	[ProductFilterInputNames.recommendations]: string;
	[ProductFilterInputNames.relewiseRecommendationType]: string;
	[ProductFilterInputNames.sortingDirection]: string;
	[ProductFilterInputNames.subcategoryIds]: string[];
	[ProductFilterInputNames.productMyNoos]: boolean;
	[ProductFilterInputNames.productIds]: string;
};

// TODO: Get this from BE when it's available
enum RecommendationEngines {
	Relewise = 'Relewise',

	// Todo: Uncomment when Appretio integration is available
	// Appretio = 'Appretio',
	None = 'None',
}

export const FiltersExcludedFromPLP = [
	ProductFilterInputNames.recommendations,
	ProductFilterInputNames.relewiseRecommendationType,
	ProductFilterInputNames.areVariantsShownInRecommendations,
];

const showIfNotRecommendations = (options): boolean => {
	return !options.get('recommendations') || options.get('recommendations') === 'None';
};

const showIfNotAppretio = (options): boolean => {
	return options.get('recommendations') !== 'Appretio';
};

const showIfRelewise = (options): boolean => {
	return options.get('recommendations') === 'Relewise';
};

const sharedProductsFilters: Input[] = [
	{
		name: ProductFilterInputNames.searchPhrase,
		type: 'string',
		required: false,
		showIf: showIfNotRecommendations,
	},
	{
		name: ProductFilterInputNames.amountOfProducts,
		type: 'number',
		defaultValue: 36,
		required: true,
	},
	{
		name: ProductFilterInputNames.recommendations,
		type: 'string',
		enum: Object.values(RecommendationEngines),
		defaultValue: RecommendationEngines.None,
		helperText: 'If anything else than None is selected, the slider will show recommended products',
		required: false,
	},
	{
		name: ProductFilterInputNames.relewiseRecommendationType,
		type: 'Bestseller Product Search Relewise Recommendation Types',
		required: false,
		showIf: showIfRelewise,
	},
	{
		name: ProductFilterInputNames.productFamilyId,
		type: 'string',
		helperText: 'Either family or master id is required for this recommendation type',
		required: false,
		showIf: (options): boolean => {
			return (
				options.get('relewiseRecommendationType') === 'PurchasedWith' ||
				options.get('relewiseRecommendationType') === 'ViewedAfterViewingProduct'
			);
		},
	},
	{
		name: ProductFilterInputNames.productIds,
		type: 'string',
		required: false,
		showIf: (options): boolean => {
			return (
				options.get('recommendations') === 'Relewise' &&
				options.get('relewiseRecommendationType') !== 'PurchasedWith' &&
				options.get('relewiseRecommendationType') !== 'ViewedAfterViewingProduct'
			);
		},
	},
	{
		name: ProductFilterInputNames.productMasterId,
		type: 'string',
		helperText: 'Either family or master id is required for this recommendation type',
		required: false,
		showIf: (options): boolean => {
			return (
				options.get('relewiseRecommendationType') === 'PurchasedWith' ||
				options.get('relewiseRecommendationType') === 'ViewedAfterViewingProduct'
			);
		},
	},
	{
		name: ProductFilterInputNames.categoryIds,
		type: 'Bestseller Product Search Categories',
		friendlyName: 'Categories',
		required: false,
	},
	{
		name: ProductFilterInputNames.subcategoryIds,
		type: 'Bestseller Product Search Subcategories',
		friendlyName: 'Subcategories',
		required: false,
	},
	{
		name: ProductFilterInputNames.collectionIds,
		type: 'Bestseller Product Search Collections',
		friendlyName: 'Collections',
		required: false,
	},
	{
		name: ProductFilterInputNames.productLineIds,
		type: 'Bestseller Product Search Product Lines',
		friendlyName: 'Product Lines',
		required: false
	},
	{
		name: ProductFilterInputNames.deliveryMonths,
		type: 'Bestseller Product Search Delivery Months',
		required: false,
		showIf: showIfNotRecommendations,
	},
	{
		name: ProductFilterInputNames.productCatalogues,
		friendlyName: 'Catalogues',
		type: 'Bestseller Product Search Catalogues',
		required: false,
	},
	{
		name: ProductFilterInputNames.productSubcatalogues,
		friendlyName: 'Subcatalogues',
		type: 'Bestseller Product Search Subcatalogues',
		required: false,
		showIf: showIfNotRecommendations,
	},
	{
		name: ProductFilterInputNames.productAssortments,
		friendlyName: 'Assortment Type',
		type: 'Bestseller Product Search Product Assortments',
		required: false,
		showIf: showIfNotRecommendations,
	},
	{
		name: ProductFilterInputNames.productMainColours,
		type: 'Bestseller Product Search Product Main Colours',
		friendlyName: 'Product colours',
		required: false,
		showIf: showIfNotAppretio,
	},
	{
		name: ProductFilterInputNames.productSizes,
		type: 'Bestseller Product Search Product Sizes',
		required: false,
		showIf: showIfNotRecommendations,
	},
	{
		name: ProductFilterInputNames.productFits,
		type: 'Bestseller Product Search Product fits',
		required: false,
		showIf: showIfNotAppretio,
	},
	{
		name: ProductFilterInputNames.productNoos,
		type: 'Bestseller Product Search Product Noos',
		required: false,
		showIf: showIfNotRecommendations,
	},
	{
		name: ProductFilterInputNames.productMyNoos,
		type: 'boolean',
		required: false,
		showIf: showIfNotRecommendations,
	},
	{
		name: ProductFilterInputNames.productGender,
		type: 'Bestseller Product Search Product Gender',
		friendlyName: 'Gender',
		required: false,
		showIf: showIfNotAppretio,
	},
	{
		name: ProductFilterInputNames.productFashions,
		type: 'Bestseller Product Search Product Fashions',
		friendlyName: 'Fashion Forward',
		required: false,
		showIf: showIfNotAppretio,
	},
	{
		name: ProductFilterInputNames.productSleeves,
		type: 'Bestseller Product Search Product Sleeves',
		friendlyName: 'Sleeves',
		required: false,
		showIf: showIfNotAppretio,
	},
	{
		name: ProductFilterInputNames.productNecks,
		type: 'Bestseller Product Search Product Necks',
		friendlyName: 'Necks',
		required: false,
		showIf: showIfNotAppretio,
	},
	{
		name: ProductFilterInputNames.productHasLicense,
		type: 'Bestseller Product Search Product Has License',
		friendlyName: 'Has License',
		required: false,
		showIf: showIfNotAppretio,
	},
	{
		name: ProductFilterInputNames.productHasDiscountValues,
		type: 'Bestseller Product Search Product Has Discount',
		friendlyName: 'Has Discount',
		required: false,
		showIf: showIfNotAppretio,
	},
	{
		name: ProductFilterInputNames.defaultSorting,
		type: 'string',
		enum: Object.values(ProductSort),
		required: false,
		showIf: showIfNotRecommendations,
	},
	{
		name: ProductFilterInputNames.sortingDirection,
		type: 'string',
		enum: Object.values(SortDirection),
		required: false,
		showIf: (options): boolean => {
			return options.get('defaultSorting');
		},
	},
];

export const getSharedProductsFilters = (blockName: 'PLP' | 'ProductSlider'): Input[] => {
	if (blockName === 'ProductSlider') {
		return sharedProductsFilters;
	}
	// Exclude recommendation filters from PLP for the time being
	return sharedProductsFilters.filter((r) => {
		return !FiltersExcludedFromPLP.includes(r.name as ProductFilterInputNames);
	});
};

const setupKeyAndValues = (items: string[]): ProductFilter | undefined => {
	const key = items[0].split('@')[1];
	const values: string[] = [];

	items
		.filter((r) => {
			return r.includes('@');
		})
		.forEach((item) => {
			const keylessItem = item.split('@')[0];
			values.push(keylessItem);
		});

	if (values.length === 0) {
		return;
	}
	return {
		attributeKey: key,
		attributeValues: values,
	};
};

export const convertPropsToProductFilter = (
	props: ProductFilterProps,
	displayedAtLocation?: string,
): ProductBundleQuery | RelewiseProductRecommendationQuery => {
	const filters: ProductFilter[] = [];

	Object.values(props).forEach((prop) => {
		if (props[ProductFilterInputNames.productMyNoos]) return;
		if (typeof prop === 'object' && prop?.length > 0) {
			const propValues = setupKeyAndValues(prop);
			if (propValues) filters.push(propValues);
		}
	});

	if (props[ProductFilterInputNames.productMyNoos]) {
		filters.push({
			attributeKey: 'MyNoos',
			attributeValues: ['True'],
		});
	}

	const amountOfProducts = props?.amountOfProducts > 0 ? props.amountOfProducts : 36;
	switch (props.recommendations) {
		case RecommendationEngines.Relewise: {
			const selectedIdsString = props[ProductFilterInputNames.productIds];
			const selectedIds = isEmpty(selectedIdsString) ? [] : selectedIdsString.split(' ');

			const query: RelewiseProductRecommendationQuery = {
				amount: amountOfProducts,
				displayedAtLocation: displayedAtLocation ?? 'Unknown',
				fillIn: false,
				predefinedProductFilters: filters,
				includeVariants: props.areVariantsShownInRecommendations,
				type: props.relewiseRecommendationType as RelewiseRecommendationType,
				productFamilyId: props.productFamilyId,
				productMasterId: props.productMasterId,
				collectionIds: props.collectionIds,
				categoryIds: props.categoryIds,
				subcategoryIds: props.subcategoryIds,
				selectedIds: selectedIds,
				productLineIds: props.productLineIds,
				catalogueIds: [...(props.productCatalogues ?? []), ...(props.productSubcatalogues ?? [])],
			};
			return query;
		}
		case RecommendationEngines.None:
		default: {
			const query: ProductBundleQuery = {
				collectionIds: props.collectionIds,
				productCatalogues: [...(props.productCatalogues ?? []), ...(props.productSubcatalogues ?? [])],
				productLineIds: props.productLineIds,
				preDefinedProductFilters: filters,
				phrase: props.searchPhrase,
				preDefinedProductCategoryIds: [...(props.categoryIds ?? []), ...(props.subcategoryIds ?? [])],
				take: amountOfProducts,
				sortBy: props.defaultSorting as ProductSort,
				sortDirection: props.sortingDirection as SortDirection,
			};
			return query;
		}
	}
};
