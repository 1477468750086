export const fontSizes = [
	{ label: 'h1', value: 'var(--font-size-heading-48)' },
	{ label: 'h2', value: 'var(--font-size-heading-40)' },
	{ label: 'h3', value: 'var(--font-size-heading-32)' },
	{ label: 'h4', value: 'var(--font-size-heading-24)' },
	{ label: 'h5', value: 'var(--font-size-heading-20)' },
	{ label: 'h6', value: 'var(--font-size-body-16)' },
	{ label: 'lg', value: 'var(--font-size-body-16)' },
	{ label: 'md', value: 'var(--font-size-body-14)' },
	{ label: 'sm', value: 'var(--font-size-body-12)' },
] as const;

export type FontSize = (typeof fontSizes)[number]['value'];
