import React from 'react';
import classNames from 'classnames';
import { AutoplayType } from 'embla-carousel-autoplay';
import { EmblaCarouselType } from 'embla-carousel-react';
import { SupportedCssUnits } from 'types/cssUnits';
import { useTranslationQuery } from 'api/translations';
import { capitalize, useViewportSize } from 'helpers/index';
import { ArrowButton } from '../ArrowButton';
import { DotButton } from '../DotButton';
import { Thumbnails } from '../Thumbnails';
import styles from './SliderMarkup.module.scss';

declare type EmblaViewportRefType = <ViewportElement extends HTMLElement>(instance: ViewportElement | null) => void;

export interface SharedSliderProps {
	className?: string;
	arrowsClassName?: string;
	arrowsContainerClassName?: string;
	viewportClassName?: string;
	thumbViewportClassName?: string;
	slidesWidth?: SupportedCssUnits | 'auto';
	thumbWidth?: SupportedCssUnits | 'auto';
	gap?: 'xs' | 'md' | 'none';
	fullscreen?: boolean;
	centerSlides?: boolean;
	transitionType?: 'slide' | 'fade';
	showArrows?: boolean;
	showArrowsOnMobile?: boolean;
	isOnPDP?: boolean;
	paginationType?: 'dots' | 'numbers' | 'thumbnails' | 'numberedThumbnail';
	customThumbnails?: React.ReactNode;
	thumbnailsPosition?: 'top' | 'right' | 'bottom' | 'left';
	numbersPosition?: 'topLeft' | 'topRight';
	thumbSelectOnHover?: boolean;
	haveArrowsInside?: boolean;
}

interface SliderMarkupProps extends SharedSliderProps {
	children?: React.ReactNode;
	emblaApi?: EmblaCarouselType;
	emblaRef: EmblaViewportRefType;
	autoplay?: React.MutableRefObject<AutoplayType>;
}

export const SliderMarkup: React.FunctionComponent<SliderMarkupProps> = ({
	emblaApi,
	emblaRef,
	arrowsClassName,
	arrowsContainerClassName,
	autoplay,
	isOnPDP,
	gap,
	fullscreen,
	centerSlides,
	slidesWidth,
	thumbWidth,
	transitionType,
	showArrows,
	showArrowsOnMobile,
	paginationType,
	thumbnailsPosition,
	numbersPosition,
	thumbSelectOnHover,
	customThumbnails,
	className,
	viewportClassName,
	thumbViewportClassName,
	haveArrowsInside,
	children,
}) => {
	const { data: translations } = useTranslationQuery();
	const { matchesSize } = useViewportSize();
	const layoutSwitch = 'sm';
	const isMobile = matchesSize(['xs']); // should be layoutSwitch - 1

	const [isPrevEnabled, setIsPrevEnabled] = React.useState(true);
	const [isNextEnabled, setIsNextEnabled] = React.useState(true);
	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const [scrollSnaps, setScrollSnaps] = React.useState<number[]>([]);
	const [slidesInView, setSlidesInView] = React.useState<number[]>([]);

	const onSelect = React.useCallback(() => {
		if (!emblaApi) return;

		setSelectedIndex(emblaApi.selectedScrollSnap());
		setSlidesInView(emblaApi.slidesInView(true));
		setIsPrevEnabled(emblaApi.canScrollPrev());
		setIsNextEnabled(emblaApi.canScrollNext());
	}, [emblaApi]);

	React.useEffect(() => {
		if (!emblaApi) return;

		if (transitionType === 'fade') {
			// Inactivate transform
			emblaApi.internalEngine().translate.toggleActive(false);
			emblaApi.internalEngine().translate.clear();
		}

		if (isMobile && autoplay) {
			// Disable autoplay on mobile
			autoplay.current.stop();
		}

		onSelect();
		setScrollSnaps(emblaApi.scrollSnapList());
		emblaApi.on('select', onSelect);
	}, [emblaApi, setScrollSnaps, onSelect, isMobile, autoplay, transitionType]);

	React.useEffect(() => {
		if (!emblaApi) return;
		emblaApi.reInit();
	}, [children, emblaApi]);

	const scrollPrev = React.useCallback(() => {
		if (!emblaApi) return;

		emblaApi.scrollPrev();
	}, [emblaApi]);

	const scrollNext = React.useCallback(() => {
		if (!emblaApi) return;

		emblaApi.scrollNext();
	}, [emblaApi]);

	const handleThumbClick = React.useCallback(
		(index: number) => {
			if (!emblaApi) return;
			emblaApi.scrollTo(index);
		},
		[emblaApi],
	);

	return (
		<section
			className={classNames(
				styles.wrapper,
				{
					[styles.thumbsOnLeft]: thumbnailsPosition === 'left',
					[styles.thumbsOnRight]: thumbnailsPosition === 'right',
					[styles.isFullscreen]: fullscreen,
					[styles.isFade]: transitionType === 'fade',
				},
				styles['gap' + (gap ? capitalize(gap) : 'Md')],
				className,
			)}
			style={
				{
					'--thumb-width': typeof thumbWidth === 'string' ? thumbWidth : '100%',
				} as React.CSSProperties
			}
		>
			<div className={styles.innerWrapper}>
				{showArrows && (!isMobile || showArrowsOnMobile) && (
					<nav
						className={classNames(
							styles.arrows,
							arrowsContainerClassName,
							{ [styles.isInside]: !slidesWidth || haveArrowsInside, [styles.isOnPDP]: isOnPDP },
							!showArrowsOnMobile && `u-hide-${layoutSwitch}-max`,
						)}
						aria-label={translations?.shared.navigation.slider}
					>
						<ArrowButton
							className={classNames(styles.arrow, arrowsClassName)}
							onClick={scrollPrev}
							disabled={!isPrevEnabled}
							prev
						/>
						<ArrowButton
							className={classNames(styles.arrow, arrowsClassName)}
							onClick={scrollNext}
							disabled={!isNextEnabled}
							next
						/>
					</nav>
				)}

				<div className={classNames(styles.viewport, viewportClassName)} ref={emblaRef}>
					<ul className={styles.sliderWrapper}>
						{React.Children.map(children as never, (child: React.ReactElement, index) => (
							<li
								className={classNames(styles.slide, {
									[styles.isSelected]: selectedIndex === index,
									[styles.isCentered]: centerSlides,
								})}
								aria-hidden={!slidesInView.includes(index) ? 'true' : undefined}
								style={
									{
										'--slide-width': typeof slidesWidth === 'string' ? slidesWidth : '100%',
									} as React.CSSProperties
								}
								key={index}
							>
								{child}
							</li>
						))}
					</ul>
				</div>
			</div>

			{paginationType === 'numbers' ||
				(paginationType === 'numberedThumbnail' && (
					<p
						className={classNames(styles.pagination, styles.isNumbersType, {
							[styles.isTopLeft]: numbersPosition === 'topLeft',
							[styles.isTopRight]: numbersPosition === 'topRight',
						})}
					>
						<span>{selectedIndex + 1}</span>
						<span>/</span>
						<span>{(children as React.ReactElement[])?.length}</span>
					</p>
				))}

			{paginationType === 'dots' && (
				<div className={classNames(styles.pagination, styles.isDotsType)}>
					{scrollSnaps.map((_, index) => (
						<DotButton
							key={index}
							index={index + 1}
							selected={index === selectedIndex}
							onClick={(): void => emblaApi?.scrollTo(index)}
						/>
					))}
				</div>
			)}

			{(paginationType === 'thumbnails' || paginationType === 'numberedThumbnail') && (
				<Thumbnails
					className={classNames(
						styles.thumbsWrapper,
						{
							[styles.isFullscreen]: fullscreen,
							[styles.isTop]: thumbnailsPosition === 'top',
							[styles.isRight]: thumbnailsPosition === 'right',
							[styles.isBottom]: thumbnailsPosition === 'bottom' || !thumbnailsPosition,
							[styles.isLeft]: thumbnailsPosition === 'left',
						},
						thumbViewportClassName,
					)}
					isVertical={thumbnailsPosition === 'left' || thumbnailsPosition === 'right'}
					selected={selectedIndex}
					fullscreen={fullscreen}
					onSelect={handleThumbClick}
					shouldSelectOnHover={thumbSelectOnHover}
				>
					{customThumbnails || children}
				</Thumbnails>
			)}
		</section>
	);
};
