import React from 'react';
import { useDispatch } from 'react-redux';
import { Alert, AlertTypes } from 'components/shared/Alert';
import { appActions } from 'store/actions/appActions';
import styles from './Notification.module.scss';

export interface NotificationProps {
	children: React.ReactNode;
	type: AlertTypes;
	options?: {
		duration?: number;
		isClosable?: boolean;
	};
	id?: string;
}

/**
 * Component that wraps the Alert component.
 * Handles the open/close functionality.
 */
export const Notification: React.FunctionComponent<NotificationProps> = ({ children, type, options = {}, id }) => {
	const timerRef = React.useRef<NodeJS.Timeout>();
	const dispatch = useDispatch();

	const handleClose = React.useCallback((): void => {
		if (id) {
			dispatch(appActions.removeNotification(id));
		}
	}, [dispatch, id]);

	const startTimer = React.useCallback(() => {
		if (options.duration) {
			timerRef.current = setTimeout(handleClose, options.duration);
		}
	}, [options.duration, handleClose]);

	React.useEffect(() => {
		startTimer();

		return (): void => {
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
		};
	}, [startTimer]);

	const stopTimer = (): void => {
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}
	};

	return (
		<div className={styles.item} onMouseEnter={stopTimer} onMouseLeave={startTimer}>
			<Alert type={type} onClose={options.isClosable ? handleClose : undefined}>
				{children}
			</Alert>
		</div>
	);
};
