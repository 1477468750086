import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { setHeaders } from 'api/apiConfig';
import { Vmi as VmiApi } from 'generated/Vmi';
import { BrandId, ProblemDetails, ShipToId } from 'generated/data-contracts';
import { HttpResponse } from 'generated/http-client';

interface VmiReportRequest {
	shipToId?: ShipToId;
	brandId?: BrandId;
}

export const useVmiReportMutation = (): UseMutationResult<
	HttpResponse<File, void | ProblemDetails>,
	HttpResponse<void>,
	VmiReportRequest
> => {
	return useMutation({
		mutationFn: async (variables) => {
			const vmiApi = new VmiApi({ baseApiParams: { headers: setHeaders() } });

			return vmiApi.vmiReportList(
				{
					shipToId: variables.shipToId,
					brandId: variables.brandId,
				},
				{ format: 'blob' },
			);
		},
	});
};
