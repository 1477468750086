import React from 'react';
import classNames from 'classnames';
import { DebouncedFunc } from 'lodash';
import { useLayoutQuery } from 'api/layout';
import { MultiRangeOnChangeProps, MultiRangeSlider } from 'components/shared/MultiRangeSlider';
import { CurrencyPosition, FilterRequest, FilterResponse } from 'generated/data-contracts';
import styles from './RangeFilter.module.scss';

export interface PriceQueryProps {
	minPrice?: number;
	maxPrice?: number;
}

interface RangeFilterProps extends FilterResponse {
	className?: string;
	isPrice?: boolean;
	priceFilter?: PriceQueryProps;
	filters: FilterRequest[];
	onChange?: DebouncedFunc<({ minVal, maxVal }: MultiRangeOnChangeProps) => void>;
	currencySymbol?: string | null;
	uniqueFilterId?: string;
}

export const RangeFilter: React.FunctionComponent<RangeFilterProps> = ({
	id,
	min,
	max,
	selectedMin,
	currencySymbol,
	selectedMax,
	className,
	isPrice,
	onChange,
	uniqueFilterId,
}) => {
	const { data: layout } = useLayoutQuery();
	let inputSuffix;
	let inputPrefix;

	if (isPrice) {
		if (layout?.currencyPosition === CurrencyPosition.Left) {
			inputPrefix = currencySymbol ? currencySymbol : layout?.currencySymbol;
		} else if (layout?.currencyPosition === CurrencyPosition.Right) {
			inputSuffix = currencySymbol ? currencySymbol : layout?.currencySymbol;
		}
	}

	if (!max) return null;

	return (
		<div className={classNames(styles.wrapper, className)}>
			<MultiRangeSlider
				className={styles.rangeSlider}
				name={id + uniqueFilterId}
				min={min || 0}
				max={max}
				selectedMin={selectedMin ?? undefined}
				selectedMax={selectedMax ?? undefined}
				onChange={(e): void => {
					if (onChange) {
						onChange(e);
					}
				}}
				showInputs
				inputSuffix={inputSuffix}
				inputPrefix={inputPrefix}
			/>
		</div>
	);
};
