import { isScannerApp, messageToApp } from 'helpers/app';

export const downloadFile = async (fileName: string, data?: File) => {
	if (!data) {
		return;
	}

	if (isScannerApp) {
		const reader = new FileReader();
		const blob = await new Promise<string>((resolve) => {
			reader.onload = () => resolve(reader.result as string);
			reader.readAsDataURL(data);
		});

		messageToApp({
			type: 'downloadFile',
			blobInBase64: blob,
			downloadTitle: fileName,
		});

		return;
	}

	const url = URL.createObjectURL(data);

	if (!url) {
		return;
	}

	const a = document.createElement('a');
	a.href = url;
	a.download = fileName;
	a.click();

	URL.revokeObjectURL(url);
};
