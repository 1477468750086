import React from 'react';
import { Icon as DSIcon, IconName, IconSize } from 'components/shared';

interface IconProps {
	icon: IconName;
	size: IconSize;
	iconLabel?: string;
}

/**
 * CMS Icon component, using shared Icon component
 */
export const Icon: React.FunctionComponent<IconProps> = ({ icon, size, iconLabel }) => (
	<DSIcon name={icon} size={size} iconLabel={iconLabel} />
);
