import { useCallback, useEffect, useState } from 'react';

const DELTA_TIME_THRESHOLD_MS = 500;

/**
 * React hook to handle double taps, as the normal 'dblclick' event does not work on touch devices, don't ask me why
 *
 * @param ref The React ref for the element you want the eventlistener to be added on
 * @param callback The event handler you want to be executed after the second click
 */
export const useOnDoubleTap = <T extends HTMLElement>(
	ref: React.MutableRefObject<T | null>,
	callback: (event: React.MouseEvent<T>) => void,
): void => {
	const [, setTimer] = useState<NodeJS.Timeout | null>(null);
	const clickEventListener = useCallback(
		(event): void => {
			setTimer((prev) => {
				if (!prev) {
					return setTimeout(() => setTimer(null), DELTA_TIME_THRESHOLD_MS);
				}
				callback(event);
				clearTimeout(prev);
				return null;
			});
		},
		[callback],
	);
	useEffect(() => {
		const element = ref.current;

		if (!element) return;
		element.addEventListener('click', clickEventListener);
		return () => element.removeEventListener('click', clickEventListener);
	}, [clickEventListener, ref]);
};
