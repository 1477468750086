import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Button, ButtonProps } from 'components/shared/Button';
import { Icon, IconProps } from 'components/shared/Icon';
import styles from './ArrowButton.module.scss';

interface ArrowButtonProps extends Partial<ButtonProps> {
	className?: string;
	prev?: boolean;
	next?: boolean;
	isVertical?: boolean;
	iconProps?: Partial<IconProps>;
}

export const ArrowButton: React.FunctionComponent<ArrowButtonProps> = ({
	className,
	prev,
	next,
	isVertical,
	onClick,
	disabled,
	size = 'xs',
	variant = 'secondary',
	iconProps,
	...props
}) => {
	const { data: translations } = useTranslationQuery();

	return (
		<Button
			className={classNames(styles.arrowButton, className, {
				[styles.isPrev]: prev,
				[styles.isNext]: next,
				[styles.isVertical]: isVertical,
			})}
			aria-label={prev ? translations?.shared.previous : translations?.shared.next}
			variant={variant}
			size={size}
			hasOnlyIcon
			onClick={onClick}
			disabled={disabled}
			{...props}
		>
			<Icon className={styles.icon} name={'chevronDown'} size={'md'} {...iconProps} />
		</Button>
	);
};
