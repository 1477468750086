import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Dropdown } from 'components/shared/Dropdown';
import { Link } from 'components/shared/Link';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/shared/Tooltip';
import { useOrderLineContext } from '../../state/useOrderLineContext';
import { SkeletonButton } from '../SkeletonButton';
import styles from './TrackOrdersDropdown.module.scss';

export interface TrackOrdersDropdownProps {
	className?: string;
	buttonClassName?: string;
}

export const TrackOrdersDropdown: React.FunctionComponent<TrackOrdersDropdownProps> = ({
	className,
	buttonClassName,
}) => {
	const { data: translations } = useTranslationQuery();
	const {
		orderLineDetails: { trackAndTraceCodes },
		isSkeleton,
	} = useOrderLineContext();
	if (isSkeleton) return <SkeletonButton />;
	const disableTrackAndTraceButton = trackAndTraceCodes.length === 0;
	return (
		<Tooltip placement="bottom">
			<TooltipTrigger className={classNames(styles.tooltipTrigger, className)}>
				<Dropdown
					buttonVariant="outline"
					buttonSize="sm"
					buttonLabel={translations?.myOrders.orderHistoryLine.trackOrder ?? ''}
					disabled={disableTrackAndTraceButton}
					className={classNames(styles.dropdown, {
						[styles.disabled]: disableTrackAndTraceButton,
					})}
					buttonClassName={classNames(buttonClassName, styles.button)}
					contentClassName={styles.dropdownContent}
				>
					<ul className={styles.options}>
						{trackAndTraceCodes.map(({ code: trackingCode, url }, index) => (
							<Tooltip key={`track-orders-dropdown-option-${trackingCode}-${index}`}>
								<TooltipTrigger className={styles.tooltip}>
									<li
										className={classNames(styles.option, {
											[styles.disabled]: !url,
										})}
									>
										<Link className={styles.link} target="_blank" linkUrl={url}>
											{trackingCode}
										</Link>
									</li>
								</TooltipTrigger>
								{!url && (
									<TooltipContent className={styles.tooltipText}>
										{translations?.myOrders.orderHistoryLine.trackingCodeButtonDisabled}
									</TooltipContent>
								)}
							</Tooltip>
						))}
					</ul>
				</Dropdown>
			</TooltipTrigger>
			{disableTrackAndTraceButton && (
				<TooltipContent className={styles.tooltipText}>
					{translations?.myOrders.orderHistoryLine.trackOrdersButtonDisabled}
				</TooltipContent>
			)}
		</Tooltip>
	);
};
