import { useDispatch, useSelector } from 'react-redux';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { queryKeys, setHeaders } from 'api/apiConfig';
import { useBasketData } from 'components/features/NewBasket/hooks/basketData';
import { AlertTypes } from 'components/shared';
import { Basket as BasketApi } from 'generated/Basket';
import { BasketExport } from 'generated/BasketExport';
import {
	BasketDetailsRequest,
	BasketDetailsResponse,
	BasketFilterType,
	BasketId,
	BasketListResponse,
	CreateBasketRequest,
	ErrorResult,
	PDFDownloadWithSummaryResponseWrapper,
	ProblemDetails,
	RouteResponse,
	SetBasketResponse,
	ShipToId,
	UpdateNameAndShipTosForBasketRequest,
} from 'generated/data-contracts';
import { HttpResponse } from 'generated/http-client';
import { messageToApp } from 'helpers/app';
import { formatTranslation } from 'helpers/stringHelpers';
import { appActions } from 'store/actions/appActions';
import { InitialState } from 'store/types';
import { useTranslationQuery } from './translations';

export const useBasketCreateMutation = (): UseMutationResult<
	HttpResponse<BasketDetailsResponse, ErrorResult | void>,
	HttpResponse<ErrorResult>,
	{ basketId: BasketId; query: UpdateNameAndShipTosForBasketRequest; host?: string; cookies?: string }
> => {
	return useMutation({
		mutationFn: async (variables) => {
			const basketApi = new BasketApi({
				baseUrl: variables.host,
				baseApiParams: { headers: setHeaders(variables.cookies) },
			});

			return basketApi.basketSetorderreferenceandshiptosCreate(variables.basketId, variables.query);
		},
	});
};
export const useSelectBasketMutation = (): UseMutationResult<
	HttpResponse<SetBasketResponse, ErrorResult | void>,
	HttpResponse<ErrorResult>,
	{
		basketId: number;
		productFamilyId: string | undefined;
		host?: string;
		cookies?: string;
	}
> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);

	return useMutation({
		mutationFn: async (variables) => {
			const basketApi = new BasketApi({
				baseUrl: variables.host,
				baseApiParams: { headers: setHeaders(variables.cookies) },
			});

			const query = { segmentationId, productFamilyId: variables.productFamilyId };

			return basketApi.basketSetbasketCreate(variables.basketId, query);
		},
	});
};

interface AutoSelectBasketRequest {
	returnUrl?: string | null;
	shipToIds: ShipToId[];
}

export const useBasketTryAutoSelectMutation = (): UseMutationResult<
	HttpResponse<RouteResponse, void>,
	HttpResponse<void>,
	AutoSelectBasketRequest
> => {
	return useMutation({
		mutationFn: async (variables) => {
			const basketApi = new BasketApi({ baseUrl: undefined, baseApiParams: { headers: setHeaders() } });

			return basketApi.basketCreateorupdatebasketbyshiptosCreate({
				shipToIds: variables.shipToIds,
				returnUrl: variables.returnUrl,
			});
		},
	});
};

export const useBasketTotalQuantityQuery = (): UseQueryResult<number> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);

	return useQuery({
		queryKey: queryKeys.basket.quantity(segmentationId).queryKey,
		queryFn: async (): Promise<number> => {
			const basketApi = new BasketApi({ baseApiParams: { headers: setHeaders() } });

			const response = await basketApi.basketTotalquantityList();

			return response.data ?? 0;
		},
	});
};

export const useMiniBasketQuantityQuery = (host?: string, cookies?: string): UseQueryResult<number> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);

	return useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: queryKeys.basket.miniQuantity(segmentationId).queryKey,
		queryFn: async (): Promise<number> => {
			const basketApi = new BasketApi({ baseUrl: host, baseApiParams: { headers: setHeaders(cookies) } });

			const response = await basketApi.basketTotalquantityList();

			return response.data ?? 0;
		},
		onSuccess: (data) => {
			messageToApp({ type: 'miniBasketQuantity', quantity: data });
		},
	});
};

export const useBasketFamilyQuantitiesQuery = (
	host?: string,
	cookies?: string,
): UseQueryResult<Record<string, number>> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);

	return useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: queryKeys.basket.productCardQuantity(segmentationId).queryKey,
		queryFn: async (): Promise<Record<string, number>> => {
			const basketApi = new BasketApi({ baseUrl: host, baseApiParams: { headers: setHeaders(cookies) } });

			const response = await basketApi.basketFamilytotalquantityList();

			return response.data.familyTotals;
		},
	});
};

export const useBasketQuery = (
	shipmentFilterValue?: string,
	shipmentFilterType?: BasketFilterType,
	host?: string,
	cookies?: string,
): UseQueryResult<BasketDetailsResponse> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);

	return useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: queryKeys.basket.current(segmentationId, shipmentFilterValue).queryKey,
		queryFn: async (): Promise<BasketDetailsResponse> => {
			const basketApi = new BasketApi({ baseUrl: host, baseApiParams: { headers: setHeaders(cookies) } });

			const response = await basketApi.basketList({
				shipmentFilterType: shipmentFilterType || BasketFilterType.All,
				shipmentFilterValue: shipmentFilterValue,
			});

			return response.data;
		},
		keepPreviousData: true,
	});
};

export interface BasketFilters {
	BasketCreators?: string[];
	BrandIds?: number[];
	SearchPhrase?: string;
	ShipToIds?: string[];
	SubBrandIds?: number[];
}

export const useBasketListQuery = (
	filters?: BasketFilters,
	disable?: boolean,
	host?: string,
	cookies?: string,
): UseQueryResult<BasketListResponse> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	return useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: queryKeys.basket.list(segmentationId, filters).queryKey,
		queryFn: async (): Promise<BasketListResponse> => {
			const basketApi = new BasketApi({ baseUrl: host, baseApiParams: { headers: setHeaders(cookies) } });
			const response = await basketApi.basketListList({
				...filters,
			});

			return response.data;
		},
		enabled: !disable,
	});
};

type UpdateOrderReferenceRequest = { basketId: BasketId; newBasketName: string; host?: string; cookies?: string };
export const useUpdateOrderReferenceMutation = (): UseMutationResult<
	HttpResponse<void>,
	HttpResponse<ProblemDetails>,
	UpdateOrderReferenceRequest
> => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const { data: translations } = useTranslationQuery();

	return useMutation({
		mutationFn: async ({ basketId, newBasketName, host, cookies }) => {
			const basketApi = new BasketApi({
				baseUrl: host,
				baseApiParams: { headers: setHeaders(cookies) },
			});

			return basketApi.basketSetorderreferenceCreate(basketId, newBasketName);
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries(queryKeys.basket.current._def);
			await queryClient.invalidateQueries(queryKeys.newBasket.current._def);
			// basket list cache should be invalidated because it contains the old basket reference.
			await queryClient.invalidateQueries(queryKeys.basket.list._def);
		},
		onError: (err) => {
			if (err.status === 500) {
				dispatch(
					appActions.addNotification({
						children: formatTranslation(translations?.shared.genericErrorMsg, {}),
						type: AlertTypes.DANGER,
					}),
				);
			}
		},
	});
};

export const useCreateBasketMutation = (): UseMutationResult<
	HttpResponse<SetBasketResponse>,
	HttpResponse<ErrorResult>,
	{ query: { body: CreateBasketRequest; familyId?: string }; host?: string; cookies?: string }
> => {
	const dispatch = useDispatch();
	const { data: translations } = useTranslationQuery();

	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (variables) => {
			const basketApi = new BasketApi({
				baseUrl: variables.host,
				baseApiParams: { headers: setHeaders(variables.cookies) },
			});

			return basketApi.basketCreateCreate(variables.query.body, { productFamilyId: variables.query.familyId });
		},
		onSuccess: () => {
			queryClient.invalidateQueries();
			messageToApp({ type: 'basketChanged' });
		},
		onError: () => {
			dispatch(
				appActions.addNotification({
					children: formatTranslation(translations?.basket.invalidShipToIds, {}),
					type: AlertTypes.DANGER,
				}),
			);
		},
	});
};

export const useDeleteBasketsMutation = (): UseMutationResult<
	HttpResponse<BasketListResponse>,
	HttpResponse<ErrorResult>,
	{ query: { data: BasketId[] }; host?: string; cookies?: string }
> => {
	const dispatch = useDispatch();

	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (variables) => {
			const basketApi = new BasketApi({
				baseUrl: variables.host,
				baseApiParams: { headers: setHeaders(variables.cookies) },
			});

			return basketApi.basketDeletemanyCreate(variables.query.data);
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries(queryKeys.basket.list._def);
		},
		onError: (err) => {
			dispatch(
				appActions.addNotification({
					children: err.data.detail,
					type: AlertTypes.DANGER,
				}),
			);
		},
	});
};

export const useBasketPdfMutation = (): UseMutationResult<
	HttpResponse<PDFDownloadWithSummaryResponseWrapper, void | ProblemDetails>,
	HttpResponse<void>
> => {
	const { filter, currentDeliveryDate } = useBasketData();
	const query: BasketDetailsRequest = {
		filterType: filter,
		filterDate: currentDeliveryDate,
	};

	return useMutation({
		mutationFn: async () => {
			const basketExportApi = new BasketExport({ baseApiParams: { headers: setHeaders() } });

			return await basketExportApi.basketexportExportaspdfCreate(query);
		},
	});
};

export const useBasketExcelMutation = (): UseMutationResult<
	HttpResponse<File, void | ProblemDetails>,
	HttpResponse<void>
> => {
	const { filter, currentDeliveryDate } = useBasketData();

	const query: BasketDetailsRequest = {
		filterType: filter,
		filterDate: currentDeliveryDate,
	};

	return useMutation({
		mutationFn: async () => {
			const basketExportApi = new BasketExport({ baseApiParams: { headers: setHeaders() } });

			return await basketExportApi.basketexportExportasexcelCreate(query, {
				format: 'blob',
			});
		},
	});
};

export const useBasketMediaContentMutation = (): UseMutationResult<
	HttpResponse<File, void | ProblemDetails>,
	HttpResponse<void>
> => {
	const { filter, currentDeliveryDate } = useBasketData();

	const query: BasketDetailsRequest = {
		filterType: filter,
		filterDate: currentDeliveryDate,
	};

	return useMutation({
		mutationFn: async () => {
			const basketExportApi = new BasketExport({ baseApiParams: { headers: setHeaders() } });

			return await basketExportApi.basketexportExportasmediacontentCreate(query, {
				format: 'blob',
			});
		},
	});
};
