declare const Android: {
	messageToApp: (data: string) => void;
};

declare const window: Window &
	typeof globalThis & {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		webkit: any;
	};

export const enum AppWebViews {
	BRAND_SELECTOR = 'BRAND_SELECTOR',
	ONBOARDING = 'ONBOARDING',
	SCAN = 'SCAN',
	BASKET = 'BASKET',
	PROFILE = 'PROFILE',
	SEARCH = 'SEARCH',
	SHOP = 'SHOP',
	FAVORITES = 'FAVORITES',
	DIALOG = 'DIALOG',
}

export type MessageToAppData =
	| {
			type:
				| 'onboardCompleted'
				| 'accountsChanged'
				| 'basketChanged'
				| 'reloadFavoriteList'
				| 'reloadWebView'
				| 'scannerButtonPressed'
				| 'openBrandSelector'
				| 'logout'
				| 'orderPlaced';
	  }
	| {
			type: 'redirectToTabAndOpenUrl';
			tabId: AppWebViews;
			url: string;
	  }
	| {
			type: 'miniBasketQuantity';
			quantity: number;
	  }
	| {
			type: 'downloadFile';
			blobInBase64: string;
			downloadTitle: string;
	  }
	| {
			type: 'presentationBrandChanged';
			presentationBrandId: number;
	  };

type MessageToAppProps = {
	(data: MessageToAppData): void;
};

export const isScannerApp = navigator.userAgent.includes('BestsellerApp');

export const messageToApp: MessageToAppProps = (data) => {
	if (!isScannerApp) return;

	const formattedData = JSON.stringify(data);

	try {
		if (/android/i.test(navigator.userAgent)) {
			Android.messageToApp(formattedData);
		} else {
			window.webkit.messageHandlers.messageToApp.postMessage(formattedData);
		}
	} catch (error) {
		console.log('messageToApp;', formattedData, error);
	}
};

export const handleMessageFromApp = (data): void => {
	console.log('data from app:', data);
};
