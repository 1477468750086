import React from 'react';
import classNames from 'classnames';
import { Icon, IconName } from 'components/shared/Icon';
import { useViewportSize } from 'helpers/useViewportSize';
import { useProductCardContext } from '../../context/ProductCardContext';
import styles from './BottomSplash.module.scss';

export interface BottomSplashProps {
	icon: IconName;
	text?: string;
	quantity?: number | null;
	hideQuantity?: boolean | null;
}

const BottomSplash: React.FunctionComponent<BottomSplashProps> = ({ icon, text, quantity, hideQuantity }) => {
	const { isBeingHovered } = useProductCardContext();

	const { isMobile } = useViewportSize();
	return (
		<div className={classNames(styles.splash, { [styles.onHover]: isBeingHovered })}>
			<Icon name={icon} size="xs" className={styles.icon} />

			<div className={styles.expandedInfo}>
				{!hideQuantity && <span className={styles.isBold}>{quantity}</span>}
				<span className={classNames(styles.text, { [styles.isHidden]: isMobile || !text })}>{text}</span>
			</div>
		</div>
	);
};

export default BottomSplash;
