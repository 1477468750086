import { Builder } from '@builder.io/react';
import { ProductFilterInputNames } from '../../shared/ProductsFilters';
import { ProductCard } from '../ProductCard';

Builder.registerComponent(ProductCard, {
	name: 'ProductCard',
	image: new URL('/dist/images/cms/productCard.svg', window.location.origin).href,
	inputs: [
		{
			name: ProductFilterInputNames.searchPhrase,
			type: 'string',
			required: false,
		},
		{
			name: 'collectionIds',
			type: 'Bestseller Product Search Collections',
			friendlyName: 'Collections',
			required: false,
		},
		{
			name: 'productLineIds',
			type: 'Bestseller Product Search Product Lines',
			friendlyName: 'Product Lines',
			required: false,
		},
		{
			name: 'deliveryMonths',
			type: 'Bestseller Product Search Delivery Months',
			required: false,
		},
		{
			name: 'productAssortments',
			type: 'Bestseller Product Search Product Assortments',
			friendlyName: 'Assortment Type',
			required: false,
		},
		{
			name: 'productMainColours',
			type: 'Bestseller Product Search Product Main Colours',
			friendlyName: 'Product colours',
			required: false,
		},
		{
			name: 'productSizes',
			type: 'Bestseller Product Search Product Sizes',
			required: false,
		},
		{
			name: 'productFits',
			type: 'Bestseller Product Search Product fits',
			required: false,
		},
		{
			name: 'productNoos',
			type: 'Bestseller Product Search Product Noos',
			required: false,
		},
		{
			name: 'productGender',
			type: 'Bestseller Product Search Product Gender',
			friendlyName: 'Gender',
			required: false,
		},
	],
});
