import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Button } from 'components/shared/Button';
import { Icon, IconSize } from 'components/shared/Icon';
import styles from './SearchBar.module.scss';

export interface SearchBarProps extends React.InputHTMLAttributes<HTMLInputElement> {
	buttonType?: 'button' | 'submit';
	classNameInput?: string;
	classNameButtons?: string;
	onClear?: () => void;
	iconSize?: IconSize;
}

/**
 * SearchBar component, includes search input. Value can be uncontrolled or controlled.
 * Submit should be handled in parent component.
 */
export const SearchBar = React.forwardRef<HTMLInputElement, SearchBarProps>(
	(
		{ className, classNameInput, classNameButtons, iconSize, placeholder, buttonType, value, onClear, ...rest },
		ref,
	) => {
		SearchBar.displayName = 'SearchBar';
		const { data: translations } = useTranslationQuery();

		return (
			<div className={classNames(styles.wrapper, className)}>
				<input
					className={classNames(styles.input, classNameInput)}
					type={'search'}
					placeholder={placeholder}
					ref={ref}
					value={value}
					{...rest}
				/>
				<div className={classNames(styles.searchBarButtons, classNameButtons)}>
					{value && (
						<Button hasNoStyles type={'reset'} onClick={onClear}>
							<Icon name={'close'} size={iconSize || 'md'} />
						</Button>
					)}
					<Button
						className={styles.submitButton}
						type={buttonType || 'submit'}
						hasNoStyles
						title={placeholder}
						aria-label={translations?.shared.searchSubmit}
					>
						<Icon className={classNames(styles.searchIcon)} name={'search'} size={iconSize || 'md'} />
					</Button>
				</div>
			</div>
		);
	},
);
