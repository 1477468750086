import React from 'react';
import { InternalServerError } from './routes/500';

interface ErrorHandlerProps {
	children?: React.ReactElement;
}

const ErrorHandler: React.FunctionComponent<ErrorHandlerProps> = ({ children }) => {
	const [data, setData] = React.useState<{
		message?: string;
		stackTrace?: string;
		success?: boolean;
	} | null>(null);

	try {
		return <>{children}</>;
	} catch (err) {
		console.log('ErrorHandler in catch', { children, err });

		setData({ message: (err as Error).message, stackTrace: (err as Error).stack });

		if (data && !data.success) {
			return <InternalServerError error={data.message} description={data.stackTrace} />;
		}

		return <>{children}</>;
	}
};

export default ErrorHandler;
