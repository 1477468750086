import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { useTranslationQuery } from 'api/translations';
import { InputCheckbox } from 'components/shared';
import { ShipToInfoResponse } from 'generated/data-contracts';
import { formatTranslation } from 'helpers/stringHelpers';
import styles from './AccountSelectorList.module.scss';

interface AccountSelectorListProps {
	isFetching: boolean;
	isFetchingSavedShipTos: boolean;
	isPreviousData: boolean;
	isPreviousDataSavedShipTos: boolean;
	isErrorOrErrorSavedShipTos: boolean;
	filteredList: ShipToInfoResponse[];
	selectedShipTos: ShipToInfoResponse[];
	searchText: string;
	displayNoAccountsMessage?: string;
	handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const AccountSelectorList: React.FC<AccountSelectorListProps> = ({
	isFetching,
	isFetchingSavedShipTos,
	isPreviousData,
	isPreviousDataSavedShipTos,
	isErrorOrErrorSavedShipTos,
	filteredList,
	selectedShipTos,
	searchText,
	displayNoAccountsMessage,
	handleChange,
}) => {
	const { data: translations } = useTranslationQuery();

	const isLoadingOrFetching =
		(isFetching && !isPreviousData) || (isFetchingSavedShipTos && !isPreviousDataSavedShipTos);

	if (isLoadingOrFetching) {
		return (
			<div className={styles.spinnerContainer}>
				<span className="u-spinner"></span>
			</div>
		);
	}

	if (isErrorOrErrorSavedShipTos) {
		return <div>{translations?.accountSelector.showingAccountsError}</div>;
	}

	if (!isEmpty(filteredList)) {
		return filteredList.map((item, index) => {
			const isSelected = selectedShipTos.some((account) => account.id === item.id);

			return (
				<li
					key={`${item.id}-${index}`}
					className={classNames(styles.shipToItem, {
						[styles.selected]: isSelected,
					})}
				>
					<InputCheckbox
						onChange={handleChange}
						checkBoxClassName={styles.checkBox}
						name="shipToIds"
						value={item.id}
						label={
							<div>
								<span>{item.name}</span>
								<div className={styles.shipToInfo}>
									<span>
										{translations?.basket.accountId}: {item.id}
									</span>
									<span>
										{item.streetAndNumber} {item.postalCode} {item.city}
									</span>
								</div>
							</div>
						}
						checked={isSelected}
					/>
				</li>
			);
		});
	}

	return (
		<li className={styles.noResults}>
			<span className={styles.noResultsHeading}>
				{searchText
					? formatTranslation(translations?.productList.noResultsFor, {
							0: searchText,
					  })
					: translations?.productList.noResults}
			</span>
			<p>{displayNoAccountsMessage}</p>
		</li>
	);
};
