import { createReducer } from '@reduxjs/toolkit';
import { BasketDetailsResponse, ProductBasketResponse } from 'generated/data-contracts';
import { basketActions } from 'store/actions/basketActions';
import { BasketState } from 'store/types';

export const initialState: BasketState = {
	basket: {} as BasketDetailsResponse,
	pdpBasket: {} as ProductBasketResponse,
	preBasket: { familyId: '', basketLines: [], basketDeliveryDate: '' },
	miniBasketQuantity: 0,
};

const basketReducer = createReducer(initialState, (builder) => {
	builder.addCase(basketActions.updateBasketContent, (state, action) => {
		state.basket = action.payload;
		state.miniBasketQuantity = action.payload.miniBasketQuantity;
	});
	builder.addCase(basketActions.updateBasketLine, (state, action) => {
		if (!state.basket) {
			state.basket = {} as BasketDetailsResponse;
		}
		state.miniBasketQuantity = action.payload.miniBasketQuantity;
		state.basket.basketContents = action.payload.basketContents;
		state.basket.totalPrice = action.payload.totalPrice;
		state.basket.subTotal = action.payload.subTotal;
		state.basket.selectedMonthsTotalPrice = action.payload.selectedMonthsTotalPrice;
		state.basket.selectedMonthsTotalQuantity = action.payload.selectedMonthsTotalQuantity;
	});

	builder.addCase(basketActions.updatePDPBasket, (state, action) => {
		state.pdpBasket = action.payload;
	});
	builder.addCase(basketActions.updateMiniQuantity, (state, action) => {
		state.miniBasketQuantity = action.payload;
	});
	builder.addCase(basketActions.clearBasketContent, (state) => {
		state.basket = null;
		state.miniBasketQuantity = 0;
	});

	builder.addCase(basketActions.updatePreBasket, (state, action) => {
		state.preBasket = action.payload;
	});
});

export default basketReducer;
