import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import { Button, Drawer, DrawerHeader, Icon, IconName } from 'components/shared';
import { LanguageSelector } from '../../LanguageSelector';
import topBarStyles from '../TopBar.module.scss';
import styles from './LanguageDrawer.module.scss';

export const LanguageDrawer: FC = () => {
	const { data: layout } = useLayoutQuery();
	const segmentations = layout?.segmentations;
	const currentSegmentation = segmentations?.find((item) => item.isCurrentSegmentation === true);
	const { data: translations } = useTranslationQuery();
	const [isOpen, setIsOpen] = useState(false);

	const handleOpen = React.useCallback((e): void => {
		e.currentTarget.blur();
		setIsOpen(true);
	}, []);

	const handleClose = React.useCallback((): void => {
		setIsOpen(false);
	}, []);

	if (!segmentations || !currentSegmentation) return null;
	const languageIconName = currentSegmentation.cultureCode?.split('-')[1]?.toUpperCase() as IconName;
	return (
		<>
			<Button
				className={classNames(styles.languageDrawerButton, topBarStyles.topBarButton)}
				variant="ghost"
				hasNoPaddingX
				onClick={handleOpen}
			>
				<Icon name={languageIconName} size="sm" />
				{currentSegmentation.name}
			</Button>
			{isOpen && (
				<Drawer desktopWidth="30rem" direction="Right" onClose={handleClose}>
					<DrawerHeader title={translations?.header.languageDrawerHeaderTitle} handleClose={handleClose} />
					<LanguageSelector />
				</Drawer>
			)}
		</>
	);
};
