import React from 'react';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { Link } from 'components/shared/Link';
import { MegaMenuItem } from 'generated/data-contracts';
import { Button } from '../Button';
import { LoadingBar } from '../LoadingBar';
import styles from './SideMenu.module.scss';

interface SideMenuProps {
	className?: string;
	ariaLabel?: string;
	menu?: SideMenuItem[];
	changeCategory?: (categoryId: string) => void;
}

export interface SideMenuItem extends MegaMenuItem {
	isInActiveSection: boolean;
	children: SideMenuItem[];
	isDisabled?: boolean;
}

/**
 * Generic SideMenu component, used for showing a local side menu (e.g. on PLP or MyPages)
 */
export const SideMenu: React.FunctionComponent<SideMenuProps> = ({ ariaLabel, className, menu, changeCategory }) => {
	const [searchParams] = useSearchParams();

	const queryParams = React.useMemo(() => {
		const updatedParams = searchParams;
		const excludedParams = ['page'];

		excludedParams.forEach((excludedParam) => {
			if (!updatedParams.get(excludedParam)) return;

			updatedParams.delete(excludedParam);
		});

		return `?${updatedParams.toString()}`;
	}, [searchParams]);

	const renderLevel = (level: SideMenuItem[], levelCount = 1): React.ReactNode => (
		<ul className={classNames(styles.list, { [styles.notFirstLevel]: levelCount > 1 })}>
			{level?.map((item, index) => {
				const isActive = item.selected;
				const shouldBeLink = !item.isDisabled;

				if (item.isDisabled) {
					return;
				}

				return (
					<li key={index}>
						{changeCategory ? (
							<Button
								className={classNames(styles.link, {
									[styles.isActive]: isActive,
									[styles.isFirstLevel]: levelCount === 1,
									[styles.isInActivePath]: item.isInActiveSection,
								})}
								variant={'linkButton'}
								onClick={(): void => changeCategory(item.route?.productCategoryId || '')}
								isTextLink
							>
								{item.name}
							</Button>
						) : (
							<Link
								className={classNames(styles.link, {
									[styles.isActive]: isActive,
									[styles.isFirstLevel]: levelCount === 1,
									[styles.isInActivePath]: item.isInActiveSection,
								})}
								to={shouldBeLink ? item.route?.externalRoute + queryParams : undefined}
								state={shouldBeLink ? item.route : undefined}
								onClick={(): void => window.scrollTo(0, 0)}
								isTextLink
							>
								{item.name}
							</Link>
						)}
						{item.children && item.children?.length > 0 && renderLevel(item.children, levelCount + 1)}
					</li>
				);
			})}
		</ul>
	);

	const renderSkeleton = (): React.ReactNode => {
		return (
			<ul className={styles.skeletonList}>
				<li>
					<LoadingBar width="75%" />
				</li>

				<li>
					<LoadingBar width="50%" />
				</li>

				<li>
					<LoadingBar width="25%" />
				</li>
			</ul>
		);
	};

	return (
		<nav className={classNames(styles.wrapper, className)} aria-label={ariaLabel} role={'navigation'}>
			{menu ? renderLevel(menu) : renderSkeleton()}
		</nav>
	);
};
