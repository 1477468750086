import React from 'react';
import classNames from 'classnames';
import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';
import styles from './Chip.module.scss';

interface ChipProps {
	children?: React.ReactElement | string;
	className?: string;
	disabled?: boolean;
	onCancel: () => void;
}

/**
 * Generic Chip component, used for e.g. visualizing active filters
 */
export const Chip: React.FunctionComponent<ChipProps> = ({ children, className, disabled, onCancel }) => {
	return (
		<Button
			disabled={disabled}
			className={classNames(styles.wrapper, className)}
			size={'sm'}
			variant={'secondary'}
			onClick={onCancel}
		>
			{children}
			<Icon className={'u-ml-2xs'} name={'close'} size={'sm'} />
		</Button>
	);
};
