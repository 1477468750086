import React from 'react';
import { FetchNextPageOptions, UseInfiniteQueryResult } from '@tanstack/react-query';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Button } from 'components/shared';
import styles from './PaginationPrevious.module.scss';

interface PaginationPreviousProps {
	className?: string;
	fetchPreviousPage?: (options?: FetchNextPageOptions) => Promise<UseInfiniteQueryResult>;
}

export const PaginationPrevious: React.FunctionComponent<PaginationPreviousProps> = ({
	className,
	fetchPreviousPage,
}) => {
	const { data: translations } = useTranslationQuery();

	const handleClick = fetchPreviousPage ? (): Promise<UseInfiniteQueryResult> => fetchPreviousPage() : undefined;

	return (
		<div className={classNames(styles.paginationPrevious, className)}>
			<Button variant={'dark'} onClick={handleClick}>
				{translations?.shared.pagination.showPrevious}
			</Button>
		</div>
	);
};
