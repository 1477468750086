import React, { PropsWithChildren } from 'react';
import { uniq, uniqBy } from 'lodash';
import { useTranslationQuery } from 'api/translations';
import { OrderHistoryProductMasterResponse } from 'generated/data-contracts';

interface UseSizeSplitContextReturnType {
	columns: ColumnType<ColumnKeys>[];
	is2DimensionalTable: boolean;
	masters?: OrderHistoryProductMasterResponse[];
	rowGroups: RowGroup[];
}

export interface RowType {
	length?: string;
	size?: string;
	quantity?: number;
}

export interface ColumnType<T = string> {
	header: string;
	key: T | string;
	subheader?: string;
}

export type RowGroup = {
	master: OrderHistoryProductMasterResponse;
	rows: {
		length?: string;
		size?: string;
		quantity?: number;
	}[];
};

export enum ColumnKeys {
	COLOR = 'color',
	SIZE = 'size',
	QUANTITY = 'quantity',
	LENGTH = 'length',
}

export type UseSizeSplitContextProps = {
	masters?: OrderHistoryProductMasterResponse[];
};

const useSizeSplitContextState = ({ masters }: UseSizeSplitContextProps): UseSizeSplitContextReturnType => {
	const { data: translations } = useTranslationQuery();
	const is2DimensionalTable = React.useMemo(
		() => masters?.flatMap((master) => master.variants).some((variant) => variant.length) ?? false,
		[masters],
	);

	const columns: ColumnType<ColumnKeys>[] = React.useMemo(() => {
		if (!masters?.length || !translations) return [];

		const basketTranslations = translations.basket;
		const allVariants = masters?.flatMap((master) => master.variants);

		if (!is2DimensionalTable)
			return [
				{
					header: basketTranslations.color,
					key: ColumnKeys.COLOR,
				},
				{
					header: basketTranslations.size,
					key: ColumnKeys.SIZE,
				},
				{
					header: basketTranslations.quantity,
					key: ColumnKeys.QUANTITY,
				},
			];
		const sizes = uniq(allVariants.map((variant) => variant.size));

		return [
			{
				header: basketTranslations.color,
				key: ColumnKeys.COLOR,
			},
			{
				header: basketTranslations.size,
				subheader: basketTranslations.length,
				key: ColumnKeys.LENGTH,
			},
			...sizes.map((size) => ({
				header: size,
				key: size,
			})),
		];
	}, [is2DimensionalTable, masters, translations]);

	const rowGroups: RowGroup[] = React.useMemo(
		() =>
			masters?.map((master) => {
				const rows: RowType[] = is2DimensionalTable
					? uniqBy(
							master.variants.map((r) => ({
								length: r.length ?? undefined,
								size: undefined,
								quantity: undefined,
							})),
							'length',
					  )
					: master.variants.map((r) => ({
							length: r.length ?? undefined,
							size: r.size ?? undefined,
							quantity: r.quantity ?? undefined,
					  }));

				return {
					master,
					rows,
				};
			}) ?? [],
		[is2DimensionalTable, masters],
	);

	return {
		columns,
		is2DimensionalTable,
		masters,
		rowGroups,
	};
};

const SizeSplitContext = React.createContext<UseSizeSplitContextReturnType | null>(null);

export const SizeSplitContextProvider: React.FunctionComponent<PropsWithChildren<UseSizeSplitContextProps>> = ({
	children,
	...props
}) => {
	const value = useSizeSplitContextState(props);

	return <SizeSplitContext.Provider value={value}>{children}</SizeSplitContext.Provider>;
};

export const useSizeSplitContext = (): UseSizeSplitContextReturnType => {
	const context = React.useContext(SizeSplitContext);

	if (!context) {
		throw new Error('useSizeSplitContext must be used within a SizeSplitContextProvider');
	}

	return context;
};
