import React from 'react';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import { useTranslationQuery } from 'api/translations';
import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';
import styles from './DrawerHeader.module.scss';

export interface DrawerHeaderWBackButtonProps {
	className?: string;
	size?: 'sm' | 'lg';
	backButtonText?: string;
	title?: string;
	handleBack?: () => void;
	handleClose: () => void;
}

export const DrawerHeaderWBackButton: React.FunctionComponent<DrawerHeaderWBackButtonProps> = ({
	className,
	title,
	size = 'lg',
	backButtonText,
	handleBack,
	handleClose,
}) => {
	const { data: translations } = useTranslationQuery();

	return (
		<header className={classNames(styles.header, className, styles['size' + capitalize(size)])}>
			{handleBack && (
				<Button
					aria-label={backButtonText || translations?.shared.back}
					variant={'outline'}
					size={'sm'}
					hasOnlyIcon
					onClick={handleBack}
					title={translations?.shared.back}
				>
					<Icon name="chevronDown" size="lg" rotate={90} />
				</Button>
			)}
			<span
				className={classNames(styles.headerTitle, {
					[styles.centerHeaderTitle]: handleBack,
					[styles.titleWithNoBackButton]: !handleBack,
				})}
			>
				{title}
			</span>
			<Button
				aria-label={translations?.shared.close}
				title={translations?.shared.close}
				variant={'outline'}
				size={'sm'}
				hasOnlyIcon
				onClick={handleClose}
			>
				<Icon name="close" size="md" />
			</Button>
		</header>
	);
};
