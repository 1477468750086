import { useDispatch } from 'react-redux';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { queryKeys, setHeaders } from 'api/apiConfig';
import { AlertTypes } from 'components/shared';
import { Image } from 'generated/Image';
import { ImageLookupRequestItem, ImageLookupResponse } from 'generated/data-contracts';
import { formatTranslation } from 'helpers/stringHelpers';
import { appActions } from 'store/actions/appActions';
import { useTranslationQuery } from './translations';

export const useImageLookup = (images: ImageLookupRequestItem[]): UseQueryResult<ImageLookupResponse> => {
	const { data: translations } = useTranslationQuery();
	const dispatch = useDispatch();

	return useQuery({
		queryKey: queryKeys.image.lookup(images).queryKey,
		queryFn: async (): Promise<ImageLookupResponse> => {
			const imageApi = new Image({ baseApiParams: { headers: setHeaders() || undefined } });
			const response = await imageApi.imageLookupCreate({ images });

			if (!response.ok) {
				dispatch(
					appActions.addNotification({
						children: formatTranslation(translations?.shared?.genericErrorMsg, {}),
						type: AlertTypes.DANGER,
					}),
				);
			}

			return response.data;
		},
	});
};
