import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { InitialState } from 'store/types';
import { Notification } from './Notification';
import styles from './Notifications.module.scss';

/**
 * Wrapper that handles notifications from redux.
 * Remember to only add this to the project once - else the notifications gets rendered twice.
 */
export const Notifications: React.FunctionComponent = () => {
	const notifications = useSelector((state: InitialState) => state.app.notifications);

	return (
		<div className={classNames(styles.list, 'u-container-sm')}>
			{notifications.map(({ children, id, ...rest }) => (
				<Notification {...rest} id={id} key={id}>
					{children}
				</Notification>
			))}
		</div>
	);
};
