/**
 * Checks whether specified media query matches or not
 *
 * @param query
 */
export const getMatchMedia = (query: string): boolean => {
	if (typeof window !== 'undefined' && typeof window.matchMedia === 'function') {
		return window.matchMedia(`only screen and ${query}`).matches;
	}

	return false;
};
