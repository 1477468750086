import { Builder, withChildren } from '@builder.io/react';
import { BuilderElement } from '@builder.io/react';
import { Carousel } from '../Carousel';

const CarouselWithChildren = withChildren(Carousel);

const defaultElement: BuilderElement = {
	'@type': '@builder.io/sdk:Element',
	children: [
		{
			'@type': '@builder.io/sdk:Element',
			responsiveStyles: {
				large: {
					/**
					 * Spacing is hardcoded to 2Xl found in spacing.scss file.
					 * Once CSS variables are introduced, these should be replaced
					 * with var(--spacing2Xl).
					 */
					marginTop: '64px',
					marginBottom: '64px',
					textAlign: 'center',
					display: 'flex',
					flexDirection: 'column',
				},
			},
			component: {
				name: 'Text',
				options: {
					text: 'I am a slide',
				},
			},
		},
	],
};

Builder.registerComponent(CarouselWithChildren, {
	name: 'Carousel',
	image: new URL('/dist/images/cms/carousel.svg', window.location.origin).href,
	canHaveChildren: true,

	inputs: [
		{
			name: 'slides',
			type: 'list',
			broadcast: true,
			subFields: [
				{
					name: 'content',
					type: 'uiBlocks',
					hideFromUI: true,
					defaultValue: [defaultElement],
				},
			],
			defaultValue: [
				{
					content: [defaultElement],
				},
				{
					content: [defaultElement],
				},
			],
		},
		{
			name: 'showDots',
			helperText: 'Show pagination dots',
			type: 'boolean',
			defaultValue: true,
		},
		{
			name: 'showArrows',
			helperText: 'Show navigation arrows',
			type: 'boolean',
			defaultValue: true,
		},
		{
			name: 'autoPlay',
			helperText: 'Automatically rotate to the next slide every few seconds',
			type: 'boolean',
			defaultValue: false,
		},
		{
			name: 'autoPlaySpeed',
			helperText: 'Milliseconds to wait before automatically changing each slide. Only applies if autoplay is on',
			type: 'number',
			advanced: true,
		},
		{
			name: 'loop',
			helperText: 'Infinite loop the slides',
			type: 'boolean',
			defaultValue: true,
			advanced: true,
		},
		{
			name: 'showArrowsOnMobile',
			helperText: 'Show navigation arrows on mobile',
			type: 'boolean',
			defaultValue: false,
			advanced: true,
		},
	],
});
