/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ErrorResult,
  ProductListResponse,
  RelewiseProductRecommendationRequest,
  UnauthorizedResult,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Recommendation<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Recommendation
   * @name RecommendationRelewiseproductrecommendationsCreate
   * @request POST:/api/recommendation/relewiseproductrecommendations
   * @secure
   */
  recommendationRelewiseproductrecommendationsCreate = (
    data: RelewiseProductRecommendationRequest,
    params: RequestParams = {},
  ) =>
    this.request<ProductListResponse, ErrorResult | UnauthorizedResult | void>({
      path: `/api/recommendation/relewiseproductrecommendations`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
