import { useCallback, useEffect, useState } from 'react';
import { useCarouselStore } from 'components/shared/Carousel/state/CarouselContext';
import { useOnDoubleTap } from 'components/shared/hooks/useOnDoubleTap';

interface ZoomableProps {
	ref: React.MutableRefObject<HTMLDivElement | null>;
	wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
}

type EventTypes = PointerEvent | MouseEvent;

export const useZoomable = ({ ref, wrapperRef }: ZoomableProps): void => {
	const MAX_ZOOM = 4;
	const MIN_ZOOM = 1;
	const [desktopZoomEnabled, setDesktopZoomEnabled] = useState(false);
	const getBounds = useCallback((): { x: number; y: number } => {
		const maximumScrollableWidth =
			(ref.current?.getBoundingClientRect().width ?? 0) -
			(wrapperRef.current?.getBoundingClientRect().width ?? 0);

		const maximumScrollableHeight =
			(ref.current?.getBoundingClientRect().height ?? 0) -
			(wrapperRef.current?.getBoundingClientRect().height ?? 0);
		const scale = +(ref.current?.style.scale || MIN_ZOOM);
		return {
			x: maximumScrollableWidth / scale,
			y: maximumScrollableHeight / scale,
		};
	}, [ref, wrapperRef]);

	const {
		slides: { activeSlide },
	} = useCarouselStore();

	const setIsZoomed = useCallback(
		(isZoomed: boolean) => {
			const image = ref.current;
			if (!image) return;
			ref.current?.setAttribute('data-is-zoomed', `${isZoomed}`);
		},
		[ref],
	);

	type CurrentOffset = {
		x: string;
		y: string;
		z: string;
	} | null;

	const getCurrentOffset = useCallback((): CurrentOffset => {
		const image = ref.current;
		if (!image) return null;
		const currentPos = image.style.transform;
		// REGEX TO GET FULL NUMBERS, INCLUDING NEGATIVE AND DECIMAL
		const regex = new RegExp(/translate3d\((?<x>.*?)px, (?<y>.*?)px, (?<z>.*?)px/);
		return regex.exec(currentPos)?.groups as CurrentOffset;
	}, [ref]);

	const getPercentageOfElementAtPoint = useCallback(
		(point: { x: number; y: number }): { x: number; y: number } => {
			const image = ref.current;
			if (!image)
				return {
					x: 0.5,
					y: 0.5,
				};
			// const previousScale = +(image.style.scale ?? MIN_ZOOM);
			const rect = image.getBoundingClientRect();

			return { x: point.x / (rect.width ?? 1), y: point.y / (rect.height ?? 1) };
		},
		[ref],
	);
	useEffect(() => {
		const image = ref.current;
		if (!image) return;
		image.style.transform = `translate3d(0px, 0px, 0)`;
		image.style.scale = `${MIN_ZOOM}`;
		setIsZoomed(false);
		setDesktopZoomEnabled(false);
	}, [activeSlide, ref, setIsZoomed]);

	const translatePositionToNewScale = useCallback(
		(
			pos: {
				x: number;
				y: number;
			},
			newScale = MAX_ZOOM,
		) => {
			const image = ref.current;
			if (!image) return { x: 0, y: 0 };

			const rect = image.getBoundingClientRect();
			const previousScale = +(image.style.scale ?? MIN_ZOOM);
			const originalWidth = rect.width / previousScale;
			const originalHeight = rect.height / previousScale;
			const percentageOfElementAtPoint = { x: pos.x / (originalWidth ?? 1), y: pos.y / (originalHeight ?? 1) };
			const newWidth = originalWidth * newScale;
			const newHeight = originalHeight * newScale;

			const maxScrollableWidth = (newWidth - originalWidth) / newScale;
			const maxScrollableHeight = (newHeight - originalHeight) / newScale;

			const positionX = maxScrollableWidth * percentageOfElementAtPoint.x;
			const positionY = maxScrollableHeight * percentageOfElementAtPoint.y;

			return { x: positionX, y: positionY };
		},
		[ref],
	);

	// START DOUBLE CLICK
	const doubleClickEventListener = useCallback(
		(event) => {
			const image = ref.current;
			if (!image) return;
			const shouldZoomToMax = +(image.style.scale ?? MIN_ZOOM) < MAX_ZOOM;
			if (shouldZoomToMax) {
				const pos = translatePositionToNewScale({
					x: event.offsetX,
					y: event.offsetY,
				});
				image.style.scale = `${MAX_ZOOM}`;
				setIsZoomed(true);
				image.style.transform = `translate3d(${-pos.x}px, ${-pos.y}px, 0)`;
			} else {
				setIsZoomed(false);
				image.style.transform = `translate3d(0, 0, 0)`;
			}
			image.style.scale = shouldZoomToMax ? `${MAX_ZOOM}` : `${MIN_ZOOM}`;
		},
		[ref, setIsZoomed, translatePositionToNewScale],
	);

	useOnDoubleTap(ref, doubleClickEventListener);
	// START PINCH
	const pinchEventListener = useCallback<
		() => {
			pointerDownHandler: (this: HTMLDivElement, ev: EventTypes) => unknown;
			pointerMoveHandler: (this: HTMLDivElement, ev: EventTypes) => unknown;
			pointerUpHandler: (this: HTMLDivElement, ev: EventTypes) => unknown;
		}
	>(() => {
		const eventCache: EventTypes[] = [];
		let currentZoomAtStart = 1;
		let startDistance = 0;
		let percentageOfImageAtPinchPointX = 0;
		let percentageOfImageAtPinchPointY = 0;
		let centerPointStartX = 0;
		let centerPointStartY = 0;
		let currentOffsetX = 0;
		let currentOffsetY = 0;

		let startOffsetX = 0;
		let startOffsetY = 0;
		let newOffsetX;
		let newOffsetY;
		let newScale;

		const getPointerId = (event: EventTypes): number | string => {
			if ('pointerId' in event) {
				return event.pointerId;
			} else {
				return 'mouse';
			}
		};

		const cacheEvent = (event: EventTypes, addIfNotExist: boolean = false): void => {
			const index = eventCache.findIndex((cachedEv) => getPointerId(cachedEv) === getPointerId(event));
			if (index === -1) {
				if (addIfNotExist) eventCache.push(event);
			} else {
				eventCache[index] = event;
			}
		};

		const removeEvent = (event: EventTypes): void => {
			const index = eventCache.findIndex((cachedEv) => getPointerId(cachedEv) === getPointerId(event));
			if (index !== -1) {
				eventCache.splice(index, 1);
			}
		};

		const getPosition = (
			event: EventTypes,
		): {
			x: number;
			y: number;
		} => {
			return {
				x: event.clientX,
				y: event.clientY,
			};
		};

		const getDistanceBetweenPointers = (firstPointer: EventTypes, secondPointer: EventTypes): number => {
			const first = getPosition(firstPointer);
			const second = getPosition(secondPointer);
			const deltaX = first.x - second.x;
			const deltaY = first.y - second.y;
			return Math.sqrt(deltaX ** 2 + deltaY ** 2);
		};

		const getCenterPoint = (
			firstPointer: EventTypes,
			secondPointer: EventTypes,
		): {
			x: number;
			y: number;
		} => {
			const first = getPosition(firstPointer);
			const second = getPosition(secondPointer);
			return {
				x: (first.x + second.x) / currentZoomAtStart / 2.0,
				y: (first.y + second.y) / currentZoomAtStart / 2.0,
			};
		};

		return {
			pointerMoveHandler: (event): void => {
				const image = ref.current;
				if (!image) return;

				if ('ontouchstart' in window === false) {
					if (!desktopZoomEnabled) return;
					setIsZoomed(true);
					const pos = translatePositionToNewScale({
						x: event.offsetX,
						y: event.offsetY,
					});

					image.style.scale = `${MAX_ZOOM}`;
					image.style.transform = `translate3d(${-pos.x}px, ${-pos.y}px, 0)`;
					return;
				}
				cacheEvent(event);
				if (eventCache.length === 0) {
					// DO DESKTOP ZOOM IF NOT USING TOUCH
				} else if (eventCache.length === 1) {
					const isZoomed = image.attributes.getNamedItem('data-is-zoomed')?.value === 'true';
					if (!isZoomed) {
						return;
					}

					const { x: endOffsetX, y: endOffsetY } = getPosition(event);
					const deltaX = (endOffsetX - startOffsetX) / currentZoomAtStart;
					const deltaY = (endOffsetY - startOffsetY) / currentZoomAtStart;
					newOffsetX = currentOffsetX + deltaX;
					newOffsetY = currentOffsetY + deltaY;
					const bounds = getBounds();
					const newX = Math.max(-bounds.x, Math.min(0, newOffsetX));
					const newY = Math.max(-bounds.y, Math.min(0, newOffsetY));
					image.style.transform = `translate3d(${newX}px, ${newY}px, 0)`;
				} else if (eventCache.length === 2) {
					const firstPointer = eventCache[0];
					const secondPointer = eventCache[1];

					// Calculate the distance between the two pointers
					const distanceBetweenPointers = getDistanceBetweenPointers(firstPointer, secondPointer);
					const pinchDelta = distanceBetweenPointers / startDistance;
					newScale = Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, pinchDelta * currentZoomAtStart));

					// Get the point between the two touches, relative to upper-left corner of image
					const { x: centerPointEndX, y: centerPointEndY } = getCenterPoint(firstPointer, secondPointer);

					const bounds = getBounds();

					// This is the translation due to pinch-zooming
					const translateFromZoomingX = -bounds.x * percentageOfImageAtPinchPointX;
					const translateFromZoomingY = -bounds.y * percentageOfImageAtPinchPointY;

					// And this is the translation due to translation of the centerpoint between the two fingers

					const translateFromTranslatingX = centerPointEndX - centerPointStartX;
					const translateFromTranslatingY = centerPointEndY - centerPointStartY;

					const translateTotalX = translateFromZoomingX + translateFromTranslatingX;
					const translateTotalY = translateFromZoomingY + translateFromTranslatingY;

					// the new offset is the old/current one plus the total translation component

					newOffsetX = Math.max(-bounds.x, Math.min(0, currentOffsetX + translateTotalX));
					newOffsetY = Math.max(-bounds.y, Math.min(0, currentOffsetY + translateTotalY));
					image.style.scale = `${newScale}`;
					if (newScale === MIN_ZOOM) {
						image.style.transform = `translate3d(0, 0, 0)`;
						setIsZoomed(false);
					} else {
						image.style.transform = `translate3d(${newOffsetX}px, ${newOffsetY}px, 0)`;
						setIsZoomed(true);
					}
				}
			},
			pointerDownHandler: (event): void => {
				cacheEvent(event, true);

				const image = ref.current;
				if (!image) return;
				currentZoomAtStart = +(image.style.scale || MIN_ZOOM);
				const currentOffsets = getCurrentOffset();
				currentOffsetX = +(currentOffsets?.x || 0);
				currentOffsetY = +(currentOffsets?.y || 0);
				if ('ontouchstart' in window === false) {
					setDesktopZoomEnabled((p) => {
						const shouldBeSetToZoomed = !p;
						setIsZoomed(shouldBeSetToZoomed);

						if (shouldBeSetToZoomed) {
							const pos = translatePositionToNewScale({
								x: event.offsetX,
								y: event.offsetY,
							});

							image.style.scale = `${MAX_ZOOM}`;
							image.style.transform = `translate3d(${-pos.x}px, ${-pos.y}px, 0)`;
						} else {
							image.style.scale = `${MIN_ZOOM}`;
							image.style.transform = `translate3d(0, 0, 0)`;
						}
						return shouldBeSetToZoomed;
					});
					return;
				}
				if (eventCache.length == 1) {
					const isZoomed = image.attributes.getNamedItem('data-is-zoomed')?.value === 'true';
					if (!isZoomed) {
						return;
					}
					image.style.touchAction = 'pan-x pan-y';
					const firstPointer = eventCache[0];
					const { x: firstPointerX, y: firstPointerY } = getPosition(firstPointer);
					startOffsetX = firstPointerX;
					startOffsetY = firstPointerY;
				} else if (eventCache.length === 2) {
					image.style.touchAction = 'pinch-zoom';

					const firstPointer = eventCache[0];
					const secondPointer = eventCache[1];

					firstPointer.preventDefault();
					secondPointer.preventDefault();

					const centerPoint = getCenterPoint(firstPointer, secondPointer);
					centerPointStartX = centerPoint.x;
					centerPointStartY = centerPoint.y;

					const percentagePoint = getPercentageOfElementAtPoint(centerPoint);
					percentageOfImageAtPinchPointX = percentagePoint.x;
					percentageOfImageAtPinchPointY = percentagePoint.y;

					startDistance = getDistanceBetweenPointers(firstPointer, secondPointer);
				}
			},
			pointerUpHandler: (event): void => {
				const image = ref.current;
				if (!image) return;
				removeEvent(event);
				if ('ontouchstart' in window === false) {
					return;
				}
				if (eventCache.length <= 2) {
					startDistance = 0;
					percentageOfImageAtPinchPointX = 0;
					percentageOfImageAtPinchPointY = 0;
					currentZoomAtStart = newScale;
					centerPointStartX = 0;
					centerPointStartY = 0;
					currentOffsetX = newOffsetX;
					currentOffsetY = newOffsetY;
				}

				// This is to ensure that when pinching stops, but dragging starts, it begins at the correct spot
				if (eventCache.length === 1) {
					const firstPointer = eventCache[0];
					const { x: firstPointerX, y: firstPointerY } = getPosition(firstPointer);
					startOffsetX = firstPointerX;
					startOffsetY = firstPointerY;
				}
				if (eventCache.length === 0) {
					image.style.touchAction = 'pan-x pan-y';
				}
			},
		};
	}, [
		desktopZoomEnabled,
		getBounds,
		getCurrentOffset,
		getPercentageOfElementAtPoint,
		ref,
		setIsZoomed,
		translatePositionToNewScale,
	]);

	useEffect(() => {
		const image = ref.current;
		const { pointerDownHandler, pointerUpHandler, pointerMoveHandler } = pinchEventListener();
		if (!image) return;
		image.addEventListener('pointerdown', pointerDownHandler);
		image.addEventListener('pointermove', pointerMoveHandler);
		image.addEventListener('pointerup', pointerUpHandler);
		image.addEventListener('pointercancel', pointerUpHandler);
		image.addEventListener('pointerout', pointerUpHandler);
		image.addEventListener('pointerleave', pointerUpHandler);

		return (): void => {
			image.removeEventListener('pointerdown', pointerDownHandler);
			image.removeEventListener('pointermove', pointerMoveHandler);
			image.removeEventListener('pointerup', pointerUpHandler);
			image.removeEventListener('pointercancel', pointerUpHandler);
			image.removeEventListener('pointerout', pointerUpHandler);
			image.removeEventListener('pointerleave', pointerUpHandler);
		};
	}, [pinchEventListener, ref]);
	// END PINCH
};
