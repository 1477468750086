import React, { FC } from 'react';
import { useTranslationQuery } from 'api/translations';
import { useProductCardContext } from '../../context/ProductCardContext';
import BottomSplash from '../BottomSplash';

export const Inbound: FC = () => {
	const { bottomSplashes } = useProductCardContext();
	const { data: translations } = useTranslationQuery();
	if (!bottomSplashes.inbound || !bottomSplashes.inbound.quantity) {
		return null;
	}
	let translation = translations?.productCard.inbound;
	if (bottomSplashes.inbound.inMultipleAccounts) {
		translation = translations?.productCard.inboundForMultipleStores;
	}
	return (
		<BottomSplash
		 icon="boxDelivery"
		 text={translation} 
		 quantity={bottomSplashes.inbound.quantity}
		 hideQuantity={bottomSplashes.inbound.inMultipleAccounts} />
	);
};
