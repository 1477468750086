import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';

interface LogOutButtonProps {
	className?: string;
	hideIcon?: boolean;
}

export const LogOutButton: React.FunctionComponent<LogOutButtonProps> = ({ className, hideIcon }) => {
	const { data: translations } = useTranslationQuery();
	return (
		<form action={'/api/user/logout'} method="post">
			<Button type={'submit'} className={className} hasNoStyles>
				{!hideIcon && <Icon name="logout" size="sm" />}
				{translations?.auth.signin.logout}
			</Button>
		</form>
	);
};
