import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import { Icon } from 'components/shared/Icon';
import { Link } from 'components/shared/Link';
import { BreadCrumbModel } from 'generated/data-contracts';
import { isScannerApp } from 'helpers/app';
import { Button } from '../Button';
import styles from './Breadcrumbs.module.scss';

interface BreadcrumbsProps {
	className?: string;
	breadCrumbs: BreadCrumbModel[] | null;
	hasBackButton?: boolean;
}

interface StructuredDataItemProps {
	'@type': 'ListItem';
	position: number;
	item: {
		'@id': string;
		name: string;
	};
}

/**
 * Generic Breadcrumbs component, used for showing path for current page.
 * Shown on CMS, PLP & PDP pages.
 */
export const Breadcrumbs: React.FunctionComponent<BreadcrumbsProps> = ({ className, breadCrumbs, hasBackButton }) => {
	const { data: translations } = useTranslationQuery();
	const { data: layout } = useLayoutQuery();
	const navigate = useNavigate();

	const hideBreadCrumbs = layout?.layoutBrandOptions?.hideBreadCrumbs;

	if (!breadCrumbs || hideBreadCrumbs) {
		return null;
	}

	const setStructuredItems = (): StructuredDataItemProps[] => {
		const arr: StructuredDataItemProps[] = [];

		breadCrumbs.forEach((item, index) => {
			arr.push({
				'@type': 'ListItem',
				position: index + 1,
				item: {
					'@id': item.link || '',
					name: item.text || '',
				},
			});
		});

		return arr;
	};

	const structuredData = {
		'@context': 'https://schema.org',
		'@type': 'BreadcrumbList',
		itemListElement: setStructuredItems(),
	};

	const handleNavigateBack = (): void => {
		navigate(-1);
	};

	if ((!hasBackButton || history.length === 0) && isScannerApp) return;

	return (
		<nav
			className={classNames(styles.navigation, className)}
			aria-label={translations?.shared.navigation.breadcrumbs}
		>
			{hasBackButton && history.length > 1 && (
				<Button
					variant={'secondary'}
					hasOnlyIcon
					size="xs"
					className={styles.backButton}
					title={translations?.shared.back}
					onClick={handleNavigateBack}
				>
					<Icon className={styles.icon} name={'chevronDown'} size={'md'} rotate={90} />
				</Button>
			)}
			{!isScannerApp && (
				<ol
					className={classNames(styles.list, 'u-scroll-bar-hidden', {
						[styles.hasBackButton]: hasBackButton,
					})}
				>
					{breadCrumbs?.map((item, index) => {
						const notLastItem = breadCrumbs?.length !== index + 1;

						if (notLastItem) {
							return (
								<li className={styles.item} key={`${item.text}${index}`}>
									<Link
										className={classNames(styles.link, {
											[styles.notClickable]: !item.route && !item.link,
										})}
										linkUrl={item.link}
										route={item.route}
										isTextLink
										to={item.link}
									>
										{item.text}
									</Link>
									<Icon className={styles.icon} name={'chevronDown'} size={'md'} rotate={270} />
								</li>
							);
						}

						return (
							<li className={styles.item} key={`${item.text}${index}`}>
								<Link
									className={classNames(styles.link, styles.notClickable, styles.truncate)}
									aria-current={'page'}
								>
									{item.text}
								</Link>
							</li>
						);
					})}
				</ol>
			)}
			<script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }} />
		</nav>
	);
};

export const MemoizedBreadcrumbs = React.memo(Breadcrumbs);
