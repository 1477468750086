import { useDispatch } from 'react-redux';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AlertTypes } from 'components/shared';
import { Language as LanguageApi } from 'generated/Language';
import { ProblemDetails, RouteResponse, SetLanguageRequest } from 'generated/data-contracts';
import { HttpResponse } from 'generated/http-client';
import { formatTranslation } from 'helpers/stringHelpers';
import { appActions } from 'store/actions/appActions';
import { setHeaders } from './apiConfig';
import { useTranslationQuery } from './translations';

export const useSetLanguageMutation = (): UseMutationResult<
	HttpResponse<RouteResponse, ProblemDetails | void>,
	HttpResponse<ProblemDetails | void>,
	SetLanguageRequest
> => {
	const dispatch = useDispatch();
	const { data: translations } = useTranslationQuery();

	return useMutation({
		mutationFn: async (variables) => {
			const languageApi = new LanguageApi({ baseUrl: undefined, baseApiParams: { headers: setHeaders() } });
			return languageApi.languageSetCreate(variables);
		},
		onError: () => {
			dispatch(
				appActions.addNotification({
					children: formatTranslation(translations?.shared.genericErrorMsg, {}),
					type: AlertTypes.DANGER,
				}),
			);
		},
	});
};
