import React from 'react';

/**
 * Listener for pressing escape, when listenerCondition is true, and fires callback when that happens
 *
 * @param callback
 * @param listenerCondition
 */
export const useOnEscape = (callback: () => void, listenerCondition: boolean): void => {
	React.useEffect(() => {
		const handleEscape = (event: KeyboardEvent): void => {
			if (event.key === 'Escape') {
				callback();
			}
		};

		if (listenerCondition) {
			document.addEventListener('keydown', handleEscape);
		}

		return (): void => {
			document.removeEventListener('keydown', handleEscape);
		};
	}, [listenerCondition, callback]);
};
