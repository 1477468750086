import { useSelector } from 'react-redux';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { queryKeys, setHeaders } from 'api/apiConfig';
import { Translations } from 'generated/Translations';
import { TranslationsWebsite } from 'generated/data-contracts';
import { InitialState } from 'store/types';

export const useTranslationQuery = (): UseQueryResult<TranslationsWebsite> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);

	return useQuery({
		queryKey: queryKeys.translations.fetch(segmentationId).queryKey,
		queryFn: async (): Promise<TranslationsWebsite> => {
			const translationsApi = new Translations({ baseApiParams: { headers: setHeaders() || undefined } });
			const response = await translationsApi.translationsList({ segmentationId });

			return response.data;
		},
	});
};
