import React, { Dispatch, SetStateAction } from 'react';


export const useLocalStorage = <S extends number | string | boolean>(
	storageKey: string,
	initialState: S | (() => S),
): [S, Dispatch<SetStateAction<S>>] => {
	const [value, setValue] = React.useState(() => {
		const storageValue = localStorage.getItem(storageKey);

		const doInitialState = () => {
			if (typeof initialState === 'function') {
				return initialState();
			}
			return initialState;
		};

		try {
			return storageValue ? JSON.parse(storageValue) : doInitialState();
		} catch (error) {
			return doInitialState();
		}
	});

	React.useEffect(() => {
		localStorage.setItem(storageKey, JSON.stringify(value));
	}, [value, storageKey]);

	return [value, setValue];
};

export const useSessionStorage = <S extends number | string | boolean | number[] | string[]>(
	storageKey: string,
	initialState: S | (() => S),
): [S, Dispatch<SetStateAction<S>>] => {
	const [value, setValue] = React.useState(() => {
		const storageValue = sessionStorage.getItem(storageKey);
		const doInitialState = () => {
			if (typeof initialState === 'function') {
				return initialState();
			}
			return initialState;
		};

		try {
			return storageValue ? JSON.parse(storageValue) : doInitialState();
		} catch (error) {
			return doInitialState();
		}
	});

	React.useEffect(() => {
		sessionStorage.setItem(storageKey, JSON.stringify(value));
	}, [value, storageKey]);

	return [value, setValue];
};