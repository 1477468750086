import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { InputText } from 'components/shared/InputText';
import styles from './MultiRangeSlider.module.scss';

export interface MultiRangeOnChangeProps {
	minVal: number;
	maxVal: number;
}

interface MultiRangeSliderProps {
	className?: string;
	showInputs?: boolean;
	name: string;
	inputSuffix?: string;
	inputPrefix?: string;
	min?: number;
	max: number;
	selectedMin?: number;
	selectedMax?: number;
	onChange: (MultiRangeOnChangeProps) => void;
}

/**
 * Generic MultiRangeSlider component, used for range inputs with two handles. (E.g. in price filter)
 */
export const MultiRangeSlider: React.FunctionComponent<MultiRangeSliderProps> = ({
	className,
	showInputs,
	name,
	inputSuffix,
	inputPrefix,
	min = 0,
	max,
	selectedMin,
	selectedMax,
	onChange,
}) => {
	const pickMinValue = (x: number | undefined, y: number): number => {
		if (x && y) return Math.max(x, y);
		else return y;
	};
	const pickMaxValue = (x: number | undefined, y: number): number => {
		if (x && y) return Math.min(x, y);
		else return y;
	};

	const { data: translations } = useTranslationQuery();
	const [minVal, setMinVal] = React.useState(pickMinValue(selectedMin, min));
	const [maxVal, setMaxVal] = React.useState(pickMaxValue(selectedMax, max));
	const minValRef = React.useRef<HTMLInputElement>(null);
	const maxValRef = React.useRef<HTMLInputElement>(null);
	const sliderRef = React.useRef<HTMLDivElement>(null);
	const railRef = React.useRef<HTMLDivElement>(null);
	const [maxWidth, setMaxWidth] = React.useState<number>(0);

	const disableInput = Math.floor(min) + 1 === Math.ceil(max);

	// Convert to percentage
	const getPixelValue = React.useCallback(
		(value): number => {
			let pixelValue = Math.floor(maxWidth * ((value - min) / (max - min)));

			if (pixelValue > maxWidth) {
				pixelValue = maxWidth;
			} else if (pixelValue < 0) {
				pixelValue = 0;
			}
			return pixelValue;
		},
		[maxWidth, min, max],
	);

	const updateMaxWidth = (): void => {
		if (!sliderRef.current) {
			return;
		}
		setMaxWidth(sliderRef.current.clientWidth);
	};

	React.useEffect(() => {
		if (!sliderRef.current) {
			return;
		}

		updateMaxWidth();

		const resizeObserver = new ResizeObserver(updateMaxWidth);
		resizeObserver.observe(sliderRef.current);
		return (): void => resizeObserver.disconnect();
	}, []);

	React.useEffect(() => {
		setMinVal(pickMinValue(selectedMin, min));
		setMaxVal(pickMaxValue(selectedMax, max));
	}, [selectedMin, selectedMax, min, max]);

	// Set width of the range to decrease from the left side
	React.useEffect(() => {
		if (maxValRef.current) {
			const minPixel = getPixelValue(minVal);
			const maxPixel = getPixelValue(+maxValRef.current.value);

			let styleWidth = `${maxPixel - minPixel}px`;

			if (minPixel > 0 && maxPixel > maxWidth) {
				styleWidth = '0%';
			}

			if (railRef.current) {
				railRef.current.style.left = `${minPixel}px`;
				railRef.current.style.width = styleWidth;
			}
		}
	}, [minVal, getPixelValue, maxWidth]);

	// Set width of the range to decrease from the right side
	React.useEffect(() => {
		if (minValRef.current) {
			const minPixel = getPixelValue(+minValRef.current.value);
			const maxPixel = getPixelValue(maxVal);

			if (railRef.current) {
				railRef.current.style.width = `${maxPixel - minPixel}px`;
			}
		}
	}, [maxVal, getPixelValue, maxWidth]);

	const handleMinChange = (e): void => {
		const value = Math.min(+(e.target.value >= min ? e.target.value : min), maxVal - 1);
		setMinVal(value);
		e.target.value = value;
		onChange({ minVal: value, maxVal: maxVal });
	};

	const handleMaxChange = (e): void => {
		const value = Math.max(+e.target.value <= max ? e.target.value : max, minVal + 1);
		setMaxVal(value);
		e.target.value = value;
		onChange({ minVal: minVal, maxVal: value });
	};

	const renderSlider = (classNameProp?: string): React.ReactNode => (
		<div
			className={classNames(styles.sliderWrapper, classNameProp, { [styles.isDisabled]: disableInput })}
			ref={sliderRef}
		>
			<input
				className={classNames(styles.handleInput, { [styles.hasHighestZIndex]: minVal > max - 100 })}
				type={'range'}
				min={Math.floor(min)}
				max={Math.ceil(max)}
				value={Math.floor(minVal)}
				disabled={disableInput}
				ref={minValRef}
				onChange={handleMinChange}
			/>
			<input
				className={classNames(styles.handleInput, styles.hasHigherZIndex)}
				type={'range'}
				min={Math.floor(min)}
				max={Math.ceil(max)}
				value={Math.ceil(maxVal)}
				ref={maxValRef}
				disabled={disableInput}
				onChange={handleMaxChange}
			/>

			<div className={styles.slider}>
				<div className={styles.track} />
				<div className={classNames(styles.track, styles.inRange)} ref={railRef} />
			</div>
		</div>
	);

	if (showInputs) {
		return (
			<div className={classNames(styles.wrapper, className)}>
				<section className={styles.inputRow}>
					<div className={styles.minMaxWrapper}>
						<InputText
							label={translations?.productList.filters.min}
							name={`min${name}`}
							id={`min${name}`}
							type={'number'}
							min={Math.floor(min)}
							max={Math.ceil(max)}
							value={Math.floor(minVal)}
							disabled={disableInput}
							suffix={inputSuffix}
							prefix={inputPrefix}
							onChange={handleMinChange}
							hideLabel
							isSmall
						/>
					</div>
					<div className={styles.minMaxWrapper}>
						<InputText
							label={translations?.productList.filters.max}
							name={`max${name}`}
							id={`max${name}`}
							type={'number'}
							min={Math.floor(min)}
							max={Math.ceil(max)}
							value={Math.ceil(maxVal)}
							disabled={disableInput}
							suffix={inputSuffix}
							prefix={inputPrefix}
							onChange={handleMaxChange}
							hideLabel
							isSmall
						/>
					</div>
				</section>

				{renderSlider()}
			</div>
		);
	}

	return <>{renderSlider(className)}</>;
};
