import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useInvoiceExportMutation } from 'api/myOrders';
import { useTranslationQuery } from 'api/translations';
import { AlertTypes } from 'components/shared/Alert';
import { Dropdown } from 'components/shared/Dropdown';
import { Icon } from 'components/shared/Icon';
import { Link } from 'components/shared/Link';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/shared/Tooltip';
import { downloadFile } from 'helpers/downloadFile';
import { appActions } from 'store/actions/appActions';
import { Invoice, useOrderLineContext } from '../../state/useOrderLineContext';
import { SkeletonButton } from '../SkeletonButton';
import styles from './InvoicesDropdown.module.scss';

export interface InvoicesDropdownProps {
	className?: string;
	buttonClassName?: string;
}

export const InvoicesDropdown: React.FunctionComponent<InvoicesDropdownProps> = ({ className, buttonClassName }) => {
	const { data: translations } = useTranslationQuery();
	const dispatch = useDispatch();
	const exportInvoice = useInvoiceExportMutation();

	const {
		orderLineDetails: { invoices },
		isSkeleton,
	} = useOrderLineContext();
	if (isSkeleton) return <SkeletonButton />;
	const disableInvoiceButton = invoices.length === 0;

	const handleInvoiceClick = (invoice: Invoice): void => {
		exportInvoice.mutate(invoice, {
			onSuccess: async ({ data }) => {
				await downloadFile(`${invoice.invoiceNumber}_${invoice.shipToId}_invoice.pdf`, data);

				dispatch(
					appActions.addNotification({
						children: translations?.shared.exports.exportSuccess,
						type: AlertTypes.SUCCESS,
					}),
				);
			},
		});
	};

	return (
		<Tooltip placement="bottom">
			<TooltipTrigger className={classNames(styles.tooltipTrigger, className)}>
				<Dropdown
					buttonVariant="outline"
					buttonSize="sm"
					buttonLabel={translations?.myOrders.orderHistoryLine.viewInvoices ?? ''}
					disabled={disableInvoiceButton}
					className={classNames(styles.dropdown, {
						[styles.disabled]: disableInvoiceButton,
					})}
					buttonClassName={classNames(buttonClassName, styles.button)}
					contentClassName={styles.dropdownContent}
				>
					<ul className={styles.options}>
						{invoices.map((invoice, index) => (
							<li
								key={`invoices-dropdown-option-${invoice.invoiceNumber}-${index}`}
								className={styles.option}
							>
								<Link className={styles.link} onClick={(): void => handleInvoiceClick(invoice)}>
									<span>{invoice.invoiceNumber}</span>

									<Icon className={styles.exportIcon} name={'external'} size={'md'} />
								</Link>
							</li>
						))}
					</ul>
				</Dropdown>
			</TooltipTrigger>
			{disableInvoiceButton && (
				<TooltipContent className={styles.tooltipText}>
					{translations?.myOrders.orderHistoryLine.viewInvoicesButtonDisabled}
				</TooltipContent>
			)}
		</Tooltip>
	);
};
