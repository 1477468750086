import * as React from 'react';
import { useIntersectionObserver } from './useIntersectionObserver';

const useInfiniteScroll = <T extends HTMLElement>(callbackParam: () => void): React.MutableRefObject<T | null> => {
	const ref = React.useRef<T | null>(null);
	const entry = useIntersectionObserver(ref, {});
	React.useEffect(() => {
		if (entry?.isIntersecting) {
			callbackParam();
		}
	}, [entry, callbackParam]);

	return ref;
};

export default useInfiniteScroll;
