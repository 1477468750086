import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { LoadingBar } from 'components/shared/LoadingBar';
import { useHideWhsPriceSettings } from 'components/shared/hooks/useHideWhsPriceSettings';
import { PricingDetailsResponse } from 'generated/data-contracts';
import styles from './ProductCardPricing.module.scss';

type ProductCardPricingProps = {
	isSkeleton: boolean;
	pricingDetails?: PricingDetailsResponse;
};

export const ProductCardPricing: React.FunctionComponent<ProductCardPricingProps> = ({
	isSkeleton,
	pricingDetails,
}) => {
	const { hideWhsPrice } = useHideWhsPriceSettings();

	const translations = useTranslationQuery().data;
	if (isSkeleton) {
		return (
			<section className={styles.pricing}>
				<div className={styles.price}>
					<LoadingBar width="4rem" />
					<LoadingBar width="3rem" />
				</div>
				<div className={styles.markup}>
					<LoadingBar width="4rem" />
					<LoadingBar width="3rem" />
				</div>
			</section>
		);
	}
	if (!pricingDetails) {
		return null;
	}

	const rrpPriceLabel = `${translations?.productCard.price.rrp}${
		pricingDetails.currencyCode ? ` (${pricingDetails.currencyCode})` : ''
	}`;
	return (
		<section className={styles.pricing}>
			<div className={styles.price}>
				<span className={styles.priceLabel}>{rrpPriceLabel}</span>
				<span className={styles.priceInfo}>{pricingDetails.recommendedRetailPrice}</span>
			</div>
			{!hideWhsPrice && (
				<div className={styles.markup}>
					<span className={styles.priceLabel}>{translations?.productCard.price.markup}</span>
					<span className={styles.priceInfo}>{pricingDetails.markup}</span>
				</div>
			)}
		</section>
	);
};
