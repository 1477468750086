import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebounce = (callback: (...args: any) => any, delay: number): any => {
	// Use a ref to store the timeout between renders
	// and prevent changes to it from causing re-renders
	const timerRef = React.useRef<NodeJS.Timeout>();

	return React.useCallback(
		(...args) => {
			clearTimeout(timerRef.current as NodeJS.Timeout);

			timerRef.current = setTimeout(() => {
				callback(...args);
			}, delay);
		},
		[callback, delay],
	);
};
