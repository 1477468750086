import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { BasketSelectorCardDataProps } from 'components/fragments/BasketSelector/BasketSelectorCard';
import { Badge, Icon } from 'components/shared';
import styles from './BasketSelectorCardInfo.module.scss';

interface BasketSelectorCardInfoProps {
	data: BasketSelectorCardDataProps;
	isLoading?: boolean;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const BasketSelectorCardInfo: React.FunctionComponent<BasketSelectorCardInfoProps> = ({
	data,
	isLoading,
	onClick,
}) => {
	const { data: translations } = useTranslationQuery();

	const brandsToDisplay = 3;
	const displayedBrands = data.brands?.slice(0, brandsToDisplay);
	const otherBrandsCount =
		data.brands?.length && data.brands.length > brandsToDisplay ? data.brands.length - brandsToDisplay : 0;

	return (
		<button type="button" className={styles.contentInfo} value={data.id} onClick={onClick}>
			<div className={styles.infoHeader}>
				<span className={styles.date}>{data.date}</span>
				{isLoading && <div className={classNames(styles.spinner, 'u-spinner')} />}
			</div>

			<div className={styles.infoMain}>
				<span className={styles.title}>{data.title}</span>
				<span className={styles.basketIdLabel}>
					{translations?.basket.basketId}: {data.id}
				</span>

				<div className={styles.brandLabels}>
					{displayedBrands?.map((brandLabel) => (
						<div className={styles.brandLabel} key={`brand-label-${brandLabel}`}>
							{brandLabel}
						</div>
					))}
					{!!otherBrandsCount && <Badge value={otherBrandsCount} />}
				</div>
			</div>

			<div className={styles.infoFooter}>
				{!!data.quantity && (
					<div className={styles.infoFooterItem}>
						<Icon name="basket" size="sm" />
						{data.quantity}
					</div>
				)}
				{data.creatorName && (
					<div className={styles.infoFooterItem}>
						<Icon name="userCircle" size="sm" />
						{data.creatorName}
					</div>
				)}
			</div>
		</button>
	);
};
