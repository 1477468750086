import React from 'react';
import { stripAllWhitespace } from 'helpers/stringHelpers';

export interface TabsPanelProps {
	children?: React.ReactElement | React.ReactElement[];
	name: string;
}

export const TabPanel: React.FunctionComponent<TabsPanelProps> = ({ name, children }) => {
	const strippedName = stripAllWhitespace(name);

	return (
		<div
			className={'tabPanel'}
			role="tabpanel"
			tabIndex={0}
			id={`${strippedName}-tab`}
			aria-labelledby={strippedName}
		>
			{children}
		</div>
	);
};
