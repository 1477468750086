import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';
import { OrderLineDrawer } from '../../OrderLineDrawer';
import { useOrderLineContext } from '../../state/useOrderLineContext';
import { SkeletonButton } from '../SkeletonButton';

export interface ViewSizeSplitButtonProps {
	showArrow?: boolean;
	buttonVariant?: 'outline' | 'ghost';
}

export const ViewSizeSplitButton: React.FunctionComponent<ViewSizeSplitButtonProps> = ({
	showArrow = false,
	buttonVariant = 'outline',
}) => {
	const { data: translations } = useTranslationQuery();
	const { isSkeleton } = useOrderLineContext();
	const handleClickViewSizeSplit = (): void => {
		setIsDrawerOpen(true);
	};
	const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
	if (isSkeleton) return <SkeletonButton />;

	return (
		<>
			<Button size="sm" variant={buttonVariant} onClick={handleClickViewSizeSplit}>
				{translations?.myOrders.orderHistoryLine.viewSizeSplit}
				{showArrow && <Icon name="chevronDown" size={'sm'} rotate={270} />}
			</Button>
			<OrderLineDrawer isOpen={isDrawerOpen} onClose={(): void => setIsDrawerOpen(false)} />
		</>
	);
};
