import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { DrawerHeader } from 'components/shared/Drawer';
import { Link } from 'components/shared/Link';
import { Area } from 'generated/data-contracts';
import { OrderLineThumbnail } from '../../OrderLineHeader/OrderLineThumbnail';
import { useOrderLineContext } from '../../state/useOrderLineContext';
import styles from './OrderLineDrawerHeader.module.scss';

export interface OrderLineDrawerHeaderProps {
	onClose: () => void;
}

export const OrderLineDrawerHeader: React.FunctionComponent<OrderLineDrawerHeaderProps> = ({ onClose }) => {
	const { data: translations } = useTranslationQuery();
	const { productInfo } = useOrderLineContext();
	return (
		<DrawerHeader handleClose={onClose} className={styles.header} closeButtonClassName={styles.closeButton}>
			<div className={styles.container}>
				<OrderLineThumbnail className={styles.thumbnail} />
				<span className={styles.modalTitleContainer}>
					<h4 className={styles.modalTitle}>{productInfo?.styleName}</h4>
					{productInfo?.productUrl && (
						<Link
							className={styles.link}
							to={productInfo.productUrl}
							route={{
								productFamilyId: productInfo.familyNumber,
								externalRoute: productInfo.productUrl,
								area: Area.Product,
							}}
							isTextLink
							hasExternalIconHidden
						>
							{translations?.myOrders.goToProduct}
						</Link>
					)}
				</span>
			</div>
		</DrawerHeader>
	);
};
