import React, { FC, useState } from 'react';
import { useViewportSize } from 'helpers';
import { useLayoutQuery } from 'api/layout';
import { useShiptoSelectedListQuery } from 'api/shipTo';
import { useTranslationQuery } from 'api/translations';
import { ChangePassword } from 'components/features/Header/MyProfile/ChangePassword';
import { LogOut } from 'components/features/Header/MyProfile/LogOut';
import { OpenAccounts } from 'components/features/Header/MyProfile/OpenAccounts';
import { OpenBaskets } from 'components/features/Header/MyProfile/OpenBaskets';
import { ProfileLanguageSelector } from 'components/features/Header/MyProfile/ProfileLanguageSelector';
import { VMIDrawer } from 'components/features/Header/MyProfile/VMIDrawer';
import { Button, Drawer, DrawerBody, DrawerFooter, DrawerHeader, Icon } from 'components/shared';
import { ChangeOpenBasket } from './ChangeOpenBasket';
import MyOrders from './MyOrders';
import styles from './MyProfile.module.scss';
import ToggleHideWHSPrice from './ToggleHideWHSPrice';
import ToggleZoomCorrection from './ToggleZoomCorrection';

export const MyProfile: FC = () => {
	const { data: layout } = useLayoutQuery();

	const { data: translations } = useTranslationQuery();
	const { data: selectedShipTos } = useShiptoSelectedListQuery();
	const user = layout?.user;
	const [isOpen, setIsOpen] = useState(false);

	const handleOpen = (): void => setIsOpen(true);
	const handleClose = (): void => {
		setIsOpen(false);
	};

	const { isMobile } = useViewportSize();

	return (
		<>
			<Button
				title={translations?.myProfile.myProfileTitle}
				size={'sm'}
				variant="ghost"
				hasOnlyIcon
				className={styles.button}
				onClick={handleOpen}
			>
				<Icon name="userCircle" size={'md'} />
			</Button>

			{isOpen && (
				<Drawer desktopWidth="26.25rem" direction="Right" onClose={handleClose}>
					<DrawerHeader title={translations?.myProfile.myProfileTitle} handleClose={handleClose} />

					<DrawerBody className={styles.body}>
						<div className={styles.userDetails}>
							<h6 className={styles.name}>{user?.name}</h6>
							<span className={styles.email}>{user?.email}</span>
						</div>

						<OpenAccounts handleClose={handleClose} />

						<div className={styles.shoppingDetails}>
							<ChangeOpenBasket handleClose={handleClose} />
							<OpenBaskets handleClose={handleClose} />
							{selectedShipTos?.vmiEnabled && <VMIDrawer handleClose={handleClose} />}
							<MyOrders />
						</div>

						<div className={styles.authSection}>
							<ChangePassword />
							{isMobile && <ProfileLanguageSelector handleClose={handleClose} />}
							<LogOut />
						</div>
					</DrawerBody>
					<DrawerFooter className={styles.footer}>
						<ToggleHideWHSPrice />
						<ToggleZoomCorrection />
					</DrawerFooter>
				</Drawer>
			)}
		</>
	);
};
