export enum OrderListUrlParamKeys {
	Tab = 'tab',
	SearchPhrase = 'search',
	PageSize = 'take',
	OrderlineSortBy = 'sortBy',
	OrderlineSortDirection = 'sortDirection',
	ShipTo = 'shipTo',
}

export const PREDEFINED_ORDERLIST_URL_PARAMS = Object.values<string>(OrderListUrlParamKeys);
