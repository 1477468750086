import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Icon } from 'components/shared/Icon';
import { Link } from 'components/shared/Link';
import { Tooltip, TooltipTrigger, TooltipContent } from 'components/shared/Tooltip';
import { Area, ResourceLocation } from 'generated/data-contracts';
import { capitalize } from 'helpers/stringHelpers';
import { useOrderLineContext } from '../../state/useOrderLineContext';
import { SkeletonButton } from '../SkeletonButton';
import styles from './GoToProductButton.module.scss';

export interface GoToProductButtonProps {
	className?: string;
	buttonVariant?: 'outline' | 'ghost';
	showArrow?: boolean;
}

export const GoToProductButton: React.FunctionComponent<GoToProductButtonProps> = ({
	className,
	buttonVariant = 'outline',
	showArrow,
}) => {
	const { data: translations } = useTranslationQuery();
	const { isSkeleton, productInfo, size } = useOrderLineContext();
	if (isSkeleton) return <SkeletonButton />;
	const url = productInfo?.productUrl;
	const disableGoToProductButton = !url;
	let route: ResourceLocation | undefined;
	if (url) {
		route = {
			area: Area.Product,
			productFamilyId: productInfo.familyNumber,
			externalRoute: url,
		};
	}
	const ButtonComponent = (
		<Link
			size="sm"
			to={url}
			route={route}
			className={classNames(styles.goToProductButton, styles[buttonVariant], styles[`size${capitalize(size)}`], {
				[styles.disabled]: disableGoToProductButton,
			})}
		>
			{translations?.myOrders.orderHistoryLine.goToProduct}
			{showArrow && <Icon name="chevronDown" size={'sm'} rotate={270} />}
		</Link>
	);

	if (!disableGoToProductButton) {
		return <span className={classNames(className, styles.tooltip)}>{ButtonComponent}</span>;
	}
	return (
		<Tooltip placement="bottom">
			<TooltipTrigger
				className={classNames(className, styles.tooltip, {
					[styles.disabled]: disableGoToProductButton,
				})}
			>
				{ButtonComponent}
			</TooltipTrigger>
			<TooltipContent className={styles.tooltipText}>
				{translations?.myOrders.orderHistoryLine.goToProductButtonDisabledTooltip}
			</TooltipContent>
		</Tooltip>
	);
};
