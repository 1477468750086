import React from 'react';
import classNames from 'classnames';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import CmsHeaderUsp from './CmsHeaderUsp';
import { LanguageDrawer } from './LanguageDrawer';
import styles from './TopBar.module.scss';

interface TopBarProps {
	className?: string;
}

export const TopBar: React.FunctionComponent<TopBarProps> = ({ className }) => {
	const { data: layout } = useLayoutQuery();

	const { data: translations } = useTranslationQuery();

	return (
		<div className={classNames(styles.topBar, className)}>
			<div className={classNames(styles.topBarInner, 'u-container-full')}>
				<CmsHeaderUsp cmsSettings={layout?.cmsSettings} audience={layout?.audienceTargeting} />
				<nav className={styles.topMenu} aria-label={translations?.shared.navigation.top}>
					<LanguageDrawer />
				</nav>
			</div>
		</div>
	);
};
