import React from 'react';
import { useCmsMenuQuery } from 'api/cms';
import { useLayoutQuery } from 'api/layout';
import { Link } from 'components/shared';
import { Area, ResourceLocation } from 'generated/data-contracts';
import createInternalLink from 'helpers/createInternalLink';

export interface CmsHeaderMenuProps {
	linkClassName: string;
	onLinkClick?: () => void;
}

const CmsHeaderMenuLinks: React.FC<CmsHeaderMenuProps> = (props) => {
	const { data: layout } = useLayoutQuery();
	const { data: menuItems } = useCmsMenuQuery(layout?.cmsSettings?.headerMenu ?? '', layout?.audienceTargeting);
	return (
		<>
			{menuItems?.at(0)?.children?.map((item) => {
				const route: ResourceLocation = {
					area: Area.CMS,
					externalRoute: item.externalLink || '',
				};

				return (
					<Link
						linkUrl={item.externalLink}
						to={createInternalLink(layout?.frontPageUrl, item.internalLink)}
						{...(item.internalLink && { route: route })}
						className={props.linkClassName}
						isTextLink
						hasExternalIconHidden
						key={item.id + item.title}
						onClick={props.onLinkClick}
					>
						{item.title}
					</Link>
				);
			})}
		</>
	);
};

export default CmsHeaderMenuLinks;
