import React from 'react';
import classNames from 'classnames';
import { InputCheckbox } from 'components/shared/InputCheckbox';
import { LoadingBar } from 'components/shared/LoadingBar';
import styles from './BasketSelectorCard.module.scss';
import { BasketSelectorCardAccounts } from './BasketSelectorCardAccounts';
import { BasketSelectorCardInfo } from './BasketSelectorCardInfo';

export interface BasketSelectorCardDataProps {
	id: number;
	date: string;
	isActive: boolean;
	title: string;
	comment: string;
	quantity: number;
	shipTos?: string[];
	otherShipTosCount?: number;
	creatorName?: string;
	brands?: string[];
}

interface BasketSelectorCardProps {
	data?: BasketSelectorCardDataProps;
	disabled?: boolean;
	isLoading?: boolean;
	isInput?: boolean;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
}

export const BasketSelectorCard: React.FunctionComponent<BasketSelectorCardProps> = (props) => {
	const { data, onClick, onChange, disabled, isInput } = props;

	if (!data) {
		return (
			<div className={styles.skeleton}>
				<LoadingBar width={'25%'} />
				<LoadingBar width={'75%'} />
				<LoadingBar width={'50%'} />
			</div>
		);
	}

	return (
		<div className={classNames(styles.card, { [styles.active]: data.isActive })}>
			{isInput && (
				<InputCheckbox
					checkBoxClassName={styles.cardInputCheckbox}
					value={data.id}
					checked={false}
					onChange={onChange}
					disabled={disabled}
				/>
			)}

			<div className={styles.content}>
				<BasketSelectorCardInfo data={data} onClick={onClick} />

				<hr className="u-hr" />

				<BasketSelectorCardAccounts data={data} />
			</div>
		</div>
	);
};
