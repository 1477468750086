import React from 'react';
import classNames from 'classnames';
import { SupportedCssUnits } from 'types/cssUnits';
import { useTranslationQuery } from 'api/translations';
import { Button, DrawerBody, Logo } from 'components/shared';
import { BrandResponse, SubBrandResponse } from 'generated/data-contracts';
import globalStyles from '../../BrandSelector.module.scss';
import styles from './BrandSelectSectionMobile.module.scss';

export interface BrandSelectSectionMobileProps {
	brand: BrandResponse;
	brandLogoHeight: SupportedCssUnits;
	lightColorMode?: boolean;
	isDrawer?: boolean;
	isInsideDrawer?: boolean;
	onChooseBrand: (brand: BrandResponse | SubBrandResponse) => void;
}

const BrandSelectSectionMobile: React.FunctionComponent<BrandSelectSectionMobileProps> = ({
	brand,
	brandLogoHeight,
	lightColorMode,
	isDrawer,
	isInsideDrawer,
	onChooseBrand,
}) => {
	const { data: translations } = useTranslationQuery();

	const subBrands = brand?.subBrands || [];
	const showSubBrands = subBrands.length > 0;
	const showMainBrand = brand.active;

	return (
		<DrawerBody
			className={classNames({
				[styles.lightColorBackground]: lightColorMode,
				[styles.primaryColorBackground]: !lightColorMode,
				[styles.reducedPadding]: isInsideDrawer,
			})}
		>
			<div
				className={classNames({
					[styles.contentWrapper]: !isDrawer,
					[styles.drawerContentWrapper]: isDrawer,
					[styles.insideDrawerContentWrapper]: isInsideDrawer,
					[styles.lightColorBackground]: lightColorMode,
					[styles.primaryColorBackground]: !lightColorMode,
				})}
			>
				{showMainBrand && (
					<div className={styles.mainBrandWrapper}>
						<div className={classNames(globalStyles.bodyHeader, styles.enterBrandPadding)}>
							{translations?.brandSelector.enterBrand}
						</div>
						<div key={`${brand.brandId}`} className={globalStyles.brandSectionWrapper}>
							<Button
								hasNoStyles
								className={classNames(globalStyles.brandButton, {
									[styles.primaryColorBackground]: lightColorMode,
								})}
								onClick={(): void => onChooseBrand(brand)}
							>
								<Logo name={brand.brandId} height={brandLogoHeight} width={'9.1875rem'} />
							</Button>
						</div>
					</div>
				)}
				{showSubBrands && (
					<div>
						<div className={classNames(globalStyles.bodyHeader, styles.enterBrandPadding)}>
							{translations?.brandSelector.enterSubBrand}
						</div>
						<div className={styles.subBrandGrid}>
							{subBrands.map((subBrand) => {
								return (
									<div key={`${subBrand.brandId}`} className={globalStyles.brandSectionWrapper}>
										<Button
											hasNoStyles
											className={classNames(globalStyles.brandButton, {
												[styles.primaryColorBackground]: lightColorMode,
											})}
											onClick={(): void => onChooseBrand(subBrand)}
										>
											<Logo
												name={subBrand.brandId}
												height={brandLogoHeight}
												width={'9.1875rem'}
											/>
										</Button>
									</div>
								);
							})}
						</div>
					</div>
				)}
			</div>
		</DrawerBody>
	);
};

export default BrandSelectSectionMobile;
