import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import _ from 'lodash';
import { ProductBundleQuery, useProductBundleQuery } from 'api/product';
import { DefaultPageSize } from 'api/search';
import { useTranslationQuery } from 'api/translations';
import { AlertTypes, ProductCardProps, PriceQueryProps, priceFilterId } from 'components/shared';
import {
	Area,
	FilterRequest,
	ProductFilter,
	ProductListResponse,
	ProductSort,
	SortDirection,
} from 'generated/data-contracts';
import { formatTranslation } from 'helpers/stringHelpers';
import { appActions } from 'store/actions/appActions';
import { UseProductListActionsProps, skeletonProductCards } from '../helpers';

export const useProductBundleActions = (
	query: ProductBundleQuery,
	cmsID?: string,
	page?: ProductListResponse,
): UseProductListActionsProps => {
	const [searchQuery, setSearchQuery] = React.useState<ProductBundleQuery>(query);
	const [toBeSet, setToBeSetFilters] = React.useState<FilterRequest[]>([]);
	const [toBeRemoved, setToBeRemovedFilters] = React.useState<FilterRequest[]>([]);
	const [priceFilter, setPriceFilter] = React.useState<PriceQueryProps>({
		minPrice: undefined,
		maxPrice: undefined,
	});
	const [filtersComingFromCms, setFiltersComingFromCms] = useState({
		collectionIds: query.collectionIds,
		productCatalogues: query.productCatalogues,
		productLineIds: query.productLineIds,
		productFilters: query.productFilters,
		preDefinedProductFilters: query.preDefinedProductFilters,
		phrase: query.phrase,
		preDefinedProductCategoryIds: query.preDefinedProductCategoryIds,
		take: query.take,
		sortBy: query.sortBy,
		sortDirection: query.sortDirection,
	});

	React.useEffect(() => {
		setFiltersComingFromCms({
			collectionIds: query.collectionIds,
			productCatalogues: query.productCatalogues,
			productLineIds: query.productLineIds,
			productFilters: query.productFilters,
			preDefinedProductFilters: query.preDefinedProductFilters,
			phrase: query.phrase,
			preDefinedProductCategoryIds: query.preDefinedProductCategoryIds,
			take: query.take,
			sortBy: query.sortBy,
			sortDirection: query.sortDirection,
		});
	}, [query]);

	React.useEffect(() => {
		setSearchQuery((prev) => {
			const normalizedQuery = {
				...prev,
				// Only overwrite the fields from the CMS filters
				...filtersComingFromCms,
			};

			return _.isEqual(prev, normalizedQuery) ? prev : normalizedQuery;
		});
	}, [filtersComingFromCms]);

	const [firstLoad, setFirstLoad] = React.useState(true);

	const {
		data: response,
		isLoading: loading,
		isFetchingNextPage,
		isFetchingPreviousPage,
		hasPreviousPage,
		hasNextPage,
		fetchPreviousPage,
		fetchNextPage,
	} = useProductBundleQuery(searchQuery);
	const [searchParams, setSearchParams] = useSearchParams();

	const [filters, setFilters] = React.useState(searchQuery.productFilters);

	const dispatch = useDispatch();
	const { data: translations } = useTranslationQuery();

	const pageData = response?.pages?.at(-1);

	let products: ProductCardProps[] =
		response?.pages?.flatMap(
			(page) =>
				page.products?.map((product) => ({
					product,
				})),
		) ?? [];
	if (isFetchingPreviousPage) {
		products = [...skeletonProductCards(pageData?.pagingInformation?.pageSize || DefaultPageSize), ...products];
	}

	if (isFetchingNextPage) {
		products = [...products, ...skeletonProductCards(pageData?.pagingInformation?.pageSize || DefaultPageSize)];
	}

	const highestPage = useRef(page?.pagingInformation?.currentPage || 1);

	const updateQuery = (newQuery): void => {
		setFilters(newQuery?.productFilters);
		setSearchQuery({ ...filtersComingFromCms, ...newQuery });
	};

	// This function updates the URL search parameters based on the current filters and search query
	const updateProductListParams = React.useCallback(
		(updatedFilters?: ProductFilter[]): void => {
			// Define the list of filter parameters that should be included in the URL
			const filterParams = [
				'skip',
				'take',
				'sortBy',
				'sortDirection',
				'minPrice',
				'maxPrice',
				'productCategoryId',
			];

			setSearchParams(
				(prevParams) => {
					const newParams = new URLSearchParams(prevParams);

					// Remove all existing filter parameters that are not in the filterParams list
					Array.from(newParams.keys()).forEach((key) => {
						if (!filterParams.includes(key)) {
							newParams.delete(key);
						}
					});

					// Create an array of objects containing the key-value pairs to update
					const paramsToUpdate = filterParams.map((key) => ({
						key,
						value: searchQuery[key]?.toString(),
					}));

					// Update or delete parameters based on their values
					paramsToUpdate.forEach(({ key, value }) => {
						if (value) {
							newParams.set(key, value);
						} else {
							newParams.delete(key);
						}
					});

					// Add new filter parameters if they exist
					if (updatedFilters && updatedFilters.length > 0) {
						updatedFilters.forEach((filter) => {
							if (!filter.attributeKey || !filter.attributeValues.length) return;
							newParams.set(filter.attributeKey, filter.attributeValues.join(','));
						});
					}

					// Only return the new params if they're different from the previous ones
					return newParams.toString() !== prevParams.toString() ? newParams : prevParams;
				},
				{
					replace: true,
					state: { area: Area.CMS },
				},
			);
		},
		[searchQuery, setSearchParams],
	);

	React.useEffect(() => {
		const hasExcludedFilters =
			pageData?.activeFilters.filter((activeFilter) => activeFilter.excluded).length ?? 0 > 0;

		if (hasExcludedFilters) {
			const activeFilters: ProductFilter[] = [];

			pageData?.activeFilters?.forEach((activeFilter) => {
				if (!activeFilter) return;
				const activeFilterIndex = activeFilters.findIndex((filter) => filter.attributeKey === activeFilter.id);

				if (activeFilterIndex !== -1) {
					activeFilters[activeFilterIndex].attributeValues.push(activeFilter.value);
				} else {
					activeFilters.push({ attributeKey: activeFilter.id, attributeValues: [activeFilter.value] });
				}
			});

			const plpFilters = activeFilters;

			setFilters(plpFilters);

			if (cmsID) {
				updateProductListParams(plpFilters);
			}

			dispatch(
				appActions.addNotification({
					children: formatTranslation(translations?.productList?.changedFilterCriteriaMessage, {}),
					type: AlertTypes.WARNING,
					options: {
						duration: 5000,
					},
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageData?.activeFilters]);

	const changeSorting = async (sortBy: string, sortDirection: string): Promise<void> => {
		const newQuery = {
			...searchQuery,
			sortBy: sortBy as ProductSort,
			sortDirection: sortDirection as SortDirection,
		};
		highestPage.current = 1;
		updateQuery(newQuery);
	};

	const changeFilter = (id: string): void => {
		const filterToBeSet = toBeSet.filter((filter) => filter.filter === id);
		const filterToBeRemoved = toBeRemoved.filter((filter) => filter.filter === id);

		const currentFilters =
			filterToBeRemoved?.length > 0 && filters && filters.length > 0
				? filters.map((filter) => {
						return {
							attributeKey: filter.attributeKey,
							attributeValues: filter.attributeValues.filter((value) => {
								return !filterToBeRemoved.some(
									(r) => r.filter === filter.attributeKey && r.value === value,
								);
							}),
						};
				  })
				: filters;

		const newFilterValues = filterToBeSet.map((filter) => filter.value) as string[];

		const newFiltersToBeSet = {
			attributeKey: filterToBeSet[0]?.filter || '',
			attributeValues: newFilterValues.length > 0 ? newFilterValues : [''],
		};

		const productFilters: ProductFilter[] = [];

		if (currentFilters?.length) {
			productFilters.push(...currentFilters);
		}

		if (!isEmpty(newFiltersToBeSet)) {
			const productFilterIndex = productFilters.findIndex(
				(filter) => filter.attributeKey === newFiltersToBeSet.attributeKey,
			);

			if (productFilterIndex !== -1) {
				productFilters[productFilterIndex].attributeValues.push(...newFiltersToBeSet.attributeValues);
			} else {
				productFilters.push(newFiltersToBeSet);
			}
		}

		const newQuery = {
			...searchQuery,
			productFilters,
		};
		highestPage.current = 1;
		updateQuery(newQuery);

		setToBeSetFilters((prev) => prev.filter((filter) => filter.filter !== id));
		setToBeRemovedFilters((prev) => prev.filter((filter) => filter.filter !== id));
	};

	const changePriceFilter = (): void => {
		const newQuery = {
			...searchQuery,
			minPrice: priceFilter.minPrice ? Math.floor(priceFilter.minPrice) : undefined,
			maxPrice: priceFilter.maxPrice ? Math.ceil(priceFilter.maxPrice) : undefined,
		};
		highestPage.current = 1;
		updateQuery(newQuery);

		setPriceFilter({
			minPrice: undefined,
			maxPrice: undefined,
		});
	};

	const changeCategory = (categoryId: string): void => {
		const newQuery = {
			...searchQuery,
			productFilters: filters,
			productCategoryId: categoryId || null,
		};

		updateQuery(newQuery);
	};

	const changeFilterMobile = (sortBy: string, sortDirection: string): void => {
		const currentFilters =
			toBeRemoved?.length > 0 && filters && filters.length > 0
				? filters.map((filter) => {
						return {
							attributeKey: filter.attributeKey,
							attributeValues: filter.attributeValues.filter((value) => {
								return !toBeRemoved.some((r) => r.filter === filter.attributeKey && r.value === value);
							}),
						};
				  })
				: filters;

		const newFilterValues = toBeSet.map((filter) => filter.value) as string[];

		const newFiltersToBeSet = {
			attributeKey: toBeSet[0]?.filter || '',
			attributeValues: newFilterValues.length > 0 ? newFilterValues : [''],
		};

		const productFilters: ProductFilter[] = [];

		if (currentFilters?.length) {
			productFilters.push(...currentFilters);
		}

		if (!isEmpty(newFiltersToBeSet)) {
			productFilters.push(newFiltersToBeSet);
		}

		const newQuery = {
			...searchQuery,
			productFilters,
			minPrice: priceFilter.minPrice ? Math.floor(priceFilter.minPrice) : undefined,
			maxPrice: priceFilter.maxPrice ? Math.ceil(priceFilter.maxPrice) : undefined,
			sortBy: sortBy as ProductSort,
			sortDirection: sortDirection as SortDirection,
		};
		highestPage.current = 1;
		updateQuery(newQuery);

		setToBeSetFilters([]);
		setToBeRemovedFilters([]);
		setPriceFilter({
			minPrice: undefined,
			maxPrice: undefined,
		});
	};

	const resetFilters = (): void => {
		const newQuery = {
			...searchQuery,
			productFilters: [],
			minPrice: undefined,
			maxPrice: undefined,
		};
		highestPage.current = 1;

		updateQuery(newQuery);

		setToBeSetFilters([]);
		setToBeRemovedFilters([]);
		setPriceFilter({
			minPrice: undefined,
			maxPrice: undefined,
		});

		// Update URL params
		updateProductListParams([]);
	};

	React.useEffect(() => {
		if (firstLoad) {
			setFirstLoad(false);
			return;
		}
		resetFilters();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.location.pathname]);

	const removeFilter = (id: string, value?: string): void => {
		const newFilters =
			filters?.filter(
				(filter) => filter.attributeKey !== id || (value && !filter.attributeValues.includes(value)),
			) || [];

		const newQuery = {
			...searchQuery,
			productFilters: newFilters,
			minPrice: id === priceFilterId ? undefined : searchQuery.minPrice,
			maxPrice: id === priceFilterId ? undefined : searchQuery.maxPrice,
		};

		highestPage.current = 1;

		updateQuery(newQuery);
		setToBeSetFilters((prev) => prev.filter((filter) => filter.filter !== id));
		setToBeRemovedFilters((prev) => prev.filter((filter) => filter.filter !== id));

		// Update URL params
		updateProductListParams(newFilters);
	};

	const updateNavParams = (productIndex: number): void => {
		if (!cmsID) return;

		const productPosition = Math.ceil(productIndex / (pageData?.pagingInformation?.pageSize || 36));
		const productPage = response?.pages?.at(0)?.pagingInformation?.currentPage || 0 + productPosition;

		const updatedParams = new URLSearchParams(searchParams.toString());
		const route = { area: Area.CMS };

		updatedParams.set('page', productPage.toString());
		setSearchParams(updatedParams.toString(), {
			replace: true,
			state: route,
		});
	};
	React.useEffect(() => {
		if (!searchQuery || !cmsID) return;

		updateProductListParams(searchQuery.productFilters);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQuery]);

	return {
		hasPreviousPage,
		pageData,
		products,
		hasNextPage,
		fetchNextPage,
		fetchPreviousPage,
		changeFilter,
		changeSorting,
		changePriceFilter,
		changeFilterMobile,
		resetFilters,
		loading,
		toBeSet,
		toBeRemoved,
		setToBeSetFilters,
		setToBeRemovedFilters,
		priceFilter,
		setPriceFilter,
		removeFilter,
		updateNavParams,
		changeCategory,
	};
};
