import React from 'react';
import { ProductBundleQuery, useProductBundleQuery } from 'api/product';
import { ProductCard as DSProductCard } from 'components/shared';
import { ProductFilter } from 'generated/data-contracts';

interface ProductCardProps {
	collectionIds: string[];
	productLineIds: string[];
	deliveryMonths: string[];
	productAssortments: string[];
	productMainColours: string[];
	productSizes: string[];
	productFits: string[];
	productNoos: string[];
	productGender: string[];
	searchPhrase: string;
}

export const ProductCard: React.FunctionComponent<ProductCardProps> = (props) => {
	const setupKeyAndValues = (items: string[]): ProductFilter => {
		const key = items[0].split('@')[1];
		const values: string[] = [];

		items.forEach((item) => {
			const keylessItem = item.split('@')[0];
			values.push(keylessItem);
		});

		return {
			attributeKey: key,
			attributeValues: values,
		};
	};

	const filters: ProductFilter[] = [];

	Object.values(props).forEach((prop) => {
		if (typeof prop === 'object' && prop?.length > 0) {
			const propValues = setupKeyAndValues(prop);

			filters.push(propValues);
		}
	});

	const query: ProductBundleQuery = {
		take: 1,
		collectionIds: props.collectionIds,
		productLineIds: props.productLineIds,
		phrase: props.searchPhrase,
		productFilters: filters,
	};

	const { data: response } = useProductBundleQuery(query);

	const productCardInfo = response?.pages?.at(-1)?.products[0];

	return <DSProductCard product={productCardInfo} />;
};
