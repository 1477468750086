import React, { FC, useState } from 'react';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import { LanguageSelector } from 'components/features/Header/LanguageSelector';
import { Button, Drawer, DrawerHeader, DrawerHeaderWBackButton, DrawerLevel, Icon, IconName } from 'components/shared';
import styles from './ProfileLanguageSelector.module.scss';

interface ProfileLanguageSelectorProps {
	handleClose?: () => void;
}

export const ProfileLanguageSelector: FC<ProfileLanguageSelectorProps> = ({ handleClose }) => {
	const { data: layout } = useLayoutQuery();

	const { data: translations } = useTranslationQuery();
	const segmentations = layout?.segmentations;
	const currentSegmentation = segmentations?.find((item) => item.isCurrentSegmentation === true);
	const [isOpen, setIsOpen] = useState(false);
	const handleOpen = (e): void => {
		e.currentTarget.blur();
		setIsOpen(true);
	};
	const handleBack = (): void => setIsOpen(false);

	if (!segmentations || !currentSegmentation) return null;
	const languageIconName = currentSegmentation.cultureCode?.split('-')[1]?.toUpperCase() as IconName;
	return (
		<>
			<Button className={styles.button} variant={'ghost'} hasNoStyles onClick={handleOpen}>
				<div className={styles.titleWrapper}>
					<Icon className="u-mr-sm" name={languageIconName} size="sm" />
					<span className={styles.title}>{currentSegmentation.name}</span>
				</div>
				<Icon className={'u-ml-sm'} name={'chevronDown'} size={'lg'} rotate={270} />
			</Button>
			{isOpen &&
				(handleClose ? (
					<DrawerLevel isActive={isOpen}>
						<DrawerHeaderWBackButton
							handleBack={handleBack}
							handleClose={handleClose}
							title={translations?.header.languageDrawerHeaderTitle}
						/>
						<LanguageSelector />
					</DrawerLevel>
				) : (
					<Drawer onClose={handleBack}>
						<DrawerHeader handleClose={handleBack} title={translations?.header.languageDrawerHeaderTitle} />
						<LanguageSelector />
					</Drawer>
				))}
		</>
	);
};
