import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { Carousel } from 'components/shared/Carousel/Carousel';
import { CarouselProvider, useCarouselStore } from 'components/shared/Carousel/state/CarouselContext';
import { ProductImageColor } from 'components/shared/ColorDot';
import { Link } from 'components/shared/Link';
import { ProductImage } from 'components/shared/ProductImage';
import { Area } from 'generated/data-contracts';
import { useProductCardContext } from '../context/ProductCardContext';
import styles from './ProductCardImage.module.scss';
import { ProductCardImageGalleryNavigation } from './ProductCardImageGalleryNavigation';

export const ProductCardImageInner: FC = () => {
	const {
		activeMaster: activeMaster,
		isSkeleton,
		isBeingHovered,
		product,
		renderedOnFavouriteList,
	} = useProductCardContext();
	const primaryImage = activeMaster?.primaryImage;

	let productUrl = '';

	if (activeMaster) {
		if (renderedOnFavouriteList) {
			const favouriteListData = product?.favouriteListCases[0];

			productUrl = `${activeMaster.url}&deliveryDate=${favouriteListData?.deliveryDate}&selectedView=${
				favouriteListData?.isFreeAssortment ? 'freeAssortments' : 'boxes'
			}`;
		} else {
			productUrl = `${activeMaster.url}&deliveryDate=${activeMaster.deliveryDate}&selectedView=${
				activeMaster.isFreeAssortment ? 'freeAssortments' : 'boxes'
			}`;
		}
	}

	const colorImages: ProductImageColor[] | undefined = activeMaster?.images?.map((image) => {
		return { ...image, color: activeMaster.colour };
	});
	const {
		slides: { activeSlide, setActiveSlide },
	} = useCarouselStore();

	React.useEffect(() => {
		setActiveSlide(0);
	}, [activeMaster?.id, setActiveSlide]);

	const route = useMemo(
		() => ({
			area: Area.Product,
			productFamilyId: product?.id,
			externalRoute: productUrl,
		}),
		[product?.id, productUrl],
	);

	const items = useMemo(() => {
		if (!activeMaster)
			return [
				<ProductImage
					key={`${product?.id}-galleryImage`}
					className={classNames(styles.wrapper, {
						[styles.skeleton]: isSkeleton,
					})}
					width={'100%'}
					altText={''}
					isSkeleton
					hasColorDotText
					colorDotSize="xl"
				/>,
			];
		return colorImages?.map((image, index) => {
			const mainImage = image?.size450;
			return (
				<Link
					key={`${activeMaster?.id}-${product?.id}-galleryImage-${index}`}
					className={classNames(styles.wrapper)}
					to={productUrl}
					route={route}
				>
					<ProductImage
						className={classNames({
							[styles.img]: primaryImage?.size450,
							[styles.onHover]: isBeingHovered && activeSlide === index,
							[styles.skeleton]: isSkeleton,
						})}
						desktopSrc={mainImage}
						mobileSrc={mainImage}
						altText={image?.altText || ''}
						colorInfo={image.color}
						hasColorDotText
						isLazy
						colorDotSize="thumbnail"
					/>
				</Link>
			);
		});
	}, [
		activeMaster,
		activeSlide,
		colorImages,
		isBeingHovered,
		isSkeleton,
		primaryImage?.size450,
		product?.id,
		productUrl,
		route,
	]);

	if (items && items.length > 1) {
		return (
			<Carousel listClassName={styles.slideList} Navigation={<ProductCardImageGalleryNavigation />}>
				{items}
			</Carousel>
		);
	}
	return (
		<Link
			key={`${activeMaster?.id}-${product?.id}-galleryImage`}
			className={classNames(styles.wrapper)}
			to={productUrl}
			route={route}
		>
			<ProductImage
				className={classNames(styles.wrapper, {
					[styles.img]: primaryImage?.size450,
					[styles.onHover]: isBeingHovered,
					[styles.skeleton]: isSkeleton,
				})}
				width={'100%'}
				desktopSrc={primaryImage?.size450}
				mobileSrc={primaryImage?.size450}
				altText={primaryImage?.altText || ''}
				isSkeleton={isSkeleton}
				colorInfo={activeMaster?.colour}
				hasColorDotText
				colorDotSize="xl"
			/>
		</Link>
	);
};

export const ProductCardImage = () => {
	return (
		<CarouselProvider showBadge={false} showNavigation>
			<ProductCardImageInner />
		</CarouselProvider>
	);
};
