import React from 'react';
import classNames from 'classnames';
import styles from './InputCheckbox.module.scss';


interface InputCheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
	label?: string | React.ReactNode;
	checkBoxClassName?: string;
	checked: boolean;
	partiallyChecked?: boolean;
	controlled?: boolean;
}

/**
 * Generic InputCheckbox component, used for handling all checkbox type user input
 */
export const InputCheckbox: React.FunctionComponent<InputCheckboxProps> = ({
	className,
	checkBoxClassName,
	controlled,
	id,
	label,
	checked,
	partiallyChecked,
	disabled,
	value,
	onChange,
	...rest
}) => {
	const [isChecked, setIsChecked] = React.useState<boolean>(checked);
	const handleChange = (e): void => {
		if (onChange) {
			onChange(e);
		}
		setIsChecked((p) => (controlled ? p : e.target.checked));
	};

	React.useEffect(() => {
		setIsChecked(checked);
	}, [checked]);

	return (
		<label className={classNames(styles.wrapperLabel, { [styles.isDisabled]: disabled }, className)} htmlFor={id}>
			<input
				className={classNames(styles.input, 'u-hide-visually')}
				type="checkbox"
				checked={isChecked}
				id={id}
				disabled={disabled}
				onChange={handleChange}
				value={value}
				{...rest}
			/>
			<span
				className={classNames(
					styles.checkboxMarker,
					partiallyChecked ? styles.partialChecked : styles.checked,
					checkBoxClassName,
				)}
			/>
			{typeof label === 'string' ? <div>{label}</div> : <>{label}</>}
		</label>
	);
};