import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Button } from 'components/shared/Button';
import styles from './FoldOut.module.scss';

interface FoldOutProps {
	className?: string;
	children?: React.ReactElement | React.ReactElement[];
	linesVisible?: 2 | 3;
	forceDisable?: boolean;
}

/**
 * Generic FoldOut component, used for toggling visibility of text content.
 * It's possible to set how many lines are visible by default.
 * And to forceDisable fold out - which means whole text is visible and
 * show more button is not rendered.
 */
export const FoldOut: React.FunctionComponent<FoldOutProps> = ({ linesVisible, forceDisable, className, children }) => {
	const translations = useTranslationQuery().data?.shared;

	const [isExpanded, setIsExpanded] = React.useState(forceDisable || false);

	const toggleFoldOut = (): void => {
		setIsExpanded(!isExpanded);
	};

	return (
		<div className={classNames(styles.wrapper, { [styles.isExpanded]: isExpanded }, className)}>
			<div
				className={classNames(
					styles.wrapperInner,
					!isExpanded ? `u-ellipsis-${linesVisible || 2}-lines` : undefined,
				)}
				style={
					{
						'--lines-visible': linesVisible || 2,
					} as React.CSSProperties
				}
			>
				{children}
			</div>

			{!forceDisable && (
				<div className={styles.buttonWrapper}>
					<Button variant={'linkButton'} onClick={toggleFoldOut}>
						{isExpanded ? translations?.showLess : translations?.showMore}
					</Button>
				</div>
			)}
		</div>
	);
};
