import React, { Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import debounce from 'lodash/debounce';
import { useTranslationQuery } from 'api/translations';
import { Button } from 'components/shared/Button';
import { MultiRangeOnChangeProps } from 'components/shared/MultiRangeSlider';
import { FilterRequest, FilterResponse, FilterType } from 'generated/data-contracts';
import { useViewportSize } from 'helpers/useViewportSize';
import { CheckboxFilter } from '../CheckboxFilter';
import { PriceQueryProps, RangeFilter } from '../RangeFilter';
import styles from './FilterContent.module.scss';

interface FilterContentProps extends FilterResponse {
	isFullScreen?: boolean;
	isPriceFilter?: boolean;
	toBeSet?: FilterRequest[];
	toBeRemoved?: FilterRequest[];
	setToBeSetFilters?: Dispatch<SetStateAction<FilterRequest[]>>;
	setToBeRemovedFilters?: Dispatch<SetStateAction<FilterRequest[]>>;
	currencySymbol?: string | null;
	priceFilter?: PriceQueryProps;
	setPriceFilter?: Dispatch<SetStateAction<PriceQueryProps>>;
	hasChanged?: boolean;
	uniqueFilterId?: string;
	hasNextPage?: boolean;
	loadMore?: () => void;
	search?: {
		searchText: string;
		setSearchText: Dispatch<SetStateAction<string>>;
		clearSearchText: () => void;
	};
}

export const FilterContent: React.FunctionComponent<FilterContentProps> = ({
	isFullScreen,
	isPriceFilter,
	selectedMin,
	selectedMax,
	currencySymbol,
	type,
	id,
	name,
	values,
	min,
	max,
	toBeSet,
	toBeRemoved,
	setToBeSetFilters,
	setToBeRemovedFilters,
	priceFilter,
	setPriceFilter,
	uniqueFilterId,
	hasChanged,
	hasNextPage,
	loadMore,
	search,
}) => {
	const { data: translations } = useTranslationQuery();

	const { isMobile } = useViewportSize();

	// Regular filter-related methods
	const handleCheckboxInputChange = (e): void => {
		if (!toBeSet || !toBeRemoved || !setToBeSetFilters || !setToBeRemovedFilters) return;
		const newFilter: FilterRequest = { filter: id || '', value: e.target.name.trim() };
		let newToBeSet = [...toBeSet];
		let newToBeRemoved = [...toBeRemoved];

		if (e.target.checked) {
			const newFilterToBeRemovedIndex = toBeRemoved.findIndex((f) => isEqual(f, newFilter));
			if (newFilterToBeRemovedIndex !== -1) {
				newToBeRemoved.splice(newFilterToBeRemovedIndex, 1);
			} else {
				newToBeSet = [...newToBeSet, newFilter];
			}
		}

		if (!e.target.checked) {
			const newFilterToBeSetIndex = toBeSet.findIndex((f) => isEqual(f, newFilter));
			if (newFilterToBeSetIndex !== -1) {
				newToBeSet.splice(newFilterToBeSetIndex, 1);
			} else {
				newToBeRemoved = [...newToBeRemoved, newFilter];
			}
		}

		setToBeSetFilters(newToBeSet);
		setToBeRemovedFilters(newToBeRemoved);
	};

	const handleNumberInputChange = ({ minVal, maxVal }: MultiRangeOnChangeProps): void => {
		if (!toBeSet || !toBeRemoved || !setToBeSetFilters || !setToBeRemovedFilters) return;
		const newFilters: FilterRequest[] = [
			{ filter: id, value: minVal.toString() },
			{ filter: id, value: maxVal.toString() },
		];
		const newRemove: FilterRequest[] = [
			{ filter: id, value: selectedMin?.toString() },
			{ filter: id, value: selectedMax?.toString() },
		];
		// Replace existing values for current id with new min & max values
		let newToBeSet = toBeSet.filter((f) => f.filter !== id);
		let newToBeRemoved = toBeRemoved.filter((f) => f.filter !== id);
		newToBeSet = [...newToBeSet, ...newFilters];
		newToBeRemoved = [...newToBeRemoved, ...newRemove];
		setToBeSetFilters(newToBeSet);
		setToBeRemovedFilters(newToBeRemoved);
	};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedNumberInputChange = React.useCallback(debounce(handleNumberInputChange, 300), [
		handleNumberInputChange,
	]);

	// Price-related methods
	const handlePriceInputChange = ({ minVal, maxVal }: MultiRangeOnChangeProps): void => {
		return setPriceFilter && setPriceFilter({ minPrice: minVal, maxPrice: maxVal });
	};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedPriceInputChange = React.useCallback(debounce(handlePriceInputChange, 300), [
		handlePriceInputChange,
	]);

	const renderSumbitButton = (): React.ReactElement => {
		return (
			<Button type={'submit'} variant={'dark'} className={styles.submitButton} isFullWidth disabled={!hasChanged}>
				{translations?.productList?.filters?.choose}
			</Button>
		);
	};

	if (isPriceFilter) {
		return (
			<>
				<RangeFilter
					className={classNames(styles.inputWrapper, isFullScreen && 'u-mt-md')}
					filters={toBeSet?.filter((filter) => filter.filter === id) ?? []}
					priceFilter={priceFilter}
					id={id}
					name={name}
					min={min}
					max={max}
					currencySymbol={currencySymbol}
					selectedMin={selectedMin}
					selectedMax={selectedMax}
					uniqueFilterId={uniqueFilterId}
					type={type}
					onChange={debouncedPriceInputChange}
					isPrice
				/>
				{!isMobile && renderSumbitButton()}
			</>
		);
	}

	if (type === FilterType.Number) {
		return (
			<>
				<RangeFilter
					className={classNames(styles.inputWrapper, isFullScreen && 'u-mt-md')}
					filters={toBeSet?.filter((filter) => filter.filter === id) ?? []}
					id={id}
					name={name}
					min={min}
					max={max}
					currencySymbol={currencySymbol}
					selectedMin={selectedMin}
					uniqueFilterId={uniqueFilterId}
					selectedMax={selectedMax}
					type={type}
					onChange={debouncedNumberInputChange}
				/>
				{!isMobile && renderSumbitButton()}
			</>
		);
	}

	if (type === FilterType.String) {
		return (
			<div className={classNames(styles.checkBoxFilter, { [styles.filterContentWrapper]: isFullScreen })}>
				<CheckboxFilter
					id={id}
					name={name}
					values={values}
					type={type}
					checkboxClassName={styles.inputWrapper}
					uniqueFilterId={uniqueFilterId}
					onCheckboxChange={handleCheckboxInputChange}
					fullScreen={isFullScreen}
					hasNextPage={hasNextPage}
					search={search}
					loadMore={loadMore}
				/>
				{!isMobile && renderSumbitButton()}
			</div>
		);
	}

	return null;
};
