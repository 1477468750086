import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	useInfiniteQuery,
	UseInfiniteQueryResult,
	useMutation,
	UseMutationResult,
	useQuery,
} from '@tanstack/react-query';
import { isEmpty, isEqual } from 'lodash';
import { useMyOrdersPageContext } from 'components/features/MyOrdersPage/MyOrdersPageContext/state';
import { AlertTypes } from 'components/shared';
import { Invoice } from 'components/shared/OrderLine/state/useOrderLineContext';
import { Export as ExportApi } from 'generated/Export';
import { OrderHistory as OrderHistoryApi } from 'generated/OrderHistory';
import { OrderHistoryExport as OrderHistoryExportApi } from 'generated/OrderHistoryExport';
import {
	NoPresentationBrandResult,
	OrderHistoryLineExportRequest,
	OrderHistoryLineSearchRequest,
	OrderHistoryLineSearchResponse,
	OrderHistorySort,
	PDFDownloadWithSummaryResponseWrapper,
	ProblemDetails,
	SortDirection,
} from 'generated/data-contracts';
import { HttpResponse } from 'generated/http-client';
import { formatTranslation } from 'helpers/stringHelpers';
import { appActions } from 'store/actions/appActions';
import { InitialState } from 'store/types';
import { queryKeys, setHeaders } from './apiConfig';
import { useTranslationQuery } from './translations';

export const useMyOrdersListQuery = (
	orderLinesSearchQuery?: OrderHistoryLineSearchRequest,
): UseInfiniteQueryResult<OrderHistoryLineSearchResponse, void | NoPresentationBrandResult> => {
	const defaultOrderLinesPageSize = 12;
	const { selectedShipTos } = useMyOrdersPageContext();

	const [searchQuery, setSearchQuery] = useState<OrderHistoryLineSearchRequest | undefined>(
		orderLinesSearchQuery
			? {
					phrase: orderLinesSearchQuery.phrase,
					pageSize: orderLinesSearchQuery.pageSize ?? defaultOrderLinesPageSize,
					pageNumber: 1,
					status: orderLinesSearchQuery.status,
					shipToIds: selectedShipTos,
					sortBy: orderLinesSearchQuery.sortBy ?? OrderHistorySort.EstimatedDeliveryDate,
					sortDirection: orderLinesSearchQuery.sortDirection ?? SortDirection.Descending,
					filters: orderLinesSearchQuery.filters,
			  }
			: undefined,
	);

	const [reset, setReset] = React.useState(true);
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	React.useEffect(() => {
		if (!orderLinesSearchQuery) return;
		setSearchQuery((prev) => {
			const newQuery: OrderHistoryLineSearchRequest = {
				...orderLinesSearchQuery,
				pageNumber: 1,
			};
			if (!isEqual(prev, newQuery)) {
				setReset(true);
				return newQuery;
			}
			return prev;
		});
	}, [orderLinesSearchQuery]);

	// currentPage should not be part of the key because react-query controls it internally
	const queryKey = React.useMemo(() => {
		return queryKeys.orderHistory.list(segmentationId, searchQuery).queryKey;
	}, [searchQuery, segmentationId]);
	return useInfiniteQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey,
		enabled: !isEmpty(searchQuery),
		queryFn: async ({ pageParam = 1 }) => {
			const orderHistoryApi = new OrderHistoryApi({
				baseApiParams: { headers: setHeaders() },
			});

			const pageNumber = reset ? 1 : pageParam;
			const response = await orderHistoryApi.orderhistorySearchCreate({
				// This cannot be null, as `enabled` is set to true only when searchQuery is defined
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				...searchQuery!,
				pageNumber,
			});
			return response.data;
		},
		getNextPageParam: (lastPage) => {
			const numberOfPages = Math.ceil(
				(lastPage.pagingInformation.totalNumberOfItems || 1) / defaultOrderLinesPageSize,
			);
			const page = lastPage.pagingInformation.currentPage + 1;
			const hasMorePages = page <= numberOfPages;
			return hasMorePages ? page : undefined;
		},
		getPreviousPageParam: (firstPage) => {
			const page = firstPage.pagingInformation.currentPage;
			return page > 1 ? page - 1 : undefined;
		},
		onSuccess: () => {
			setReset(false);
		},
		retry: false,
	});
};

export const useOrderDetailQuery = (orderNumber?: string) => {
	const defaultOrderLinesPageSize = 12;
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const orderHistoryApi = new OrderHistoryApi({
		baseApiParams: { headers: setHeaders() },
	});
	const queryKey = queryKeys.orderHistory.detailLines(segmentationId, orderNumber).queryKey;
	return useInfiniteQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey,
		enabled: !isEmpty(orderNumber),
		queryFn: async ({ pageParam = 1 }) => {
			const response = await orderHistoryApi.orderhistorySearchDetailsLinesCreate({
				pageNumber: pageParam,
				// This can never be undefined, as `enabled` is set to true only when orderNumber is defined
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				orderNumber: orderNumber!,
			});
			return response.data;
		},
		getNextPageParam: (lastPage) => {
			const numberOfPages = Math.ceil(
				(lastPage.pagingInformation.totalNumberOfItems || 1) / defaultOrderLinesPageSize,
			);
			const page = lastPage.pagingInformation.currentPage + 1;
			const hasMorePages = page <= numberOfPages;
			return hasMorePages ? page : undefined;
		},
		getPreviousPageParam: (firstPage) => {
			const page = firstPage.pagingInformation.currentPage;
			return page > 1 ? page - 1 : undefined;
		},
		retry: false,
	});
};

export const useOrderDetailHeaderQuery = (orderNumber?: string) => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const orderHistoryApi = new OrderHistoryApi({
		baseApiParams: { headers: setHeaders() },
	});
	const queryKey = queryKeys.orderHistory.detail(segmentationId, orderNumber).queryKey;
	return useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey,
		enabled: !isEmpty(orderNumber),
		queryFn: async () => {
			const response = await orderHistoryApi.orderhistorySearchDetailsCreate({
				// This can never be undefined, as `enabled` is set to true only when orderNumber is defined
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				orderNumber: orderNumber!,
			});
			return response.data;
		},
		retry: false,
	});
};

export const useInvoiceExportMutation = (): UseMutationResult<
	HttpResponse<File, void | ProblemDetails>,
	HttpResponse<void | ProblemDetails>,
	Invoice
> => {
	const dispatch = useDispatch();
	const { data: translations } = useTranslationQuery();

	const exportApi = new ExportApi({
		baseApiParams: { headers: setHeaders() },
	});

	return useMutation({
		mutationFn: async (variables) => {
			return exportApi.exportExportList(variables, { format: 'blob' });
		},
		onError: () => {
			dispatch(
				appActions.addNotification({
					children: formatTranslation(translations?.shared.exports.exportFailed, {}),
					type: AlertTypes.DANGER,
				}),
			);
		},
	});
};

export const useMyOrdersPdfMutation = (): UseMutationResult<
	HttpResponse<PDFDownloadWithSummaryResponseWrapper, void | ProblemDetails>,
	HttpResponse<void>,
	OrderHistoryLineExportRequest
> => {
	const { data: translations } = useTranslationQuery();
	const dispatch = useDispatch();

	return useMutation({
		mutationFn: async (variables) => {
			const orderHistoryExportApi = new OrderHistoryExportApi({
				baseApiParams: { headers: setHeaders() },
			});

			return await orderHistoryExportApi.orderhistoryexportListaspdfCreate(variables);
		},
		onError: () => {
			dispatch(
				appActions.addNotification({
					children: translations?.shared.exports.exportFailed,
					type: AlertTypes.DANGER,
				}),
			);
		},
	});
};

export const useMyOrdersExcelMutation = (): UseMutationResult<
	HttpResponse<File, void | ProblemDetails>,
	HttpResponse<void>,
	OrderHistoryLineExportRequest
> => {
	const { data: translations } = useTranslationQuery();
	const dispatch = useDispatch();

	return useMutation({
		mutationFn: async (variables) => {
			const orderHistoryExportApi = new OrderHistoryExportApi({
				baseApiParams: { headers: setHeaders() },
			});

			return await orderHistoryExportApi.orderhistoryexportExportasexcelCreate(variables, { format: 'blob' });
		},
		onError: () => {
			dispatch(
				appActions.addNotification({
					children: translations?.shared.exports.exportFailed,
					type: AlertTypes.DANGER,
				}),
			);
		},
	});
};
