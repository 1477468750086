import React, { FC } from 'react';
import { useTranslationQuery } from 'api/translations';
import { useProductCardContext } from '../../context/ProductCardContext';
import BottomSplash from '../BottomSplash';

export const Delivered: FC = () => {
	const { bottomSplashes } = useProductCardContext();
	const { data: translations } = useTranslationQuery();
	if (!bottomSplashes.delivered || !bottomSplashes.delivered.quantity) {
		return null;
	}
	let translation = translations?.productCard.delivered;
	if (bottomSplashes.delivered.inMultipleAccounts) {
		translation = translations?.productCard.deliveredForMultipleStores;
	}
	return (
		<BottomSplash
			icon="checkCircle"
			text={translation}
			quantity={bottomSplashes.delivered.quantity}
			hideQuantity={bottomSplashes.delivered.inMultipleAccounts}
		/>
	);
};
