import React from 'react';
import { ProductImage } from 'components/shared/ProductImage';
import { ColourResponse } from 'generated/data-contracts';
import { ProductImageResponse } from 'generated/data-contracts';
import styles from './SizeSplitThumbnailCell.module.scss';

export interface SizeSplitThumbnailCellProps {
	image?: ProductImageResponse;
	color?: ColourResponse;
}

export const SizeSplitThumbnailCell: React.FunctionComponent<SizeSplitThumbnailCellProps> = ({ color, image }) => {
	return (
		<div role="rowheader" className={styles.thumbnailColumn}>
			<div className={styles.masterInfoWrapper}>
				<ProductImage
					className={styles.masterImage}
					desktopSrc={image?.size100}
					isThumbnail
					altText={image?.altText || ''}
					colorInfo={color}
					colorDotClassName={styles.masterImageColorDot}
					colorDotSize="xl"
				/>
			</div>
			<span className={styles.masterName}>{color?.name ?? ''}</span>
		</div>
	);
};
