import React from 'react';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import { LoadingBar } from 'components/shared/LoadingBar';
import { useOrderLineContext } from '../../state/useOrderLineContext';
import styles from './SkeletonButton.module.scss';

export interface SkeletonButtonProps {}

export const SkeletonButton: React.FunctionComponent<SkeletonButtonProps> = () => {
	const { size } = useOrderLineContext();
	return <LoadingBar className={classNames(styles.skeletonButton, styles[`size${capitalize(size)}`])} />;
};

