import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { BasketFilters } from 'api/basket';
import { useTranslationQuery } from 'api/translations';
import { Badge, Icon } from 'components/shared';
import { Button } from 'components/shared/Button';
import { DrawerBody, DrawerFooter, DrawerHeaderWBackButton, DrawerLevel } from 'components/shared/Drawer';
import { BasketSearchResponse } from 'generated/data-contracts';
import FilterValueView from './FilterValueView';
import styles from './FilterView.module.scss';

interface CreateViewProps {
	handleBack: () => void;
	handleClose: () => void;
	filters?: BasketSearchResponse[];
	activeFilters: BasketFilters;
	setActiveFilters: React.Dispatch<React.SetStateAction<BasketFilters>>;
}

const FilterView: React.FunctionComponent<CreateViewProps> = ({
	handleBack,
	handleClose,
	filters,
	activeFilters,
	setActiveFilters,
}) => {
	const { data: translations } = useTranslationQuery();

	const [valueView, setValueView] = useState(false);
	const [selectedFilter, setSelectedFilter] = useState<BasketSearchResponse>();
	const [selectedValues, setSelectedValues] = useState<string[]>([]);
	const selectedFilterId = selectedFilter?.id ?? '';

	useEffect(() => {
		if (isEmpty(filters)) return;

		setSelectedFilter((prevFilter) => filters?.find((filter) => filter.id === prevFilter?.id));
	}, [filters]);

	const toggleValueView = (): void => {
		setValueView(!valueView);
	};

	const handleFilterClicked = (filter: BasketSearchResponse): void => {
		toggleValueView();
		setSelectedFilter(filter);
		setSelectedValues(activeFilters[filter.id] ?? []);
	};

	const handleSetActiveFilters = useCallback((): void => {
		if (selectedFilter) {
			const newActiveFilters = { ...activeFilters };
			newActiveFilters[selectedFilterId] = selectedValues.length ? selectedValues : undefined;
			setActiveFilters(newActiveFilters);
		}
	}, [activeFilters, selectedFilter, selectedFilterId, selectedValues, setActiveFilters]);

	useEffect(() => {
		if (!selectedValues.length && !isEmpty(activeFilters[selectedFilterId])) {
			handleSetActiveFilters();
		}
	}, [activeFilters, handleSetActiveFilters, selectedFilterId, selectedValues]);

	const handleBackButton = (): void => {
		handleSetActiveFilters();
		toggleValueView();
	};

	const handleApply = (): void => {
		handleSetActiveFilters();
		handleBack();
	};

	const handleReset = (): void => {
		setActiveFilters({});
		handleBack();
	};

	return (
		<>
			<DrawerBody className={styles.body}>
				{filters?.map((filter) => (
					<Button
						className={classNames(styles.filterButton, { [styles.disabled]: !filter.values?.length })}
						key={`filter-${filter.id}`}
						hasNoStyles
						onClick={(): void => handleFilterClicked(filter)}
						disabled={!filter.values?.length}
					>
						<div className={styles.filter}>
							<span className={styles.filterTitle}>{filter.name}</span>
							<div className={styles.filterIcons}>
								<Badge value={activeFilters[filter.id]?.length} />
								<Icon name="chevronDown" size="md" rotate={270} />
							</div>
						</div>
					</Button>
				))}
			</DrawerBody>

			<DrawerFooter className={styles.footer}>
				<Button type="submit" variant="dark" isFullWidth onClick={handleBack}>
					{translations?.openBaskets.filters.apply}
				</Button>

				<Button variant="secondary" isFullWidth onClick={handleReset}>
					{translations?.openBaskets.filters.resetFilters}
				</Button>
			</DrawerFooter>

			{valueView && selectedFilter && (
				<DrawerLevel isActive={valueView}>
					<DrawerHeaderWBackButton
						title={selectedFilter.name}
						handleBack={handleBackButton}
						handleClose={handleClose}
					/>
					<FilterValueView
						filter={selectedFilter}
						selectedValues={selectedValues}
						activeValues={activeFilters[selectedFilter.id] ?? []}
						setSelectedValues={setSelectedValues}
						handleApply={handleApply}
						handleReset={handleReset}
					/>
				</DrawerLevel>
			)}
		</>
	);
};

export default FilterView;
