import React, { useEffect } from 'react';
import classNames from 'classnames';
import { TabsPanelProps } from 'components/shared';
import { stripAllWhitespace } from 'helpers/stringHelpers';
import styles from './Tabs.module.scss';

interface TabsProps {
	className?: string;
	tablistClassName?: string;
	tabButtonsClassName?: string;
	children?: React.ReactElement<TabsPanelProps> | React.ReactElement<TabsPanelProps>[];
	tabList: string[];
	setParentSelectedTab?: React.Dispatch<React.SetStateAction<number>>;
}

/**
 * Generic Tabs component used by both CMS and regular features. Has to be fed with TabPanels as children.
 */
export const Tabs: React.FunctionComponent<TabsProps> = ({
	tabList,
	children,
	className,
	tablistClassName,
	tabButtonsClassName,
	setParentSelectedTab,
}) => {
	const [selectedTab, setSelectedTab] = React.useState(0);
	const tabRefs = React.useRef<(HTMLButtonElement | null)[]>([]);

	useEffect(() => {
		if (setParentSelectedTab) setParentSelectedTab(selectedTab);
	}, [selectedTab, setParentSelectedTab]);

	const handleSelectTab = (index): void => {
		setSelectedTab(index);
	};

	// Helper to calculate correct next or prev tab
	const handleTabSelection = (firstTabInRound: number, nextTab: number, lastTabInRound: number): void => {
		const tabToSelect = selectedTab === lastTabInRound ? firstTabInRound : nextTab;
		setSelectedTab(tabToSelect);
		tabRefs.current[tabToSelect]?.focus();
	};

	// Handle navigation with arrow keys
	const handleKeyPress = (event): void => {
		const tabCount = tabList.length - 1;

		if (event.key === 'ArrowLeft') {
			const last = tabCount;
			const next = selectedTab - 1;
			handleTabSelection(last, next, 0);
		}
		if (event.key === 'ArrowRight') {
			const first = 0;
			const next = selectedTab + 1;
			handleTabSelection(first, next, tabCount);
		}
	};

	return (
		<div className={className}>
			<div role="tablist" className={tablistClassName} onKeyDown={handleKeyPress} tabIndex={0}>
				{tabList.map((tab, index) => {
					const isSelected = selectedTab === index;
					const strippedTabName = stripAllWhitespace(tab);

					return (
						<button
							className={classNames(styles.tab, tabButtonsClassName)}
							role="tab"
							aria-selected={isSelected}
							aria-controls={`${strippedTabName}-tab`}
							id={strippedTabName}
							tabIndex={!isSelected ? -1 : undefined}
							onClick={(): void => handleSelectTab(index)}
							ref={(ref): HTMLButtonElement | null => (tabRefs.current[index] = ref)}
							key={strippedTabName + index}
						>
							{tab}
						</button>
					);
				})}
			</div>
			{React.Children.toArray(children)[selectedTab]}
		</div>
	);
};
