import React, { ReactElement, useEffect } from 'react';
import classNames from 'classnames';
import { RenderStates, useMounting } from 'components/shared/hooks/useMounting';
import styles from './DrawerLevel.module.scss';

interface DrawerLevelProps {
	children: React.ReactNode;
	className?: string;
	isActive?: boolean;
	direction?: 'Left' | 'Right' | 'None';
	handleClose?: () => void;
}

/**
 * Generic DrawerLevel component, used for displaying content within a level of the Drawer
 * */
export const DrawerLevel: React.FunctionComponent<DrawerLevelProps> = ({
	className,
	children,
	isActive,
	direction = 'Right',
	handleClose,
}) => {
	const { UNMOUNTING, UNMOUNTED } = RenderStates;
	const { status } = useMounting(isActive, 300);

	useEffect(() => {
		if (direction !== 'None') {
			document.documentElement.style.setProperty('--direction', direction === 'Left' ? '-100%' : '100%');
		} else {
			document.documentElement.style.setProperty('--direction', '0');
		}
	}, [direction, isActive]);

	const newChildren = React.Children.map(children, (child) => {
		if (!React.isValidElement(child) || !handleClose) {
			return child;
		}

		const childProps = React.Children.map((child as ReactElement).props.children, (grandChild) => {
			if (!React.isValidElement(grandChild)) {
				return grandChild;
			}
			const grandChildProps = React.Children.map(
				(grandChild as ReactElement).props.children,
				(greatGrandChild) => {
					if (
						!React.isValidElement(greatGrandChild) ||
						!(greatGrandChild as ReactElement)?.props?.handleClose
					) {
						return greatGrandChild;
					}

					return React.cloneElement(greatGrandChild as ReactElement, {
						handleClose,
					});
				},
			);

			if (!(grandChild as ReactElement).props.handleClose) {
				return React.cloneElement(grandChild as ReactElement, {}, grandChildProps);
			}
			return React.cloneElement(grandChild as ReactElement, { handleClose }, grandChildProps);
		});

		if ((child as ReactElement).props.handleClose) {
			return React.cloneElement(child as ReactElement, { handleClose }, childProps);
		} else if ((child as ReactElement).props.onClose) {
			return React.cloneElement(child as ReactElement, { onClose: handleClose }, childProps);
		}

		return React.cloneElement(child as ReactElement, {}, childProps);
	});

	if (status === UNMOUNTED) {
		return null;
	}

	return (
		<section
			className={classNames(
				styles.wrapper,
				styles.animationStart,
				{ [styles.animationEnd]: status === UNMOUNTING },
				className,
			)}
		>
			{newChildren}
		</section>
	);
};
