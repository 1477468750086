import React from 'react';
import { useLocation } from 'react-router-dom';
import { BuilderComponent } from '@builder.io/react';
import { useCmsContentQuery } from 'api/cms';
import { useLayoutQuery } from 'api/layout';
import styles from './500.module.scss';
import Head from './_head.partial';
import Page from './_page.partial';

interface InternalServerErrorProps {
	error?: string;
	description?: string;
}

export const InternalServerError: React.FunctionComponent<InternalServerErrorProps> = ({ error, description }) => {
	const location = useLocation();
	const { data: layout } = useLayoutQuery();
	const modelName = layout?.cmsSettings?.internalServerError || '';
	const { data: content, isLoading } = useCmsContentQuery(location.pathname, modelName, layout?.audienceTargeting);

	if (process.env.NODE_ENV === 'development' && error) {
		return (
			<div className={styles.developerError}>
				{error && <h1 dangerouslySetInnerHTML={{ __html: error }} />}
				{description && <div dangerouslySetInnerHTML={{ __html: description.replaceAll('\n', '<br>') }} />}
			</div>
		);
	}

	if (isLoading) {
		return <Page />;
	}

	return (
		<Page>
			<Head
				metaData={{
					title: '500',
					description: 'Internal Server Error',
					keywords: '',
					noIndex: true,
					noFollow: true,
				}}
			/>

			<BuilderComponent model={modelName} content={content || undefined} />
		</Page>
	);
};
