import { Builder } from '@builder.io/react';
import { iconNames } from 'components/shared';
import { Usp } from '../Usp';

Builder.registerComponent(Usp, {
	name: 'Usp',
	image: new URL('/dist/images/cms/usp.svg', window.location.origin).href,
	inputs: [
		{
			type: 'string',
			name: 'icon',
			required: true,
			enum: Array.from(iconNames),
			defaultValue: 'uspPhone',
		},
		{
			type: 'string',
			name: 'text',
			required: true,
			defaultValue: '24/7 Support',
		},
		{
			name: 'link',
			type: 'url',
		},
		{
			name: 'isLarge',
			type: 'boolean',
			defaultValue: false,
			friendlyName: 'Large icon',
		},
	],
});
