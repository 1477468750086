import React, { FC } from 'react';
import { Badge } from 'components/shared/Badge';
import { useCarouselStore } from '../state/CarouselContext';
import styles from './CarouselBadge.module.scss';

export const CarouselBadge: FC = () => {
	const {
		slides: { activeSlide, numberOfSlides },
	} = useCarouselStore();
	return <Badge size="sm" value={`${activeSlide + 1}/${numberOfSlides}`} className={styles.imageCountChip} />;
};
