import React, { FC } from 'react';
import { Icon } from 'components/shared/Icon';
import { useProductCardContext } from '../../context/ProductCardContext';

export const FashionForward: FC = () => {
	const { bottomSplashes } = useProductCardContext();
	if (!bottomSplashes.fashionForward) {
		return null;
	}

	return <Icon name={'fastForward'} size="sm" />;
};
