import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { queryKeys } from 'api/apiConfig';
import { useSetFavouriteMutation, useRemoveFavouriteMutation } from 'api/favouriteList';
import { useTranslationQuery } from 'api/translations';
import { Button, ButtonSizeType } from 'components/shared/Button';
import { Icon, IconSize } from 'components/shared/Icon';
import { AddToFavouriteListRequest, RemoveFromFavouriteListRequest } from 'generated/data-contracts';
import { messageToApp } from 'helpers/app';
import { InitialState } from 'store/types';
import styles from './FavouriteIcon.module.scss';

interface FavouriteIconProps {
	variantId: string;
	productMasterId?: string;
	deliveryMonth?: string;
	productId?: string;
	isFreeAssortment?: boolean;
	className?: string;
	isFavourite?: boolean;
	showRemoveIcon?: boolean;
	buttonSize?: ButtonSizeType;
	iconSize?: IconSize;
	updateProductState?: (productId, state) => void;
}

/**
 * Generic FavouriteIcon component, used for setting products as favourites
 */
export const FavouriteIcon: React.FunctionComponent<FavouriteIconProps> = ({
	className,
	productId,
	deliveryMonth = '',
	productMasterId = '',
	isFreeAssortment = false,
	isFavourite,
	showRemoveIcon,
	variantId,
	buttonSize = 'md',
	iconSize = 'md',
	updateProductState,
}) => {
	const queryClient = useQueryClient();
	const [isLoading, setLoading] = React.useState(false);
	const { data: translations } = useTranslationQuery();

	const favouriteInfo: RemoveFromFavouriteListRequest | AddToFavouriteListRequest = {
		productMasterId: productMasterId,
		deliveryDate: deliveryMonth,
		isFreeAssortment: isFreeAssortment,
	};

	const { segmentationId } = useSelector((state: InitialState) => state.app);

	const [isFilled, setIsFilled] = React.useState(isFavourite);
	const [isBeingDeleted, setIsBeingDeleted] = useState(false);

	const setFavourite = useSetFavouriteMutation();
	const removeFavourite = useRemoveFavouriteMutation();

	const buttonTitle = showRemoveIcon
		? translations?.shared.delete
		: isFilled
		? translations?.favouriteList.removeProductToFavouriteList
		: translations?.favouriteList.addProductToFavouriteList;

	useEffect(() => {
		setIsFilled(isFavourite);
	}, [variantId, isFavourite]);

	const handleClick = (): void => {
		if (isLoading || isFilled != isFavourite) return;

		setLoading(true);
		setIsFilled((prev) => !prev);

		const handleSettled = (): void => {
			setLoading(false);
		};

		if (isFilled || showRemoveIcon) {
			setIsBeingDeleted(showRemoveIcon || false);

			removeFavourite.mutate(favouriteInfo, {
				onSuccess: async () => {
					const currentQuantity: number =
						queryClient.getQueryData(queryKeys.favouritelist.total(segmentationId).queryKey) || 0;
					const newQuantity = currentQuantity <= 0 ? 0 : currentQuantity - 1;

					queryClient.setQueryData(queryKeys.favouritelist.total(segmentationId).queryKey, newQuantity);

					await queryClient.invalidateQueries(queryKeys.favouritelist.groupItems._def);
					await queryClient.invalidateQueries(queryKeys.favouritelist.list._def);
					await queryClient.invalidateQueries(queryKeys.product.fetch._def);
					await queryClient.invalidateQueries(queryKeys.product.list._def);
					await queryClient.invalidateQueries(queryKeys.product.bundleQuery._def);

					if (updateProductState) updateProductState(productId, false);
					messageToApp({ type: 'reloadFavoriteList' });
				},
				onError: () => setIsFilled(true),
				onSettled: handleSettled,
			});
		} else {
			setFavourite.mutate(favouriteInfo, {
				onSuccess: async () => {
					const currentQuantity: number =
						queryClient.getQueryData(queryKeys.favouritelist.total(segmentationId).queryKey) || 0;
					queryClient.setQueryData(
						queryKeys.favouritelist.total(segmentationId).queryKey,
						currentQuantity + 1,
					);

					await queryClient.invalidateQueries(queryKeys.favouritelist.groupItems._def);
					await queryClient.invalidateQueries(queryKeys.favouritelist.list._def);
					await queryClient.invalidateQueries(queryKeys.product.fetch._def);
					await queryClient.invalidateQueries(queryKeys.product.list._def);
					await queryClient.invalidateQueries(queryKeys.product.bundleQuery._def);

					if (updateProductState) updateProductState(productId, true);
					messageToApp({ type: 'reloadFavoriteList' });
				},
				onError: () => setIsFilled(false),
				onSettled: handleSettled,
			});
		}
	};

	return (
		<Button
			disabled={showRemoveIcon && isBeingDeleted}
			className={classNames(styles.wrapper, className)}
			title={buttonTitle}
			variant="ghost"
			size={buttonSize}
			hasOnlyIcon
			onClick={handleClick}
		>
			{showRemoveIcon ? (
				<Icon className={styles.iconHover} name={'bin'} size={iconSize} />
			) : (
				<Icon
					className={classNames(styles.favouriteIcon, styles.iconHover, { [styles.isFilled]: isFilled })}
					name={isFilled ? 'favouriteFilled' : 'favourite'}
					size={iconSize}
				/>
			)}
		</Button>
	);
};
