import React from 'react';
import { isSameOrContains } from './isSameOrContains';

/**
 * Listener for clicking outside of ref, which fires callback when that happens
 *
 * @param wrapperRef
 * @param callback
 * @param listenerCondition
 */
export const useOnClickAway = (
	wrapperRef: React.RefObject<Element | null>,
	callback: () => void,
	listenerCondition: boolean,
): void => {
	React.useEffect(() => {
		if (!wrapperRef.current) {
			return;
		}

		const handleClickAway = (event: MouseEvent): void => {
			if (!isSameOrContains(wrapperRef.current, event.target as Element)) {
				callback();
			}
		};

		if (listenerCondition) {
			document.addEventListener('mouseup', handleClickAway);
		}

		return (): void => {
			document.removeEventListener('mouseup', handleClickAway);
		};
	}, [wrapperRef, listenerCondition, callback]);
};
