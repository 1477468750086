import React from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { useCmsMenuQuery } from 'api/cms';
import { useLayoutQuery } from 'api/layout';
import { Link } from 'components/shared';
import createInternalLink from 'helpers/createInternalLink';
import styles from './Menu.module.scss';

export interface MenuProps {
	className: string;
}

const Menu: React.FC<MenuProps> = (props) => {
	const { data: layout } = useLayoutQuery();
	const { data: footerMenu } = useCmsMenuQuery(layout?.cmsSettings?.footerMenu ?? '', layout?.audienceTargeting);
	if (!footerMenu || footerMenu.length === 0) {
		return null;
	}
	return (
		<div className={styles.menuContainer}>
			{footerMenu?.map((item) => {
				const isItemEmpty = isEmpty(item.title.trim());
				return (
					<ul className={classNames(styles.list, props.className)} key={item.id + item.title}>
						<li>
							{!isItemEmpty && (
								<Link
									linkUrl={item.externalLink}
									to={createInternalLink(layout?.frontPageUrl, item.internalLink)}
									isTextLink
									size={'lg'}
									weight={'bold'}
								>
									{item.title}
								</Link>
							)}

							{item.children && (
								<ul className={classNames({ ['u-mt-md']: !isItemEmpty })}>
									{item.children.map((child) => (
										<li className={styles.childItem} key={child.id + child.title}>
											<Link
												className={styles.childLink}
												linkUrl={child.externalLink}
												to={createInternalLink(layout?.frontPageUrl, child.internalLink)}
												isTextLink
												size={'lg'}
												weight={'bold'}
											>
												{child.title}
											</Link>
										</li>
									))}
								</ul>
							)}
						</li>
					</ul>
				);
			})}
		</div>
	);
};

export default Menu;
