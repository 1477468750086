import React from 'react';
import classNames from 'classnames';
import styles from './SizeSplitCell.module.scss';

export interface SizeSplitCellProps {
	id?: string;
	className?: string;
}

export const SizeSplitCell: React.FunctionComponent<React.PropsWithChildren<SizeSplitCellProps>> = ({
	id,
	className,
	children,
}) => {
	return (
		<div role="cell" className={classNames(className, styles.tableCell)} aria-describedby={id}>
			{children}
		</div>
	);
};
