import React from 'react';

export enum RenderStates {
	MOUNTED,
	MOUNTING,
	UNMOUNTING,
	UNMOUNTED,
}

interface UseMountingResponse {
	status: RenderStates;
	setStatus: React.Dispatch<React.SetStateAction<RenderStates>>;
}

export const useMounting = (isExpanded?: boolean, startDelayInMs = 400, endDelayInMs = 400): UseMountingResponse => {
	const { MOUNTED, MOUNTING, UNMOUNTING, UNMOUNTED } = RenderStates;

	const [status, setStatus] = React.useState(isExpanded ? MOUNTED : UNMOUNTED);
	const [firstLoad, setFirstLoad] = React.useState(true);

	React.useEffect(() => {
		if (!firstLoad) {
			setStatus(isExpanded ? MOUNTING : UNMOUNTING);
		}
		setFirstLoad(false);
	}, [MOUNTING, UNMOUNTING, firstLoad, isExpanded]);

	React.useEffect(() => {
		let timeoutId;
		if (status === UNMOUNTING) {
			timeoutId = setTimeout(() => {
				setStatus(UNMOUNTED);
			}, startDelayInMs);
		} else if (status === MOUNTING) {
			timeoutId = setTimeout(() => {
				setStatus(MOUNTED);
			}, endDelayInMs);
		}

		return (): void => {
			clearTimeout(timeoutId);
		};
	}, [MOUNTED, MOUNTING, UNMOUNTED, UNMOUNTING, endDelayInMs, startDelayInMs, status]);

	return { status, setStatus };
};
