import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { useOrderLineContext } from 'components/shared/OrderLine/state/useOrderLineContext';
import { OrderDetailCell } from '../../OrderDetailCell';

export interface OrderNumberProps {}

export const OrderNumber: React.FunctionComponent<OrderNumberProps> = () => {
	const { orderLineDetails } = useOrderLineContext();
	const { data: translations } = useTranslationQuery();
	if (!translations) return null;
	return orderLineDetails.orderNumber ? (
		<OrderDetailCell
			title={translations?.myOrders.orderDetails.orderNumberShort}
			value={orderLineDetails.orderNumber}
			copyable
			copyTooltip={translations.myOrders.orderDetails.copyOrderNumber}
			onCopyText={translations.myOrders.orderDetails.copiedOrderNumber}
		/>
	) : (
		<OrderDetailCell
			iconName="basket"
			title={translations?.myOrders.orderDetails.basketId}
			value={orderLineDetails.basketId ? String(orderLineDetails.basketId) : undefined}
		/>
	);
};
