import React, { CSSProperties } from 'react';
import { uniqueId } from 'lodash';
import { SizeSplitRow } from '../SizeSplitRow';
import { SizeSplitThumbnailCell } from '../SizeSplitThumbnailCell';
import { RowGroup } from '../context/SizeSplitContext';
import styles from './SizeSplitRowGroup.module.scss';

export interface SizeSplitRowGroupProps {
	rowGroup: RowGroup;
}

const SizeSplitRowGroup: React.FunctionComponent<SizeSplitRowGroupProps> = ({ rowGroup: { master, rows } }) => {
	const key = React.useRef(uniqueId('size-split-row-group-'));
	return (
		<div
			role="rowgroup"
			style={
				{
					'--js-number-of-rows': rows.length,
				} as CSSProperties
			}
			className={styles.tableBody}
		>
			<SizeSplitThumbnailCell color={master.colour} image={master.image} />
			{rows.map((variant, variantIndex) => {
				return (
					<SizeSplitRow
						key={`master-table-${master.id}-${key}-${variantIndex}`}
						variant={variant}
						master={master}
					/>
				);
			})}
		</div>
	);
};

export default SizeSplitRowGroup;
