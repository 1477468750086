import React from 'react';
import { useViewportSize } from 'helpers';

export type OrderHistoryViewportSize = 'lg' | 'sm' | 'md';

export const useOrderHistoryViewportSize = (): OrderHistoryViewportSize => {
	const sizes = useViewportSize();
	const size = React.useMemo((): OrderHistoryViewportSize => {
		switch (sizes.size) {
			case 'xs':
				return 'sm';
			case 'sm':
			case 'md':
				return 'md';
			default:
				return 'lg';
		}
	}, [sizes.size]);
	return size;
};
