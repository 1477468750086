import React from 'react';
import { Button as DSButton, ButtonSizeType, ButtonVariantType } from 'components/shared';

interface ButtonProps {
	text: string;
	link?: string;
	openInNewTab?: boolean;
	variant?: ButtonVariantType;
	size?: ButtonSizeType;
}

/**
 * CMS Button component, used for creating buttons. It overrides Builder's default
 * button component extending it with props coming from the Design System.
 * */
export const Button: React.FC<ButtonProps> = ({ text, link, openInNewTab, variant, size }) => {
	return (
		<DSButton
			size={size || undefined}
			variant={variant || 'secondary'}
			href={link ? link : undefined}
			target={openInNewTab ? '_blank' : undefined}
		>
			{text}
		</DSButton>
	);
};
