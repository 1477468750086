import React from 'react';
import classNames from 'classnames';
import styles from './DrawerBody.module.scss';

export interface DrawerBodyProps {
	children: React.ReactNode;
	className?: string;
}

export const DrawerBody: React.FunctionComponent<DrawerBodyProps> = ({ className, children }) => {
	return <main className={classNames(styles.body, className)}>{children}</main>;
};
