import React, { PropsWithChildren } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { breakpoints, DATA_DISABLE_ZOOM_NAME } from 'helpers/cssVariables';
import { useLocalStorage } from './useLocalStorage';

interface UseZoomCorrectionSettingsReturnType {
	isZoomCorrectionEnabled: boolean;
	isSwitchDisabled: boolean;
	handleClick: (value: boolean) => void;
	screenPixelRatio?: number;
	zoomLevel: number;
}

/**
 *
 *
 */
const useZoomCorrectionSettingsState = (): UseZoomCorrectionSettingsReturnType => {
	const MIN_SUPPORTED_RESOLUTION = breakpoints.md;
	const MAX_SUPPORTED_RESOLUTION = 1920;

	const [doesUserPreferZoomCorrection, setDoesUserPreferZoomCorrection] = useLocalStorage<boolean>(
		DATA_DISABLE_ZOOM_NAME,
		true,
	);

	const [isZoomingDisabled, setIsZoomingDisabled] = useState(doesUserPreferZoomCorrection);
	const [isOptionDisabled, setIsOptionDisabled] = useState(false);
	const getHTMLElement = () => document.getElementsByTagName('html')[0];

	const recalculateIfZoomCorrectionEnabled = useCallback(() => {
		const HTMLElement = getHTMLElement();

		setIsZoomingDisabled(Boolean(HTMLElement.attributes.getNamedItem(DATA_DISABLE_ZOOM_NAME)?.value));
	}, []);

	const setZoomCorrection = useCallback((value: boolean) => {
		const HTMLElement = getHTMLElement();
		HTMLElement.setAttribute(DATA_DISABLE_ZOOM_NAME, String(value));
		setIsZoomingDisabled(value);
	}, []);

	/**
	 * If the screen is the size of a tablet or smaller, we should not allow zoom correction
	 *
	 * This is because many small screens have a screen pixel ratio larger than 1,
	 * which means that the zoom correction would treat it as a desktop screen
	 *
	 * The `availWidth` property is the width of the screen that the browser is located in.
	 * If this is smaller than the `md` breakpoint, we should disable the zoom correction, and not allow it to be enabled.
	 */
	const recalculateIfZoomCorrectionIsSupported = useCallback(() => {
		if (
			window.screen.availWidth < MIN_SUPPORTED_RESOLUTION ||
			window.screen.availWidth > MAX_SUPPORTED_RESOLUTION ||
			window.devicePixelRatio >= 2
		) {
			setIsOptionDisabled(true);
			setZoomCorrection(false);
			return;
		}
		setIsOptionDisabled(false);
		setZoomCorrection(doesUserPreferZoomCorrection);
	}, [MIN_SUPPORTED_RESOLUTION, doesUserPreferZoomCorrection, setZoomCorrection]);

	const handleClick = (value: boolean) => {
		if (isOptionDisabled) return;
		setZoomCorrection(value);
		setDoesUserPreferZoomCorrection(value);
	};

	useEffect(() => {
		recalculateIfZoomCorrectionIsSupported();
	}, [recalculateIfZoomCorrectionIsSupported]);

	useEffect(() => {
		recalculateIfZoomCorrectionEnabled();
	}, [recalculateIfZoomCorrectionEnabled]);

	React.useEffect(() => {
		recalculateIfZoomCorrectionEnabled();
		recalculateIfZoomCorrectionIsSupported();
		window.addEventListener('currentscreenchange', recalculateIfZoomCorrectionEnabled);
		window.addEventListener('currentscreenchange', recalculateIfZoomCorrectionIsSupported);
		window.addEventListener('resize', recalculateIfZoomCorrectionEnabled);
		window.addEventListener('resize', recalculateIfZoomCorrectionIsSupported);

		return () => {
			window.removeEventListener('currentscreenchange', recalculateIfZoomCorrectionEnabled);
			window.removeEventListener('currentscreenchange', recalculateIfZoomCorrectionIsSupported);
			window.removeEventListener('resize', recalculateIfZoomCorrectionEnabled);
			window.removeEventListener('resize', recalculateIfZoomCorrectionIsSupported);
		};
	}, [recalculateIfZoomCorrectionEnabled, recalculateIfZoomCorrectionIsSupported]);

	return {
		isZoomCorrectionEnabled: isZoomingDisabled,
		isSwitchDisabled: isOptionDisabled,
		handleClick,
		zoomLevel: window.devicePixelRatio * 100,
	};
};

const ZoomCorrectionContext = React.createContext<UseZoomCorrectionSettingsReturnType | null>(null);

export const ZoomCorrectionProvider: React.FunctionComponent<PropsWithChildren<object>> = ({ children }) => {
	const value = useZoomCorrectionSettingsState();

	return <ZoomCorrectionContext.Provider value={value}>{children}</ZoomCorrectionContext.Provider>;
};

export const useZoomCorrectionSettings = (): UseZoomCorrectionSettingsReturnType => {
	const context = React.useContext(ZoomCorrectionContext);

	if (!context) {
		throw new Error('useZoomCorrectionSettings must be used within a ZoomCorrectionProvider');
	}

	return context;
};
