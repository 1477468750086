import React from 'react';
import classNames from 'classnames';
import { Icon, IconName } from 'components/shared';
import { Link } from 'components/shared/Link';
import styles from './Usp.module.scss';

interface UspProps {
	icon: IconName;
	text: string;
	link?: string;
	isLarge?: boolean;
}

/**
 * Usp component, used for showing USPs from CMS.
 */
export const Usp: React.FunctionComponent<UspProps> = ({ text, link, icon, isLarge }) => {
	return (
		<Link className={classNames(styles.wrapper, { [styles.hasBiggerGap]: isLarge })} linkUrl={link} isTextLink>
			<Icon name={icon} size={isLarge ? 'lg' : 'sm'} />
			{text}
		</Link>
	);
};
