import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { ArrowButton } from 'components/shared/Slider/ArrowButton';
import { useCarouselStore } from '../state/CarouselContext';
import styles from './CarouselNavigation.module.scss';

export interface CarouselNavigationProps {
	arrowsClassName?: string;
}

export const CarouselNavigation: React.FunctionComponent<CarouselNavigationProps> = ({ arrowsClassName }) => {
	const translations = useTranslationQuery().data;
	const {
		slides: { activeSlide, nextSlide, prevSlide, numberOfSlides },
	} = useCarouselStore();
	return (
		<nav className={styles.carouselNavigation} aria-label={translations?.shared.navigation.slider}>
			<ArrowButton
				className={classNames(styles.arrow, arrowsClassName)}
				onClick={prevSlide}
				disabled={activeSlide === 0}
				prev
				variant="dark"
				size="sm"
				iconProps={{
					size: 'md',
				}}
			/>
			<ArrowButton
				className={classNames(styles.arrow, arrowsClassName)}
				onClick={nextSlide}
				disabled={activeSlide === numberOfSlides - 1}
				next
				variant="dark"
				size="sm"
				iconProps={{
					size: 'md',
				}}
			/>
		</nav>
	);
};
