import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/shared';
import Toggle from 'components/shared/Toggle';
import { useZoomCorrectionSettings } from 'components/shared/hooks/useZoomCorrectionSettings';
import styles from './ToggleZoomCorrection.module.scss';

export interface ToggleZoomCorrectionProps {}

const ToggleZoomCorrection: React.FunctionComponent<ToggleZoomCorrectionProps> = () => {
	const { isZoomCorrectionEnabled, handleClick, isSwitchDisabled } = useZoomCorrectionSettings();
	const { data: translations } = useTranslationQuery();
	return (
		<Tooltip placement="top">
			<TooltipTrigger className={classNames(styles.zoomCorrection, { [styles.disabled]: isSwitchDisabled })}>
				<span className={styles.wrapper}>
					{translations?.myProfile.preventDeviceZoom}
					<Toggle
						disabled={isSwitchDisabled}
						value={isZoomCorrectionEnabled}
						onChange={handleClick}
						variant="secondary"
					/>
				</span>
			</TooltipTrigger>
			<TooltipContent className={styles.tooltipText}>
				{translations?.myProfile.preventDeviceZoomTooltip}
			</TooltipContent>
		</Tooltip>
	);
};

export default ToggleZoomCorrection;
