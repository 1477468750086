import React, { FC, useRef } from 'react';
import { messageToApp } from 'helpers';
import { useTranslationQuery } from 'api/translations';
import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';
import styles from './LogOut.module.scss';

export const LogOut: FC = () => {
	const { data: translations } = useTranslationQuery();
	const formRef = useRef<HTMLFormElement>(null);

	const submit = (): void => {
		messageToApp({
			type: 'logout',
		});
	};

	return (
		<form ref={formRef} action={'/api/user/logout'} method="post">
			<Button type="submit" className={styles.button} hasNoStyles onClick={submit}>
				<Icon className="u-mr-sm" name="logout" size="md" />
				<span className={styles.title}>{translations?.auth.signin.logout}</span>
			</Button>
		</form>
	);
};
