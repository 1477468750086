import React from 'react';
import classNames from 'classnames';
import { useViewportSize, formatTranslation } from 'helpers';
import { useLayoutQuery } from 'api/layout';
import { MyProfile } from 'components/features/Header/MyProfile';
import { Button, Drawer, DrawerHeader, Icon, Link, Logo, LogOutButton } from 'components/shared';
import { Area, LayoutResponse, TranslationsWebsite } from 'generated/data-contracts';
import CmsHeaderMenu from '../Cms/CmsHeaderMenu';
import { BrandSelectorMenu } from './BrandSelectorMenu';
import { DesktopMenu } from './DesktopMenu';
import styles from './Header.module.scss';
import { MiniBasket } from './MiniBasket';
import { MiniFavourite } from './MiniFavourite';
import { MobileMenu } from './MobileMenu';
import { QuickSearch } from './Quicksearch';
import { SubBrandSelectorMenu } from './SubBrandSelectorMenu';
import { TopBar } from './TopBar';

interface HeaderProps {
	critical?: boolean;
	layout?: LayoutResponse | null;
	translations?: TranslationsWebsite;
}

const Header: React.FunctionComponent<HeaderProps> = ({ critical, layout, translations }) => {
	const frontPageUrl = layout?.frontPageUrl;
	const user = layout?.user;

	const { data: layoutQuery } = useLayoutQuery();

	const { isMobile } = useViewportSize();
	const [isOpen, setIsOpen] = React.useState(false);
	const layoutSwitchHeader = 'md';
	const megaMenu = layoutQuery?.megaMenu;
	const showSubBrandMenu = !isMobile && !layout?.layoutBrandOptions?.hideBrandDropdownSelection;

	const handleOpen = (): void => {
		setIsOpen(true);
	};

	const handleClose = (): void => {
		setIsOpen(false);
	};

	const route = {
		area: Area.CMS,
	};

	const shouldHideDesktopMenu =
		!isMobile &&
		(layout?.layoutBrandOptions?.hideSearch ||
			layout?.layoutBrandOptions?.hideCategories ||
			!megaMenu ||
			!megaMenu.children.length);

	return (
		<header className={classNames(styles.wrapper, styles.zIndex)}>
			{user?.isImpersonating && (
				<div className={styles.impersonateBar}>
					{formatTranslation(translations?.auth.impersonating, { 0: user.email })}
					<LogOutButton hideIcon className={styles.logoutButton} />
				</div>
			)}

			<TopBar className={`u-hide-${layoutSwitchHeader}-max`} />
			<section
				className={classNames(styles.headerContainer, {
					[styles.hasWhiteBackground]: showSubBrandMenu,
				})}
			>
				<div className={classNames(styles.header, { [styles.isCritical]: critical })}>
					{!critical && isMobile && <MobileMenu />}

					{!isMobile && (
						<>
							<Button
								className={'u-mr-md'}
								title={translations?.brandSelector.chooseBrand}
								variant={'ghost'}
								hasOnlyIcon
								onClick={handleOpen}
							>
								<Icon name={'apps'} size={'sm'} />
							</Button>

							{isOpen && (
								<Drawer {...(layout?.selectedBrandId && { onClose: handleClose })}>
									<DrawerHeader
										className={styles.brandHeader}
										handleClose={handleClose}
										title={translations?.brandSelector.chooseBrand}
									/>
									<BrandSelectorMenu onClose={handleClose} />
								</Drawer>
							)}
						</>
					)}
					<div
						className={classNames(styles.brandWrapper, {
							[styles.fullWidth]: shouldHideDesktopMenu,
						})}
					>
						{layout?.selectedBrandId && (
							<Link to={frontPageUrl || '/'} state={route} hasMinHeight>
								<Logo className={styles.logo} name={layout.selectedBrandId} />
							</Link>
						)}

						{showSubBrandMenu && <SubBrandSelectorMenu />}
					</div>

					{!critical && (
						<>
							{/* css hide class is needed because of server-side */}
							{!isMobile &&
								!layout?.layoutBrandOptions?.hideCategories &&
								megaMenu &&
								megaMenu.children.length > 0 && (
									<DesktopMenu megaMenu={megaMenu} className={`u-hide-${layoutSwitchHeader}-max`} />
								)}

							{!layout?.layoutBrandOptions?.hideSearch && <QuickSearch isMobile={isMobile} />}

							<nav className={styles.userMenu} aria-label={translations?.shared.navigation.user}>
								<MyProfile />
								<MiniFavourite />
								<MiniBasket />
							</nav>
						</>
					)}
				</div>
			</section>
			<CmsHeaderMenu translations={translations} />
		</header>
	);
};

export const MemoizedHeader = React.memo(Header);
