import React, { FC } from 'react';
import { useTranslationQuery } from 'api/translations';
import { useProductCardContext } from '../../context/ProductCardContext';
import BottomSplash from '../BottomSplash';

export const InBasket: FC = () => {
	const { bottomSplashes } = useProductCardContext();
	const { data: translations } = useTranslationQuery();
	if (!bottomSplashes.quantityInBasket) {
		return null;
	}
	return (
		<BottomSplash
			icon="basket"
			text={translations?.productCard.inBasket}
			quantity={bottomSplashes.quantityInBasket}
		/>
	);
};
