import React from 'react';
import classNames from 'classnames';
import { SupportedCssUnits } from 'types/cssUnits';
import { useTranslationQuery } from 'api/translations';
import { Button, Logo } from 'components/shared';
import { BrandResponse, SubBrandResponse } from 'generated/data-contracts';
import styles from './BrandSelectSectionDesktop.module.scss';

export interface BrandSelectSectionDesktopProps {
	brandLogoHeight: SupportedCssUnits;
	brand: BrandResponse;
	contentWrapperClassName?: string;
	onChooseBrand: (brand: BrandResponse | SubBrandResponse) => void;
}

const BrandSelectSectionDesktop: React.FunctionComponent<BrandSelectSectionDesktopProps> = ({
	brandLogoHeight,
	brand,
	contentWrapperClassName,
	onChooseBrand,
}) => {
	const { data: translations } = useTranslationQuery();

	const subBrands = brand?.subBrands || [];
	const showSubBrands = subBrands.length > 0;
	const showMainBrand = brand.active;

	const createBrandButton = (brandResponse: BrandResponse | SubBrandResponse): React.ReactElement => {
		return (
			<Button
				hasNoStyles
				className={styles.brandButton}
				{...(brand && { onClick: (): void => onChooseBrand(brandResponse) })}
			>
				<Logo name={brandResponse.brandId} height={brandLogoHeight} width={'7.5rem'} />
			</Button>
		);
	};

	return (
		<div
			className={classNames({ [styles.contentWrapper]: showMainBrand && showSubBrands }, contentWrapperClassName)}
		>
			{showMainBrand && (
				<div>
					<div className={styles.underline}>{translations?.brandSelector.enterBrand}</div>
					<div>{createBrandButton(brand)}</div>
				</div>
			)}
			{showSubBrands && (
				<div>
					<div className={styles.underline}>{translations?.brandSelector.enterSubBrand}</div>
					<div className={styles.subBrandGrid}>
						{subBrands.map((subBrand) => {
							return <div key={`${subBrand.brandId}`}>{createBrandButton(subBrand)}</div>;
						})}
					</div>
				</div>
			)}
		</div>
	);
};

export default BrandSelectSectionDesktop;
