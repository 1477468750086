import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { useOrderLineContext } from 'components/shared/OrderLine/state/useOrderLineContext';
import { IsDeliveredLabel } from '../../IsDeliveredLabel';
import { OrderDetailCell } from '../../OrderDetailCell';

export interface EstimatedShippingDateProps {}

export const EstimatedShippingDate: React.FunctionComponent<EstimatedShippingDateProps> = () => {
	const { data: translations } = useTranslationQuery();
	const { orderLineDetails } = useOrderLineContext();
	return (
		<OrderDetailCell
			title={translations?.myOrders.orderDetails.estimatedShipping}
			value={orderLineDetails.estimatedShippingDate}
			label={orderLineDetails.isDelivered && <IsDeliveredLabel />}
		/>
	);
};
