import React from 'react';
import classNames from 'classnames';
import { GalleryModal } from 'components/features/ProductDetail/Gallery/GalleryModal';
import { Button } from 'components/shared/Button';
import { CarouselProvider } from 'components/shared/Carousel/state/CarouselContext';
import { LoadingBar } from 'components/shared/LoadingBar';
import { useModal } from 'components/shared/Modal';
import { ProductImage } from 'components/shared/ProductImage';
import { Area, ResourceLocation } from 'generated/data-contracts';
import { useOrderLineContext } from '../../state/useOrderLineContext';
import styles from './OrderLineThumbnail.module.scss';

interface OrderLineThumbnailProps {
	className?: string;
}

export const OrderLineThumbnail: React.FunctionComponent<OrderLineThumbnailProps> = ({ className }) => {
	const { isSkeleton, productInfo } = useOrderLineContext();
	const modal = useModal();
	let colorImages = [
		{
			color: productInfo?.mainColor,
			size100: '',
			size450: '',
			size1800: '',
		},
	];
	if (productInfo?.productImages && productInfo?.productImages.length > 0) {
		colorImages = productInfo?.productImages.map((image) => {
			return { ...image, color: productInfo?.mainColor };
		});
	}

	const route: ResourceLocation = {
		area: Area.Product,
		externalRoute: productInfo?.productUrl || '',
		productFamilyId: productInfo?.familyNumber,
	};

	const handleOpen = React.useCallback((): void => {
		modal.showModal();
	}, [modal]);

	if (isSkeleton) {
		return <LoadingBar width="4rem" height="4rem" className={styles.thumbSkeleton}></LoadingBar>;
	}

	return (
		<CarouselProvider>
			<Button
				hasNoStyles
				className={classNames(className, styles.thumbnailButton, {
					[styles.isColorDot]: !productInfo?.primaryImage?.size450,
				})}
				onClick={handleOpen}
			>
				<ProductImage
					className={classNames(styles.image)}
					desktopSrc={productInfo?.primaryImage?.size450}
					mobileSrc={productInfo?.primaryImage?.size450}
					altText={productInfo?.primaryImage?.altText || ''}
					isLazy
					isSmallImage
					isThumbnail
					colorDotClassName={styles.colorDot}
					colorInfo={productInfo?.mainColor}
					colorDotSize="lg"
				/>
			</Button>
			{colorImages && colorImages.length > 0 && (
				<GalleryModal
					colorImages={colorImages}
					modalTitle={productInfo?.styleName}
					modalProps={modal}
					to={productInfo?.productUrl}
					route={route}
				/>
			)}
		</CarouselProvider>
	);
};
