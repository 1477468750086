import React from 'react';
import classNames from 'classnames';
import { capitalize } from 'helpers/stringHelpers';
import styles from './Badge.module.scss';

interface BadgeProps {
	className?: string;
	innerClassName?: string;
	value?: number | string;
	size?: 'sm' | 'md';
	disableAnimation?: true;
}

/**
 * Generic Badge component, used for showing a number badge on components (e.g. on mini-basket)
 */
export const Badge: React.FunctionComponent<BadgeProps> = ({
	className,
	innerClassName,
	value,
	size,
	disableAnimation,
}) => {
	const [doAnimate, setDoAnimate] = React.useState(false);

	React.useEffect(() => {
		// Only animate when value is larger than 1
		if (disableAnimation || !value || (typeof value === 'number' && value < 2)) {
			return;
		}

		setDoAnimate(true);

		setTimeout(() => {
			setDoAnimate(false);
		}, 500);
	}, [disableAnimation, value]);

	return (
		<span className={classNames(styles.wrapper, className)}>
			<span
				className={classNames(styles.badge, innerClassName, size && styles['size' + capitalize(size)], {
					[styles.hasValue]:
						value && ((typeof value === 'number' && value > 0) || value.toString().length > 0),
					[styles.doAnimate]: doAnimate,
				})}
			>
				{value}
			</span>
		</span>
	);
};
