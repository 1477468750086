import React from 'react';
import { mergeRefs } from 'helpers';
import { useTooltipState } from './helpers/useTooltipState';

export const TooltipTrigger = React.forwardRef<HTMLElement, React.HTMLProps<HTMLElement> & { asChild?: boolean }>(
	function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
		const state = useTooltipState();


		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const childrenRef = (children as any).ref;
		const ref = React.useMemo(
			() => mergeRefs([state.reference, propRef, childrenRef]),
			[state.reference, propRef, childrenRef],
		);

		if (asChild && React.isValidElement(children)) {
			return React.cloneElement(
				children,
				state.getReferenceProps({
					ref,
					...props,
					...children.props,
					'data-state': state.open ? 'open' : 'closed',
				}),
			);
		}

		return (
			<span
				className={props.className}
				ref={ref}
				data-state={state.open ? 'open' : 'closed'}
				{...state.getReferenceProps(props)}
			>
				{children}
			</span>
		);
	},
);
