import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { useOrderLineContext } from 'components/shared/OrderLine/state/useOrderLineContext';
import { OrderDetailCell } from '../../OrderDetailCell';

export interface QuantityProps {
	shortName?: boolean;
}

export const Quantity: React.FunctionComponent<QuantityProps> = ({ shortName = false }) => {
	const { orderLineDetails } = useOrderLineContext();
	const { data: translations } = useTranslationQuery();
	const label = shortName
		? translations?.myOrders.orderDetails?.quantityShortName
		: translations?.myOrders.orderDetails?.quantity;
	return <OrderDetailCell title={label} value={orderLineDetails.quantity} />;
};
