import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { Icon } from 'components/shared';
import { formatTranslation } from 'helpers/stringHelpers';
import styles from './NoBaskets.module.scss';

interface NoBasketsProps {
	isLoading?: boolean;
	noResults?: boolean;
	searchPhrase?: string;
}

export const NoBaskets: React.FC<NoBasketsProps> = ({ isLoading, noResults: NoResults, searchPhrase = '' }) => {
	const { data: translations } = useTranslationQuery();

	let noResultTitle = translations?.openBaskets.noBasketsTitle;
	let noResultDescription = translations?.openBaskets.noBasketsDescription;

	if (NoResults) {
		noResultTitle = formatTranslation(translations?.openBaskets.filters.noResultsFor, {
			0: searchPhrase,
		});
		noResultDescription = translations?.openBaskets.filters.noFiltersForSearch;
	}

	if (isLoading) {
		return (
			<div className={styles.fetchingBaskets}>
				<div className={styles.spinner}>
					<span className="u-spinner" />
				</div>
				<div className={styles.fetchingBasketsInfo}>
					<span className={styles.fetchingBasketsTitle}>
						{translations?.openBaskets.fetchingBasketsTitle}
					</span>
					<span className={styles.fetchingBasketsDescription}>
						{translations?.openBaskets.fetchingBasketsDescription}
					</span>
				</div>
			</div>
		);
	}

	return (
		<div className={styles.noBasketsContent}>
			<div className={styles.noBasketsIconContainer}>
				<Icon name="basket" size="md" />
			</div>
			<div className={styles.noBasketsTextContainer}>
				<span className={styles.noBasketsTitle}>{noResultTitle}</span>
				<span className={styles.noBasketsDescription}>{noResultDescription}</span>
			</div>
		</div>
	);
};
