import React from 'react';
import { BuilderBlocks, BuilderElement } from '@builder.io/react';
import { Slider } from 'components/shared';

type BuilderBlockType = BuilderElement;

// Matches inputs from Carousel in Builder
interface SlideProps {
	content: BuilderBlockType[];
}

interface CarouselProps {
	slides: SlideProps[];
	builderBlock: BuilderBlockType;
	loop: boolean;
	showArrows: boolean;
	showArrowsOnMobile: boolean;
	showDots: boolean;
	autoPlay: boolean;
	autoPlaySpeed?: number;
}

/**
 * CMS Carousel component, used for displaying content in a carousel.
 * It has support for autoplay, and can be used with or without arrows and dots.
 */
export const Carousel: React.FunctionComponent<CarouselProps> = ({
	slides,
	builderBlock,
	loop,
	showArrows,
	showArrowsOnMobile,
	showDots,
	autoPlay,
	autoPlaySpeed,
}) => {
	const setAutoplaySpeed = (): number | undefined => {
		if (!autoPlay) return undefined;

		if (autoPlaySpeed) return autoPlaySpeed;

		return 7000;
	};

	if (!slides) {
		return null;
	}

	return (
		<Slider
			loop={loop}
			showArrows={showArrows}
			showArrowsOnMobile={showArrowsOnMobile}
			paginationType={showDots ? 'dots' : undefined}
			autoPlaySpeed={setAutoplaySpeed()}
		>
			{slides.map((slide, index) => (
				<BuilderBlocks
					key={index}
					parentElementId={builderBlock && builderBlock.id}
					dataPath={`component.options.slides.${index}.content`}
					child
					blocks={slide.content}
				/>
			))}
		</Slider>
	);
};
