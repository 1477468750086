import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { capitalize } from 'helpers';
import { uniqueId } from 'lodash';
import styles from './ConfirmDialog.module.scss';

type DialogSize = 'sm' | 'md' | 'fullscreen';

export enum ConfirmationAnswer {
	Confirmed,
	Rejected,
}

export interface ConfirmDialogProps {
	confirm: () => void;
	reject: () => void;
	labelId: string;
	descriptionId: string;
}

const ConfirmDialog: React.FunctionComponent<
	PropsWithChildren<{
		className?: string;
		size: DialogSize;
		labelId: string;
		descriptionId: string;
		closeDialog: () => void;
	}>
> = ({ children, size, className, labelId, descriptionId, closeDialog }) => {
	const dialogRef = useRef<HTMLDialogElement>(null);
	useEffect(() => {
		dialogRef.current?.showModal();
	}, []);

	return (
		<dialog
			ref={dialogRef}
			className={classNames(className, styles.modal, {
				[styles[`size${capitalize(size)}`]]: size,
			})}
			onClose={(e): void => {
				e.stopPropagation();
				closeDialog();
			}}
			role="alertdialog"
			aria-labelledby={labelId}
			aria-describedby={descriptionId}
		>
			{children}
		</dialog>
	);
};

export type UseConfirmDialogReturnType<P> = {
	dialog: React.ReactElement | null;
	waitForAnswer: (options?: { props: P }) => Promise<ConfirmationAnswer>;
};

type PropsOrNot<Props> = Props extends undefined ? NonNullable<unknown> : Props;

/**
 *
 * React hook to be able to easily render a confirmation dialog.
 * Returns a waitForAnswer function that returns a promise with an answer
 *
 * @param DialogComponent The content you want in the Modal. Has `ConfirmDialogProps` as prop type.
 * @param config optional options for the dialog
 *
 * @returns UseConfirmDialogReturnType
 *
 * @example
 * ```tsx
 *	const confirmDialog = useConfirmDialog(DeleteBasketConfirmDialog, { size: 'sm' });
 *	const handleDelete = (): void => {
 *		// Open confirm dialog
 *		confirmDialog.waitForAnswer().then((answer) => {
 *			if (ConfirmationAnswer.Rejected === answer) return;
 *
 * 			// Delete basket
 *      })
 * }
 * ```
 *
 */
export const useConfirmDialog = <Props extends Record<string, unknown> | undefined = undefined>(
	DialogComponent: React.FunctionComponent<ConfirmDialogProps & PropsOrNot<Props>>,
	config: Partial<{
		className: string;
		size: DialogSize;
	}> &
		(Props extends undefined
			? NonNullable<unknown>
			: {
					extraProps: Props;
			  }),
): UseConfirmDialogReturnType<Props> => {
	const [dialog, setDialog] = useState<React.ReactElement | null>(null);
	const { className, size = 'md' } = config ?? {};
	const labelId = uniqueId('confirm-dialog-label');
	const descriptionId = uniqueId('confirm-dialog-description');
	const hideModal = useCallback(() => {
		setDialog(null);
	}, []);

	const propsNotComingFromHook = {
		extraProps: {},
		...config,
	}.extraProps as PropsOrNot<Props>;

	const waitForAnswer: UseConfirmDialogReturnType<Props>['waitForAnswer'] = (options) =>
		new Promise<ConfirmationAnswer>((res) => {
			const dialogComponent = (
				<ConfirmDialog
					className={className}
					size={size}
					labelId={labelId}
					descriptionId={descriptionId}
					closeDialog={hideModal}
				>
					<DialogComponent
						confirm={(): void => res(ConfirmationAnswer.Confirmed)}
						reject={(): void => res(ConfirmationAnswer.Rejected)}
						labelId={labelId}
						descriptionId={descriptionId}
						{...propsNotComingFromHook}
						{...(options?.props ?? {})}
					/>
				</ConfirmDialog>
			);
			setDialog(dialogComponent);
		}).then((answer) => {
			hideModal();
			return answer;
		});

	return {
		dialog,
		waitForAnswer,
	};
};
