import React from 'react';
import classNames from 'classnames';
import { SpacingSize } from 'types/spacing';
import styles from './VerticalSpacer.module.scss';

interface VerticalSpacerProps {
	desktopSize: SpacingSize | 'unset';
	tabletSize?: SpacingSize | 'unset';
	mobileSize?: SpacingSize | 'unset';
	attributes?: React.HTMLAttributes<HTMLDivElement>;
}

/**
 * CMS VerticalSpacer component, used for creating vertical space between elements via the spacing utility.
 */
export const VerticalSpacer: React.FC<VerticalSpacerProps> = ({ desktopSize, tabletSize, mobileSize, attributes }) => {
	const { className: builderClassNames, ...restOfAttributes } = attributes || {};

	return (
		<div
			className={classNames(
				styles.wrapper,
				{
					[styles.hasTabletSize]: tabletSize !== undefined && tabletSize !== 'unset',
					[styles.hasMobileSize]: mobileSize !== undefined && mobileSize !== 'unset',
				},
				builderClassNames,
			)}
			{...restOfAttributes}
			style={
				{
					'--desktopSize': desktopSize,
					'--tabletSize': tabletSize ?? undefined,
					'--mobileSize': mobileSize ?? undefined,
				} as React.CSSProperties
			}
		/>
	);
};
