import { Builder, withChildren } from '@builder.io/react';
import { Section, containerSizes } from '../Section';

const SectionWithChildren = withChildren(Section);

Builder.registerComponent(SectionWithChildren, {
	name: 'Core:Section',
	override: true,
	inputs: [
		{
			name: 'size',
			type: 'string',
			required: true,
			enum: Array.from(containerSizes),
			defaultValue: 'md',
		},
	],
	canHaveChildren: true,

	defaultChildren: [
		{
			'@type': '@builder.io/sdk:Element',
			responsiveStyles: {
				large: {
					textAlign: 'center',
				},
			},
			component: {
				name: 'Text',
				options: {
					text: `
					<p>
						I am a section! My content keeps from getting too wide, so that it's easy to read even on big screens.
					</p>
					`,
				},
			},
		},
	],
});
