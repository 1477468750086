import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { ColourResponse, ProductImageResponse } from 'generated/data-contracts';
import styles from './ColorDot.module.scss';

export type ColorDotSize = 'md' | 'lg' | 'xl' | 'thumbnail';

export interface ProductImageColor extends ProductImageResponse {
	color?: ColourResponse;
}

interface ColorDotProps {
	className?: string;
	colorInfo: ColourResponse;
	size?: ColorDotSize;
	hasText?: boolean;
}

/**
 * Generic ColorDot component, used to replace placeholder images for galleries or
 * thumbnails if they have an attached color value.
 */

export const ColorDot: React.FunctionComponent<ColorDotProps> = ({ className, colorInfo, size = 'lg', hasText }) => {
	const { data: translations } = useTranslationQuery();

	return (
		<div className={classNames(styles.wrapper, className)}>
			<div
				className={classNames(styles.colorDotCircle, {
					[styles.sizeMd]: size === 'md',
					[styles.sizeLg]: size === 'lg',
					[styles.sizeXl]: size === 'xl',
					[styles.sizeThumbnail]: size === 'thumbnail',
				})}
				style={{ backgroundColor: colorInfo.hexCode }}
			/>
			{hasText && (
				<>
					<span className={styles.colorDotName}>{colorInfo.name}</span>
					<span className={styles.missingImageText}>{translations?.shared.imageComingSoon}</span>
				</>
			)}
		</div>
	);
};
