import React from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { messageToApp, useOnClickAway, useScrollLock } from 'helpers';
import { useBrandGetByPresentationBrandQuery, useSetBrandMutation } from 'api/brand';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import BrandSelectSectionDesktop from 'components/features/BrandSelector/BrandSelectorDesktop/BrandSelectSectionDesktop';
import { Button, Icon } from 'components/shared';
import { BrandResponse, SubBrandResponse } from 'generated/data-contracts';
import { appActions } from 'store/actions/appActions';
import { OverlayTypes } from 'store/types';
import styles from './SubBrandSelectorMenu.module.scss';

export const SubBrandSelectorMenu: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const setScrollLock = useScrollLock();
	const { data: layout } = useLayoutQuery();
	const { data: translations } = useTranslationQuery();

	const dropdownRef = React.useRef<HTMLDivElement>(null);

	const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
	const { data: brand } = useBrandGetByPresentationBrandQuery(
		layout?.segmentations[0]?.presentationBrandId || 0,
		isDropdownOpen,
	);
	const setBrand = useSetBrandMutation();

	const onClose = (): void => {
		setIsDropdownOpen(false);
		setScrollLock(false);
		dispatch(appActions.setShowOverlay({ showOverlay: false }));
	};

	const handleDropdownToggle = (): void => {
		if (isDropdownOpen) {
			onClose();
			return;
		}

		setIsDropdownOpen(true);
		setScrollLock(true);
		dispatch(appActions.setShowOverlay({ showOverlay: OverlayTypes.CONTENT }));
	};
	const [searchParams] = useSearchParams();

	useOnClickAway(dropdownRef, onClose, isDropdownOpen);

	const onChooseBrand = (_brand: BrandResponse | SubBrandResponse): void => {
		const body = {
			presentationBrandId: _brand.presentationBrandId,
			languageId: layout?.languageId,
			returnUrl: searchParams.get('returnUrl'),
		};

		setBrand.mutate(body, {
			onSuccess: (response) => {
				const frontPageRoute = response.data.route.externalRoute || '/';

				messageToApp({ type: 'presentationBrandChanged', presentationBrandId: _brand.presentationBrandId });
				// Navigate with full page reload to re-initialize all the data in the store
				window.location.href = window.location.origin + frontPageRoute;
			},
		});
	};
	return (
		<div ref={dropdownRef}>
			<Button
				variant={'ghost'}
				size={'sm'}
				title={translations?.brandSelector.chooseSubBrand}
				hasOnlyIcon
				onClick={handleDropdownToggle}
				className={styles.subBrandMenuButton}
			>
				<Icon name={'chevronDown'} size={'lg'} {...(isDropdownOpen && { rotate: 180 })} />
			</Button>
			{isDropdownOpen && (
				<div className={styles.fullBleedSection}>
					{brand && (
						<BrandSelectSectionDesktop
							contentWrapperClassName={'u-container-full'}
							brand={brand}
							brandLogoHeight={'7.5rem'}
							onChooseBrand={onChooseBrand}
						/>
					)}
				</div>
			)}
		</div>
	);
};
