import React, { FC } from 'react';
import { useTranslationQuery } from 'api/translations';
import { useOrderLineContext } from 'components/shared/OrderLine/state/useOrderLineContext';
import { OrderDetailCell } from '../../OrderDetailCell';

export const Markup: FC = () => {
	const { orderLineDetails } = useOrderLineContext();
	const { data: translations } = useTranslationQuery();
	return <OrderDetailCell title={translations?.myOrders.orderDetails?.markup} value={orderLineDetails.markup} />;
};
