/* eslint-disable @typescript-eslint/no-explicit-any */
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDebounce, useEventListener } from 'helpers';
import { clamp } from 'lodash';
import styles from './useScrollable.module.scss';

interface IsDisabledProps {
	left: boolean;
	right: boolean;
	up: boolean;
	down: boolean;
}

export type ScrollableTo = {
	left: () => void;
	right: () => void;
	up: () => void;
	down: () => void;
};

interface UseScrollableReturnProps {
	scrollableRefs: {
		wrapper: any;
		container: any;
	};
	scrollableClasses: {
		navigation: string;
		wrapper: string;
	};
	scrollableTo: ScrollableTo;
	scrollableActionsStates: IsDisabledProps;
}

export interface OptionsProps {
	scrollInPx?: number;
	hideHorizontalScroll: boolean;
	navigationClickDelay?: number;
	autoScroll?: boolean;
	autoScrollDelay?: number;
	infiniteScroll?: boolean;
	threshold?: number;
}

export const useScrollable = (passedOptions?: OptionsProps): UseScrollableReturnProps => {
	const defaultOptions: OptionsProps = {
		// FIXME! This has to account for rem
		scrollInPx: 100,
		hideHorizontalScroll: false,
		threshold: 0,
	};

	const options = passedOptions ?? defaultOptions;
	const threshold = options.threshold ?? 0;
	const wrapper = useRef<HTMLElement>();
	const container = useRef<HTMLElement>();

	const [isScrolling, setIsScrolling] = useState(false);
	const [disabledButtons, setDisabledButtons] = useState<IsDisabledProps>({
		left: true,
		right: true,
		up: true,
		down: true,
	});
	const recalculateDisabledState = useCallback(
		() =>
			setDisabledButtons((p) => {
				if (container.current === undefined || wrapper.current === undefined) return p;

				return {
					right:
						Math.ceil(container.current?.clientWidth + wrapper.current?.scrollLeft) >=
						wrapper.current?.scrollWidth - threshold,
					left: wrapper.current?.scrollLeft === 0,

					up: wrapper.current?.scrollTop === 0,
					down:
						Math.ceil(container.current?.clientHeight + wrapper.current?.scrollTop) >=
						wrapper.current?.scrollHeight - threshold,
				};
			}),
		[container, wrapper, threshold],
	);

	useEffect(() => {
		recalculateDisabledState();
	}, [wrapper.current?.clientWidth, recalculateDisabledState]);

	const scrollTo = useCallback(
		(direction: 'top' | 'left' | 'right' | 'down'): void => {
			setIsScrolling(true);

			const pixels = options.scrollInPx ?? (defaultOptions.scrollInPx as number);
			// TODO: have a possibility to scroll to the next element, instead of always scrolling by pixels

			if (direction === 'right')
				wrapper.current?.scrollTo({
					left: clamp(wrapper.current.scrollLeft + pixels, 0, wrapper.current.scrollWidth),
				});
			if (direction === 'left')
				wrapper.current?.scrollTo({
					left: clamp(wrapper.current.scrollLeft + -pixels, 0, wrapper.current.scrollWidth),
				});
			if (direction === 'down')
				wrapper.current?.scrollTo({
					top: clamp(wrapper.current.scrollTop + pixels, 0, wrapper.current.scrollHeight),
				});
			if (direction === 'top')
				wrapper.current?.scrollTo({
					top: clamp(wrapper.current.scrollTop + -pixels, 0, wrapper.current.scrollHeight),
				});
		},
		[defaultOptions.scrollInPx, options.scrollInPx],
	);

	// START NAV TIMEOUT
	// This is to ensure that clicking the nav button many times doesn't accidentally trigger any action in the slider when spam clicking.
	const NAV_CLICK_TIMEOUT = passedOptions?.navigationClickDelay ?? 1000;

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const setScrollingFalse = useCallback(
		useDebounce(() => setIsScrolling(false), NAV_CLICK_TIMEOUT),
		[setIsScrolling],
	);
	// END NAV TIMEOUT

	// START INFINITE SCROLL
	const clonedChildren = useRef<boolean>(false);
	useEffect(() => {
		if (options.infiniteScroll && wrapper.current?.children && clonedChildren.current === false) {
			Array.from(wrapper.current.children).forEach((child) => {
				const clonedChild = child.cloneNode(true) as Element;
				clonedChild.setAttribute('aria-hidden', 'true');
				clonedChild.setAttribute('duplicated', 'true');
				clonedChild.setAttribute('key', `cloned-${child.getAttribute('key')}`);
				wrapper.current?.appendChild(clonedChild);
			});
			wrapper.current.scrollTo({ left: wrapper.current.scrollWidth / 2, behavior: 'instant' });
			clonedChildren.current = true;
		}
		if (!options.infiniteScroll && clonedChildren.current === true && wrapper.current?.children) {
			Array.from(wrapper.current.children).forEach((child) => {
				if (child.getAttribute('duplicated') === 'true') {
					wrapper.current?.removeChild(child);
				}
			});
			clonedChildren.current = false;
		}
	}, [options.infiniteScroll]);

	const calculateInfiniteScroll = useCallback(() => {
		if (options.infiniteScroll && wrapper.current) {
			const midpointOfArray = wrapper.current.scrollWidth / 2;
			const containerCurrentWidth = container.current?.clientWidth ?? 0;

			if (wrapper.current?.scrollLeft === 0) {
				// If we reach the start of the list, instantly scroll to the middle of the list, so that there is still content to the left.
				wrapper.current.scrollTo({ left: midpointOfArray, behavior: 'instant' });
			}

			if (wrapper.current?.scrollLeft === wrapper.current?.scrollWidth - containerCurrentWidth) {
				// If we reach the end of the list, instantly scroll to the middle of the list, so that there is still content to the right.
				wrapper.current.scrollTo({
					left: midpointOfArray - containerCurrentWidth,
					behavior: 'instant',
				});
			}
		}
	}, [options.infiniteScroll]);
	// END INFINITE SCROLL

	// START AUTO SCROLL
	const AUTO_SCROLL_DELAY = options.autoScrollDelay ?? 5000;

	useEffect(() => {
		if (options.autoScroll) {
			const interval = setInterval(() => {
				scrollTo('right');
			}, AUTO_SCROLL_DELAY);
			return () => clearInterval(interval);
		}
		return;
	}, [AUTO_SCROLL_DELAY, options.autoScroll, scrollTo]);
	// END AUTO SCROLL

	const scrollEventListener = useCallback(() => {
		setScrollingFalse();
		calculateInfiniteScroll();
		recalculateDisabledState();
	}, [calculateInfiniteScroll, recalculateDisabledState, setScrollingFalse]);

	useEventListener('scroll', scrollEventListener, wrapper as RefObject<HTMLElement>);
	useEventListener('resize', recalculateDisabledState);

	const scrollableRefs = {
		wrapper,
		container,
	};

	const scrollableClasses = {
		navigation: classNames({ [styles.blockTouchEvents]: isScrolling }),
		wrapper: styles.wrapper,
	};

	const scrollableTo = {
		left: (): void => {
			scrollTo('left');
		},
		right: (): void => {
			scrollTo('right');
		},
		up: (): void => {
			scrollTo('top');
		},
		down: (): void => {
			scrollTo('down');
		},
	};

	return {
		scrollableRefs,
		scrollableClasses,
		scrollableTo,
		scrollableActionsStates: disabledButtons,
	};
};
