import React from 'react';

export const containerSizes = ['xs', 'sm', 'md', 'lg'] as const;

export type ContainerSize = (typeof containerSizes)[number];

interface SectionProps {
	size?: ContainerSize;
	children?: React.ReactNode;
}

/**
 * CMS Section component that overrides Builder's core section,
 * used for setting the width of the content via the container utility.
 */
export const Section: React.FC<SectionProps> = ({ size, children }) => (
	<div className={`u-container-${size}`}>{children}</div>
);
