/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { InvoiceNumber, ProblemDetails, ShipToId } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Export<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Export
   * @name ExportExportList
   * @summary Exports a pdf of the invoice
   * @request GET:/api/export/export
   * @secure
   */
  exportExportList = (
    query: {
      invoiceNumber: InvoiceNumber;
      shipToId: ShipToId;
    },
    params: RequestParams = {},
  ) =>
    this.request<File, ProblemDetails | void>({
      path: `/api/export/export`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
