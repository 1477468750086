import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/shared';
import Toggle from 'components/shared/Toggle';
import { useHideWhsPriceSettings } from 'components/shared/hooks/useHideWhsPriceSettings';
import styles from './ToggleHideWHSPrice.module.scss';

export interface ToggleHideWHSPriceProps {}

const ToggleHideWHSPrice: React.FunctionComponent<ToggleHideWHSPriceProps> = () => {
	const { hideWhsPrice, handleClick } = useHideWhsPriceSettings();
	const { data: translations } = useTranslationQuery();
	return (
		<Tooltip placement="top">
			<TooltipTrigger className={classNames(styles.hideWhsPrice)}>
				<span className={styles.wrapper}>
					{translations?.myProfile.hideWhsPrice}
					<Toggle value={hideWhsPrice} onChange={handleClick} variant="secondary" />
				</span>
			</TooltipTrigger>
			<TooltipContent className={styles.tooltipText}>
				{translations?.myProfile.hideWhsPriceTooltip}
			</TooltipContent>
		</Tooltip>
	);
};

export default ToggleHideWHSPrice;
