import React from 'react';
import classNames from 'classnames';
import { Button, ButtonProps } from 'components/shared/Button';
import { Icon, IconProps } from 'components/shared/Icon';
import styles from './IconLink.module.scss';

interface IconLinkProps extends Partial<ButtonProps>, React.AriaAttributes {
	className?: string;
	icon: IconProps['name'];
	title: string;
}

/**
 * Generic IconLink component, a link with a combination of an icon and text label. Used e.g. in header
 */
export const IconLink: React.FunctionComponent<IconLinkProps> = ({
	className,
	children,
	icon,
	title,
	href,
	route,
	to,
	onClick,
	...props // For passing props inherited from React.AriaAttributes
}) => {
	return (
		<Button
			hasNoStyles
			className={classNames(styles.link, className)}
			title={title}
			to={to}
			route={route}
			href={href}
			onClick={onClick}
			{...props}
		>
			<Icon name={icon} size={'md'} />
			{children}
		</Button>
	);
};
