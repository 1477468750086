import { Builder } from '@builder.io/react';
import { buttonSizes, buttonVariants } from 'components/shared';
import { Button } from '../Button';

Builder.registerComponent(Button, {
	name: 'Core:Button',
	override: true,
	inputs: [
		{
			name: 'text',
			type: 'string',
			required: true,
			defaultValue: 'Click me!',
			bubble: true,
		},
		{
			name: 'link',
			type: 'url',
			bubble: true,
		},
		{
			name: 'openInNewTab',
			type: 'boolean',
			defaultValue: false,
			friendlyName: 'Open link in new tab',
		},
		{
			name: 'variant',
			type: 'string',
			enum: Array.from(buttonVariants),
			defaultValue: 'primary',
		},
		{
			name: 'size',
			type: 'string',
			enum: Array.from(buttonSizes),
			defaultValue: 'md',
		},
	],
});
