import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { Icon } from 'components/shared/Icon';
import { useOrderLineContext } from '../../state/useOrderLineContext';
import styles from './IsDeliveredLabel.module.scss';

export interface IsDeliveredLabelProps {}

export const IsDeliveredLabel: React.FunctionComponent<IsDeliveredLabelProps> = () => {
	const { orderLineDetails, size } = useOrderLineContext();
	const { data: translations } = useTranslationQuery();
	if (!orderLineDetails?.isDelivered) {
		if (size === 'lg') {
			return <div aria-hidden="true" />;
		}
		return null;
	}
	return (
		<span className={styles.delivered}>
			{translations?.myOrders.orderDetails.delivered}
			<Icon name="checkCircle" size={'xs'} className={styles.icon} />
		</span>
	);
};
