/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PageTrackingDataResponse, ProblemDetails, ProductTrackingDataResponse } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Tracking<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Tracking
   * @name TrackingGlobalpagedataList
   * @summary Get global page data for tracking
   * @request GET:/api/tracking/globalpagedata
   * @secure
   */
  trackingGlobalpagedataList = (params: RequestParams = {}) =>
    this.request<PageTrackingDataResponse, ProblemDetails | void>({
      path: `/api/tracking/globalpagedata`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Tracking
   * @name TrackingProducttrackingdataList
   * @summary Get tracking data for a product by style option number
   * @request GET:/api/tracking/producttrackingdata
   * @secure
   */
  trackingProducttrackingdataList = (
    query: {
      styleOptionNumber: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<ProductTrackingDataResponse, ProblemDetails | void>({
      path: `/api/tracking/producttrackingdata`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
