import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UseInfiniteQueryResult, UseQueryResult, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { queryKeys, setHeaders } from 'api/apiConfig';
import { AlertTypes } from 'components/shared';
import { Product as ProductApi } from 'generated/Product';
import {
	NoPresentationBrandResult,
	ProductCategoryId,
	ProductFilter,
	ProductListResponse,
	ProductResponse,
	ProductSort,
	SortDirection,
} from 'generated/data-contracts';
import { isScannerApp } from 'helpers/app';
import { formatTranslation } from 'helpers/stringHelpers';
import { appActions } from 'store/actions/appActions';
import { InitialState } from 'store/types';
import { RelewiseProductRecommendationQuery, getRelewiseProductRecommendationsQuery } from './recommendations';
import { useTranslationQuery } from './translations';

export interface ProductBundleQuery {
	skip?: number;
	take?: number;
	sortBy?: ProductSort;
	sortDirection?: SortDirection;
	phrase?: string;
	minPrice?: number;
	maxPrice?: number;

	productCategoryId?: string;
	preDefinedProductCategoryIds?: string[];
	customerGroupIds?: string[];
	productLineIds?: string[];
	collectionIds?: string[];
	productFilters?: ProductFilter[];
	excludedFilters?: ProductFilter[];
	preDefinedProductFilters?: ProductFilter[];
	productCatalogues?: string[];
	productSubcatalogues?: string[];
}

export const defaultProductBundlePageSize = 36;

export const useProductDetailQuery = (id: string, host?: string, cookies?: string): UseQueryResult<ProductResponse> => {
	const { data: translations } = useTranslationQuery();
	const dispatch = useDispatch();

	return useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: queryKeys.product.fetch(id).queryKey,
		queryFn: async (): Promise<ProductResponse> => {
			const productListApi = new ProductApi({
				baseUrl: host,
				baseApiParams: { headers: setHeaders(cookies) || undefined },
			});
			const response = await productListApi.productDetail(id);

			if (!response.ok) {
				dispatch(
					appActions.addNotification({
						children: formatTranslation(translations?.shared.layoutErrorMsg, {}),
						type: AlertTypes.DANGER,
					}),
				);
			}

			return response.data;
		},
		refetchOnWindowFocus: isScannerApp ? 'always' : true,
	});
};

export const getProductBundleQuery = async (
	query: ProductBundleQuery,
	page: number,
	resetPage?: boolean,
): Promise<ProductListResponse> => {
	const productListApi = new ProductApi({
		baseApiParams: { headers: setHeaders() },
	});

	const skipAmount = resetPage ? 0 : (page - 1) * (query.take || defaultProductBundlePageSize);

	const response = await productListApi.productSearchqueryCreate({
		skip: skipAmount,
		take: query.take || defaultProductBundlePageSize,
		...query,
		sortBy: query.sortBy || ProductSort.Relevance,
		sortDirection: query.sortDirection || SortDirection.Descending,
	});
	return response.data;
};

export const parseBundleQueryParameters = (
	searchParams: URLSearchParams,
	preDefinedFilters?: ProductFilter[],
	preDefinedProductCategoryIds?: ProductCategoryId[],
): ProductBundleQuery => {
	const filters = [] as ProductFilter[];
	const predefinedKeys = [
		'productLineIds',
		'collectionIds',
		'productCategoryId',
		'phrase',
		'page',
		'pageSize',
		'sortBy',
		'sortDirection',
		'minPrice',
		'maxPrice',
		'productCatalogues',
		'productSubcatalogues',
	];
	searchParams.forEach((value, key) => {
		if (predefinedKeys.some((k) => k === key)) return;
		filters.push({
			attributeKey: key,
			attributeValues: value.split(','),
		});
	});

	const returnedBundleQueryParams = {
		productLineIds: searchParams.get('productLineIds')?.split(','),
		collectionIds: searchParams.get('collectionIds')?.split(','),
		productCategoryId: (searchParams.get('productCategoryId') as ProductCategoryId) || undefined,
		phrase: searchParams.get('phrase') || undefined,
		sortBy: (searchParams.get('sortBy') as ProductSort) || undefined,
		sortDirection: (searchParams.get('sortDirection') as SortDirection) || undefined,
		productFilters: filters,
		preDefinedProductFilters: preDefinedFilters,
		preDefinedProductCategoryIds: preDefinedProductCategoryIds,
		productCatalogues: searchParams.get('productCatalogues')?.split(','),
		productSubcatalogues: searchParams.get('productSubcatalogues')?.split(','),
		minPrice: Number(searchParams.get('minPrice')) || undefined,
		maxPrice: Number(searchParams.get('maxPrice')) || undefined,
	};

	Object.keys(returnedBundleQueryParams).forEach((key) => {
		if (returnedBundleQueryParams[key] === undefined) {
			// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
			delete returnedBundleQueryParams[key];
		}
	});

	return returnedBundleQueryParams;
};

const isProductBundleQuery = (
	query: ProductBundleQuery | RelewiseProductRecommendationQuery,
): query is ProductBundleQuery => (query as RelewiseProductRecommendationQuery).type === undefined;

export const useProductBundleQuery = (
	query: ProductBundleQuery | RelewiseProductRecommendationQuery,
): UseInfiniteQueryResult<ProductListResponse, void | NoPresentationBrandResult> => {
	const [currentPage, setCurrentPage] = useState(1);
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const [oldQuery, setOldQuery] = useState<ProductBundleQuery>(query as ProductBundleQuery);

	// MergedQuery is a combination of the query from the CMS and the url params (if there's only 1 cms block)

	const queryKey = useMemo(
		() =>
			isProductBundleQuery(query)
				? queryKeys.product.bundleQuery(segmentationId, query).queryKey
				: queryKeys.recommendations.relewise(segmentationId, query as RelewiseProductRecommendationQuery)
						.queryKey,
		[segmentationId, query],
	);

	return useInfiniteQuery({
		// currentPage should not be part of the key because react-query controls it internally
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey,
		queryFn: ({ pageParam = currentPage || 1 }) => {
			if (isProductBundleQuery(query)) {
				const resetPage = !isEqual(query, oldQuery);

				return getProductBundleQuery(query as ProductBundleQuery, pageParam, resetPage);
			} else {
				return getRelewiseProductRecommendationsQuery(query as RelewiseProductRecommendationQuery);
			}
		},
		getNextPageParam: (lastPage) => {
			let pageSize = defaultProductBundlePageSize;
			if (isProductBundleQuery(query) && query.take) {
				pageSize = query.take;
			}
			const numberOfPages = Math.ceil((lastPage.pagingInformation.totalNumberOfItems || 1) / pageSize);

			const page = lastPage.pagingInformation.currentPage + 1;
			const hasMorePages = page <= numberOfPages;
			return hasMorePages ? page : undefined;
		},
		getPreviousPageParam: (firstPage) => {
			const page = firstPage.pagingInformation.currentPage;
			return page > 1 ? page - 1 : undefined;
		},
		onSuccess: (data) => {
			const index = currentPage === data.pages.at(-1)?.pagingInformation?.currentPage ? 0 : -1;
			const nextPage = data.pages.at(index)?.pagingInformation?.currentPage || 1;
			setCurrentPage(nextPage);
			if (isProductBundleQuery(query)) {
				setOldQuery(query as ProductBundleQuery);
			}
		},
		retry: false,
		refetchOnWindowFocus: isScannerApp ? 'always' : true,
		keepPreviousData: true,
	});
};
