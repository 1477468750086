export const spacingSizes = [
	{ label: '2xs', value: 'var(--spacing-4)' },
	{ label: 'xs', value: 'var(--spacing-8)' },
	{ label: 'sm', value: 'var(--spacing-12)' },
	{ label: 'md', value: 'var(--spacing-16)' },
	{ label: 'lg', value: 'var(--spacing-24' },
	{ label: 'xl', value: 'var(--spacing-48)' },
	{ label: '2xl', value: 'var(--spacing-64)' },
] as const;

export type SpacingSize = (typeof spacingSizes)[number]['value'];
