import React, { FC } from 'react';
import { useTranslationQuery } from 'api/translations';
import { useOrderLineContext } from 'components/shared/OrderLine/state/useOrderLineContext';
import { OrderDetailCell } from '../../OrderDetailCell';

export interface WholesalePriceProps {
	hasWspLabel?: boolean;
}

export const WholesalePriceNet: FC<WholesalePriceProps> = ({ hasWspLabel = false }) => {
	const { orderLineDetails, size } = useOrderLineContext();
	const { data: translations } = useTranslationQuery();

	const wholesalePriceLabel =
		size === 'sm'
			? `${translations?.myOrders.orderDetails?.wholesalePrice} (${translations?.myOrders.orderDetails?.net})`
			: translations?.myOrders.orderDetails?.net;
	const label = hasWspLabel ? wholesalePriceLabel : translations?.myOrders.orderDetails?.price;

	return <OrderDetailCell title={label} value={orderLineDetails.wholesaleNetPrice} />;
};
