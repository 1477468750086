import { Builder, withChildren } from '@builder.io/react';
import { Modal } from '.';
import { buttonSizes, buttonVariants, modalSizes } from 'components/shared';

const ModalWithChildren = withChildren(Modal);

Builder.registerComponent(ModalWithChildren, {
	name: 'Modal',
	image: new URL('/dist/images/cms/modal.svg', window.location.origin).href,
	noWrap: true,
	inputs: [
		{
			type: 'string',
			name: 'modalSize',
			required: true,
			enum: Array.from(modalSizes),
			defaultValue: 'md',
		},
		{
			type: 'string',
			name: 'buttonText',
			required: true,
			defaultValue: 'Launch modal!',
		},
		{
			type: 'string',
			name: 'buttonVariant',
			required: true,
			enum: Array.from(buttonVariants),
			defaultValue: 'primary',
		},
		{
			type: 'string',
			name: 'buttonSize',
			required: true,
			enum: Array.from(buttonSizes),
			defaultValue: 'md',
		},
	],

	defaultChildren: [
		{
			'@type': '@builder.io/sdk:Element',
			component: {
				name: 'Core:Section',
				options: {
					size: 'md',
				},
			},
			responsiveStyles: {
				large: {
					paddingTop: '24px',
					paddingBottom: '24px',
				},
			},
			children: [
				{
					'@type': '@builder.io/sdk:Element',
					component: {
						name: 'Text',
						options: {
							text: `
							<h2>Example modal content</h2>
							<br>
							<p>
								<strong>
									<em>You can edit this content or even delete it and insert your own instead.</em>
								</strong>
							</p>
							<br>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus aliquam euismod nisl,
								quis interdum turpis. Proin lacinia erat vitae nunc viverra pretium. Proin pharetra ultrices
								purus, ac luctus metus vehicula non. Nam eget quam nisl. Etiam efficitur rutrum nisi, nec
								tincidunt orci dignissim ut. Curabitur purus est, euismod eget maximus sed, tincidunt ut
								lacus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
								curae; Fusce congue viverra eros, vel molestie elit posuere eget. Phasellus ut ultrices dui.
								In aliquet metus nec tortor fermentum, eu bibendum lorem dignissim. Pellentesque convallis
								ornare dui ut venenatis. Integer cursus ornare erat sit amet ornare.
							</p>
							`,
						},
					},
				},
				{
					'@type': '@builder.io/sdk:Element',
					component: {
						name: 'Core:Button',
						options: {
							text: 'Click me!',
							openInNewTab: false,
							variant: 'primary',
							size: 'md',
						},
					},
					responsiveStyles: {
						large: {
							/**
							 * This is hardcoded to spacingLg in spacing.scss. Once
							 * CSS variables are introduced, this should point to
							 * var(--spacingLg) instead.
							 */
							marginTop: '24px',
						},
					},
				},
			],
		},
	],
});
