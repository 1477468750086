import React, { FC } from 'react';
import classNames from 'classnames';
import { SupportedCssUnits } from 'types/cssUnits';
import { Button, Logo } from 'components/shared';
import { BrandResponse } from 'generated/data-contracts';
import globalStyles from '../BrandSelector.module.scss';
import styles from './BrandGroup.module.scss';

export interface BrandGroupProps {
	animationsProps?: AnimationProps;
	brandGroup: BrandResponse[];
	brandGroupId: string;
	columnSize: number;
	brandGroupButtonClassname?: string;
	logoSize: SupportedCssUnits;
	logoHeight?: SupportedCssUnits;
	children?: React.ReactNode;
	onBrandClick: (brandGroupId: string, brand: BrandResponse) => void;
}

export interface AnimationProps {
	isAnimating: boolean;
	closingBrand?: BrandResponse;
	closingBrandGroupId?: string;
	selectedBrand?: BrandResponse;
	selectedBrandGroupId?: string;
	getHeight: (brandGroup: BrandResponse[]) => string;
	onAnimationEnd: (brandGroupId: string) => void;
	onAnimationStart: () => void;
}

const BrandGroup: FC<BrandGroupProps> = ({
	animationsProps,
	brandGroup,
	brandGroupButtonClassname,
	brandGroupId,
	columnSize,
	logoSize,
	logoHeight = '6rem',
	children,
	onBrandClick,
}) => {
	const getAnimationStyles = (): string => {
		if (animationsProps?.selectedBrandGroupId === brandGroupId) {
			return styles.openAnimation;
		}

		if (animationsProps?.closingBrandGroupId === brandGroupId) {
			return styles.closeAnimation;
		}

		return '';
	};

	const getLogoBrandId = (brand: BrandResponse): string => {
		if (!brand.active && brand.subBrands.length === 1) {
			return brand.subBrands[0].brandId;
		} else {
			return brand.brandId;
		}
	};

	const shouldShowChildren = (): boolean => {
		if (children && animationsProps) {
			return (
				(animationsProps.selectedBrandGroupId === brandGroupId ||
					animationsProps.closingBrandGroupId === brandGroupId) &&
				(animationsProps.selectedBrand !== undefined || animationsProps.closingBrand !== undefined)
			);
		}

		return false;
	};

	return (
		<div
			className={classNames(styles.brandGroupWrapper, { [getAnimationStyles()]: animationsProps })}
			{...(animationsProps && {
				onAnimationStart: animationsProps.onAnimationStart,
				onAnimationEnd: (): void => animationsProps.onAnimationEnd(brandGroupId),
			})}
			style={
				{
					'--gridColumnSize': `${columnSize}`,
					...(animationsProps && { '--contentHeight': animationsProps.getHeight(brandGroup) }),
				} as React.CSSProperties
			}
		>
			{brandGroup.map((brand) => {
				const isFadedOut = animationsProps
					? (animationsProps.selectedBrand !== undefined || animationsProps.closingBrand !== undefined) &&
					  animationsProps.selectedBrand !== brand &&
					  animationsProps.closingBrand !== brand
					: false;

				const isSelected = animationsProps?.selectedBrand === brand || animationsProps?.closingBrand === brand;

				return (
					<div
						key={`${brand.brandId}`}
						className={classNames(globalStyles.brandSectionWrapper, {
							[styles.isSemiTransparent]: !isSelected && !!animationsProps?.selectedBrand,
						})}
					>
						<Button
							hasNoStyles
							className={classNames(globalStyles.brandButton, brandGroupButtonClassname, {
								[styles.isSelected]: isSelected,
								[styles.fadeOut]: isFadedOut,
							})}
							disabled={isFadedOut && animationsProps?.isAnimating}
							onClick={(): void => onBrandClick(brandGroupId, brand)}
						>
							<Logo name={getLogoBrandId(brand)} width={logoSize} height={logoHeight} />
						</Button>
					</div>
				);
			})}
			{shouldShowChildren() && <div className={styles.brandSelectSectionWrapper}>{children}</div>}
		</div>
	);
};

export default BrandGroup;
