import React from 'react';
import { BuilderBlocks, BuilderElement } from '@builder.io/react';
import { TabPanel, Tabs as DSTabs } from 'components/shared';

type BuilderBlockType = BuilderElement;

export interface TabProps {
	label: string;
	content: BuilderBlockType[];
}

export interface TabsProps {
	tabs?: TabProps[];
	builderBlock: BuilderBlockType;
}

/**
 * CMS Tabs component, used for showing CMS content in tabs
 */
export const Tabs: React.FC<TabsProps> = ({ tabs, builderBlock }) => {
	if (!tabs) return null;

	return (
		<DSTabs tabList={tabs.map((tab) => tab.label)}>
			{tabs.map((tab, index) => (
				<TabPanel name={tab.label} key={tab.label}>
					<BuilderBlocks
						key={index}
						parentElementId={builderBlock && builderBlock.id}
						dataPath={`component.options.tabs.${index}.content`}
						child
						blocks={tab.content}
					/>
				</TabPanel>
			))}
		</DSTabs>
	);
};
