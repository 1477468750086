import React from 'react';
import Autoplay, { AutoplayOptionsType } from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import { SliderFrameProps } from './SliderFrame';
import { SliderMarkup } from './SliderMarkup';

interface SliderFrameWithAutoplayProps extends SliderFrameProps {
	autoplayOptions?: AutoplayOptionsType;
}

export const SliderFrameWithAutoplay: React.FunctionComponent<SliderFrameWithAutoplayProps> = ({
	sliderOptions,
	autoplayOptions,
	...props // For passing SharedSliderProps
}) => {
	const autoplay = React.useRef(Autoplay(autoplayOptions));

	const [emblaRef, emblaApi] = useEmblaCarousel(sliderOptions, [autoplay.current]);

	return <SliderMarkup emblaApi={emblaApi} emblaRef={emblaRef} autoplay={autoplay} {...props} />;
};
