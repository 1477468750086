/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { BasketDetailsRequest, BasketErrorResult, PDFDownloadWithSummaryResponseWrapper } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class BasketExport<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags BasketExport
   * @name BasketexportExportaspdfCreate
   * @summary List products in basket to generate a PDF
   * @request POST:/api/basketexport/exportaspdf
   * @secure
   */
  basketexportExportaspdfCreate = (data: BasketDetailsRequest, params: RequestParams = {}) =>
    this.request<PDFDownloadWithSummaryResponseWrapper, BasketErrorResult | void>({
      path: `/api/basketexport/exportaspdf`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BasketExport
   * @name BasketexportExportasexcelCreate
   * @summary Exports an excel file containing all of the variants or bundles in current basket, with all details
   * @request POST:/api/basketexport/exportasexcel
   * @secure
   */
  basketexportExportasexcelCreate = (data: BasketDetailsRequest, params: RequestParams = {}) =>
    this.request<File, BasketErrorResult | void>({
      path: `/api/basketexport/exportasexcel`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
 * No description
 *
 * @tags BasketExport
 * @name BasketexportExportasmediacontentCreate
 * @summary Exports a zip file containing an excel file with all of the variants or bundles in current basket along with the style option images
The exported excel, is the same with 'ExportAsExcel'
 * @request POST:/api/basketexport/exportasmediacontent
 * @secure
 */
  basketexportExportasmediacontentCreate = (data: BasketDetailsRequest, params: RequestParams = {}) =>
    this.request<File, BasketErrorResult | void>({
      path: `/api/basketexport/exportasmediacontent`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
