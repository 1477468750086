import React, { FC } from 'react';
import { useBasketQuery } from 'api/basket';
import { useTranslationQuery } from 'api/translations';
import { Icon } from 'components/shared';
import { CreateOrderResponse } from 'generated/data-contracts';
import styles from './OrderSummary.module.scss';

interface OrderSummaryProps {
	showTitle?: boolean;
	showCompletedBadge?: boolean;
	orderReference?: CreateOrderResponse;
}
export const OrderSummary: FC<OrderSummaryProps> = ({
	showTitle = false,
	showCompletedBadge = false,
	orderReference,
}) => {
	const { data: translations } = useTranslationQuery();
	const { data: basket } = useBasketQuery();
	const shipTos = basket?.basketContents.shipTos;

	const extraShipTosLabel = shipTos && shipTos.length > 1 ? `+${shipTos.length - 1}` : '';

	return (
		<>
			{showTitle && <span className={styles.orderSummaryTitle}>{translations?.basket.orderSummary}</span>}
			<ul className={styles.orderConfirmationOverview}>
				{showCompletedBadge && (
					<li>
						<span className={styles.completedBadge}>
							<Icon size={'sm'} name="checkMark" />
							<span>{translations?.basket.complete}</span>
						</span>
					</li>
				)}
				<li className={styles.orderConfirmationOverviewItem}>
					<span>{`${translations?.basket.customerRef}:`}</span>
					<span className={styles.orderConfirmationOverviewValue}>
						{orderReference ? orderReference.customerOrderRef : basket?.name}
					</span>
				</li>

				{shipTos && (
					<li className={styles.orderConfirmationOverviewItem}>
						<span>{`${translations?.basket.accountName}:`}</span>
						<span className={styles.orderConfirmationOverviewValue}>
							{shipTos[0].name} {extraShipTosLabel}
						</span>
					</li>
				)}
				<li className={styles.orderConfirmationOverviewItem}>
					<span>{`${translations?.basket.basketId}:`}</span>
					<span>{orderReference ? orderReference.placedBasketId : basket?.id}</span>
				</li>
				<li className="u-py-xs">
					<hr />
				</li>
				<li className={styles.orderConfirmationOverviewItem}>
					<span>{`${translations?.basket.totalQuantity}:`}</span>
					<span>{orderReference ? orderReference.totalQuantity : basket?.miniBasketQuantity || 0}</span>
				</li>
				<li className={styles.orderConfirmationOverviewItem}>
					<span>{`${translations?.basket.totalValue}:`}</span>
					<span className={styles.orderConfirmationOverviewValue}>
						{orderReference ? orderReference.totalPrice : basket?.totalPrice}
					</span>
				</li>
			</ul>
		</>
	);
};
