import React from 'react';
import useEmblaCarousel, { EmblaOptionsType } from 'embla-carousel-react';
import { SharedSliderProps, SliderMarkup } from './SliderMarkup';

export interface SliderFrameProps extends SharedSliderProps {
	sliderOptions?: EmblaOptionsType;
}

export const SliderFrame: React.FunctionComponent<SliderFrameProps> = ({
	sliderOptions,
	...props // For passing SharedSliderProps
}) => {
	const [emblaRef, emblaApi] = useEmblaCarousel(sliderOptions, []);

	return <SliderMarkup emblaApi={emblaApi} emblaRef={emblaRef} {...props} />;
};
