/**
 * Checks whether param 1 element is the ancestor of param 2 element
 *
 * @param container
 * @param child
 */
export const isAncestorOf = (container: Element, child: Element): boolean => {
	let currentElement = child.parentElement;

	while (currentElement) {
		if (currentElement === container) {
			return true;
		}

		currentElement = currentElement.parentElement;
	}

	return false;
};
