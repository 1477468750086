import { createAction } from '@reduxjs/toolkit';
import { BasketDetailsResponse, ProductBasketRequest, ProductBasketResponse } from 'generated/data-contracts';
import { BasketTypes } from 'store/types';

const updateBasketContent = createAction<BasketDetailsResponse>(BasketTypes.BASKET_UPDATE_CONTENT);
const updateBasketLine = createAction<BasketDetailsResponse>(BasketTypes.BASKET_UPDATE_BASKET_LINE);
const updatePDPBasket = createAction<ProductBasketResponse>(BasketTypes.BASKET_UPDATE_PDP);
const updatePreBasket = createAction<ProductBasketRequest>(BasketTypes.PRE_BASKET_UPDATE);
const updateMiniQuantity = createAction<number>(BasketTypes.BASKET_UPDATE_MINI_QUANTITY);
const clearBasketContent = createAction<void>(BasketTypes.BASKET_CLEAR_CONTENT);

export const basketActions = {
	updateBasketContent,
	updateBasketLine,
	updatePDPBasket,
	updatePreBasket,
	updateMiniQuantity,
	clearBasketContent,
};
